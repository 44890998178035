import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles({
    root: {
        marginRight: 5,
        marginLeft: 5,
        marginBottom: 10,
        background: 'white',
        paddingBottom: 0,
        paddingTop: 0,
        borderColor: 'grey',
    },
    header: {
        fontSize: 16,
        fontWeight: 700,
        color: 'black',
        marginBottom: 6,
    },
    icon: {
        color: '#6dc353',
    },
    content: {
        color: 'black',
        marginBottom: 4,
        paddingLeft: 5,
    },
});

const DescriptionCard = (props) => {
    const classes = useStyles();

    return (
        <>
            <Card className={classes.root} variant="outlined">
                <CardContent>
                    <Typography className={classes.header}>
                        {props.header}
                    </Typography>

                    <Typography className={classes.content} style={{whiteSpace: 'pre-line'}}>
                        {props.content}
                    </Typography>
                </CardContent>
            </Card>
        </>
    )
}

export default DescriptionCard;