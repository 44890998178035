import * as React from 'react';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StyledPhoneInput from '../../components/entities/StyledPhoneInput';
import { useState } from 'react';
import { Grid } from '@material-ui/core';
import HelperCard from '../../components/settings/HelperCard';
import StyledButton from '../entities/StyledButton';
import { phoneUpdateAC } from '../../redux/user-reducer';
import { connect } from "react-redux";
import Snackbar from '@mui/material/Snackbar';

const BindPhoneBlock = (props) => {
    const [phone, setPhone] = useState(props.phone);
    const [err, setErr] = React.useState('');
    const [open, setOpen] = React.useState(false);

    const phoneChanged = (value, country, e, formattedValue) => {
        setPhone(value);
    };

    async function post(descData) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/users/setsettings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(descData)
        })
            .then(data => data.json())
    }

    const sendPhone = async () => {
        console.log("Sending phone");
        console.log(phone);

        const response = await post({
            id: props.id,
            phone: phone,
        });

        setErr(response.statusCode === 200 ? "Сделано!" : "Что-то пошло не так. Извините и попробуйте еще раз позже пожалуйста");
        setOpen(true);
    }

    const submitPhone = () => {
        props.updatePhone(phone);
        sendPhone();
    };

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <PhoneEnabledIcon sx={{ marginRight: 1 }} />
                    Номер телефона для покупателей
                </AccordionSummary>

                <AccordionDetails>
                    Номер телефона, который будет показан покупателям:

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        alignContent='center'
                    >
                        <Grid item>
                            <StyledPhoneInput
                                value={phone}
                                onChange={phoneChanged}
                            />
                        </Grid>
                        <Grid item>
                            <StyledButton
                                icon={<PhoneEnabledIcon />}
                                label="Сохранить"
                                onClick={submitPhone}
                                disabled={phone.length !== 11}
                            />
                        </Grid>
                    </Grid>

                    <HelperCard helperText={
                        "Рекомендуется заполнить это поле. Этот номер телефона будет отображаться в Ваших объявлениях, соответственно, на него будут звонить покупатели"
                    } />

                    <Snackbar
                        open={open}
                        autoHideDuration={10000}
                        onClose={handleSnackBarClose}
                        message={err}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    />
                </AccordionDetails>
            </Accordion>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        id: state.userState.userId,
        phone: state.userState.phone,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updatePhone: (phone) => {
            dispatch(phoneUpdateAC(phone));
        },
    }
}

const BindPhoneBlockContainer = connect(mapStateToProps, mapDispatchToProps)(BindPhoneBlock);
export default BindPhoneBlockContainer;