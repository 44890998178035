import React from "react";
import { connect } from "react-redux";
import StyledSelect from "../entities/StyledSelect";
import { setHwTypeAC, confirmHwTypeAC, setPartnumbersAC, confirmPartnumbersAC, fetchHwTypes } from "../../redux/add-1-listing-reducer";
import { packPnsConfirmAC, packSetPnsAC, packConfirmStageIfLastPnAC, addPackFetchHwTypes } from "../../redux/add-multi-listings-reducer";
import StyledButton from "../entities/StyledButton";
import { Box, Container, Grid, Typography } from '@material-ui/core';
import DoneIcon from '@mui/icons-material/Done';
import StyledCheckBox from "../entities/StyledCheckBox";
import InjectionContainer from "./Injection";

const PartNumberConfirm = (props) => {
    const [noPartNumberInList, setNoPartNumberInList] = React.useState(false);
    React.useEffect(() => {
        if( props.hwTypesList.length === 0 )
        {
            props.initHwTypesList();
        }
    }, [props]);

    const injection = () => {
        if (props.packMode) {
            return (
                <>
                    <InjectionContainer
                        multi
                        hwType={props.hwType}
                        injectionIndex={props.index}
                    />
                </>
            )
        }

        return <InjectionContainer />
    }

    const render = () => {
        if (props.hwTypeConfirmed) {
            if (!props.partnumbersConfirmed) {
                if (props.noKnownPns) {
                    return injection();
                }
                else {
                    return (
                        <>
                            {noPartNumberInList ?
                                injection()
                                :
                                (
                                    <>
                                        <Typography>Подтвердите партнамбер(ы):</Typography>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            alignContent='center'
                                        >
                                            <Grid item xs={12} sm={8}>
                                                <StyledSelect
                                                    label="Выберите партнамбер платы"
                                                    items={props.partnumbersList.map(a => a.pns.join(', '))}
                                                    cb={props.setPartnumbers}
                                                />
                                            </Grid>
                                            <Grid item >
                                                <StyledButton
                                                    icon={<DoneIcon />}
                                                    label="Готово"
                                                    onClick={() => {
                                                        if (props.partnumbers !== "") {
                                                            props.confirmPartnumbers()
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                )
                            }

                            <Box>
                                < StyledCheckBox
                                    label="Моего партнамбера нет в списке"
                                    checked={noPartNumberInList}
                                    onChange={(e) => {
                                        setNoPartNumberInList(!noPartNumberInList);
                                        if (!noPartNumberInList) {
                                            props.setPartnumbers("", -1);
                                        }
                                    }}
                                />
                            </Box>
                        </>
                    )
                }
            }
        }

        return (
            <>
                Выберите тип запчасти и подтвердите выбор:
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    alignContent='center'
                >
                    <Grid item xs={12} sm={8}>
                        <StyledSelect
                            label="Выберите тип"
                            items={props.hwTypesList.map(a => a.text)}
                            cb={props.setHwType}
                        />
                    </Grid>
                    <Grid item >
                        <StyledButton
                            icon={<DoneIcon />}
                            label="Готово"
                            onClick={() => {
                                if (props.hwType !== "") {
                                    props.confirmHwType()
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </>
        )
    }

    return (
        <>
            <Container sx={{ alignContent: 'center', justifyContent: 'center' }}>
                {render()}
            </Container>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    if (ownProps.multi) {
        const idx = ownProps.index;
        return {
            packMode: ownProps.multi,
            hwTypesList: state.addPack.hwTypes,
            hwTypeConfirmed: true,
            index: idx,
            hwType: state.addPack.listingsData[idx],
            partnumbersConfirmed: state.addPack.listingsData[idx].partnumbersConfirmed,
            partnumbersList: state.addPack.listingsData[idx].partnumbersList,
            partnumbers: state.addPack.listingsData[idx].partnumbers,
            noKnownPns: state.addPack.listingsData[idx].noKnownPartnumbers,
        }
    }

    return {
        hwTypesList: state.addSingle.hwTypes,
        hwType: state.addSingle.hwType,
        hwTypeConfirmed: state.addSingle.hwTypeConfirmed,
        partnumbersConfirmed: state.addSingle.partnumbersConfirmed,
        partnumbersList: state.addSingle.partnumbersList,
        partnumbers: state.addSingle.partnumbers,
        noKnownPns: state.addSingle.noKnownPartnumbers,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    if (ownProps.multi) {
        return {
            initHwTypesList: () => {
                dispatch(addPackFetchHwTypes());
            },
            setPartnumbers: (pns, index) => {
                dispatch(packSetPnsAC(ownProps.index, pns, index));
            },
            confirmPartnumbers: () => {
                dispatch(packPnsConfirmAC(ownProps.index));
                dispatch(packConfirmStageIfLastPnAC());
            },
        }
    }

    return {
        initHwTypesList: () => {
            dispatch(fetchHwTypes());
        },
        setHwType: (type, index) => {
            dispatch(setHwTypeAC(type, index));
        },
        confirmHwType: () => {
            dispatch(confirmHwTypeAC());
        },

        setPartnumbers: (pns, index) => {
            dispatch(setPartnumbersAC(pns, index));
        },

        confirmPartnumbers: () => {
            dispatch(confirmPartnumbersAC());
        },
    }
}

const PartNumberConfirmContainer = connect(mapStateToProps, mapDispatchToProps)(PartNumberConfirm);
export default PartNumberConfirmContainer;