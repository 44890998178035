import React from 'react'
import { Box } from '@mui/system';
import { connect } from 'react-redux';
import StyledButton from '../entities/StyledButton';
import SearchIcon from '@material-ui/icons/Search';
import history from "../../history/history";

const StateNotice = (props) => {
    const searchByTypeModel = (e) => {
        e.preventDefault();
        if (props.hwType === '' || props.typeSearchModel === '') {
            return;
        }

        const url = `/services/search/tv/bytype$${props.hwType}$${props.model}`;
        history.push(url);
    }

    const getStateNotice = () => {
        if (props.state === 'active') return null;

        let notice = '';
        switch (props.state) {
            case 'expired': notice = 'Объявление устарело'; break;
            case 'removed': notice = 'Объявление удалено'; break;
            default: break;
        }

        return (
            <>
                <Box
                    sx={{
                        border: '1px solid #f6f8f1',
                        borderRadius: '4px',
                        marginBottom: 1,
                        bgcolor: '#e5e5e5',
                    }}
                >
                    <h2>{notice}</h2>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                    >
                        <StyledButton
                            icon={<SearchIcon />}
                            label="Поискать такие же"
                            size='small'
                            type="submit"
                            onClick={searchByTypeModel}
                        />

                    </Box>
                </Box>
            </>
        )
    }

    return (
        <>

            {getStateNotice()}
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        state: state.listingPage.listingState,
        hwType: state.listingPage.type,
        model: state.listingPage.model,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

const StateNoticeContainer = connect(mapStateToProps, mapDispatchToProps)(StateNotice);
export default StateNoticeContainer;