import fetch from "cross-fetch";



const ADD_SINGLE_SET_MODEL = 'ADD_SINGLE_SET_MODEL';
const ADD_SINGLE_SET_VENDOR = 'ADD_SINGLE_SET_VENDOR';
const ADD_SINGLE_SET_FETCHING = 'ADD_SINGLE_SET_FETCHING';
const ADD_SINGLE_RECEIVE_COMPOSITION = 'ADD_SINGLE_RECEIVE_COMPOSITION';
const ADD_SINGLE_RECEIVE_VENDOR_LIST = 'ADD_SINGLE_RECEIVE_VENDOR_LIST';
const ADD_SINGLE_RECEIVE_HW_TYPES_LIST = 'ADD_SINGLE_RECEIVE_HW_TYPES_LIST';
const ADD_SINGLE_CONFIRM_VENDOR = 'ADD_SINGLE_CONFIRM_VENDOR';

const ADD_SINGLE_SET_HW_TYPE = 'ADD_SINGLE_SET_HW_TYPE';
const ADD_SINGLE_CONFIRM_HW_TYPE = 'ADD_SINGLE_CONFIRM_HW_TYPE';
const ADD_SINGLE_SET_PARTNUMBERS = 'ADD_SINGLE_SET_PARTNUMBERS';
const ADD_SINGLE_CONFIRM_PARTNUMBERS = 'ADD_SINGLE_CONFIRM_PARTNUMBERS';

const ADD_SINGLE_UPDATE_PN1 = "ADD_SINGLE_UPDATE_PN1";
const ADD_SINGLE_UPDATE_PN2 = "ADD_SINGLE_UPDATE_PN2";
const ADD_SINGLE_UPDATE_PN3 = "ADD_SINGLE_UPDATE_PN3";
const ADD_SINGLE_UPDATE_PN4 = "ADD_SINGLE_UPDATE_PN4";
const ADD_SINGLE_UPDATE_PN5 = "ADD_SINGLE_UPDATE_PN5";
const ADD_SINGLE_UPDATE_PN6 = "ADD_SINGLE_UPDATE_PN6";

const ADD_SINGLE_SET_AND_CONFIRM_INJECTED_PNS = "ADD_SINGLE_SET_AND_CONFIRM_INJECTED_PNS";

const ADD_SINGLE_CONFIRM_BASE_INFO = 'ADD_SINGLE_CONFIRM_BASE_INFO';
const ADD_SINGLE_CONFIRM_PHOTOS = 'ADD_SINGLE_CONFIRM_PHOTOS';

const ADD_SINGLE_CREATING_LISTING = 'ADD_SINGLE_CREATE_LISTING';
const ADD_SINGLE_LISTING_CREATED = 'ADD_SINGLE_LISTING_CREATED';


// backward
const ADD_SINGLE_DROP_VENDOR_MODEL_CONFIRM = 'ADD_SINGLE_DROP_VENDOR_MODEL_CONFIRM';
const ADD_SINGLE_DROP_HWTYPE_PNS_CONFIRM = 'ADD_SINGLE_DROP_HWTYPE_PNS_CONFIRM';
const ADD_SINGLE_DROP_BASE_INFO_CONFIRM = 'ADD_SINGLE_DROP_BASE_INFO_CONFIRM';
const ADD_SINGLE_DROP_PHOTOS_CONFIRM = 'ADD_SINGLE_DROP_PHOTOS_CONFIRM';

const ADD_SINGLE_RESET = 'ADD_SINGLE_RESET';

const LOG_REG_LOGOUT = 'LOG_REG_LOGOUT';

let initialState = {
    isFetching: false,

    model: "",
    modelId: -1,
    modelInjection: false,
    modelConfirmed: false,

    vendor: "",
    vendorId: -1,
    vendorInjection: false,
    vendorConfirmed: false,

    composition: {
        empty: true,
        vendor: '',
        model: '',
        chasis: '',
        pseudonim: '',

        ssb: [ // alternatives
            { id: '', pns: [] }, // part-numbers of the current alt
        ],
        psu: [
            { id: '', pns: [] },
        ],
        tcn: [
            { id: '', pns: [] },
        ],
        bkl: [
            { id: '', pns: [] },
        ],
        pnl: [
            { id: '', pns: [] },
        ],
        ld: [
            { id: '', pns: [] },
        ],
        ir: [
            { id: '', pns: [] },
        ],
        btn: [
            { id: '', pns: [] },
        ],
        wlan: [
            { id: '', pns: [] },
        ],
        bt: [
            { id: '', pns: [] },
        ],
        dyn: [
            { id: '', pns: [] },
        ],
        tun: [
            { id: '', pns: [] },
        ],
        lvds: [
            { id: '', pns: [] },
        ],
        mtx: [
            { id: '', pns: [] },
        ],
        cam: [
            { id: '', pns: [] },
        ],
        inv: [
            { id: '', pns: [] },
        ],
    },

    vendors: [],

    hwType: "",
    hwTypeIndex: 0,
    hwTypeConfirmed: false,
    hwTypes: [],

    partnumbersList: [],
    partnumbers: "",
    partnumbersId: -1,
    partnumbersConfirmed: false,
    noKnownPartnumbers: false,

    // injection
    pn1: '',
    pn2: '',
    pn3: '',
    pn4: '',
    pn5: '',
    pn6: '',
    pn1Err: false,
    pn2Err: false,
    pn3Err: false,
    pn4Err: false,
    pn5Err: false,
    pn6Err: false,

    price: '',
    summary: '',
    description: '',
    baseInfoConfirmed: false,

    photos: [],
    photosConfirmed: false,

    previewConfirmed: false,
};

const partnumbersOfType = (hwType, hwTypes, composition) => {
    switch (hwTypes.find(e => e.text === hwType).type) {
        case "ssb": return composition.ssb || [];
        case "psu": return composition.psu || [];
        case "tcn": return composition.tcn || [];
        case "bkl": return composition.bkl || [];
        case "pnl": return composition.pnl || [];
        case "ld": return composition.ld || [];
        case "ir": return composition.ir || [];
        case "btn": return composition.btn || [];
        case "wlan": return composition.wlan || [];
        case "bt": return composition.bt || [];
        case "dyn": return composition.dyn || [];
        case "lvds": return composition.lvds || [];
        case "mtx": return composition.mtx || [];
        case "cam": return composition.cam || [];
        case "inv": return composition.inv || [];
        default: return [];
    }
}

const addSingleListingReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_SINGLE_SET_MODEL:
            return Object.assign({}, state, {
                model: action.model,
                modelConfirmed: true,
                hwType: "",
                partnumbers: "",
                isFetching: true,
            });

        case ADD_SINGLE_SET_VENDOR:
            return Object.assign({}, state, {
                vendor: action.vendor,
                vendorInjection: action.vendorInjection,
                vendorId: action.vendorInjection || action.vendor === "" ? -1 : state.vendors.find(v => v.vendor === action.vendor).id,
            });

        case ADD_SINGLE_SET_FETCHING:
            return Object.assign({}, state, {
                isFetching: action.isFetching,
            });

        case ADD_SINGLE_RECEIVE_COMPOSITION:
            return Object.assign({}, state, {
                isFetching: false,
                composition: action.composition,
                modelId: action.composition.empty ? -1 : action.composition.id,
                modelInjection: action.composition.empty,
                vendor: action.composition.empty ? "" : action.composition.vendor,
                vendorInjection: false,
                vendorId: action.composition.empty ? -1 : action.composition.vendor_id,
                vendorConfirmed: !action.composition.empty,
            });

        case ADD_SINGLE_RECEIVE_VENDOR_LIST:
            return Object.assign({}, state, {
                isFetching: false,
                vendors: action.vendors,
            });

        case ADD_SINGLE_RECEIVE_HW_TYPES_LIST:
            return Object.assign({}, state, {
                isFetching: false,
                hwTypes: action.types,
            });



        case ADD_SINGLE_CONFIRM_VENDOR:
            return Object.assign({}, state, {
                vendorConfirmed: true,
            });


        case ADD_SINGLE_SET_HW_TYPE:
            return Object.assign({}, state, {
                hwType: action.hwType,
                hwTypeIndex: action.hwTypeIndex,
            });

        case ADD_SINGLE_CONFIRM_HW_TYPE:
            return Object.assign({}, state, {
                hwTypeConfirmed: true,
                partnumbersList: partnumbersOfType(state.hwType, state.hwTypes, state.composition),
                noKnownPartnumbers: partnumbersOfType(state.hwType, state.hwTypes, state.composition).length === 0,
            });

        case ADD_SINGLE_SET_PARTNUMBERS:
            return Object.assign({}, state, {
                partnumbers: action.partnumbers,
                partnumbersId: action.partnumberIndex === -1 ? -1 : state.partnumbersList[action.partnumberIndex].id,
            });

        case ADD_SINGLE_CONFIRM_PARTNUMBERS:
            return Object.assign({}, state, {
                partnumbersConfirmed: true,
                description: "",
                summary: "",
            });




        case ADD_SINGLE_UPDATE_PN1: return Object.assign({}, state, {
            pn1: action.newText,
            pn1Err: action.err.isErr,
            pn1ErrWhat: action.err.what
        });
        case ADD_SINGLE_UPDATE_PN2: return Object.assign({}, state, {
            pn2: action.newText,
            pn2Err: action.err.isErr,
            pn2ErrWhat: action.err.what
        });
        case ADD_SINGLE_UPDATE_PN3: return Object.assign({}, state, {
            pn3: action.newText,
            pn3Err: action.err.isErr,
            pn3ErrWhat: action.err.what
        });
        case ADD_SINGLE_UPDATE_PN4: return Object.assign({}, state, {
            pn4: action.newText,
            pn4Err: action.err.isErr,
            pn4ErrWhat: action.err.what
        });
        case ADD_SINGLE_UPDATE_PN5: return Object.assign({}, state, {
            pn5: action.newText,
            pn5Err: action.err.isErr,
            pn5ErrWhat: action.err.what
        });
        case ADD_SINGLE_UPDATE_PN6: return Object.assign({}, state, {
            pn6: action.newText,
            pn6Err: action.err.isErr,
            pn6ErrWhat: action.err.what
        });

        case ADD_SINGLE_SET_AND_CONFIRM_INJECTED_PNS:
            return Object.assign({}, state, {
                partnumbers: action.partnumbers,
                partnumbersConfirmed: true,
                description: "",
                summary: "",
            });

        case ADD_SINGLE_CONFIRM_BASE_INFO:
            return Object.assign({}, state, {
                summary: action.summary,
                description: action.description,
                price: action.price,
                baseInfoConfirmed: true,
            });

        case ADD_SINGLE_CONFIRM_PHOTOS:
            return Object.assign({}, state, {
                photos: action.photos,
                photosConfirmed: true,
            });

        case ADD_SINGLE_CREATING_LISTING:
            return Object.assign({}, state, {
                isFetching: true,
            });

        case ADD_SINGLE_LISTING_CREATED:
            return Object.assign({}, state, {
                previewConfirmed: true,
                isFetching: false,
            });





        case ADD_SINGLE_DROP_VENDOR_MODEL_CONFIRM:
            return Object.assign({}, state, {
                vendorConfirmed: false,
                modelConfirmed: false,
                hwTypeConfirmed: false,
            });

        case ADD_SINGLE_DROP_HWTYPE_PNS_CONFIRM:
            return Object.assign({}, state, {
                hwType: "",
                hwTypeIndex: 0,
                hwTypeConfirmed: false,
                partnumbersConfirmed: false,
                partnumbers: "",
                partnumbersId: -1,
                partnumbersList: [],
                noKnownPartnumbers: false,
            });

        case ADD_SINGLE_DROP_BASE_INFO_CONFIRM:
            return Object.assign({}, state, {
                baseInfoConfirmed: false,
            });

        case ADD_SINGLE_DROP_PHOTOS_CONFIRM:
            return Object.assign({}, state, {
                photosConfirmed: false,
            });

        case ADD_SINGLE_RESET:
            return Object.assign({}, state, initialState);

        case LOG_REG_LOGOUT:
            return Object.assign({}, state, initialState);

        default:
            return state;
    }
};

export const setisFetchingAC = (isFetching) => ({
    type: ADD_SINGLE_SET_FETCHING,
    isFetching: isFetching,
})

export const setModelAC = (model) => ({
    type: ADD_SINGLE_SET_MODEL,
    model: model,
})

export const setVendorAC = (vendor, injectVendor) => ({
    type: ADD_SINGLE_SET_VENDOR,
    vendor: vendor,
    vendorInjection: injectVendor,
})

export const confirmVendorAC = () => ({
    type: ADD_SINGLE_CONFIRM_VENDOR,
})

export function fetchComposition(item) {
    let what = item.split("/").join("____");
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + `/api/tech/tv/composition/${what}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    dispatch(addSingleReceiveCompositionAC(json))
                    if (json.data.tv_composition.empty) {
                        return fetch(process.env.REACT_APP_ORIGIN + '/api/tech/tv/vendorlist', {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            credentials: 'include',
                        })
                            .then(
                                response => response.json()
                            )
                            .then(
                                (json) => dispatch(receiveVendorListAC(json))
                            )
                    }
                }
            )
    }
}

export function fetchHwTypes() {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/tech/tv/hwtypes', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    dispatch(receiveHwTypesListAC(json))
                }
            )
    }
}

export const addSingleReceiveCompositionAC = (json) => ({
    type: ADD_SINGLE_RECEIVE_COMPOSITION,
    composition: json.data.tv_composition
})

export const receiveVendorListAC = (json) => ({
    type: ADD_SINGLE_RECEIVE_VENDOR_LIST,
    vendors: json.data.vendors
})

export const receiveHwTypesListAC = (json) => ({
    type: ADD_SINGLE_RECEIVE_HW_TYPES_LIST,
    types: json.data.types
})

export const setHwTypeAC = (type, index) => ({
    type: ADD_SINGLE_SET_HW_TYPE,
    hwType: type,
    hwTypeIndex: index
})

export const confirmHwTypeAC = () => ({
    type: ADD_SINGLE_CONFIRM_HW_TYPE,
})

export const setPartnumbersAC = (pns, index) => ({
    type: ADD_SINGLE_SET_PARTNUMBERS,
    partnumbers: pns,
    partnumberIndex: index
})

export const confirmPartnumbersAC = () => ({
    type: ADD_SINGLE_CONFIRM_PARTNUMBERS,
})

export const updatePn1AC = (text, err) => ({ type: ADD_SINGLE_UPDATE_PN1, newText: text, err: err })
export const updatePn2AC = (text, err) => ({ type: ADD_SINGLE_UPDATE_PN2, newText: text, err: err })
export const updatePn3AC = (text, err) => ({ type: ADD_SINGLE_UPDATE_PN3, newText: text, err: err })
export const updatePn4AC = (text, err) => ({ type: ADD_SINGLE_UPDATE_PN4, newText: text, err: err })
export const updatePn5AC = (text, err) => ({ type: ADD_SINGLE_UPDATE_PN5, newText: text, err: err })
export const updatePn6AC = (text, err) => ({ type: ADD_SINGLE_UPDATE_PN6, newText: text, err: err })

export const setAndConfirmNewPnsAC = (pns) => ({
    type: ADD_SINGLE_SET_AND_CONFIRM_INJECTED_PNS,
    partnumbers: pns
})

export const confirmBaseInfoAC = (summary, description, price) => ({
    type: ADD_SINGLE_CONFIRM_BASE_INFO,
    summary: summary,
    description: description,
    price: price,
})

export const confirmPhotosAC = (photos) => ({
    type: ADD_SINGLE_CONFIRM_PHOTOS,
    photos: photos,
})

export const createListingAC = () => ({
    type: ADD_SINGLE_CREATING_LISTING,
})

export const listingCreatedAC = () => ({
    type: ADD_SINGLE_LISTING_CREATED,
})





export const dropVendorModelAC = () => ({
    type: ADD_SINGLE_DROP_VENDOR_MODEL_CONFIRM,
})

export const dropHwTypeAndPnsAC = () => ({
    type: ADD_SINGLE_DROP_HWTYPE_PNS_CONFIRM,
})

export const dropBaseInfoAC = () => ({
    type: ADD_SINGLE_DROP_BASE_INFO_CONFIRM,
})

export const dropPhotosAC = () => ({
    type: ADD_SINGLE_DROP_PHOTOS_CONFIRM,
})

export const resetAC = () => ({
    type: ADD_SINGLE_RESET,
})


export default addSingleListingReducer;
