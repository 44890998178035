import React from 'react'
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from '@mui/icons-material/Delete';
import { InlineNoteAddUpdateDialogContainer } from './NoteAddUpdateDialog';
import { Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';

const useStyles = makeStyles({
    border: {
        borderRadius: '4px',
        backgroundColor: '#badbad',
    },
    content: {
        color: 'black',
        fontSize: 14,
        marginBottom: 4,
        paddingLeft: 5,
        fontWeight: 500,
    },
    iconButton: {
        color: '#202938',
        padding: '3px',
    },
});

const ListingNoteRow = (props) => {
    const classes = useStyles();

    if (!props.noteExist) {
        return <InlineNoteAddUpdateDialogContainer
            listingId={props.listingId}
            noteText={props.noteText}
            noteExist={props.noteExist}
            setFetchingCb={props.setFetchingCb}
            createListingNoteCb={props.createListingNoteCb}
            updateListingNoteCb={props.updateListingNoteCb}
        />;
    }

    return (
        <>
            <Stack direction={'row'} className={classes.border}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    alignContent='center'
                >
                    <Grid item>
                        <Stack direction={'row'}>
                            <Typography className={classes.content}>
                                {props.noteText}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <span>
                            <InlineNoteAddUpdateDialogContainer
                                updateExisting
                                listingId={props.listingId}
                                noteText={props.noteText}
                                noteExist={props.noteExist}
                                setFetchingCb={props.setFetchingCb}
                                createListingNoteCb={props.createListingNoteCb}
                                updateListingNoteCb={props.updateListingNoteCb}
                            />
                        </span>

                        <Tooltip
                            title="Удалить заметку"
                            placement="bottom"
                        >
                            <IconButton
                                aria-label="delete"
                                onClick={() => { props.remove(props.listingId) }}
                            >
                                <DeleteIcon
                                    className={classes.iconButton}
                                    fontSize='small'
                                />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>

            </Stack>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        noteExist: ownProps.noteExist,
        noteText: ownProps.noteText,
        listingId: ownProps.listingId,
        setFetchingCb: ownProps.setFetchingCb,
        createListingNoteCb: ownProps.createListingNoteCb,
        updateListingNoteCb: ownProps.updateListingNoteCb,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        remove: (id) => {
            dispatch(ownProps.setFetchingCb(true));
            dispatch(ownProps.deleteListingNoteCb(id));
        }
    }
}

const ListingNoteRowContainer = connect(mapStateToProps, mapDispatchToProps)(ListingNoteRow);
export default ListingNoteRowContainer;