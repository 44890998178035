import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import { isMobile } from 'react-device-detect';

function MinusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
                d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
                d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function CloseSquare(props) {
    return (
        <SvgIcon className="memory" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path
                d="M15 9H9v6h6V9zm-2 4h-2v-2h2v2zm8-2V9h-2V7c0-1.1-.9-2-2-2h-2V3h-2v2h-2V3H9v2H7c-1.1 0-2 .9-2 2v2H3v2h2v2H3v2h2v2c0 1.1.9 2 2 2h2v2h2v-2h2v2h2v-2h2c1.1 0 2-.9 2-2v-2h2v-2h-2v-2h2zm-4 6H7V7h10v10z" />
        </SvgIcon>
    );
}

function TransitionComponent(props) {
    return (
        <Collapse {...props} />
    );
}

TransitionComponent.propTypes = {
    /**
     * Show the component; triggers the enter or exit states
     */
    in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 7,
        paddingLeft: 18,
        borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
        color: 'black',
    },
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        maxWidth: 400,
        paddingBottom: 10,
    },
});

export default function CompositionTree(props) {
    const classes = useStyles();

    const handleClick = (arr) => {
        if (isMobile) {
            const anchor = document.querySelector('#DynamicUsagesLinksBlock');
            anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        props.fetchUsagesCb(arr);
    }

    const generateSubnodes = (arr) => {
        const items = []
        for (const [index, value] of arr.entries()) {
            items.push(<StyledTreeItem
                nodeId={value.id.toString()}
                label={value.pns.join(", ")}
                key={index}
                onClick={() => {
                    handleClick(value.pns)
                }} />);
        }
        return items
    }

    const generateNode = (nodeData, nodeId, nodeLabel) => {
        if (typeof nodeData !== 'undefined' &&
            nodeData.length > 0) {
            return (
                <StyledTreeItem nodeId={nodeId} label={nodeLabel}>
                    {generateSubnodes(nodeData)}
                </StyledTreeItem>
            )
        }
    };

    const generateTree = () => {
        return (
            <StyledTreeItem nodeId="root" label={props.composition.vendor + " " + props.composition.model}>
                {generateNode(props.composition.ssb, "ssb", "SSB/Майн/Матплата")}
                {generateNode(props.composition.psu, "psu", "PSU/Блок питания")}
                {generateNode(props.composition.tcn, "tcn", "T-Con/Тайминг-контроллер/Тикон")}
                {generateNode(props.composition.bkl, "bkl", "LED-подсветка")}
                {generateNode(props.composition.ld, "ld", "LED-драйвер")}
                {generateNode(props.composition.pnl, "pnl", "Панель/Матрица")}
                {generateNode(props.composition.ir, "ir", "ИК-приемник/ИК-ресивер")}
                {generateNode(props.composition.btn, "btn", "Кнопки")}
                {generateNode(props.composition.wlan, "wlan", "WiFi/Вай-Фай")}
                {generateNode(props.composition.bt, "bt", "Bluetooth/Блютуз")}
                {generateNode(props.composition.dyn, "dyn", "Динамики")}
                {generateNode(props.composition.tun, "tun", "Тюнер")}
                {generateNode(props.composition.lvds, "lvds", "LVDS-шлейф")}
                {generateNode(props.composition.mtx, "mtx", "Шлейфы матрицы")}
                {generateNode(props.composition.cam, "cam", "Камера")}
                {generateNode(props.composition.inv, "inv", "Инвертор (CCFL/EEFL)")}
            </StyledTreeItem>
        )
    };

    return (
        <>
            <TreeView
                className={classes.root}
                defaultExpanded={
                    ['root', 'ssb', 'psu', 'tcn',
                        'bkl', 'ld', 'pnl', 'ir',
                        'btn', 'wlan', 'bt', 'dyn',
                        'tun', 'lvds', 'mtx', 'cam', 'inv']}
                defaultCollapseIcon={<MinusSquare />}
                defaultExpandIcon={<PlusSquare />}
                defaultEndIcon={<CloseSquare />}
                multiSelect={false}
            >
                {generateTree()}
            </TreeView>
        </>
    );
}