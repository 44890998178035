import React from 'react'
import { connect } from "react-redux";
import { fetchUserData, setFetchingAC } from '../redux/another-user-reducer';
import ProfileCardContainer from '../components/another-user/ProfileCard';
import { Grid } from '@material-ui/core';
import UsersFeedbackContainer from '../components/another-user/UsersFeedback';
import UsersListingsContainer from '../components/listings/UsersListings';
import { UsersFeedbackFromContainer } from '../components/feedback/FeedbackFrom';
import MetaTags from 'react-meta-tags';

const UserProfile = ({ match, ...props }) => {
    React.useEffect(() => {
        let login = match.params.userLogin;
        props.syncState(login);
    }, [])

    const renderDesc = () => {
        if (props.description === "") {
            return;
        }
        else {
            return (
                <>
                    <h2>Описание</h2>
                    {props.description}
                </>
            )
        }
    }

    const renderContent = () => {
        if (props.noSuchUser) {
            return (
                <>
                    <h2>{match.params.userLogin}?</h2>
                    <h3>Такого пользователя не существует</h3>
                    <h2>¯\_(ツ)_/¯</h2>
                </>
            )
        } else {
            return (
                <>
                    <h1>Профиль пользователя {props.login}</h1>
                    <Grid container direction="row" justifyContent="center" alignItems="stretch" alignContent='center'>
                        <Grid item xs={12} sm={6}>
                            <ProfileCardContainer />
                        </Grid>
                    </Grid>

                    {renderDesc()}

                    <h2>Отзывы</h2>
                    <Grid container direction="row" justifyContent="center" alignItems="stretch" alignContent='center'>
                        <Grid item xs={12} sm={6}>
                            <UsersFeedbackFromContainer fromRole="buyer" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <UsersFeedbackFromContainer fromRole="seller" />
                        </Grid>
                    </Grid>
                    <UsersFeedbackContainer
                        pageSize={5}
                    />

                    <h2>Объявления пользователя</h2>
                    <UsersListingsContainer pageSize={8} />
                </>
            )
        }
    }

    return (
        <>
            <MetaTags>
                <title>Профиль пользователя {props.login} - naTextolite</title>
            </MetaTags>

            {renderContent()}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isFetching: state.anotherUserPage.isFetching,
        noSuchUser: state.anotherUserPage.noSuchUser,
        login: state.anotherUserPage.login,
        city: state.anotherUserPage.city,
        cityType: state.anotherUserPage.cityType,
        region: state.anotherUserPage.region,
        ratingsAbs: state.anotherUserPage.ratingsAbs,
        ratingRel: state.anotherUserPage.ratingRel,
        worksSince: state.anotherUserPage.worksSince,
        description: state.anotherUserPage.description,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        syncState: (login) => {
            dispatch(setFetchingAC(true));
            dispatch(fetchUserData(login));
        },
    }
}

const UserProfileContainer = connect(mapStateToProps, mapDispatchToProps)(UserProfile);
export default UserProfileContainer;