import React from 'react'
import { connect } from "react-redux";
import { Grid } from '@material-ui/core';
import StyledButton from '../../components/entities/StyledButton';
import ShareIcon from '@mui/icons-material/Share';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { addToFav, rmFromFav } from '../../redux/listing-reducer';
import Tooltip from "@mui/material/Tooltip";
import { ButtonAddUpdateDialogContainer } from './NoteAddUpdateDialog';

const Actions = (props) => {
    const fav = () => {
        if (props.inFavorite) {
            props.fromFav(props.listingId);
        }
        else {
            props.toFav(props.listingId);
        }
    }

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent='center'
            >
                <Grid item>
                    <Tooltip
                        title={props.inFavorite ? "Удалить это объявление из Вашего избранного" : "Добавить это объявление в Ваше избранное"}
                        placement="bottom"
                    >
                        <span>
                            <StyledButton
                                label={props.inFavorite ? "Из избранного" : "В избранное"}
                                icon={props.inFavorite ? <DeleteOutlineIcon /> : <ShoppingCartIcon />}
                                size='small'
                                disabled={props.preview || !props.userLoggedIn}
                                onClick={fav}
                            />
                        </span>
                    </Tooltip>
                </Grid>

                <Grid item>
                    <ButtonAddUpdateDialogContainer
                        updateExisting={props.noteExist}
                        buttonDisabled={props.preview || !props.userLoggedIn}
                    />
                </Grid>

                <Grid item>
                    <Tooltip
                        title="Нажмите чтобы скопировать ссылку на эту страницу в буфер обмена"
                        placement="bottom"
                    >
                        <span>
                            <StyledButton
                                label="Поделиться"
                                icon={<ShareIcon />}
                                size='small'
                                disabled={props.preview}
                                onClick={() => { navigator.clipboard.writeText(window.location.href) }}
                            />
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        preview: ownProps.preview,
        userLoggedIn: state.loginReg.isLoggedIn,
        listingId: state.listingPage.listingId,
        inFavorite: state.listingPage.inFavorite,
        noteExist: state.listingPage.noteExist,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toFav: (id) => {
            dispatch(addToFav(id));
        },
        fromFav: (id) => {
            dispatch(rmFromFav(id));
        }
    }
}

const ActionsContainer = connect(mapStateToProps, mapDispatchToProps)(Actions);
export default ActionsContainer;