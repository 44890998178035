import React from 'react'
import { connect } from "react-redux";
import { createListingAC, listingCreatedAC } from '../../redux/add-1-listing-reducer';
import { Grid } from '@material-ui/core';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import ListingSubmitterCardContainer from '../../components/listings/ListingSubmitterCard';
import ContactsCardContainer from '../../components/listings/ContactsCard';
import DynamicUsagesLinksCardContainer from "../../components/DynamicUsagesLinksCard";
import { fetchSecondaryUsages } from '../../redux/composition-reducer';
import DescriptionContainer from '../../components/listings/Description';
import ActionsContainer from '../../components/listings/Actions';
import { Box } from '@mui/material';
import StyledButton from '../entities/StyledButton';
import { packPreviewConfirmAC, addPackListingCreatedAC, addPackPostingAC } from '../../redux/add-multi-listings-reducer';
import Spinner from '../Spinner';
import { Stack } from '@mui/material';

const NewListingPreview = (props) => {
    function dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        var ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], { type: mimeString });
        return blob;
    }

    async function postPhotos(images) {
        const data = new FormData();
        images.forEach(function (img, i) {
            data.append(`img_${i}`, dataURItoBlob(img.original), `${i}.jpeg`);
            data.append(`thm_${i}`, dataURItoBlob(img.thumbnail), `${i}_thm.jpeg`);
        })

        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/upload', {
            method: 'POST',
            body: data,
            credentials: 'include',
        })
            .then(data => data.json())
    }

    async function postData(data) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/new', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(data)
        })
            .then(data => data.json())
    }

    const postListing = async () => {
        const uploadResponse = await postPhotos(props.imgs);

        if (uploadResponse.statusCode === 200) {
            //console.log("Post images success, uuid: " + uploadResponse.data.uuid);

            // POST GENERAL DATA
            const response = await postData({
                id: props.id,
                // vendor
                vendorId: props.listingData.vendorId,
                vendorInjection: props.listingData.vendorInjection,
                vendor: props.listingData.vendor,

                // model
                modelId: props.listingData.modelId,
                modelInjection: props.listingData.modelInjection,
                model: props.listingData.model,

                // HW type
                hwTypeId:
                    props.listingData.hwTypes[props.listingData.hwTypeIndex].id,
                hwType:
                    props.listingData.hwType,

                // Partnumbers
                pnsId:
                    props.listingData.partnumbersId,
                pnsInjection:
                    props.listingData.partnumbersId === -1,
                injectionPn1: props.listingData.pn1,
                injectionPn2: props.listingData.pn2,
                injectionPn3: props.listingData.pn3,
                injectionPn4: props.listingData.pn4,
                injectionPn5: props.listingData.pn5,
                injectionPn6: props.listingData.pn6,
                partnumbers: props.listingData.partnumbers,

                // General data
                price: props.listingData.price,
                summary: props.listingData.summary,
                description: props.listingData.description,
                photosUuid: uploadResponse.data.uuid,
                photosNum: props.imgs.length,
            });

            //console.log(response.statusCode === 200 ? "Сделано!" : "Что-то пошло не так. Извините и попробуйте еще раз позже пожалуйста");
            props.created();
        }
        else {
            console.log("something went wrong, upload response -> " + uploadResponse.statusCode);
        }
    }

    const postListingOfIndex = async (images, listingData) => {
        const uploadResponse = await postPhotos(images);

        if (uploadResponse.statusCode === 200) {
            //console.log("Post images success, uuid: " + uploadResponse.data.uuid);

            // POST GENERAL DATA
            const response = await postData({
                id: props.id,
                // vendor
                vendorId: props.listingData.vendorId,
                vendorInjection: false,
                vendor: props.listingData.vendor,

                // model
                modelId: props.listingData.modelId,
                modelInjection: false,
                model: props.listingData.model,

                // HW type
                hwTypeId: listingData.typeId,
                hwType: listingData.text,

                // Partnumbers
                pnsId: listingData.partnumbersId,
                pnsInjection: listingData.partnumbersId === -1,
                injectionPn1: listingData.pn1,
                injectionPn2: listingData.pn2,
                injectionPn3: listingData.pn3,
                injectionPn4: listingData.pn4,
                injectionPn5: listingData.pn5,
                injectionPn6: listingData.pn6,
                partnumbers: listingData.partnumbers,

                // General data
                price: listingData.price,
                summary: listingData.summary,
                description: listingData.description,
                photosUuid: uploadResponse.data.uuid,
                photosNum: images.length,
            });

            //console.log(response.statusCode === 200 ? "Сделано!" : "Что-то пошло не так. Извините и попробуйте еще раз позже пожалуйста");

            props.created();
        }
        else {
            console.log("something went wrong, upload resp -> " + uploadResponse.statusCode);
        }
    }

    const postListingsPack = async () => {
        if (props.multi) {
            props.startPostingPack();
            props.listingData.listingsData.forEach(function (listing, i) {
                postListingOfIndex(
                    listing.photos.map((a) => { return { original: a.data_url, thumbnail: a.thumbnail } }),
                    listing
                );
            })
        }
    }

    if (props.isFetching) {
        if (props.multi && props.isPosting) {
            return (
                <>
                    <h3>Генерируем объявления</h3>
                    <Stack direction="column" alignItems="center" >
                        <Spinner />
                        <p>Готово: {props.posted}/{props.listingData.listingsData.length}</p>
                    </Stack>
                </>
            )
        }
        else {
            return <Spinner />;
        }
    }


    return (
        <>
            <h2>
                {props.summary}
            </h2>
            <p>{props.date}</p>

            <Grid container direction="row" justifyContent="center" alignItems="center" alignContent='center'>
                <Grid item xs={12} sm={6}>
                    <ListingSubmitterCardContainer preview multi={props.multi} index={props.index} />
                    <ContactsCardContainer preview />
                </Grid>
                <Grid item xs={12} sm={6}>
                    {
                        props.imgs.length > 0 ? (
                            <ImageGallery
                                items={props.imgs}
                                showPlayButton={false}
                            />
                        ) : (
                            <>
                                <h3>К сожалению, продавец не предоставил фото</h3>
                            </>
                        )
                    }
                </Grid>
            </Grid>

            <ActionsContainer preview />

            {
                (props.showCompatBlock && !props.noUsages) ? (
                    <>
                        <Grid container direction="row" justifyContent="center" alignItems="stretch" alignContent='center'>
                            <Grid item xs={12} sm={8}>
                                <DescriptionContainer preview multi={props.multi} index={props.index} />
                                <ContactsCardContainer preview />
                                <ActionsContainer preview />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <DynamicUsagesLinksCardContainer
                                    header="Совместимость"
                                    notice={`Внимание! В базе составов есть информация что ${props.pns[0]} устанавливается в ТВ из списка ниже. 
                                    Однако, мы НЕ можем гарантировать, что платы с этих ТВ взаимозаменяемы.`}
                                    paginate
                                    pageSize={10}
                                />
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <>
                        <DescriptionContainer preview multi={props.multi} index={props.index} />
                        <ContactsCardContainer preview />
                        <ActionsContainer preview />
                    </>
                )
            }

            <Box display={'flex'} justifyContent={'center'}>
                <StyledButton
                    label='Создать!'
                    onClick={() => {
                        if (props.multi) {
                            // is last
                            if (props.index === (props.listingData.listingsData.length - 1)) {
                                postListingsPack();
                            } else {
                                props.create();
                            }
                        }
                        else {
                            postListing();
                            props.create();
                        }
                    }}
                />
            </Box>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let date = new Date();
    const today = String(date.getDate()).padStart(2, '0') + '.' +
        String(date.getMonth() + 1).padStart(2, '0') + '.' +
        date.getFullYear();

    if (ownProps.multi) {
        return {
            multi: true,
            index: ownProps.index,
            submitter: state.userState.login,
            showCompatBlock: state.settings.showCompatBlock,
            summary: state.addPack.listingsData[ownProps.index].summary,
            date: today,
            imgs: state.addPack.listingsData[ownProps.index].photos.map((a) => { return { original: a.data_url, thumbnail: a.thumbnail } }),
            pns: state.addPack.listingsData[ownProps.index].partnumbers.split(", "),
            noUsages: state.compositionPage.secondaryUsages.empty,

            isFetching: state.addPack.isFetching,
            isPosting: state.addPack.isPosting,
            posted: state.addPack.posted,
            listingData: state.addPack,
        }
    }

    return {
        submitter: state.userState.login,
        showCompatBlock: state.settings.showCompatBlock,
        summary: state.addSingle.summary,
        date: today,
        imgs: state.addSingle.photos.map((a) => { return { original: a.data_url, thumbnail: a.thumbnail } }),
        pns: state.addSingle.partnumbers.split(", "),
        noUsages: state.compositionPage.secondaryUsages.empty,
        isFetching: state.addSingle.isFetching,

        listingData: state.addSingle,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    if (ownProps.multi) {
        return {
            checkUsages: (pns, doNotClear) => {
                dispatch(fetchSecondaryUsages(pns, doNotClear));
            },
            create: () => {
                dispatch(packPreviewConfirmAC(ownProps.index))
                if (ownProps.onConfirm) {
                    ownProps.onConfirm(ownProps.index);
                }
            },
            created: () => { dispatch(addPackListingCreatedAC()) },
            startPostingPack: () => { dispatch(addPackPostingAC()) }
        }
    }

    return {
        checkUsages: (pns, doNotClear) => {
            dispatch(fetchSecondaryUsages(pns, doNotClear));
        },
        create: () => { dispatch(createListingAC()) },
        created: () => { dispatch(listingCreatedAC()) },
    }
}

const NewListingPreviewContainer = connect(mapStateToProps, mapDispatchToProps)(NewListingPreview);
export default NewListingPreviewContainer;