import * as React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { connect } from "react-redux";
import StyledButton from "../entities/StyledButton";
import { Box } from '@material-ui/core';
import DoneIcon from '@mui/icons-material/Done';
import { packSetHwTypesAC, packSetHwTypesConfirmAC, addPackFetchHwTypes } from '../../redux/add-multi-listings-reducer';
import Typography from "@material-ui/core/Typography";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 5,
        marginBottom: 10,
        '&$cssFocused $notchedOutline': {
            borderColor: `${theme.palette.primary.main} !important`,
            fontWeight: '600',
        },
    },
    cssFocused: {
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: '#202938 !important',
    },
    input: {
        color: '#202938',
        fontWeight: 600,
        maxWidth: '600px',
    },
}));

const HwTypesPicker = (props) => {
    React.useEffect(() => {
        if (props.hwTypes.length === 0) {
            props.initHwTypesList();
        }
    }, [props]);

    const classes = useStyles();

    return (
        <>
            <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={props.hwTypes}
                disableCloseOnSelect
                getOptionLabel={(option) => option.text}
                onChange={(event, newValue, reason) => {
                    props.setTypes(newValue);
                }}
                sx={{
                    '& input': {
                        fontFamily: 'Jura',
                        color: 'black',
                        fontWeight: 700,
                    },
                    '& .MuiChip-label': {
                        fontFamily: 'Jura',
                        color: 'black',
                        fontWeight: 600,
                    },
                    '& .MuiFormLabel-root': {
                        fontFamily: 'Jura',
                        color: 'black',
                    },
                }}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 4 }}
                            checked={selected}
                            sx={{
                                '&.Mui-checked': {
                                    color: '#6dc353',
                                    backgroundColor: 'white',
                                },
                            }}
                        />
                        <Typography style={{ fontFamily: 'Jura', color: 'black', fontWeight: 700, }}>{option.text}</Typography>
                    </li>
                )}
                style={{ width: 500 }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Выберите запчасти"
                        InputProps={{
                            ...params.InputProps,
                            //type: 'search',
                            //placeholder: "Выберите запчасти",
                            size: "small",
                            classes: {
                                root: classes.root,
                                focused: classes.cssFocused,
                                notchedOutline: classes.notchedOutline,
                                input: classes.input,
                            }
                        }}
                        InputLabelProps={{
                            ...params.InputLabelProps,
                            style: {
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                width: '100%',
                                color: '#202938',
                                fontWeight: '600',
                            }
                        }}
                    />
                )}
            />

            <Box display={'flex'} justifyContent={'center'}>
                <StyledButton
                    icon={<DoneIcon />}
                    label="Готово"
                    onClick={() => {
                        props.confirm();
                    }}
                />
            </Box>
        </>
    );
}


const mapStateToProps = (state) => {
    return {
        hwTypes: state.addPack.hwTypes,
        hwTypesConfirmed: state.addPack.hwTypesConfirmed,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        initHwTypesList: () => {
            dispatch(addPackFetchHwTypes());
        },
        setTypes: (types) => {
            dispatch(packSetHwTypesAC(types));
        },
        confirm: () => { dispatch(packSetHwTypesConfirmAC()) }
    }
}

const HwTypesPickerContainer = connect(mapStateToProps, mapDispatchToProps)(HwTypesPicker);
export default HwTypesPickerContainer;