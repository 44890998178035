import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Box, Link } from '@mui/material';

/*
Props:
    label - text to be displayed
    onChange - cb 
    checked - initial state
    disabled - disabled state
*/

const StyledCheckBoxWithLink = (props) => {
    return (
        <>

            <FormControlLabel
                disableTypography
                sx={{ fontFamily: 'Jura', color: 'black', fontWeight: 700, fontSize: '13px' }}
                control={<Checkbox
                    sx={{
                        '&.Mui-checked': {
                            color: '#6dc353',
                            backgroundColor: 'white',
                        },
                    }}
                />}
                label={
                    <Box sx={{
                        marginY: '4px'
                    }}>
                        {props.labelBeforeLink}
                        <Link href={props.linkTo} rel="noopener" target="_blank">{props.linkLabel}</Link>
                        {props.labelAfterLink}
                    </Box>
                }
                onChange={props.onChange}
                checked={props.checked}
                disabled={props.disabled}
            />
        </>
    );
}

export default StyledCheckBoxWithLink;