import React from 'react'
import { connect } from "react-redux";
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@mui/icons-material/Check';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Divider from '@mui/material/Divider';
import { Link } from '@material-ui/core';

const useStyles = makeStyles({
    iconOk: {
        color: '#6dc353'
    },
    iconWarn: {
        color: '#d5b60a'
    },
    link: {
        paddingLeft: 5,
    },
    content: {
        fontSize: 15,
        fontWeight: 500,
        color: 'black',
    },
    notice: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 15,
        fontSize: 15,
        fontWeight: 500,
        color: 'black',
    },
});


const RequiredActions = (props) => {
    const classes = useStyles();

    const actionRow = (icon, text) => {
        return (
            <>
                <Box
                    sx={{
                        border: '1px solid #f6f8f1',
                        borderRadius: '4px',
                        //marginBottom: 1,
                        //bgcolor: calcBgColor(),
                    }}
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems='center'
                >
                    <Box display={'flex'} flexDirection={'row'} >
                        <Box marginX={2} >
                            {icon}
                        </Box>

                        <Typography className={classes.content} style={{ wordWrap: 'break-word' }}>
                            {text}
                        </Typography>
                    </Box>
                </Box>
            </>
        )
    }

    const renderGeo = () => {
        if (props.city === '' || props.region === '') {
            return actionRow(<ReportProblemIcon className={classes.iconWarn} />, 'Не указан населенный пункт');
        }
        return actionRow(<CheckIcon className={classes.iconOk} />, 'Населенный пункт указан');
    }

    const renderStoreDesc = () => {
        if (props.storeDesc === '') {
            return actionRow(<ReportProblemIcon className={classes.iconWarn} />, 'Не заполнено описание магазина');
        }
        return actionRow(<CheckIcon className={classes.iconOk} />, 'Описание магазина заполнено');
    }

    const renderPhone = () => {
        if (props.phone === '') {
            return actionRow(<ReportProblemIcon className={classes.iconWarn} />, 'Не указан телефон для связи');
        }
        else {
            return actionRow(<CheckIcon className={classes.iconOk} />, 'Телефон для связи указан');
        }
    }

    const renderWhatsapp = () => {
        if (!props.useWa || props.waPhone === '') {
            return actionRow(<ReportProblemIcon className={classes.iconWarn} />, 'Не указан номер Whatsapp');
        }
        else {
            return actionRow(<CheckIcon className={classes.iconOk} />, 'Номер Whatsapp указан');
        }
    }

    const renderTg = () => {
        if (props.tg === '') {
            return actionRow(<ReportProblemIcon className={classes.iconWarn} />, 'Не указан контакт Telegram');
        }
        else {
            return actionRow(<CheckIcon className={classes.iconOk} />, 'Контакт Telegram указан');
        }
    }

    const renderMessengers = () => {
        if (props.tg === '' && (!props.useWa || props.waPhone === '')) {
            return actionRow(<ReportProblemIcon className={classes.iconWarn} />, 'Не указан ни один мессенджер');
        }
    }

    const renderPickupAddr = () => {
        if (props.pickupAdress === '') {
            return actionRow(<ReportProblemIcon className={classes.iconWarn} />, 'Не указаны инструкции по самовывозу');
        }
        else {
            return actionRow(<CheckIcon className={classes.iconOk} />, 'Адрес самовывоза заполнен');
        }
    }

    const renderListingTemplates = () => {
        if (props.paymentOptions === '' ||
            props.deliveryOptions === '' ||
            props.warrantyOptions === '' ||
            props.descriptionAddon === '') {
            return actionRow(<ReportProblemIcon className={classes.iconWarn} />, 'Не все шаблоны для объявлений заполнены');
        }
        return actionRow(<CheckIcon className={classes.iconOk} />, 'Все шаблоны для объявлений заполнены');
    }

    return (
        <>
            <h2>Заполненность профиля</h2>
            <Box sx={{
                marginBottom: '10px',
                marginX: '5px',
                bgcolor: '#cccccc',
                border: '1px solid black',
                borderRadius: '4px',
                bgcolor: '#f3f3f3',
                paddingTop: '8px',
                paddingBottom: '5px',
            }}>
                {renderGeo()}
                {renderStoreDesc()}
                {renderPhone()}
                {renderWhatsapp()}
                {renderTg()}
                {renderMessengers()}
                {renderPickupAddr()}
                {renderListingTemplates()}

                <Divider />
                <Typography className={classes.notice}>
                    <Link href="/personal/settings" className={classes.link}>
                        Для внесения изменений перейдите в Настройки
                    </Link>
                </Typography>
            </Box>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        city: state.userState.city,
        region: state.userState.region,
        phone: state.userState.phone,

        tg: state.settings.tgUserName,
        useWa: state.settings.useWa,
        waPhone: state.settings.waPhoneNum,

        storeDesc: state.settings.storeDescription,
        paymentOptions: state.settings.paymentOptions,
        pickupAdress: state.settings.pickupAdress,
        deliveryOptions: state.settings.deliveryOptions,
        warrantyOptions: state.settings.warrantyOptions,
        descriptionAddon: state.settings.descriptionAddon,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

const RequiredActionsContainer = connect(mapStateToProps, mapDispatchToProps)(RequiredActions);
export default RequiredActionsContainer;