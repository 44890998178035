import { FormGroup3pn } from "../../FormGroup";
import React from "react";
import { updateCamPn1AC, updateCamPn2AC, updateCamPn3AC } from "../../../redux/add-composition-reducer";
import { connect } from "react-redux";

const CamAddForm = (props) => {

    return (
        <>
            <FormGroup3pn
                title={"Камера"}
                description={"Внимательно осмотрите модуль и наклейки на нем. После этого укажите здесь партнамберы, которые видите.\n" +
                    "                            Каждый партнамбер указывайте в отдельном поле.\n" +
                    "                            Например, на модуле вы видите \"BN96-26578A\", а на наклейке \"AQUILA-L8\".\n" +
                    "                            В поле \"Партнамбер 1\" укажите \"BN96-26578A\", а в поле \"Партнамбер 2\" - \"AQUILA-L8\"."}
                val_pn1={props.cam1}
                val_pn2={props.cam2}
                val_pn3={props.cam3}
                cb_pn1={props.updateCamPn1}
                cb_pn2={props.updateCamPn2}
                cb_pn3={props.updateCamPn3}
                pn1_err={props.cam1Err}
                pn2_err={props.cam2Err}
                pn3_err={props.cam3Err}
                pn1_errDesc={props.cam1ErrWhat}
                pn2_errDesc={props.cam2ErrWhat}
                pn3_errDesc={props.cam3ErrWhat}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        cam1: state.addCompositionPage.cam_pn1,
        cam2: state.addCompositionPage.cam_pn2,
        cam3: state.addCompositionPage.cam_pn3,
        cam1Err: state.addCompositionPage.cam_pn1Err,
        cam2Err: state.addCompositionPage.cam_pn2Err,
        cam3Err: state.addCompositionPage.cam_pn3Err,
        cam1ErrWhat: state.addCompositionPage.cam_pn1ErrWhat,
        cam2ErrWhat: state.addCompositionPage.cam_pn2ErrWhat,
        cam3ErrWhat: state.addCompositionPage.cam_pn3ErrWhat,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateCamPn1: (val, err) => {
            dispatch(updateCamPn1AC(val, err));
        },
        updateCamPn2: (val, err) => {
            dispatch(updateCamPn2AC(val, err));
        },
        updateCamPn3: (val, err) => {
            dispatch(updateCamPn3AC(val, err));
        },
    }
}
const CamAddFormContainer = connect(mapStateToProps, mapDispatchToProps)(CamAddForm);
export default CamAddFormContainer;