import {
    resetPassAC,
    updateLoginLoginText,
    updateNewPwdText, updateNewPwdConfirmText,
    newPassCodeTextUpdatedAC, resetUnknownErrAC,
    resetInvalidCodeAC, passChangedAC, resetStateAC,
    showLoginDialogAC, setFetchingAC
} from "../../redux/login-reg-reducer";
import { connect } from "react-redux";
import Spinner from "../Spinner";
import React from "react";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import StyledTextField from "../entities/StyledTextField";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import StyledButton from "../entities/StyledButton";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(10),
    },
    title: {
        fontSize: 17,
        fontWeight: 500,
        color: '#202938',
        alignContent: 'center',
    },
}));

async function requestCode(credentials) {
    return fetch(process.env.REACT_APP_ORIGIN + '/api/users/resetpass', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

async function changePass(credentials) {
    return fetch(process.env.REACT_APP_ORIGIN + '/api/users/setpass', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

function ForgotPass(props) {
    const classes = useStyles();

    /* Actions */
    const sendPassResetRequest = async (e) => {
        e.preventDefault();

        if (props.login === '' || props.loginErr) {
            return;
        }

        props.setFetching(true);

        const response = await requestCode({
            login: props.login,
        });

        if (response.statusCode === 200) {
            props.resetPass();
        }
        else {
            props.raiseUnknownErr((typeof response.data.reason !== "undefined") ? response.data.reason : '');
        }
        props.setFetching(false);
    };

    const handleChangePass = async (e) => {
        e.preventDefault();
        if (props.code === '' ||
            props.newPassword === '' ||
            props.newPasswordConfirm === '' ||
            props.newPasswordErr ||
            props.newPasswordConfirmErr) {
            return;
        }

        props.setFetching(true);

        const response = await changePass({
            login: props.login,
            pass: props.newPassword,
            code: props.code,
        });

        if (response.statusCode === 200) {
            props.sendPassChanged();
        }
        else if (response.statusCode === 400) {
            props.invalidCode();
        }
        else {
            props.raiseUnknownErr();
        }
        props.setFetching(false);
    };

    const handleFinish = (e) => {
        e.preventDefault();
        props.reset();
    }

    /* Render details */
    const renderInvalidCodeNotice = () => {
        if (props.codeMismatch)
            return (
                <>
                    <Grid item lg={8} md={10} sm={12}>
                        <Typography className={classes.title} color="textSecondary" align={'center'} gutterBottom>
                            Неверный проверочный код. Проверьте код и повторите попытку.
                        </Typography>
                    </Grid>
                </>
            )
    }

    const renderUnknownErrorNotice = () => {
        if (props.unknownErr) {
            return (
                <>
                    <Grid item lg={8} md={10} sm={12}>
                        <Typography className={classes.title} color="textSecondary" align={'center'} gutterBottom>
                            {props.errReason ? props.errReason : "Что-то пошло не так. Пожалуйста, повторите попытку через несколько минут"}
                        </Typography>
                    </Grid>
                </>
            )
        }
    }

    if (props.isFetching) {
        return (<Spinner />);
    }

    if (props.waitCode) {
        return (
            <>
                <form onSubmit={handleChangePass}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item lg={8} md={10} sm={12}>
                            <Typography className={classes.title} color="textSecondary" align={'center'} gutterBottom>
                                На Вашу почту был выслан проверочный код. Код действителен в течение 10 минут.
                                Введите его в поле ниже и задайте новый пароль
                            </Typography>
                            <StyledTextField
                                label="Проверочный код"
                                type="text"
                                value={props.code}
                                onChange={props.codeChanged}
                                placeholder="Код из письма"
                                icon={<VerifiedUserIcon />}
                                className={classes.root}
                            />

                            <StyledTextField
                                label="Новый пароль"
                                type="password"
                                value={props.newPassword}
                                onChange={props.newPasswordChanged}
                                err={props.newPasswordErr}
                                errWhat={props.newPasswordErrWhat}
                                placeholder="Пароль"
                                icon={<VpnKeyIcon />}
                                className={classes.root}
                            />

                            <StyledTextField
                                label="Новый пароль еще раз"
                                type="password"
                                value={props.newPasswordConfirm}
                                onChange={props.newPasswordConfirmChanged}
                                err={props.newPasswordConfirmErr}
                                errWhat={props.newPasswordConfirmErrWhat}
                                placeholder="Пароль"
                                icon={<VpnKeyIcon />}
                                className={classes.root}
                            />
                            <Grid item lg={8} md={10} sm={12}>
                                <StyledButton
                                    label={"Сбросить пароль"}
                                    type={"submit"} />
                            </Grid>
                            {renderInvalidCodeNotice()}
                            {renderUnknownErrorNotice()}
                        </Grid>
                    </Grid>
                </form>
            </>
        )
    }

    if (props.passChanged) {
        return (
            <>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item lg={8} md={10} sm={12}>
                        <Typography className={classes.title} color="textSecondary" align={'center'} gutterBottom>
                            Пароль успешно изменен.
                        </Typography>
                    </Grid>
                    <Grid item lg={8} md={10} sm={12}>
                        <StyledButton
                            label={"Продолжить"}
                            type={"button"}
                            onClick={handleFinish}
                        />
                    </Grid>
                </Grid>
            </>
        )
    }

    return (
        <>
            <form onSubmit={sendPassResetRequest}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    alignContent="center"
                >
                    <Grid item xs={6}>
                        <StyledTextField
                            label="Имя пользователя или почта"
                            type="text"
                            value={props.login}
                            onChange={props.loginChanged}
                            err={props.loginErr}
                            errWhat={props.loginErrWhat}
                            placeholder="Login"
                            icon={<AssignmentIndIcon />}
                            className={classes.root}
                        />
                    </Grid>
                    <Grid item >
                        <StyledButton
                            label={"Далее"}
                            type={"submit"} />
                    </Grid>
                    {renderUnknownErrorNotice()}
                </Grid>
            </form>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        login: state.loginReg.loginLogin,
        loginErr: state.loginReg.loginLoginErr,
        loginErrWhat: state.loginReg.loginLoginErrWhat,

        isFetching: state.loginReg.isFetching,
        waitCode: state.loginReg.forgotWaitCode,

        newPassword: state.loginReg.newPasswd,
        newPasswordErr: state.loginReg.newPasswdErr,
        newPasswordErrWhat: state.loginReg.newPasswdErrWhat,

        newPasswordConfirm: state.loginReg.newPasswdConfirm,
        newPasswordConfirmErr: state.loginReg.newPasswdConfirmErr,
        newPasswordConfirmErrWhat: state.loginReg.newPasswdConfirmErrWhat,

        code: state.loginReg.passResetCode,
        unknownErr: state.loginReg.passResetUnknownErr,
        codeMismatch: state.loginReg.passResetInvalidCodeErr,
        errReason: state.loginReg.passResetErrReason,
        passChanged: state.loginReg.passChanged,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginChanged: (e) => {
            dispatch(updateLoginLoginText(e.target.value));
        },
        resetPass: () => {
            dispatch(resetPassAC());
        },
        newPasswordChanged: (e) => {
            dispatch(updateNewPwdText(e.target.value));
        },
        newPasswordConfirmChanged: (e) => {
            dispatch(updateNewPwdConfirmText(e.target.value));
        },
        codeChanged: (e) => {
            dispatch(newPassCodeTextUpdatedAC(e.target.value));
        },
        raiseUnknownErr: (reason) => {
            dispatch(resetUnknownErrAC(reason));
        },
        invalidCode: () => {
            dispatch(resetInvalidCodeAC());
        },
        sendPassChanged: () => {
            dispatch(passChangedAC());
        },
        reset: () => {
            dispatch(resetStateAC());
            dispatch(showLoginDialogAC());
        },
        setFetching: (fetching) => {
            dispatch(setFetchingAC(fetching));
        },
    }
}

const ForgotPassViewContainer = connect(mapStateToProps, mapDispatchToProps)(ForgotPass);
export default ForgotPassViewContainer;