import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import StyledButton from '../entities/StyledButton';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { useState } from 'react';
import StyledTextField from '../entities/StyledTextField';
import RadioRow from '../entities/RadiosColumn';
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

export default function FeedbackDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [text, setText] = useState(props.currentText === undefined ? '' : props.currentText);
    const [type, setType] = useState(props.currentType === undefined ? 1 : props.currentType);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const maxLen = 300;

    const textChanged = (e) => {
        if (e.target.value.length > maxLen)
            return;
        setText(e.target.value);
    };

    const onTypeChange = (event) => {
        setType(event.target.value);
    }

    return (
        <React.Fragment>
            <StyledButton
                size={'small'}
                label={props.update ? 'Изменить отзыв' : 'Оставить отзыв'}
                icon={props.update ? <EditIcon /> : <ThumbUpIcon />}
                onClick={handleClickOpen}
                disabled={props.disabled}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const email = formJson.email;
                        console.log(email);
                        handleClose();
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        fontFamily: 'Jura',
                        bgcolor: '#262831',
                        color: '#6dc353',
                    }}
                >
                    Ваш отзыв
                </DialogTitle>

                <DialogContent
                    sx={{
                        fontFamily: 'Jura',
                        fontWeight: '600',
                        bgcolor: '#eeeeee',
                        '&.MuiTypography-root': {
                            color: 'black',
                        }
                    }}
                >
                    <DialogContentText sx={{
                        fontFamily: 'Jura',
                        fontWeight: '600',
                        '&.MuiTypography-root': {
                            color: 'black',
                        }
                    }}>
                        Выберите тип отзыва
                    </DialogContentText>

                    <RadioRow
                        items={[
                            { label: "Положительный", value: 1 },
                            { label: "Нейтральный", value: 0 },
                            { label: "Отрицательный", value: -1 },
                        ]}
                        defaultValue={props.currentType === undefined ? 1 : props.currentType}
                        onChange={onTypeChange}
                    />

                    <DialogContentText noT sx={{
                        fontFamily: 'Jura',
                        fontWeight: '600',
                        marginBottom: '10px',
                        '&.MuiTypography-root': {
                            color: 'black',
                        }
                    }}>
                        Опишите, в свободной форме, как всё прошло? Максимум 300 символов:
                    </DialogContentText>


                    <StyledTextField
                        value={text}
                        onChange={textChanged}
                        multiline={true}
                        label="Текст отзыва"
                        darkMode
                    />
                </DialogContent>
                <DialogActions sx={{
                    bgcolor: '#eeeeee',
                }}>
                    <StyledButton
                        onClick={handleClose}
                        label={"Отмена"}
                    />
                    <StyledButton
                        onClick={() => {
                            handleClose();

                            props.onConfirm(props.id, type, text);
                            if (props.currentText === undefined) {
                                setType(1);
                                setText('');
                            }
                        }}
                        label={"Готово"}
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
