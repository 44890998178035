import { FormGroup2pn } from "../../FormGroup";
import React from "react";
import {
    updateMtxPn1AC, updateMtxPn2AC,
} from "../../../redux/add-composition-reducer";
import { connect } from "react-redux";

const MtxAddForm = (props) => {

    return (
        <>
            <FormGroup2pn
                title={"Шлейф(ы) матрицы"}
                description={"Внимательно осмотрите шлейф и наклейки на нем. После этого укажите здесь партнамберы, которые видите.\n" +
                    "                            Каждый партнамбер указывайте в отдельном поле."}
                val_pn1={props.mtx1}
                val_pn2={props.mtx2}
                cb_pn1={props.updateMtxPn1}
                cb_pn2={props.updateMtxPn2}
                pn1_err={props.mtx1Err}
                pn2_err={props.mtx2Err}
                pn1_errDesc={props.mtx1ErrWhat}
                pn2_errDesc={props.mtx2ErrWhat}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        mtx1: state.addCompositionPage.mtx_pn1,
        mtx2: state.addCompositionPage.mtx_pn2,
        mtx1Err: state.addCompositionPage.mtx_pn1Err,
        mtx2Err: state.addCompositionPage.mtx_pn2Err,
        mtx1ErrWhat: state.addCompositionPage.mtx_pn1ErrWhat,
        mtx2ErrWhat: state.addCompositionPage.mtx_pn2ErrWhat,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateMtxPn1: (val, err) => { dispatch(updateMtxPn1AC(val, err)); },
        updateMtxPn2: (val, err) => { dispatch(updateMtxPn2AC(val, err)); },
    }
}
const MtxAddFormContainer = connect(mapStateToProps, mapDispatchToProps)(MtxAddForm);
export default MtxAddFormContainer;