import React from 'react'
import { connect } from "react-redux";
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import TvIcon from '@material-ui/icons/Tv';
import ClearAllIcon from "@material-ui/icons/ClearAll";
import { makeStyles } from "@material-ui/core/styles";
import CompositionCheckerContainer from "../components/CompositionChecker";
import Spinner from "../components/Spinner";
import CompositionCheckerResults from "../components/CompositionCheckerResults";
import CompositionFormContainer from "../components/CompositionForm";
import CakeIcon from '@mui/icons-material/Cake';
import { styled } from "@mui/material/styles";
import { Container, Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import logo from "../components/logo.svg";
import MetaTags from "react-meta-tags";
import ErrorIcon from '@mui/icons-material/Error';
import { showLoginDialogAC } from "../redux/login-reg-reducer";
import Unauthorized from "../components/entities/Unauthed";


const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        margin: 5,
        background: '#202938',
    },
    card: {
        padding: '0px',
        paddingBottom: '0px !important',
    },
    link: {
        display: 'flex',
        marginTop: 10,
        marginBottom: 10,
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    title: {
        fontSize: 17,
        fontWeight: 500,
        color: '#6dc353',
        alignContent: 'center',
    },
    iconButton: {
        color: '#6dc353',
        padding: '3px',
    },
}));

const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}));

const AddComposition = (props) => {
    const classes = useStyles();

    if (!props.isLoggedIn) {
        props.showLoginDialog();
        return (
            <>
                <Unauthorized authCb={props.showLoginDialog} />
            </>
        )
    }

    const renderCheckerResults = () => {
        if (props.notStarted) {
            return (
                <>
                </>
            )
        }

        if (props.isChecking) {
            return (
                <Spinner />
            )
        }

        if (props.isFinished) {
            if (props.isSuccess) {
                return (
                    <>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={10} sm={10}>
                                <Card className={classes.root} variant="outlined">
                                    <CardContent className={classes.card}>
                                        <StyledContainer maxWidth="md">
                                            <CakeIcon
                                                className={classes.iconButton}
                                                fontSize={'large'} />
                                            <Typography className={classes.title} color="textSecondary" align={'center'} gutterBottom>
                                                Поздравляем!
                                            </Typography>
                                            <Typography className={classes.title} color="textSecondary" align={'center'} gutterBottom>
                                                Только что, Вы сделали naTeXtolite чуточку лучше!
                                            </Typography>
                                            <Typography className={classes.title} color="textSecondary" align={'center'} gutterBottom>
                                                Команда проекта благодарит Вас за проделанную работу и высоко ценит Вашу вовлеченность
                                            </Typography>
                                        </StyledContainer>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </>
                )
            }
            else {
                return (
                    <>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={10} sm={10}>
                                <Card className={classes.root} variant="outlined">
                                    <CardContent className={classes.card}>
                                        <StyledContainer maxWidth="md">
                                            <ErrorIcon
                                                className={classes.iconButton}
                                                fontSize={'large'} />
                                            <Typography className={classes.title} color="textSecondary" align={'center'} gutterBottom>
                                                Что-то пошло не так.
                                            </Typography>
                                            <Typography className={classes.title} color="textSecondary" align={'center'} gutterBottom>
                                                Пожалуйста попробуйте снова позднее
                                            </Typography>
                                        </StyledContainer>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </>
                )
            }
        }

        return (
            <>
                <CompositionCheckerResults isFound={props.isFound} />
                <CompositionFormContainer show={!props.isFound} />
            </>
        )
    }

    return (
        <>
            <MetaTags>
                <title>{`Добавить состав - наТекстолите`}</title>
                <meta name="description" content={`Добавить состав - наТекстолите`} />
                <meta property="og:title" content="naTeXtolite" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`${window.location.href}`} />
                <meta property="og:image" content={logo} />
                <meta property="og:description" content={`Сервисы - наТекстолите`} />
            </MetaTags>

            <Breadcrumbs aria-label="breadcrumb">
                <Link color="textPrimary" href="/" className={classes.link}>
                    <HomeIcon className={classes.icon} />
                    naTeXtolite
                </Link>
                <Link color="textPrimary" href="/services" className={classes.link}>
                    <ClearAllIcon className={classes.icon} />
                    Сервисы
                </Link>
                <Typography color="textPrimary" className={classes.link}>
                    <TvIcon className={classes.icon} />
                    Добавление состава ТВ
                </Typography>
            </Breadcrumbs>
            <h1>Добавление состава ТВ</h1>

            <p>Проверьте, нет ли в базе модели ТВ, состав которого вы собираетесь добавить:</p>

            <CompositionCheckerContainer />
            {renderCheckerResults()}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        searchedItem: state.addCompositionPage.searchedItem.replace("____", "/"),
        isFound: !state.addCompositionPage.tv_composition.empty,
        isChecking: state.addCompositionPage.isFetching,
        notStarted: !state.addCompositionPage.tried,
        isFinished: state.addCompositionPage.isFinished,
        isSuccess: state.addCompositionPage.isSuccess,
        isLoggedIn: state.loginReg.isLoggedIn,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showLoginDialog: () => {
            dispatch(showLoginDialogAC());
        },
    }
}

const AddCompositionContainer = connect(mapStateToProps, mapDispatchToProps)(AddComposition);
export default AddCompositionContainer;