import { FormGroup2pn, FormGroup3pn, FormGroup6pn } from "../FormGroup";
import React from "react";
import { updatePn1AC, updatePn2AC, updatePn3AC, updatePn4AC, updatePn5AC, updatePn6AC, setAndConfirmNewPnsAC } from "../../redux/add-1-listing-reducer";
import { addPackUpdatePn1AC, addPackUpdatePn2AC, addPackUpdatePn3AC, addPackUpdatePn4AC, addPackUpdatePn5AC, addPackUpdatePn6AC } from "../../redux/add-multi-listings-reducer";
import { packPnsConfirmAC, packSetPnsAC, packConfirmStageIfLastPnAC } from "../../redux/add-multi-listings-reducer";
import { connect } from "react-redux";
import StyledButton from "../entities/StyledButton";
import DoneIcon from '@mui/icons-material/Done';
import { Stack } from "@mui/material";

const Injection = (props) => {

    const partnumersPerType = () => {
        switch (props.hwTypes.find(e => e.text === props.hwType).type) {
            case "bkl":
                return 6;

            case "ssb":
            case "psu":
            case "tcn":
            case "ir":
            case "btn":
            case "wlan":
            case "bt":
            case "dyn":
            case "cam":
            case "inv":
                return 3;

            case "pnl":
            case "ld":
            case "lvds":
            case "mtx":
                return 2;

            default: return undefined;
        }
    }

    const render = () => {
        switch (partnumersPerType()) {
            case 2:
                return (
                    <>
                        <FormGroup2pn
                            title={`${props.hwType} на ${props.vendor} ${props.model}`}
                            description={"Внимательно осмотрите плату, шелкографию и наклейки на ней. После этого укажите здесь партнамберы, которые видите.\n" +
                                "                            Каждый партнамбер указывайте в отдельном поле.\n" +
                                "                            Например, на плате вы видите \"BN41-01976B\", а на наклейке \"BN96-26401N\".\n" +
                                "                            В поле \"Партнамбер 1\" укажите \"BN41-01976B\", а в поле \"Партнамбер 2\" - \"BN96-26401N\"."}
                            val_pn1={props.pn1}
                            val_pn2={props.pn2}
                            cb_pn1={props.updatePn1}
                            cb_pn2={props.updatePn2}
                            pn1_err={props.pn1Err}
                            pn2_err={props.pn2Err}
                            pn1_errDesc={props.pn1ErrWhat}
                            pn2_errDesc={props.pn2ErrWhat}
                        />
                    </>
                );
            case 3:
                return (
                    <>
                        <FormGroup3pn
                            title={`${props.hwType} на ${props.vendor} ${props.model}`}
                            description={"Внимательно осмотрите плату, шелкографию и наклейки на ней. После этого укажите здесь партнамберы, которые видите.\n" +
                                "                            Каждый партнамбер указывайте в отдельном поле.\n" +
                                "                            Например, на плате вы видите \"BN41-01976B\", а на наклейке \"BN96-26401N\".\n" +
                                "                            В поле \"Партнамбер 1\" укажите \"BN41-01976B\", а в поле \"Партнамбер 2\" - \"BN96-26401N\"."}
                            val_pn1={props.pn1}
                            val_pn2={props.pn2}
                            val_pn3={props.pn3}
                            cb_pn1={props.updatePn1}
                            cb_pn2={props.updatePn2}
                            cb_pn3={props.updatePn3}
                            pn1_err={props.pn1Err}
                            pn2_err={props.pn2Err}
                            pn3_err={props.pn3Err}
                            pn1_errDesc={props.pn1ErrWhat}
                            pn2_errDesc={props.pn2ErrWhat}
                            pn3_errDesc={props.pn3ErrWhat}
                        />
                    </>
                );
            case 6:
                return (
                    <FormGroup6pn
                        title={`${props.hwType} на ${props.vendor} ${props.model}`}
                        description={"Внимательно осмотрите плату, шелкографию и наклейки на ней. После этого укажите здесь партнамберы, которые видите.\n" +
                            "                            Каждый партнамбер указывайте в отдельном поле.\n" +
                            "                            Например, на плате вы видите \"BN41-01976B\", а на наклейке \"BN96-26401N\".\n" +
                            "                            В поле \"Партнамбер 1\" укажите \"BN41-01976B\", а в поле \"Партнамбер 2\" - \"BN96-26401N\"."}
                        val_pn1={props.pn1}
                        val_pn2={props.pn2}
                        val_pn3={props.pn3}
                        val_pn4={props.pn4}
                        val_pn5={props.pn5}
                        val_pn6={props.pn6}
                        cb_pn1={props.updatePn1}
                        cb_pn2={props.updatePn2}
                        cb_pn3={props.updatePn3}
                        cb_pn4={props.updatePn4}
                        cb_pn5={props.updatePn5}
                        cb_pn6={props.updatePn6}
                        pn1_err={props.pn1Err}
                        pn2_err={props.pn2Err}
                        pn3_err={props.pn3Err}
                        pn4_err={props.pn4Err}
                        pn5_err={props.pn5Err}
                        pn6_err={props.pn6Err}
                        pn1_errDesc={props.pn1ErrWhat}
                        pn2_errDesc={props.pn2ErrWhat}
                        pn3_errDesc={props.pn3ErrWhat}
                        pn4_errDesc={props.pn4ErrWhat}
                        pn5_errDesc={props.pn5ErrWhat}
                        pn6_errDesc={props.pn6ErrWhat}
                    />
                );
            default:
                console.log("WARNING: no default handler");
        }
    }

    return (
        <>
            {render()}

            <Stack direction='row' justifyContent='center'>
                <StyledButton
                    icon={<DoneIcon />}
                    label="Готово"
                    onClick={() => {
                        const pns = partnumersPerType();
                        switch (pns) {
                            case 2:
                                if (props.pn1 === "" && props.pn2 === "")
                                    return;
                                break;
                            case 3:
                                if (props.pn1 === "" && props.pn2 === "" && props.pn3 === "")
                                    return;
                                break;
                            case 6:
                                if (props.pn1 === "" && props.pn2 === "" && props.pn3 === "" &&
                                    props.pn4 === "" && props.pn5 === "" && props.pn6 === "")
                                    return;
                                break;
                            default: return;
                        }

                        switch (pns) {
                            case 2:
                                if (props.pn1Err || props.pn2Err)
                                    return;
                                break;
                            case 3:
                                if (props.pn1Err || props.pn2Err || props.pn3Err)
                                    return;
                                break;
                            case 6:
                                if (props.pn1Err || props.pn2Err || props.pn3Err ||
                                    props.pn4Err || props.pn5Err || props.pn6Err)
                                    return;
                                break;
                            default: return;
                        }

                        let pnsValue = "";
                        switch (pns) {
                            case 2:
                                pnsValue = [props.pn1, props.pn2].filter(Boolean).join(', ');
                                break;
                            case 3:
                                pnsValue = [
                                    props.pn1, props.pn2, props.pn3
                                ].filter(Boolean).join(', ');
                                break;
                            case 6:
                                pnsValue = [
                                    props.pn1, props.pn2, props.pn3,
                                    props.pn4, props.pn5, props.pn6
                                ].filter(Boolean).join(', ');
                                break;
                            default: break;
                        }

                        props.setAndConfirm(pnsValue);
                    }}
                />
            </Stack>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    if (ownProps.multi) {
        const idx = ownProps.injectionIndex;
        return {
            multi: ownProps.multi,
            hwType: ownProps.hwType.text,
            hwTypes: state.addPack.hwTypes,
            vendor: state.addPack.vendor,
            model: state.addPack.model,
            pn1: state.addPack.listingsData[idx].pn1,
            pn2: state.addPack.listingsData[idx].pn2,
            pn3: state.addPack.listingsData[idx].pn3,
            pn4: state.addPack.listingsData[idx].pn4,
            pn5: state.addPack.listingsData[idx].pn5,
            pn6: state.addPack.listingsData[idx].pn6,
            pn1Err: state.addPack.listingsData[idx].pn1Err,
            pn2Err: state.addPack.listingsData[idx].pn2Err,
            pn3Err: state.addPack.listingsData[idx].pn3Err,
            pn4Err: state.addPack.listingsData[idx].pn4Err,
            pn5Err: state.addPack.listingsData[idx].pn5Err,
            pn6Err: state.addPack.listingsData[idx].pn6Err,
            pn1ErrWhat: state.addPack.listingsData[idx].pn1ErrWhat,
            pn2ErrWhat: state.addPack.listingsData[idx].pn2ErrWhat,
            pn3ErrWhat: state.addPack.listingsData[idx].pn3ErrWhat,
            pn4ErrWhat: state.addPack.listingsData[idx].pn4ErrWhat,
            pn5ErrWhat: state.addPack.listingsData[idx].pn5ErrWhat,
            pn6ErrWhat: state.addPack.listingsData[idx].pn6ErrWhat,
        }
    }
    return {
        hwType: state.addSingle.hwType,
        hwTypes: state.addSingle.hwTypes,
        vendor: state.addSingle.vendor,
        model: state.addSingle.model,
        pn1: state.addSingle.pn1,
        pn2: state.addSingle.pn2,
        pn3: state.addSingle.pn3,
        pn4: state.addSingle.pn4,
        pn5: state.addSingle.pn5,
        pn6: state.addSingle.pn6,
        pn1Err: state.addSingle.pn1Err,
        pn2Err: state.addSingle.pn2Err,
        pn3Err: state.addSingle.pn3Err,
        pn4Err: state.addSingle.pn4Err,
        pn5Err: state.addSingle.pn5Err,
        pn6Err: state.addSingle.pn6Err,
        pn1ErrWhat: state.addSingle.pn1ErrWhat,
        pn2ErrWhat: state.addSingle.pn2ErrWhat,
        pn3ErrWhat: state.addSingle.pn3ErrWhat,
        pn4ErrWhat: state.addSingle.pn4ErrWhat,
        pn5ErrWhat: state.addSingle.pn5ErrWhat,
        pn6ErrWhat: state.addSingle.pn6ErrWhat,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    if (ownProps.multi) {
        return {
            updatePn1: (val, err) => { dispatch(addPackUpdatePn1AC(ownProps.injectionIndex, val, err)); },
            updatePn2: (val, err) => { dispatch(addPackUpdatePn2AC(ownProps.injectionIndex, val, err)); },
            updatePn3: (val, err) => { dispatch(addPackUpdatePn3AC(ownProps.injectionIndex, val, err)); },
            updatePn4: (val, err) => { dispatch(addPackUpdatePn4AC(ownProps.injectionIndex, val, err)); },
            updatePn5: (val, err) => { dispatch(addPackUpdatePn5AC(ownProps.injectionIndex, val, err)); },
            updatePn6: (val, err) => { dispatch(addPackUpdatePn6AC(ownProps.injectionIndex, val, err)); },
            setAndConfirm: (pns) => {
                dispatch(packSetPnsAC(ownProps.injectionIndex, pns, -1));
                dispatch(packPnsConfirmAC(ownProps.injectionIndex));
                dispatch(packConfirmStageIfLastPnAC());
            },
        }
    }

    return {
        updatePn1: (val, err) => { dispatch(updatePn1AC(val, err)); },
        updatePn2: (val, err) => { dispatch(updatePn2AC(val, err)); },
        updatePn3: (val, err) => { dispatch(updatePn3AC(val, err)); },
        updatePn4: (val, err) => { dispatch(updatePn4AC(val, err)); },
        updatePn5: (val, err) => { dispatch(updatePn5AC(val, err)); },
        updatePn6: (val, err) => { dispatch(updatePn6AC(val, err)); },
        setAndConfirm: (pns) => { dispatch(setAndConfirmNewPnsAC(pns)); },
    }
}
const InjectionContainer = connect(mapStateToProps, mapDispatchToProps)(Injection);
export default InjectionContainer;