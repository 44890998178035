import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import StyledButton from './StyledButton';

/*
 * Props:
 * buttonText
 * buttonIcon
 * onConfirm - cb if user decide to proceed
 * titleText - ...
 * descriptionText - ...
 * cancelText - ...
 * proceedText - ...
*/

export default function DialogAlert(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <StyledButton
                icon={props.buttonIcon}
                size={props.primaryButtonSize ? props.primaryButtonSize : 'small'}
                onClick={handleClickOpen}
                label={props.buttonText}
                disabled={props.startButtonDisabled}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                        fontFamily: 'Jura',
                        bgcolor: '#262831',
                        color: '#6dc353',
                    }}
                >
                    {props.titleText}
                </DialogTitle>
                <DialogContent
                    sx={{
                        fontFamily: 'Jura',
                        fontWeight: '600',
                        bgcolor: '#262831',
                        color: '#6dc353',
                    }}
                >
                    <DialogContentText id="alert-dialog-description" sx={{
                        fontFamily: 'Jura',
                        fontWeight: '500',
                        bgcolor: '#262831',
                        color: '#cccccc',
                    }}>
                        {props.descriptionText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{
                    bgcolor: '#262831',
                }}>
                    <StyledButton
                        onClick={handleClose}
                        label={props.cancelText ? props.cancelText : "Отмена"}
                    />
                    <StyledButton
                        onClick={() => {
                            props.onConfirm();
                            handleClose();
                        }}
                        label={props.proceedText ? props.proceedText : "Продолжить"}
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}
