import fetch from "cross-fetch";

const FB_SET_FETCHING = 'FB_SET_FETCHING';
const FB_DATA_UPDATED = 'FB_DATA_UPDATED';

const FB_FILTER_FROM_CHANGED = 'FB_FILTER_FROM_CHANGED';
const FB_FILTER_TYPE_CHANGED = 'FB_FILTER_TYPE_CHANGED';

const FB_FETCH_ERROR = 'FB_FETCH_ERROR';

const LOG_REG_LOGOUT = 'LOG_REG_LOGOUT';

let initialState = {
    isFetching: false,

    // feedback data...
    feedback: [
        /*
        { fromUser: "VladTvParts", fromRole: "buyer", type: "positive", date: "17.03.2023", feedback: "На 5+. Упаковано грамотно, отправка сразу утром, вечером пришло.Следующий день забрал, установили на UE46F8000AT - работает. Рекомендую" },
        { fromUser: "MonahTuk", fromRole: "buyer", type: "neutral", date: "28.12.2022", feedback: "Не оказалось в наличии" },
        */
    ],
    feedbackTotal: 0,

    filteredFeedback: [],

    filterFrom: 'any',
    filterType: 'any',
};

const filtered = (src, typeFilter, fromFilter) => {
    let result = src;
    if (typeFilter !== 'any') {
        result = result.filter(function (item) { return item.type === typeFilter; });
    }

    if (fromFilter !== 'any') {
        result = result.filter(function (item) { return item.fromRole === fromFilter; });
    }

    return result;
}

const myFeedbackReducer = (state = initialState, action) => {
    switch (action.type) {
        case FB_SET_FETCHING:
            return Object.assign({}, state, {
                isFetching: action.isFetching,
            });

        case FB_DATA_UPDATED:
            return Object.assign({}, state, {
                isFetching: false,
                feedback: action.feedback,
                feedbackTotal: action.feedbackTotal,
                filteredFeedback: filtered(action.feedback, 'any', 'any'),
                filterFrom: 'any',
                filterType: 'any',
            });

        case FB_FILTER_FROM_CHANGED:
            return Object.assign({}, state, {
                isFetching: false,
                filterFrom: action.filter,
                filteredFeedback: filtered(state.feedback, state.filterType, action.filter)
            });

        case FB_FILTER_TYPE_CHANGED:
            return Object.assign({}, state, {
                isFetching: false,
                filterType: action.filter,
                filteredFeedback: filtered(state.feedback, action.filter, state.filterFrom)
            });

        case LOG_REG_LOGOUT:
            return Object.assign({}, state, initialState);

        default:
            return state;
    }
};

export const fbSetFetchingAC = (isFetching) => ({
    type: FB_SET_FETCHING,
    isFetching: isFetching
})

export const updatedFeedbackData = (data) => ({
    type: FB_DATA_UPDATED,
    feedback: data.feedback,
    feedbackTotal: data.feedbackTotal
})

export const fbFilterFromChangedAC = (filter) => ({
    type: FB_FILTER_FROM_CHANGED,
    filter: filter
})

export const fbFilterTypeChangedAC = (filter) => ({
    type: FB_FILTER_TYPE_CHANGED,
    filter: filter
})

export const fbFetchErrorAC = (what) => ({
    type: FB_FETCH_ERROR,
    what: what
})




export function fetchMyFeedback() {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/myfeedback', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(updatedFeedbackData(json.data))
                    } else {
                        dispatch(fbFetchErrorAC('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export default myFeedbackReducer;