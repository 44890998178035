import React from 'react'
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import SellIcon from '@mui/icons-material/Sell';
import HistoryIcon from '@mui/icons-material/History';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import DeleteIcon from '@mui/icons-material/Delete';
import FunctionsIcon from '@mui/icons-material/Functions';
import { Stack } from '@mui/material';
import { stateFilterUpdated } from '../../redux/my-listings-reducer';
import { Link } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        marginRight: 5,
        marginLeft: 5,
        //marginBottom: 10,
        background: '#202938',
        paddingBottom: 0,
        paddingTop: 5,
        borderColor: 'white',
    },
    header: {
        fontSize: 16,
        fontWeight: 700,
        color: '#dddddd',
        marginBottom: 6,
    },
    content: {
        color: '#dddddd',
        marginBottom: 4,
        paddingLeft: 5,
    },
    positive: {
        color: '#6dc353',
    },
    negative: {
        color: 'red',
    },
    neutral: {
        color: '#dddddd',
    },
});

const ListingsSummaryCard = (props) => {
    const classes = useStyles();

    return (
        <>
            <Card className={classes.root} variant="outlined">
                <CardContent className={classes.root}>
                    <Typography className={classes.header}>
                        Статус:
                    </Typography>

                    <Stack direction='row' spacing={2}>
                        <Link href="#" onClick={() => {
                            props.stateFilterChange('active')
                        }}>
                            <Stack direction='row' spacing={1}>
                                <SellIcon sx={{ color: '#6dc353' }} />
                                <Typography className={classes.content}>
                                    Активные:
                                </Typography>
                                <Typography className={props.active > 0 ? classes.positive : classes.neutral}>
                                    {props.active}
                                </Typography>
                            </Stack>
                        </Link>
                    </Stack>

                    <Stack direction='row' spacing={2}>
                        <Link href="#" onClick={() => {
                            props.stateFilterChange('expired')
                        }}>
                            <Stack direction='row' spacing={1}>
                                <HistoryIcon sx={{ color: props.expired > 0 ? 'red' : '#6dc353' }} />
                                <Typography className={classes.content}>
                                    Устарело:
                                </Typography>
                                <Typography className={props.expired > 0 ? classes.negative : classes.positive}>
                                    {props.expired}
                                </Typography>
                            </Stack>
                        </Link>
                    </Stack>

                    <Stack direction='row' spacing={2}>
                        <Link href="#" onClick={() => {
                            props.stateFilterChange('removed')
                        }}>
                            <Stack direction='row' spacing={1}>
                                <DeleteIcon sx={{ color: '#6dc353' }} />
                                <Typography className={classes.content}>
                                    Удалено:
                                </Typography>
                                <Typography className={classes.neutral}>
                                    {props.removed}
                                </Typography>
                            </Stack>
                        </Link>
                    </Stack>

                    <Stack direction='row' spacing={2}>
                        <Link href="#" onClick={() => {
                            props.stateFilterChange('any')
                        }}>
                            <Stack direction='row' spacing={1}>
                                <FunctionsIcon sx={{ color: '#6dc353' }} />
                                <Typography className={classes.content}>
                                    Всего:
                                </Typography>
                                <Typography className={classes.neutral}>
                                    {props.total}
                                </Typography>
                            </Stack>
                        </Link>
                    </Stack>
                </CardContent>
            </Card>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        active: state.myListingsPage.activeListings,
        expired: state.myListingsPage.expiredListings,
        removed: state.myListingsPage.removedListings,
        total: state.myListingsPage.listingsTotal,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        stateFilterChange: (newValue) => {
            dispatch(stateFilterUpdated(newValue));
        },
    }
}

const ListingsSummaryCardContainer = connect(mapStateToProps, mapDispatchToProps)(ListingsSummaryCard);
export default ListingsSummaryCardContainer;