import { React, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    listSearchFetchHwTypes, searchByTypeModel, listSearchByTypeSetModelText, listSearchByPnSetModelText,
    listSearchSetHwTypeAC, listSearchFixModelAC, searchByPnModel, listSearchSetPnText
} from '../../redux/listings-search-reducer';
import { GenericModelAutocompleteContainer } from '../entities/ModelAutocomplete';
import { Grid } from '@material-ui/core';
import { Stack, Typography } from '@mui/material';
import StyledSelect from "../entities/StyledSelect";
import StyledButton from '../entities/StyledButton';
import SearchIcon from '@material-ui/icons/Search';
import { Box } from '@mui/system';
import StyledTextField from '../entities/StyledTextField';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import useMediaQuery from '@mui/material/useMediaQuery';
import HelpIconWithTooltip from '../entities/HelpIconWithTooltip';
import history from "../../history/history";

const useStyles = makeStyles({
    root: {
        marginRight: 5,
        marginLeft: 5,
        marginBottom: 10,
        background: 'white',
        paddingTop: 0,
        borderColor: 'grey',

    },
    cardContent: {
        '&.MuiCardContent-root': {
            paddingTop: 7,
            paddingBottom: 0,
        },
    },
    header: {
        fontSize: 16,
        color: 'black',
        '&.MuiTypography-root': {
            fontFamily: 'Jura',
            fontWeight: 700,
            marginBottom: 10,
        },
    },
    icon: {
        color: '#6dc353',
    },
    content: {
        '&.MuiTypography-root': {
            fontFamily: 'Jura',
            fontWeight: 600,
        },
        color: 'black',
        paddingTop: 7,
    },
    searchModeHeader: {
        fontFamily: 'Jura, Arial',
        fontSize: 16,
    },
    stack: {
        marginBottom: 5,
    }
});

const ListingsSearchHeader = (props) => {
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down("xs")) ? true : false;
    useEffect(() => {
        props.fetchHwTypes();
    }, []);

    const classes = useStyles();

    const searchByTypeModel = (e) => {
        e.preventDefault();
        console.log("search - type+model")
        if (props.hwType === '' || props.typeSearchModel === '') {
            return;
        }

        const url = `/services/search/tv/bytype$${props.hwType}$${props.typeSearchModel}`;
        history.push(url);
        //props.searchByTypeModel(props.hwType, props.typeSearchModel);
    }

    const searchByPns = (e) => {
        e.preventDefault();
        console.log("search - pn+model")
        if (props.searchedPn === '') {
            return;
        }

        const url = `/services/search/tv/pnmodel$${props.searchedPn}$${props.pnSearchModel}`;
        history.push(url);
        //props.searchByPnModel(props.searchedPn, props.pnSearchModel);
    }

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                alignContent='center'
            >
                <Grid item xs={12} sm={6}>
                    <Card className={classes.root} variant="outlined" style={{ height: small ? 'auto' : '100%' }} >
                        <CardContent className={classes.cardContent}>
                            <Stack direction='row' spacing={1}>
                                <Typography className={classes.header}>
                                    Поиск по типу и модели ТВ
                                </Typography>
                                <HelpIconWithTooltip
                                    text="В этом режиме в результатах поиска будут те объявления, в которых и модель ТВ и тип запчасти совпали с указанными Вами. Оба параметра должны быть указаны"
                                />
                            </Stack>

                            <Box sx={{
                                direction: 'column',
                                alignItems: 'center',
                                textAlign: 'center'
                            }}>
                                <form onSubmit={searchByTypeModel}>
                                    <Stack direction='row' spacing={1} className={classes.stack}>
                                        <Typography className={classes.content}>
                                            Что
                                        </Typography>

                                        <StyledSelect
                                            label="Выберите тип"
                                            items={props.hwTypesList.map(a => a.text)}
                                            cb={props.setHwType}
                                            forceValue
                                            value={props.hwTypeIndex === -1 ? '' : props.hwTypeIndex}
                                        />
                                    </Stack>

                                    <Stack direction='row' spacing={1} className={classes.stack}>
                                        <Typography className={classes.content}>
                                            Для
                                        </Typography>

                                        <GenericModelAutocompleteContainer
                                            noButton
                                            model={props.typeSearchModel}
                                            onValueChangeCb={(text) => { props.listSearchByTypeSetModelText(text) }}
                                            forceValue
                                            value={props.typeSearchModel}
                                        />
                                    </Stack>

                                    <StyledButton
                                        icon={<SearchIcon />}
                                        label="Искать"
                                        size='small'
                                        type="submit"
                                        onClick={searchByTypeModel}
                                    />
                                </form>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Card className={classes.root} variant="outlined" style={{ height: '100%' }}>
                        <CardContent className={classes.cardContent}>
                            <Stack direction='row' spacing={1}>
                                <Typography className={classes.header}>
                                    Поиск по партнамберам
                                </Typography>
                                <HelpIconWithTooltip
                                    text="Если указан только партнамбер - в результатах поиска будут объявления, ассоциированные с указанным Вами партнамбером. Если указаны и партнамбер и модель ТВ - в результатах поиска будут объявления, в которых и модель ТВ и партнамбер запчасти совпали с указанными Вами"
                                />
                            </Stack>

                            <Box sx={{
                                direction: 'column',
                                alignItems: 'center',
                                textAlign: 'center'
                            }}>
                                <form onSubmit={searchByPns}>
                                    <Stack direction='row' spacing={1} className={classes.stack}>
                                        <Typography className={classes.content}>
                                            Что
                                        </Typography>
                                        <StyledTextField
                                            placeholder="Введите партнамбер"
                                            noMargins
                                            onChange={(e) => { props.setPnText(e.target.value) }}
                                            value={props.searchedPn}
                                        />
                                    </Stack>

                                    <Stack direction='row' spacing={1} className={classes.stack}>
                                        <Typography className={classes.content}>
                                            Для
                                        </Typography>

                                        <GenericModelAutocompleteContainer
                                            noButton
                                            model={props.pnSearchModel}
                                            onValueChangeCb={(text) => { props.listSearchByPnSetModelText(text) }}
                                            forceValue
                                            value={props.pnSearchModel}
                                        />
                                    </Stack>

                                    <StyledButton
                                        icon={<SearchIcon />}
                                        label="Искать"
                                        size='small'
                                        type="submit"
                                        onClick={searchByPns}
                                    />
                                </form>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid >
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        // common
        isFetching: state.listingsSearch.isFetching,
        hwTypesList: state.listingsSearch.hwTypes,
        // search by type + model
        hwType: state.listingsSearch.hwType,
        hwTypeIndex: state.listingsSearch.hwTypeIndex,
        typeSearchModel: state.listingsSearch.typeSearchModel,
        // search by pn + model
        pnSearchModel: state.listingsSearch.pnSearchModel,
        searchedPn: state.listingsSearch.searchedPn,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchHwTypes: () => {
            dispatch(listSearchFetchHwTypes());
        },
        listSearchByTypeSetModelText: (newText) => {
            if (newText !== undefined) {
                dispatch(listSearchByTypeSetModelText(newText));
            }
        },
        listSearchByPnSetModelText: (newText) => {
            if (newText !== undefined) {
                dispatch(listSearchByPnSetModelText(newText));
            }
        },
        setHwType: (type, index) => {
            dispatch(listSearchSetHwTypeAC(type, index));
        },
        setPnText: (newText) => {
            dispatch(listSearchSetPnText(newText));
        },
        searchByTypeModel: (hwType, model) => {
            dispatch(listSearchFixModelAC(model));
            dispatch(searchByTypeModel(`${hwType}$${model}`));
        },
        searchByPnModel: (pn, model) => {
            dispatch(listSearchFixModelAC(model));
            dispatch(searchByPnModel(`${pn}$${model}`));
        },
    }
}

const ListingsSearchHeaderContainer = connect(mapStateToProps, mapDispatchToProps)(ListingsSearchHeader);
export default ListingsSearchHeaderContainer;