import fetch from "cross-fetch";

const ANOTHER_USER_SET_FETCHING = 'ANOTHER_USER_SET_FETCHING';
const ANOTHER_USER_DATA_UPDATED = 'ANOTHER_USER_DATA_UPDATED';

const ANOTHER_USER_NO_USER = 'ANOTHER_USER_NO_USER';

let initialState = {
    isFetching: false,

    noSuchUser: false,

    login: '',
    city: '',
    cityType: '',
    region: '',
    ratingAbs: 0,
    ratingRel: 0,
    worksSince: '',

    description: '',

    //listings data:...
    listingsTotal: 0,
    listings: [
    ],

    // feedback data...
    feedback: [
    ]

};

const anotherUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case ANOTHER_USER_SET_FETCHING:
            return Object.assign({}, state, {
                isFetching: action.isFetching,
            });

        case ANOTHER_USER_DATA_UPDATED:
            return Object.assign({}, state, {
                isFetching: false,
                noSuchUser: false,
                login: action.userData.login,
                city: action.userData.city,
                cityType: action.userData.cityType,
                region: action.userData.region,
                ratingAbs: action.userData.absRating,
                ratingRel: action.userData.relRating,
                worksSince: action.userData.registered_at,
                description: action.userData.store_desc,
                listings: action.listings,
                listingsTotal: action.listingsTotal,
                feedback: action.feedback,
                feedbackTotal: action.feedbackTotal,
            });

        case ANOTHER_USER_NO_USER:
            return Object.assign({}, state, {
                noSuchUser: true,
                isFetching: false,
            });


        default:
            return state;
    }
};

export const setFetchingAC = (isFetching) => ({
    type: ANOTHER_USER_SET_FETCHING,
    isFetching: isFetching
})

export const setNoSuchUserAC = () => ({
    type: ANOTHER_USER_NO_USER,
})


export const updatedUserData = (data) => ({
    type: ANOTHER_USER_DATA_UPDATED,
    userData: data.userData,
    listings: data.listings,
    listingsTotal: data.listingsTotal,
    feedback: data.feedback,
    feedbackTotal: data.feedbackTotal,
})

export function fetchUserData(login) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + `/api/users/profile/${login}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(updatedUserData(json.data))
                    } else if (json.statusCode === 400) {
                        dispatch(setNoSuchUserAC())
                    }
                }
            )
    }
}


export default anotherUserReducer;