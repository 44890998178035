import * as React from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

/* Props:
    - value: current phone num value
    - onChange: onChange callback with the following signature:
        (value, country, e, formattedValue)
*/

const StyledPhoneInput = (props) => {
    return (
        <>
            <PhoneInput
                country={'ru'}
                disableDropdown
                countryCodeEditable={false}
                value={props.value}
                onChange={props.onChange}
                inputStyle={{
                    width: 'auto',
                    fontFamily: "Jura",
                    
                }}
                buttonStyle={{
                    paddingLeft: '0px',
                }}
                disabled={props.disabled}
            />
        </>
    );
}

export default StyledPhoneInput;