import React from 'react'
import { Container } from '@material-ui/core';
import logo from './logo.svg'
import { Box, Grid, Stack } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    header: {
        color: '#6dc353',
        fontSize: '16px',
        fontWeight: 600,
        paddingLeft: 5,
    },
    link: {
        color: '#6dc353',
        fontSize: '13px',
        fontWeight: 500,
        marginBottom: 5,
        paddingLeft: 10,
    },
    brand: {
        color: '#6dc353',
        fontSize: 20,
        fontWeight: 300,
        marginBottom: 5,
        paddingLeft: 5,
    },
    copyright: {
        color: '#6dc353',
        fontSize: 16,
        fontWeight: 300,
        marginBottom: 5,
        paddingLeft: 5,
    },
    feedbackTop: {
        color: '#6dc353',
        fontSize: 16,
        fontWeight: 300,
        marginTop: 20,
    },
    feedback: {
        color: '#6dc353',
        fontSize: 16,
        fontWeight: 300,
        marginBottom: 15,
    },
});

const Footer = () => {
    const classes = useStyles();

    return (
        <>
            <footer>
                <Container maxWidth="md">
                    <div className="container_footer">
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="stretch"
                        >
                            <Grid item
                                display={'flex'}
                                flexDirection={'column'}
                                marginBottom={5}
                            >
                                <Stack direction={'row'} alignItems={'flex-end'} spacing={0}>
                                    <img
                                        src={logo}
                                        height="100"
                                        width="100"
                                        className="logo"
                                        alt=""
                                    />
                                    <Box>
                                        <Typography className={classes.brand} gutterBottom>
                                            naTeXtolite® Project
                                        </Typography>
                                        <Typography className={classes.copyright} gutterBottom>
                                            © naTeXtolite 2020-2024
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Grid>

                            <Grid item
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'сenter'}
                                marginBottom={2}
                            >
                                <Box display={'flex'}
                                    flexDirection={'column'}
                                    alignItems={'start'}

                                >
                                    <Typography className={classes.header} gutterBottom>
                                        О нас
                                    </Typography>

                                    <Typography className={classes.link} gutterBottom>
                                        ИП Ечкало Е.М.
                                    </Typography>

                                    <Typography className={classes.link} gutterBottom>
                                        ИНН: 000000000
                                    </Typography>

                                    <Typography className={classes.link} gutterBottom>
                                        ОГРНИП: 013013013013
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'сenter'}
                                marginBottom={2}
                            >
                                <Box display={'flex'}
                                    flexDirection={'row'}
                                    justifyContent={'center'}
                                >
                                </Box>
                                <Box display={'flex'}
                                    flexDirection={'column'}
                                >
                                    <Typography className={classes.header} gutterBottom>
                                        Документы
                                    </Typography>

                                    <Link href={'#'}>
                                        <Typography className={classes.link} gutterBottom>
                                            Согласие на обработку ПД
                                        </Typography>
                                    </Link>

                                    <Link href={'#'}>
                                        <Typography className={classes.link} gutterBottom>
                                            Пользовательское соглашение
                                        </Typography>
                                    </Link>

                                    <Link href={'#'}>
                                        <Typography className={classes.link} gutterBottom>
                                            Политика персональных данных
                                        </Typography>
                                    </Link>

                                    <Link href={'#'}>
                                        <Typography className={classes.link} gutterBottom>
                                            Правила сайта
                                        </Typography>
                                    </Link>
                                </Box>
                            </Grid>
                        </Grid>

                        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                            <Typography className={classes.feedbackTop}>

                            </Typography>
                            <Typography className={classes.feedback}>
                                Обратная связь: admin@natextolite.ru
                            </Typography>
                        </Box>
                    </div>
                </Container>
            </footer >
        </>
    )
}

export default Footer;