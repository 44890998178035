import { React, useEffect } from 'react'
import ProfileHeaderContainer from '../../components/ProfileHeader';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import SellIcon from '@mui/icons-material/Sell';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import MyListingsHeaderContainer from '../../components/profile/MyListingsHeader';
import MyListingsContainer from '../../components/listings/MyListings';
import { connect } from 'react-redux';
import { fetchMyListings } from '../../redux/my-listings-reducer';
import Spinner from '../../components/Spinner';
import MetaTags from 'react-meta-tags';
import Unauthorized from '../../components/entities/Unauthed';
import { showLoginDialogAC } from "../../redux/login-reg-reducer";

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
        marginTop: 10,
        marginBottom: 10,
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        spacing: 2,
    }
}));

const MyListings = (props) => {
    const classes = useStyles();
    useEffect(() => {
        props.sync();
    }, []);

    if (!props.isLoggedIn) {
        props.showLoginDialog();
        return (
            <>
                <Unauthorized authCb={props.showLoginDialog} />
            </>
        )
    }

    const renderContent = () => {
        if (props.isFetching) {
            return <Spinner />
        }

        if (props.listingsTotal === 0) {
            return (
                <>
                    <h2>¯\_(ツ)_/¯</h2>
                    <h2>Вы пока не добавили ни одного объявления</h2>
                </>
            )
        }

        if (props.listingsFiltered.length === 0) {
            return (
                <>
                    <h2>¯\_(ツ)_/¯</h2>
                    <h2>Нет объявлений, отвечающих выбранным критериям</h2>
                </>
            )
        }

        return <MyListingsContainer pageSize={10} />;
    }

    return (
        <>
            <MetaTags>
                <title>Мои объявления - naTextolite</title>
            </MetaTags>

            <Breadcrumbs aria-label="breadcrumb">
                <Link color="textPrimary" href="/" className={classes.link}>
                    <HomeIcon className={classes.icon} />
                    naTeXtolite
                </Link>
                <Link color="textPrimary" href="/personal" className={classes.link}>
                    <AccountBoxIcon className={classes.icon} />
                    Личный кабинет
                </Link>
                <Typography color="textPrimary" className={classes.link}>
                    <SellIcon className={classes.icon} />
                    Мои объявления
                </Typography>
            </Breadcrumbs>

            <h1>Мои объявления</h1>

            <ProfileHeaderContainer />

            <MyListingsHeaderContainer />

            {renderContent()}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isFetching: state.myListingsPage.isFetching,
        listingsTotal: state.myListingsPage.listingsTotal,
        listingsFiltered: state.myListingsPage.filteredListings,
        sortType: state.myListingsPage.sortType,
        isLoggedIn: state.loginReg.isLoggedIn,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sync: () => {
            dispatch(fetchMyListings());
        },
        showLoginDialog: () => {
            dispatch(showLoginDialogAC());
        },
    }
}

const MyListingsPageContainer = connect(mapStateToProps, mapDispatchToProps)(MyListings);
export default MyListingsPageContainer;