import { React, useEffect } from 'react'
import ProfileHeaderContainer from '../../components/ProfileHeader';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import MyPurchasesContainer from '../../components/trading/MyPurchases';
import { connect } from 'react-redux';
import { tradingSetFetchingAC, fetchTradingActivity } from '../../redux/my-trading-activity-reducer';
import MetaTags from 'react-meta-tags';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import Unauthorized from '../../components/entities/Unauthed';
import { showLoginDialogAC } from "../../redux/login-reg-reducer";

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
        marginTop: 10,
        marginBottom: 10,
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        spacing: 2,
    }
}));

const MyPurchasesPage = (props) => {
    const classes = useStyles();
    useEffect(() => {
        props.sync();
    }, []);

    if (!props.isLoggedIn) {
        props.showLoginDialog();
        return (
            <>
                <Unauthorized authCb={props.showLoginDialog} />
            </>
        )
    }

    return (
        <>
            <MetaTags>
                <title>Мои покупки - naTextolite</title>
            </MetaTags>

            <Breadcrumbs aria-label="breadcrumb">
                <Link color="textPrimary" href="/" className={classes.link}>
                    <HomeIcon className={classes.icon} />
                    naTeXtolite
                </Link>
                <Link color="textPrimary" href="/personal" className={classes.link}>
                    <AccountBoxIcon className={classes.icon} />
                    Личный кабинет
                </Link>
                <Typography color="textPrimary" className={classes.link}>
                    <ShoppingBagIcon className={classes.icon} />
                    Мои покупки
                </Typography>
            </Breadcrumbs>

            <h1>Мои покупки</h1>

            <ProfileHeaderContainer />

            <MyPurchasesContainer pageSize={5} />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.loginReg.isLoggedIn,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sync: () => {

            dispatch(tradingSetFetchingAC(true));
            dispatch(fetchTradingActivity());
        },
        showLoginDialog: () => {
            dispatch(showLoginDialogAC());
        },
    }
}

const MyPurchasesPageContainer = connect(mapStateToProps, mapDispatchToProps)(MyPurchasesPage);
export default MyPurchasesPageContainer;