import React from "react";
import { Grid } from "@material-ui/core";
import StyledTextField from "../entities/StyledTextField";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { makeStyles } from "@material-ui/core/styles";
import StyledButton from "../entities/StyledButton";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import {
    updateRegLoginText, updateRegMailText, updateRegPwdText,
    updateRegPwdConfirmText, regStartAC, regWaitVerificationCodeAC,
    regCodeTextUpdatedAC, regWaitCodeConfirmAC, regSuccessAC, regInvalidCodeAC,
    regUnknownErrorAC, regKnownErrorAC, regAutoLoginAC, regAutoLoginFailedAC, regClearErrorsAC
} from "../../redux/login-reg-reducer";
import { connect } from "react-redux";
import Spinner from "../Spinner";
import Typography from "@material-ui/core/Typography";
import StyledCheckBox from "../entities/StyledCheckBox";
import { Stack } from "@mui/material";
import { Link } from '@material-ui/core';
import StyledCheckBoxWithLink from '../entities/StyledCheckBoxWithLink';
//import doc from '../../../src/assets/doc.pdf';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(10),
    },
    title: {
        fontSize: 17,
        fontWeight: 500,
        color: '#202938',
        alignContent: 'center',
    },
    link: {
        //fontSize: 17,
        //fontWeight: 500,
        color: 'black',
        //alignContent: 'center',
    },
}));

async function registerUser(userInfo) {
    return fetch(process.env.REACT_APP_ORIGIN + '/api/users/new', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(userInfo)
    })
        .then(data => data.json())
}

async function checkCode(data) {
    return fetch(process.env.REACT_APP_ORIGIN + '/api/users/emailConfirm', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(data => data.json())
}

async function loginUser(credentials) {
    return fetch(process.env.REACT_APP_ORIGIN + '/api/users/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

function RegisterDialogView(props) {
    const classes = useStyles();
    const [checked1, setChecked1] = React.useState(false);
    const [checked2, setChecked2] = React.useState(false);
    const [checked3, setChecked3] = React.useState(false);


    const autoLogin = async () => {
        const response = await loginUser({
            login: props.login,
            pass: props.password,
        });

        if (response.statusCode === 200) {
            props.autoLoginDone(props.login);
            return;
        }
        else {
            props.autoLoginFailed();
            return;
        }
    }

    const handleRegister = async (e) => {
        e.preventDefault();

        // ... validate form data ...
        // 1. Only latin in all fields
        // 2. Email structure
        // 3. Passwords are identical

        if (!props.login ||
            !props.password ||
            !props.passwordConfirm ||
            !props.mail) {
            return;
        }

        if (props.loginErr || props.passwordErr || props.passwordConfirmErr || props.mailErr) {
            return;
        }

        /* Post user data */
        props.regStart();
        const response = await registerUser({
            login: props.login,
            pass: props.password,
            mail: props.mail,
        });

        if (response.statusCode === 200) {
            props.clearErrors();
            props.waitCode();
        }
        else {
            if (!response.data || !response.data.reason) {
                props.setUnknownErr();
            }
            else {
                props.setKnownErr(response.data.reason);
            }
        }
    }

    const handleCode = async (e) => {
        e.preventDefault();

        props.waitConfirm();

        const response = await checkCode({
            login: props.login,
            mail: props.mail,
            code: props.code,
        });
        if (response.statusCode === 200) {
            props.regSuccess();
            autoLogin();
        }
        else if (response.statusCode === 400) {
            props.invalidCode();
        }
        else {
            props.setUnknownErr();
        }
    }

    const renderInvalidCodeNotice = () => {
        if (props.codeMismatch)
            return (
                <>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item lg={8} md={10} sm={12}>
                            <Typography className={classes.title} color="textSecondary" align={'center'} gutterBottom>
                                Неверный проверочный код. Проверьте код и повторите попытку.
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            )
    }

    const renderUnknownErrorNotice = () => {
        if (props.unknownError) {
            return (
                <>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item lg={8} md={10} sm={12}>
                            <Typography className={classes.title} color="textSecondary" align={'center'} gutterBottom>
                                Что-то пошло не так. Пожалуйста, повторите попытку через несколько минут
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            )
        }
        else if (props.knownError) {
            return (
                <>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item lg={8} md={10} sm={12}>
                            <Typography className={classes.title} color="textSecondary" align={'center'} gutterBottom>
                                {props.knownErrorReason}
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            )
        }
    }

    const toggleAcceptance1 = (e) => {
        setChecked1(!checked1);
    }

    const toggleAcceptance2 = (e) => {
        setChecked2(!checked2);
    }

    const toggleAcceptance3 = (e) => {
        setChecked3(!checked3);
    }

    if (props.isFetching) {
        return (<Spinner />);
    }

    if (props.isWaitingVerify) {
        return (
            <>
                <form onSubmit={handleCode}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item lg={8} md={10} sm={12}>
                            <Typography className={classes.title} color="textSecondary" align={'center'} gutterBottom>
                                На указанный почтовый ящик мы выслали письмо, в котором Вы можете найти проверочный код.
                                Введите его в поле ниже. Код действителен в течение 10 минут
                            </Typography>
                        </Grid>
                        <Grid item lg={8} md={10} sm={12}>
                            <StyledTextField
                                label="Проверочный код"
                                type="text"
                                value={props.code}
                                onChange={props.codeChanged}
                                placeholder="Код из письма"
                                icon={<VerifiedUserIcon />}
                                className={classes.root}
                            />
                        </Grid>
                        <Grid item lg={8} md={10} sm={12}>
                            <StyledButton
                                label={"Готово"}
                                type={"submit"} />
                        </Grid>
                        {renderInvalidCodeNotice()}
                        {renderUnknownErrorNotice()}
                    </Grid>
                </form>
            </>
        );
    }

    return (
        <>
            <form onSubmit={handleRegister}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item lg={8} md={10} sm={12}>

                        <StyledTextField
                            label="Имя пользователя"
                            type="text"
                            value={props.login}
                            onChange={props.loginChanged}
                            err={props.loginErr}
                            errWhat={props.loginErrWhat}
                            placeholder="Логин"
                            icon={<AssignmentIndIcon />}
                            className={classes.root}
                        />

                        <StyledTextField
                            label="Электронная почта"
                            type="text"
                            value={props.mail}
                            onChange={props.mailChanged}
                            err={props.mailErr}
                            errWhat={props.mailErrWhat}
                            placeholder="Электронная почта"
                            icon={<AlternateEmailIcon />}
                            className={classes.root}
                        />

                        <StyledTextField
                            label="Пароль"
                            type="password"
                            value={props.password}
                            onChange={props.passwordChanged}
                            err={props.passwordErr}
                            errWhat={props.passwordErrWhat}
                            placeholder="Пароль"
                            icon={<VpnKeyIcon />}
                            className={classes.root}
                        />

                        <StyledTextField
                            label="Пароль еще раз"
                            type="password"
                            value={props.passwordConfirm}
                            onChange={props.passwordConfirmChanged}
                            err={props.passwordConfirmErr}
                            errWhat={props.passwordConfirmErrWhat}
                            placeholder="Пароль"
                            icon={<VpnKeyIcon />}
                            className={classes.root}
                        />
                    </Grid>

                    <Grid item lg={8} md={10} sm={12}>
                        <StyledButton
                            label={"Зарегистрироваться"}
                            type={"submit"}
                            disabled={!checked1 || !checked2 || !checked3}
                        />
                    </Grid>
                    {renderUnknownErrorNotice()}
                </Grid>

                <StyledCheckBoxWithLink
                    labelBeforeLink="Я даю согласие на обработку своих персональных данных в соответствии с "
                    linkLabel="Политикой обработки персональных данных"
                    labelAfterLink=". С Политикой обработки персональных данных ознакомлен (-на) и согласен (-на)"
                    //linkTo={doc}
                    checked={checked1}
                    onChange={toggleAcceptance1}
                />

                <StyledCheckBoxWithLink
                    labelBeforeLink="Я согласен с условиями "
                    linkLabel="Пользовательского соглашения"
                    labelAfterLink=""
                    //linkTo={doc}
                    checked={checked2}
                    onChange={toggleAcceptance2}
                />

                <StyledCheckBoxWithLink
                    labelBeforeLink="Я согласен с "
                    linkLabel="Правилами сайта"
                    labelAfterLink=""
                    //linkTo={doc}
                    checked={checked3}
                    onChange={toggleAcceptance3}
                />


            </form>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        login: state.loginReg.regLogin,
        loginErr: state.loginReg.regLoginErr,
        loginErrWhat: state.loginReg.regLoginErrWhat,

        mail: state.loginReg.regMail,
        mailErr: state.loginReg.regMailErr,
        mailErrWhat: state.loginReg.regMailErrWhat,

        password: state.loginReg.regPasswd,
        passwordErr: state.loginReg.regPasswdErr,
        passwordErrWhat: state.loginReg.regPasswdErrWhat,

        passwordConfirm: state.loginReg.regPasswdConfirm,
        passwordConfirmErr: state.loginReg.regPasswdConfirmErr,
        passwordConfirmErrWhat: state.loginReg.regPasswdConfirmErrWhat,

        isFetching: state.loginReg.regIsFetching,
        isWaitingVerify: state.loginReg.regIsWaitingVerify,
        code: state.loginReg.regVerificationCode,
        codeMismatch: state.loginReg.regInvalidCode,
        unknownError: state.loginReg.regUnknownError,
        knownError: state.loginReg.regKnownError,
        knownErrorReason: state.loginReg.regKnownErrReason,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginChanged: (e) => {
            dispatch(updateRegLoginText(e.target.value));
        },
        mailChanged: (e) => {
            dispatch(updateRegMailText(e.target.value));
        },
        passwordChanged: (e) => {
            dispatch(updateRegPwdText(e.target.value));
        },
        passwordConfirmChanged: (e) => {
            dispatch(updateRegPwdConfirmText(e.target.value));
        },
        codeChanged: (e) => {
            dispatch(regCodeTextUpdatedAC(e.target.value));
        },
        // state of the form:
        regStart: () => { dispatch(regStartAC()); },
        waitCode: () => { dispatch(regWaitVerificationCodeAC()); },
        waitConfirm: () => { dispatch(regWaitCodeConfirmAC()); },
        regSuccess: () => { dispatch(regSuccessAC()); },
        invalidCode: () => { dispatch(regInvalidCodeAC()); },
        setUnknownErr: () => { dispatch(regUnknownErrorAC()) },
        setKnownErr: (reason) => { dispatch(regKnownErrorAC(reason)) },
        clearErrors: () => { dispatch(regClearErrorsAC()) },
        autoLoginDone: (login) => { dispatch(regAutoLoginAC(login)) },
        autoLoginFailed: () => { dispatch(regAutoLoginFailedAC()) },
    }
}

const RegisterDialogViewContainer = connect(mapStateToProps, mapDispatchToProps)(RegisterDialogView);
export default RegisterDialogViewContainer;
