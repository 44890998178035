import React from 'react'
import { connect } from "react-redux";
import Paginated from '../entities/Paginated';
import MyListingPreviewContainer from './MyListingPreview';
import StyledCheckBox from '../entities/StyledCheckBox';
import { myListingsToggleAllFilteredSelection } from '../../redux/my-listings-reducer';

const MyListings = (props) => {
    const renderListingRow = (index, value) => {
        return <MyListingPreviewContainer
            id={value.id}
            key={value.id}
            imgUrl={value.cover}
            title={value.summary}
            date={value.created_at}
            viewsToday={value.views_today}
            viewsTotal={value.views_total}
            inFavorites={value.inFav}
            price={value.price}
            state={value.stateRu}
            type={value.type}
            vendor={value.vendor}
            model={value.model}
            odd={index % 2}
            noteExist={value.note_exist}
            noteText={value.note_text}
        />
    }

    return (
        <>
            <StyledCheckBox
                label="Выбрать все"
                checked={props.allChecked}
                onChange={(e) => {
                    props.toggleAllSelection();
                }}
            />

            {/*
            <StyledCheckBox
                label="Выбрать все на текущей странице"
                checked={props.allChecked}
                onChange={(e) => {
                    props.toggleAllSelection();
                }}
            />
            */}

            <Paginated
                pagesize={props.pageSize}
                renderItem={renderListingRow}
                items={props.listings}
            />
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        listings: state.myListingsPage.filteredListings,
        pageSize: ownProps.pageSize ? ownProps.pageSize : 10,
        allChecked: state.myListingsPage.selected.length === state.myListingsPage.filteredListings.length,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleAllSelection: () => {
            dispatch(myListingsToggleAllFilteredSelection());
        },
    }
}

const MyListingsContainer = connect(mapStateToProps, mapDispatchToProps)(MyListings);
export default MyListingsContainer;