import { React, useEffect } from 'react'
import { connect } from "react-redux";
import { showLoginDialogAC } from "../redux/login-reg-reducer";
import Unauthorized from "../components/entities/Unauthed";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ProfileHeaderContainer from '../components/ProfileHeader';
import MetaTags from 'react-meta-tags';
import { eventsSetFetchingAC, fetchMyEvents } from '../redux/events-reducer';
import MyEventsContainer from '../components/events/MyEvents';
import Spinner from '../components/Spinner';
import { Grid } from '@mui/material';
import NavigationMenu from '../components/entities/SideNavigationMenu';
import RequiredActionsContainer from './personal/RequiredActions';

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
        marginTop: 10,
        marginBottom: 10,
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
}));

const Personal = ({ sync, ...props }) => {
    const classes = useStyles();
    useEffect(() => {
        sync();
    }, []);

    if (!props.isLoggedIn) {
        props.showLoginDialog();
        return (
            <>
                <Unauthorized authCb={props.showLoginDialog} />
            </>
        )
    }

    if (props.isFetching) {
        return <Spinner />
    }


    return (
        <>
            <MetaTags>
                <title>Личный кабинет - naTextolite</title>
            </MetaTags>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="textPrimary" href="/" className={classes.link}>
                    <HomeIcon className={classes.icon} />
                    naTeXtolite
                </Link>
                <Typography color="textPrimary" className={classes.link}>
                    <AccountBoxIcon className={classes.icon} />
                    Личный кабинет
                </Typography>
            </Breadcrumbs>
            <h1>Личный кабинет</h1>

            <ProfileHeaderContainer />

            <Grid
                container
                direction="row"
                justifyContent=''
                alignItems="stretch"
                alignContent='center'
            >
                <Grid item md={3} sx={{ bgcolor: '#202938', borderRadius: '4px' }}>
                    <NavigationMenu />
                </Grid>

                <Grid item md={9} sm={12}>
                    <RequiredActionsContainer />

                    <MyEventsContainer pageSize={8} />
                </Grid>
            </Grid >
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isFetching: state.events.isFetching,
        isLoggedIn: state.loginReg.isLoggedIn,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sync: () => {
            dispatch(eventsSetFetchingAC(true));
            dispatch(fetchMyEvents());
        },
        showLoginDialog: () => {
            dispatch(showLoginDialogAC());
        },
    }
}

const PersonalContainer = connect(mapStateToProps, mapDispatchToProps)(Personal);
export default PersonalContainer;