import React from 'react'
import { connect } from "react-redux";
import Paginated from '../entities/Paginated';
import Spinner from '../Spinner';
import { eventTextByType, eventIconByType } from '../../utils/eventsUtils';
import EventRow from './EventRow';
import { linkHrefByType } from '../../utils/eventsUtils';
import { Box } from '@mui/material';

const MyEvents = (props) => {
    const renderRow = (index, value) => {
        return <EventRow
            id={value.id}
            key={value.id}
            icon={eventIconByType(value.event_type)}
            text={eventTextByType(value.event_type, value.param)}
            linkTo={linkHrefByType(value.event_type, value.param)}
            appeared={value.appeared}
            odd={index % 2}
        />
    }

    if (props.isFetching) {
        return <Spinner />
    }

    if (props.eventsTotal == 0) {
        return (
            <>
                <h2>Пока нет событий для отображения</h2>
            </>
        )
    }

    return (
        <>
            <h2>Последние события</h2>
            <Paginated
                pagesize={props.pageSize}
                renderItem={renderRow}
                items={props.events}
            />
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching: state.events.isFetching,
        eventsTotal: state.events.eventsTotal,
        events: state.events.events,
        pageSize: ownProps.pageSize ? ownProps.pageSize : 10,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

const MyEventsContainer = connect(mapStateToProps, mapDispatchToProps)(MyEvents);
export default MyEventsContainer;