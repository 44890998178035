import React from 'react'
import ProfileHeaderContainer from '../components/ProfileHeader';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
        marginTop: 10,
        marginBottom: 10,
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
}));

const UnderConstruction = () => {
    const classes = useStyles();

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="textPrimary" href="/" className={classes.link}>
                    <HomeIcon className={classes.icon} />
                    naTeXtolite
                </Link>
                <Typography color="textPrimary" className={classes.link}>
                    <AccountBoxIcon className={classes.icon} />
                    Личный кабинет
                </Typography>
            </Breadcrumbs>

            <h1>Эммм...</h1>

            <ProfileHeaderContainer />

            <h2>Эта страница еще не готова, но мы изо всех сил над этим работаем</h2>
        </>
    )
}

export default UnderConstruction;