import React from 'react'
import { connect } from "react-redux";
import FeedbackRow from '../../components/entities/FeedbackRow';
import Paginated from '../entities/Paginated';
import Spinner from '../Spinner';

const UsersFeedback = (props) => {
    const renderFeedbackRow = (index, value) => {
        return <FeedbackRow
            fromUser={value.fromUser}
            variant={value.type}
            feedback={value.feedback}
            date={value.date}
            listingSummary={value.summary}
            listingId={value.listing_id}
        />
    }

    if (props.isFetching) {
        return <Spinner />
    }

    if (props.feedbackTotal === 0) {
        return (
            <>
                <h2>¯\_(ツ)_/¯</h2>
                <h2>У этого пользователя пока нет отзывов</h2>
            </>
        )
    }

    return (
        <>
            <Paginated
                pagesize={props.pageSize}
                renderItem={renderFeedbackRow}
                items={props.feedbackArr}
            />
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching: state.anotherUserPage.isFetching,
        feedbackTotal: state.anotherUserPage.feedbackTotal,
        feedbackArr: state.anotherUserPage.feedback,
        pageSize: ownProps.pageSize ? ownProps.pageSize : 10,
    }
}

const UsersFeedbackContainer = connect(mapStateToProps, {})(UsersFeedback);
export default UsersFeedbackContainer;