import fetch from "cross-fetch";
import { signedInAC } from "./user-reducer";
import { intialAC } from "./my-settings-reducer";

const LOG_REG_SHOW_LOGIN_DIALOG = 'LOG_REG_SHOW_LOGIN_DIALOG';
const LOG_REG_HIDE_LOGIN_DIALOG = 'LOG_REG_HIDE_LOGIN_DIALOG';

const LOG_REG_LOGIN_LOGIN_TEXT_CHANGED = 'LOG_REG_LOGIN_LOGIN_TEXT_CHANGED';
const LOG_REG_LOGIN_PASSWORD_TEXT_CHANGED = 'LOG_REG_LOGIN_PASSWORD_TEXT_CHANGED';
const LOG_REG_LOGIN_START = 'LOG_REG_LOGIN_START';
const LOG_REG_LOGIN_FAILED_BY_UNKNOWN = 'LOG_REG_LOGIN_FAILED_BY_UNKNOWN';
const LOG_REG_LOGIN_FAILED_BY_CREDENTIALS = 'LOG_REG_LOGIN_FAILED_BY_CREDENTIALS';
const LOG_REG_LOGIN_OK = 'LOG_REG_LOGIN_OK';

const LOG_REG_FORGOT_PASSWORD = 'LOG_REG_FORGOT_PASSWORD';
const LOG_REG_PASS_RESET_REQUEST = 'LOG_REG_PASS_RESET_REQUEST';
const LOG_REG_RESET_PASSWD_TEXT_CHANGED = 'LOG_REG_RESET_PASSWD_TEXT_CHANGED';
const LOG_REG_RESET_PASSWD_CONFIRM_TEXT_CHANGED = 'LOG_REG_RESET_PASSWD_CONFIRM_TEXT_CHANGED';
const LOG_REG_RESET_CODE_TEXT_CHANGED = 'LOG_REG_RESET_CODE_TEXT_CHANGED';
const LOG_REG_RESET_RAISE_UNKNOWN_ERROR = 'LOG_REG_RESET_RAISE_UNKNOWN_ERROR';
const LOG_REG_RESET_INVALID_CODE_ERROR = 'LOG_REG_RESET_INVALID_CODE_ERROR';
const LOG_REG_SET_FETCHING = 'LOG_REG_SET_FETCHING';
const LOG_REG_PASS_CHANGED = 'LOG_REG_PASS_CHANGED';

const LOG_REG_REGISTER_LOGIN_TEXT_CHANGED = 'LOG_REG_REGISTER_LOGIN_TEXT_CHANGED';
const LOG_REG_REGISTER_MAIL_TEXT_CHANGED = 'LOG_REG_REGISTER_MAIL_TEXT_CHANGED';
const LOG_REG_REGISTER_PASSWD_TEXT_CHANGED = 'LOG_REG_REGISTER_PASSWD_TEXT_CHANGED';
const LOG_REG_REGISTER_PASSWD_CONFIRM_TEXT_CHANGED = 'LOG_REG_REGISTER_PASSWD_CONFIRM_TEXT_CHANGED';
const LOG_REG_REGISTER_CODE_TEXT_CHANGED = 'LOG_REG_REGISTER_CODE_TEXT_CHANGED';
const LOG_REG_REG_START = 'LOG_REG_REG_START';
const LOG_REG_REG_WAIT_CODE = 'LOG_REG_REG_WAIT_CODE';
const LOG_REG_REG_WAIT_CODE_CONFIRM = 'LOG_REG_REG_WAIT_CODE_CONFIRM';
const LOG_REG_REG_INVALID_CODE = 'LOG_REG_REG_INVALID_CODE';
const LOG_REG_REG_UNKNOWN_ERROR = 'LOG_REG_REG_UNKNOWN_ERROR';
const LOG_REG_REG_KNOWN_ERROR = 'LOG_REG_REG_KNOWN_ERROR';
const LOG_REG_REG_CLEAR_ERROR = 'LOG_REG_REG_CLEAR_ERROR';
const LOG_REG_REG_DONE = 'LOG_REG_REG_DONE';
const LOG_REG_REG_AUTO_LOGIN = 'LOG_REG_REG_AUTO_LOGIN';
const LOG_REG_REG_AUTO_LOGIN_FAILED = 'LOG_REG_REG_AUTO_LOGIN_FAILED';

const LOG_REG_LOGOUT = 'LOG_REG_LOGOUT';
const LOG_REG_RESET = 'LOG_REG_RESET';

let initialState = {
    isLoggedIn: false,
    showLoginDialog: false,
    isFetching: false,

    /* Login/Register data */
    // login tab data:
    loginLogin: '',
    loginLoginErr: false,
    loginLoginErrWhat: '',
    loginPasswd: '',
    loginPasswdErr: false,
    loginPasswdErrWhat: '',
    loginFailed: false,
    invalidCredentials: false,
    loginErrReason: '',

    // forgot password functionality
    forgotPassword: false,
    forgotWaitCode: false,

    newPasswd: '',
    newPasswdErr: false,
    newPasswdErrWhat: '',

    newPasswdConfirm: '',
    newPasswdConfirmErr: false,
    newPasswdConfirmErrWhat: '',

    passResetCode: '',
    passResetUnknownErr: false,
    passResetInvalidCodeErr: false,
    passResetErrReason: '',

    passChanged: false,


    // register tab data:
    regLogin: '',
    regLoginErr: false,
    regLoginErrWhat: '',

    regMail: '',
    regMailErr: false,
    regMailErrWhat: '',

    regPasswd: '',
    regPasswdErr: false,
    regPasswdErrWhat: '',

    regPasswdConfirm: '',
    regPasswdConfirmErr: false,
    regPasswdConfirmErrWhat: '',

    regIsFetching: false,
    regIsWaitingVerify: false,
    regVerificationCode: '',
    regInvalidCode: false,
    regUnknownError: false,
    regKnownError: false,
    regKnownErrReason: '',

};

let checkCyrillic = (text) => {
    let ru = /[а-яё]+/i.test(text);
    return ru;
}

const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

let checkMailFormatValid = (text) => {
    return EMAIL_REGEXP.test(text);
}

const loginRegisterReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOG_REG_SHOW_LOGIN_DIALOG:
            return Object.assign({}, state, {
                showLoginDialog: true,
            });
        case LOG_REG_HIDE_LOGIN_DIALOG:
            return Object.assign({}, state, {
                showLoginDialog: false,
            });

        case LOG_REG_LOGIN_LOGIN_TEXT_CHANGED:
            return Object.assign({}, state, {
                loginLogin: action.newText,
                loginLoginErr: action.isErr,
                loginLoginErrWhat: action.errWhat,
                invalidCredentials: false,
                loginFailed: false,
            });

        case LOG_REG_LOGIN_PASSWORD_TEXT_CHANGED:
            return Object.assign({}, state, {
                loginPasswd: action.newText,
                loginPasswdErr: action.isErr,
                loginPasswdErrWhat: action.errWhat,
                invalidCredentials: false,
                loginFailed: false,
            });


        case LOG_REG_LOGIN_START:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case LOG_REG_LOGIN_FAILED_BY_CREDENTIALS:
            return Object.assign({}, state, {
                invalidCredentials: true,
                isFetching: false,
                loginErrReason: '',
            });
        case LOG_REG_LOGIN_FAILED_BY_UNKNOWN:
            return Object.assign({}, state, {
                loginFailed: true,
                isFetching: false,
                loginErrReason: action.reason,
            });
        case LOG_REG_LOGIN_OK:
            return Object.assign({}, state, {
                isFetching: false,
                showLoginDialog: false,
                loginLogin: '',
                loginPasswd: '',
                isLoggedIn: true,
            });
        case LOG_REG_FORGOT_PASSWORD:
            return Object.assign({}, state, {
                forgotPassword: true,
            });

        case LOG_REG_PASS_RESET_REQUEST:
            return Object.assign({}, state, {
                forgotWaitCode: true,
            });

        case LOG_REG_RESET_PASSWD_TEXT_CHANGED: {
            return Object.assign({}, state, {
                newPasswd: action.newText,
                newPasswdErr: action.isErr,
                newPasswdErrWhat: action.errWhat,
            });
        }

        case LOG_REG_RESET_PASSWD_CONFIRM_TEXT_CHANGED: {
            if (!action.isErr) {
                if (action.newText !== state.newPasswd) {
                    action.isErr = true;
                    action.errWhat = 'Введенные пароли не совпадают';
                }
            }
            return Object.assign({}, state, {
                newPasswdConfirm: action.newText,
                newPasswdConfirmErr: action.isErr,
                newPasswdConfirmErrWhat: action.errWhat,
            });
        }

        case LOG_REG_RESET_CODE_TEXT_CHANGED:
            return Object.assign({}, state, {
                passResetCode: action.newText,
            });

        case LOG_REG_RESET_RAISE_UNKNOWN_ERROR:
            return Object.assign({}, state, {
                passResetUnknownErr: true,
                passResetErrReason: action.reason,
            });

        case LOG_REG_RESET_INVALID_CODE_ERROR:
            return Object.assign({}, state, {
                passResetInvalidCodeErr: true,
            });

        case LOG_REG_SET_FETCHING:
            return Object.assign({}, state, {
                isFetching: action.isFetching,
            });

        case LOG_REG_PASS_CHANGED:
            return Object.assign({}, state, {
                forgotWaitCode: false,
                passChanged: true,
            });



        case LOG_REG_REGISTER_LOGIN_TEXT_CHANGED: {
            return Object.assign({}, state, {
                regLogin: action.newText,
                regLoginErr: action.isErr,
                regLoginErrWhat: action.errWhat,
            });
        }

        case LOG_REG_REGISTER_MAIL_TEXT_CHANGED: {
            return Object.assign({}, state, {
                regMail: action.newText,
                regMailErr: action.isErr,
                regMailErrWhat: action.errWhat,
            });
        }

        case LOG_REG_REGISTER_PASSWD_TEXT_CHANGED: {
            let passMatch = true;
            if (action.newText !== state.regPasswdConfirm) {
                action.isErr = true;
                action.errWhat = 'Введенные пароли не совпадают';
                passMatch = false;
            }
            return Object.assign({}, state, {
                regPasswd: action.newText,
                regPasswdErr: action.isErr,
                regPasswdErrWhat: action.errWhat,
                regPasswdConfirmErr: passMatch ? false : state.regPasswdConfirmErr,
                regPasswdConfirmErrWhat: passMatch ? "" : state.regPasswdConfirmErrWhat
            });
        }

        case LOG_REG_REGISTER_PASSWD_CONFIRM_TEXT_CHANGED: {
            let passMatch = true;
            if (!action.isErr) {
                if (action.newText !== state.regPasswd) {
                    action.isErr = true;
                    action.errWhat = 'Введенные пароли не совпадают';
                    passMatch = false;
                }
            }
            return Object.assign({}, state, {
                regPasswdConfirm: action.newText,
                regPasswdConfirmErr: action.isErr,
                regPasswdConfirmErrWhat: action.errWhat,
                regPasswdErr: passMatch ? false : state.regPasswdErr,
                regPasswdErrWhat: passMatch ? "" : state.regPasswdErrWhat
            });
        }

        case LOG_REG_REGISTER_CODE_TEXT_CHANGED:
            return Object.assign({}, state, {
                regVerificationCode: action.newText,
            });


        case LOG_REG_REG_START:
            return Object.assign({}, state, {
                regIsFetching: true,
            });
        case LOG_REG_REG_WAIT_CODE:
            return Object.assign({}, state, {
                regIsFetching: false,
                regIsWaitingVerify: true,
            });
        case LOG_REG_REG_WAIT_CODE_CONFIRM:
            return Object.assign({}, state, {
                regIsFetching: true,
            });
        case LOG_REG_REG_INVALID_CODE:
            return Object.assign({}, state, {
                regIsFetching: false,
                regInvalidCode: true,
            });
        case LOG_REG_REG_UNKNOWN_ERROR:
            return Object.assign({}, state, {
                regIsFetching: false,
                regUnknownError: true,
            });
        case LOG_REG_REG_KNOWN_ERROR:
            return Object.assign({}, state, {
                regIsFetching: false,
                regUnknownError: false,
                regKnownError: true,
                regKnownErrReason: action.reason,
            });
        case LOG_REG_REG_CLEAR_ERROR:
            return Object.assign({}, state, {
                regIsFetching: false,
                regUnknownError: false,
                regKnownError: false,
                regKnownErrReason: '',
            });
        case LOG_REG_REG_DONE:
            return Object.assign({}, state, {
                regIsWaitingVerify: false,
                regUnknownError: false,
                regInvalidCode: false,
            });


        case LOG_REG_REG_AUTO_LOGIN:
            return Object.assign({}, state, {
                regIsFetching: false,
                regIsWaitingVerify: false,
                regUnknownError: false,
                regInvalidCode: false,
                regLogin: '',
                regMail: '',
                regPasswd: '',
                regPasswdConfirm: '',
                regVerificationCode: '',
                showLoginDialog: false,
                isLoggedIn: true,
            });

        case LOG_REG_REG_AUTO_LOGIN_FAILED:
            return Object.assign({}, state, {
                showLoginDialog: false,
            });
        case LOG_REG_LOGOUT:
            localStorage.removeItem('token');
            return Object.assign({}, state, {
                isLoggedIn: false,
            });

        case LOG_REG_RESET:
            return Object.assign({}, state, initialState);


        default:
            return state;
    }
};

export const showLoginDialogAC = () => ({ type: LOG_REG_SHOW_LOGIN_DIALOG })
export const hideLoginDialogAC = () => ({ type: LOG_REG_HIDE_LOGIN_DIALOG })

export const loginLoginTextUpdatedAC = (newText, isErr, errWhat) => ({
    type: LOG_REG_LOGIN_LOGIN_TEXT_CHANGED,
    newText: newText,
    isErr: isErr,
    errWhat: errWhat,
})
export const loginPasswordTextUpdatedAC = (newText, isErr, errWhat) => ({
    type: LOG_REG_LOGIN_PASSWORD_TEXT_CHANGED,
    newText: newText,
    isErr: isErr,
    errWhat: errWhat,
})

export const loginStartAC = () => ({ type: LOG_REG_LOGIN_START })
export const loginFailedInvalidCredentialsAC = () => ({ type: LOG_REG_LOGIN_FAILED_BY_CREDENTIALS })
export const loginFailedAC = (reason) => ({
    type: LOG_REG_LOGIN_FAILED_BY_UNKNOWN,
    reason: reason,
})
export const loginSuccessAC = (json) => ({
    type: LOG_REG_LOGIN_OK,
})
export const forgotPassAC = () => ({ type: LOG_REG_FORGOT_PASSWORD })
export const resetPassAC = () => ({ type: LOG_REG_PASS_RESET_REQUEST })
export const resetUnknownErrAC = (reason) => ({
    type: LOG_REG_RESET_RAISE_UNKNOWN_ERROR,
    reason: reason,
})
export const resetInvalidCodeAC = () => ({ type: LOG_REG_RESET_INVALID_CODE_ERROR })
export const passChangedAC = () => ({ type: LOG_REG_PASS_CHANGED })

export const newPasswordTextUpdatedAC = (newText, isErr, errWhat) => ({
    type: LOG_REG_RESET_PASSWD_TEXT_CHANGED,
    newText: newText,
    isErr: isErr,
    errWhat: errWhat
})

export const newPasswordConfirmTextUpdatedAC = (newText, isErr, errWhat) => ({
    type: LOG_REG_RESET_PASSWD_CONFIRM_TEXT_CHANGED,
    newText: newText,
    isErr: isErr,
    errWhat: errWhat
})
export const newPassCodeTextUpdatedAC = (newText) => ({ type: LOG_REG_RESET_CODE_TEXT_CHANGED, newText: newText })

export const setFetchingAC = (isFetching) => ({ type: LOG_REG_SET_FETCHING, isFetching: isFetching })



export function updateNewPwdText(newText) {
    return function (dispatch) {
        let what = '';
        let isRu = checkCyrillic(newText);

        let isErr = isRu || newText.length < 6 || newText.length > 32;
        if (isErr) {
            what = isRu ? "Используйте буквы английского алфавита" : "Пароль должен быть от 6 до 32 символов";
        }
        dispatch(newPasswordTextUpdatedAC(newText, isErr, what))
    }
}

export function updateNewPwdConfirmText(newText) {
    return function (dispatch) {
        let isRu = checkCyrillic(newText);
        dispatch(newPasswordConfirmTextUpdatedAC(newText, isRu, isRu ? "Используйте буквы английского алфавита" : ""))
    }
}








export const regLoginTextUpdatedAC = (newText, isErr, errWhat) => ({
    type: LOG_REG_REGISTER_LOGIN_TEXT_CHANGED,
    newText: newText,
    isErr: isErr,
    errWhat: errWhat
})
export const regMailTextUpdatedAC = (newText, isErr, errWhat) => ({
    type: LOG_REG_REGISTER_MAIL_TEXT_CHANGED,
    newText: newText,
    isErr: isErr,
    errWhat: errWhat
})

export const regPasswordTextUpdatedAC = (newText, isErr, errWhat) => ({
    type: LOG_REG_REGISTER_PASSWD_TEXT_CHANGED,
    newText: newText,
    isErr: isErr,
    errWhat: errWhat
})

export const regPasswordConfirmTextUpdatedAC = (newText, isErr, errWhat) => ({
    type: LOG_REG_REGISTER_PASSWD_CONFIRM_TEXT_CHANGED,
    newText: newText,
    isErr: isErr,
    errWhat: errWhat
})
export const regCodeTextUpdatedAC = (newText) => ({ type: LOG_REG_REGISTER_CODE_TEXT_CHANGED, newText: newText })
export const regStartAC = () => ({ type: LOG_REG_REG_START })
export const regWaitVerificationCodeAC = () => ({ type: LOG_REG_REG_WAIT_CODE })
export const regWaitCodeConfirmAC = () => ({ type: LOG_REG_REG_WAIT_CODE_CONFIRM })
export const regInvalidCodeAC = () => ({ type: LOG_REG_REG_INVALID_CODE })
export const regUnknownErrorAC = () => ({ type: LOG_REG_REG_UNKNOWN_ERROR })
export const regKnownErrorAC = (reason) => ({
    type: LOG_REG_REG_KNOWN_ERROR,
    reason: reason,
})
export const regClearErrorsAC = () => ({ type: LOG_REG_REG_CLEAR_ERROR })
export const regSuccessAC = () => ({ type: LOG_REG_REG_DONE })
export const regAutoLoginAC = (login) => ({ type: LOG_REG_REG_AUTO_LOGIN, login: login })
export const regAutoLoginFailedAC = () => ({ type: LOG_REG_REG_AUTO_LOGIN_FAILED })

export const logoutAC = () => ({ type: LOG_REG_LOGOUT })



export function updateLoginLoginText(newText) {
    return function (dispatch) {
        let isRu = checkCyrillic(newText);
        dispatch(loginLoginTextUpdatedAC(newText, isRu, isRu ? "Используйте буквы английского алфавита" : ""))
    }
}

export function updateLoginPasswordText(newText) {
    return function (dispatch) {
        let isRu = checkCyrillic(newText);
        dispatch(loginPasswordTextUpdatedAC(newText, isRu, isRu ? "Используйте буквы английского алфавита" : ""))
    }
}

export function updateRegLoginText(newText) {
    return function (dispatch) {
        let what = '';
        let isRu = checkCyrillic(newText);

        let isErr = isRu || newText.length < 4 || newText.length > 32;
        if (isErr) {
            what = isRu ? "Используйте буквы английского алфавита" : "Логин должен быть от 4 до 32 символов";
        }
        dispatch(regLoginTextUpdatedAC(newText, isErr, what))
    }
}

export function updateRegMailText(newText) {
    return function (dispatch) {
        let what = '';
        let isRu = checkCyrillic(newText);

        let isErr = isRu || !checkMailFormatValid(newText);
        if (isErr) {
            what = isRu ? "Используйте буквы английского алфавита" : "Некорректный формат email";
        }
        dispatch(regMailTextUpdatedAC(newText, isErr, what))
    }
}

export function updateRegPwdText(newText) {
    return function (dispatch) {
        let what = '';
        let isRu = checkCyrillic(newText);

        let isErr = isRu || newText.length < 6 || newText.length > 32;
        if (isErr) {
            what = isRu ? "Используйте буквы английского алфавита" : "Пароль должен быть от 6 до 32 символов";
        }
        dispatch(regPasswordTextUpdatedAC(newText, isErr, what))
    }
}

export function updateRegPwdConfirmText(newText) {
    return function (dispatch) {
        let isRu = checkCyrillic(newText);
        dispatch(regPasswordConfirmTextUpdatedAC(newText, isRu, isRu ? "Используйте буквы английского алфавита" : ""))
    }
}

export function initialSync() {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/users/whoami', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(signedInAC(json.data));
                        dispatch(loginSuccessAC(json));
                        dispatch(intialAC(json.data.settings));
                    }
                    else {
                        dispatch(logoutAC(json))
                    }
                }
            )
    }
}

export function logout() {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/users/logout', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(logoutAC(json))
                    }
                }
            )
    }
}

export const resetStateAC = () => ({ type: LOG_REG_RESET })

export default loginRegisterReducer;