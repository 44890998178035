import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SettingsIcon from '@mui/icons-material/Settings';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import SellIcon from '@mui/icons-material/Sell';
import { makeStyles } from "@material-ui/core/styles";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import BadgeIcon from '@mui/icons-material/Badge';
import store from '../../redux/store';



const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: '#6dc353',
  },
  menu: {
    background: '#202938',
    color: '#6dc353',
    fontFamily: 'Jura, Arial',
  },
  menuIcon: {
    color: '#6dc353',
  }
}));

const TemporaryDrawer = (props) => {
  const [state, setState] = React.useState({
    left: false,
  });

  const classes = useStyles();

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className={classes.menu}
    >
      <List>
        <ListItem key='Мои объявления'
          component={Link}
          to="/personal/mylistings"
          style={{
            textDecoration: "none",
            color: "#6dc353"
          }}
          disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <SellIcon className={classes.menuIcon} />
            </ListItemIcon>
            <ListItemText primary='Мои объявления' disableTypography />
          </ListItemButton>
        </ListItem>

        <ListItem key='Избранное'
          component={Link}
          to="/personal/favorites"
          style={{
            textDecoration: "none",
            color: "#6dc353"
          }}
          disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <ShoppingCartIcon className={classes.menuIcon} />
            </ListItemIcon>
            <ListItemText primary='Избранное' disableTypography />
          </ListItemButton>
        </ListItem>

        <ListItem key='Подписки' component={Link}
          to="/personal/subscriptions"
          style={{
            textDecoration: "none",
            color: "#6dc353"
          }}
          disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <NotificationsActiveIcon className={classes.menuIcon} />
            </ListItemIcon>
            <ListItemText primary='Подписки' disableTypography />
          </ListItemButton>
        </ListItem>

        <ListItem key='Купленное' component={Link}
          to="/personal/mypurchases"
          style={{
            textDecoration: "none",
            color: "#6dc353"
          }}
          disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <ShoppingBagIcon className={classes.menuIcon} />
            </ListItemIcon>
            <ListItemText primary='Купленное' disableTypography />
          </ListItemButton>
        </ListItem>

        <ListItem key='Проданное' component={Link}
          to="/personal/mysales"
          style={{
            textDecoration: "none",
            color: "#6dc353"
          }}
          disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <PointOfSaleIcon className={classes.menuIcon} />
            </ListItemIcon>
            <ListItemText primary='Проданное' disableTypography />
          </ListItemButton>
        </ListItem>

        <ListItem key='Отзывы' component={Link}
          to="/personal/feedback"
          style={{
            textDecoration: "none",
            color: "#6dc353"
          }}
          disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <ThumbUpIcon className={classes.menuIcon} />
            </ListItemIcon>
            <ListItemText primary='Отзывы' disableTypography />
          </ListItemButton>
        </ListItem>

        <ListItem key='Моя страница' component={Link}
          to={`/profile/${store.getState().userState.login}`}
          style={{
            textDecoration: "none",
            color: "#6dc353"
          }}
          disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <BadgeIcon className={classes.menuIcon} />
            </ListItemIcon>
            <ListItemText primary='Моя страница' disableTypography />
          </ListItemButton>
        </ListItem>

      </List>

      <Divider />

      <List>

        <ListItem key='Настройки' component={Link}
          to="/personal/settings"
          style={{
            textDecoration: "none",
            color: "#6dc353"
          }}
          disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <SettingsIcon className={classes.menuIcon} />
            </ListItemIcon>
            <ListItemText primary='Настройки' disableTypography />
          </ListItemButton>
        </ListItem>

        {/*<ListItem key='Сообщения' component={Link}
          to="/personal/messages"
          style={{
            textDecoration: "none",
            color: "#6dc353"
          }}
          disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <MailIcon className={classes.menuIcon} />
            </ListItemIcon>
            <ListItemText primary='Сообщения' disableTypography />
          </ListItemButton>
        </ListItem>

        <ListItem key='Платежи' component={Link}
          to="/personal/billing"
          style={{
            textDecoration: "none",
            color: "#6dc353"
          }}
          disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <CurrencyRubleIcon className={classes.menuIcon} />
            </ListItemIcon>
            <ListItemText primary='Платежи' disableTypography />
          </ListItemButton>
        </ListItem>

        <ListItem key='Бонусы' component={Link}
          to="/personal/bonuses"
          style={{
            textDecoration: "none",
            color: "#6dc353"
          }}
          disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <CardGiftcardIcon className={classes.menuIcon} />
            </ListItemIcon>
            <ListItemText primary='Бонусы' disableTypography />
          </ListItemButton>
        </ListItem>*/}

      </List>
    </Box>
  );

  return (
    <>
      <Tooltip title="Меню" placement="bottom">
        <Link to="#">
          <MenuOpenIcon
            className={classes.iconButton}
            fontSize={props.mainIconSize}
            onClick={toggleDrawer('left', true)}
          />
        </Link>
      </Tooltip>

      <Drawer
        anchor='left'
        open={state['left']}
        onClose={toggleDrawer('left', false)}
        sx={{
          '& .MuiDrawer-paper': { bgcolor: "#202938" },
        }}
      >
        {list('left')}
      </Drawer>
    </>
  );
}

export default TemporaryDrawer;