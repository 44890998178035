import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MapIcon from '@mui/icons-material/Map';
import CitySelectorContainer from '../../components/profile/CitySelector';
import HelperCard from '../../components/settings/HelperCard';

const SetLocationBlock = (props) => {
    return (
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <MapIcon sx={{marginRight: 1}} />
                    Указать или сменить населенный пункт
                </AccordionSummary>

                <AccordionDetails>
                    Начните печатать название Вашего населенного пункта в поле ниже и щелкните на подсказку.
                    Для появления подсказки нужно минимум 3 символа. <br />
                    После этого, нажмите кнопку "Сохранить"
                    <CitySelectorContainer />

                    <HelperCard helperText={
                        "Знание населенного пункта широко используется в различных сценариях для обеспечения наилучшего пользовательского опыта. В частности, указанный населенный пункт будет отображаться во всех Ваших объявлениях. Таким образом, другие пользователи сразу смогут понять, нужно ли им добавлять к стоимости Ваших запчастей стоимость доставки. Другой пример - при поиске запчастей мы сначала покажем объявления из Вашего города, затем из Вашего региона, и, только затем, из других регионов."
                    } />
                </AccordionDetails>
            </Accordion>
        </>
    );
}

export default SetLocationBlock;