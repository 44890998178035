import fetch from 'cross-fetch';

const GET_COMP_REQUEST_COMPOSITION = 'GET_COMP_REQUEST_COMPOSITION';
const GET_COMP_RECEIVE_COMPOSITION = 'GET_COMP_RECEIVE_COMPOSITION';
const GET_COMP_CLEAR_SECONDARY_USAGES = 'GET_COMP_CLEAR_SECONDARY_USAGES';
const GET_COMP_RECEIVE_SECONDARY_USAGES = 'GET_COMP_RECEIVE_SECONDARY_USAGES';

const GET_COMP_UPDATE_SEARCH_MODEL_TEXT = 'GET_COMP_UPDATE_SEARCH_MODEL_TEXT';

const GET_COMP_SEARCH_TYPE_MODEL = 'GET_COMP_SEARCH_TYPE_MODEL';

const GET_COMP_SET_FETCHING = 'GET_COMP_SET_FETCHING';

let initialState = {
    searchModelText: '',
    searchType: '',
    searchedItem: '',
    isFetching: true,
    triedToFetch: false,
    tv_composition: {
        empty: true,
        vendor: '',
        model: '',
        chasis: '',
        pseudonim: '',
        added_by: '',

        ssb: [ // alternatives
            { id: '', pns: [] }, // part-numbers of the current alt
        ],
        psu: [
            { id: '', pns: [] },
        ],
        tcn: [
            { id: '', pns: [] },
        ],
        bkl: [
            { id: '', pns: [] },
        ],
        pnl: [
            { id: '', pns: [] },
        ],
        ld: [
            { id: '', pns: [] },
        ],
        ir: [
            { id: '', pns: [] },
        ],
        btn: [
            { id: '', pns: [] },
        ],
        wlan: [
            { id: '', pns: [] },
        ],
        bt: [
            { id: '', pns: [] },
        ],
        dyn: [
            { id: '', pns: [] },
        ],
        tun: [
            { id: '', pns: [] },
        ],
        lvds: [
            { id: '', pns: [] },
        ],
        mtx: [
            { id: '', pns: [] },
        ],
        cam: [
            { id: '', pns: [] },
        ],
        inv: [
            { id: '', pns: [] },
        ],
    },
    isSecondaryUsagesFetching: false,
    secondaryUsages: {
        empty: true,
        usages: [
            { id: '', model: '' },
        ],
    }
};

const compositionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMP_UPDATE_SEARCH_MODEL_TEXT:
            return Object.assign({}, state, {
                searchModelText: action.newText
            });
        case GET_COMP_REQUEST_COMPOSITION:
            return Object.assign({}, state, {
                isFetching: true,
                searchedItem: state.searchModelText.split("/").join("____"),
                searchType: GET_COMP_SEARCH_TYPE_MODEL,
                searchModelText: '',
                isSecondaryUsagesFetching: false,
                secondaryUsages: {
                    empty: true,
                    usages: [],
                },
            });
        case GET_COMP_RECEIVE_COMPOSITION:
            return Object.assign({}, state, {
                isFetching: false,
                triedToFetch: true,
                tv_composition: action.composition,
            });

        case GET_COMP_CLEAR_SECONDARY_USAGES:
            return Object.assign({}, state, {
                isSecondaryUsagesFetching: true,
                secondaryUsages: {
                    usages: [],
                },
            });

        case GET_COMP_RECEIVE_SECONDARY_USAGES:
            return Object.assign({}, state, {
                isSecondaryUsagesFetching: false,
                secondaryUsages: {
                    empty: action.secondaryUsages.empty,
                    usages: action.secondaryUsages.usages,
                }
            });

        case GET_COMP_SET_FETCHING:
            return Object.assign({}, state, {
                isFetching: action.isFetching,
            });

        default:
            return state;
    }
};

export const requestCompositionAC = () => ({ type: GET_COMP_REQUEST_COMPOSITION })
export const clearSecondaryUsagesAC = () => ({ type: GET_COMP_CLEAR_SECONDARY_USAGES })

export function fetchComposition(item) {
    let what = item.split("/").join("____");
    return function (dispatch) {
        dispatch(requestCompositionAC());
        return fetch(process.env.REACT_APP_ORIGIN + `/api/tech/tv/composition/${what}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                json => dispatch(receiveCompositionAC(json))
            )
    }
}

export function fetchSecondaryUsages(arr, doNotClear = false) {
    return function (dispatch) {
        if (!doNotClear) {
            dispatch(clearSecondaryUsagesAC());
        }
        return fetch(process.env.REACT_APP_ORIGIN + `/api/tech/tv/usages/${arr[0].split("/").join("____")}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                json => dispatch(receiveSecondaryUsagesAC(json))
            )
    }
}

export const receiveCompositionAC = (json) => ({
    type: GET_COMP_RECEIVE_COMPOSITION,
    composition: json.data.tv_composition
})

export const receiveSecondaryUsagesAC = (json) => ({
    type: GET_COMP_RECEIVE_SECONDARY_USAGES,
    secondaryUsages: json.data,
})

export const compositionSetFetchingAC = (fetching) => ({
    type: GET_COMP_SET_FETCHING,
    isFetching: fetching,
})

export const updateSearchModelTextActionCreator = (text) =>
    ({ type: GET_COMP_UPDATE_SEARCH_MODEL_TEXT, newText: text })

export default compositionReducer;