import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@mui/material';

const useStyles = makeStyles({
    root: {
        marginTop: 10,
        marginX: 2,
        marginBottom: 10,
        background: '#202938',
        paddingBottom: 0,
        paddingTop: 0,
        borderColor: 'white',
    },
    header: {
        color: '#6dc353',
        '&.MuiTypography-root': {
            fontFamily: 'Jura',
            fontWeight: 700,
            marginBottom: 10,
        },
        fontSize: 16,
        marginBottom: 6,
        margin: 5,
    },
    content: {
        color: '#dddddd',
        '&.MuiTypography-root': {
            fontFamily: 'Jura',
            fontWeight: 500,
            marginBottom: 10,
        },
        marginBottom: 4,
        paddingLeft: 5,
    },
    contentBold: {
        fontSize: 15,
        fontWeight: 700,
        color: 'black',
        marginBottom: 4,
        paddingLeft: 5,
    },
    iconButtons: {
        color: '#262831'
    },
    badge: {
        bgcolor: '#262831'
    }
});

const InfoCard = (props) => {
    const classes = useStyles();

    return (
        <Card
            className={classes.root}
            sx={{ maxWidth: 300, bgcolor: '#202938' }}>
            <CardActionArea>
                <CardContent >
                    <Typography gutterBottom className={classes.header}>
                        {props.title}
                    </Typography>
                    <Typography className={classes.content}>
                        {props.text}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default InfoCard;