import fetch from "cross-fetch";

const TRADING_SET_FETCHING = 'TRADING_SET_FETCHING';
const TRADING_DATA_UPDATED = 'TRADING_DATA_UPDATED';
const TRADING_SET_ERR = 'TRADING_SET_ERR';
const TRADING_PURCHASES_PARTIAL_UPDATE = 'TRADING_PURCHASES_PARTIAL_UPDATE';
const TRADING_SALES_PARTIAL_UPDATE = 'TRADING_SALES_PARTIAL_UPDATE';

const TRADING_PURCHASES_STATE_FILTER_CHANGED = 'TRADING_PURCHASES_STATE_FILTER_CHANGED';
const TRADING_PURCHASES_FB_FILTER_CHANGED = 'TRADING_PURCHASES_FB_FILTER_CHANGED';

const TRADING_SALES_STATE_FILTER_CHANGED = 'TRADING_SALES_STATE_FILTER_CHANGED';
const TRADING_SALES_FB_FILTER_CHANGED = 'TRADING_SALES_FB_FILTER_CHANGED';

const LOG_REG_LOGOUT = 'LOG_REG_LOGOUT';

let initialState = {
    isFetching: false,

    purchasesTotal: 0,
    purchases: [
        /*
            "id": "3",
            "seller_id": "13",
            "listing_id": "26",
            "buyer_confirm": "0",
            "seller_confirm": "0",
            "login": "Failure",
            "email": "failure13@mail.ru",
            "city": "Владивосток",
            "city_type": "город",
            "region": "Приморский край",
            "phone": "79532179019",
            "whatsapp": "79532179018",
            "telegram": "echkalo",
            "pickup_addr": "улица Светланская, 52 (район остановки Центр), вход со двора, зеленая дверь. Парковаться лучше через дорогу, на парковке торгового центра",
            "price": "1000",
            "summary": "Блок питания для ТВ SAMSUNG UE40F6400AK BN44-00622B",
            "type": "psu",
            "text": "Блок питания/Модуль питания/PSU",
            "vendor": "SAMSUNG",
            "model": "UE40F6400AK",
            "cover": "https://storage.yandexcloud.net/ntx-public-staging/b7ef69f9-9aa7-4fc7-aad9-5d879959d3fd/0_thm.jpeg"
        */
    ],

    salesTotal: 0,
    sales: [],

    // filters
    filteredPurchases: [],
    purchasesStateFilter: 'any',
    purchasesFeedbackFilter: 'any',

    filteredSales: [],
    salesStateFilter: 'any',
    salesFeedbackFilter: 'any',

    errorCondition: false,
    errWhat: '',
};

const filteredPurchases = (src, stateFilter, feedbackFilter) => {
    let result = src;
    if (stateFilter !== 'any') {
        switch (stateFilter) {
            case 'inProgress':
                result = result.filter(function (item) { return !item.discarded && (!item.buyer_confirm || !item.seller_confirm) });
                break;
            case 'completed':
                result = result.filter(function (item) { return item.buyer_confirm === true && item.seller_confirm === true; });
                break;
            case 'discarded':
                result = result.filter(function (item) { return item.discarded === true; });
                break;
            default: break;
        }
    }

    if (feedbackFilter !== 'any') {
        switch (feedbackFilter) {
            case 'expectMy':
                result = result.filter(function (item) { return item.buyer_confirm === true && item.seller_confirm === true && item.buyer_fb_type === null });
                break;
            case 'expectSeller':
                result = result.filter(function (item) { return item.buyer_confirm === true && item.seller_confirm === true && item.seller_fb_type === null });
                break;
            default: break;
        }
    }

    return result;
}

const filteredSales = (src, stateFilter, feedbackFilter) => {
    let result = src;
    if (stateFilter !== 'any') {
        switch (stateFilter) {
            case 'inProgress':
                result = result.filter(function (item) { return !item.discarded && (!item.buyer_confirm || !item.seller_confirm) });
                break;
            case 'completed':
                result = result.filter(function (item) { return item.buyer_confirm === true && item.seller_confirm === true; });
                break;
            case 'discarded':
                result = result.filter(function (item) { return item.discarded === true; });
                break;
            default: break;
        }
    }

    if (feedbackFilter !== 'any') {
        switch (feedbackFilter) {
            case 'expectMy':
                result = result.filter(function (item) { return item.buyer_confirm === true && item.seller_confirm === true && item.seller_fb_type === null });
                break;
            case 'expectBuyer':
                result = result.filter(function (item) { return item.buyer_confirm === true && item.seller_confirm === true && item.buyer_fb_type === null });
                break;
            default: break;
        }
    }

    return result;
}

const myTradingActivityReducer = (state = initialState, action) => {
    switch (action.type) {
        case TRADING_SET_FETCHING:
            return Object.assign({}, state, {
                isFetching: action.isFetching,
            });

        case TRADING_DATA_UPDATED:
            return Object.assign({}, state, {
                isFetching: false,
                purchasesTotal: action.purchasesTotal,
                purchases: action.purchases,
                salesTotal: action.salesTotal,
                sales: action.sales,
                // purchases filters
                purchasesStateFilter: 'any',
                purchasesFeedbackFilter: 'any',
                filteredPurchases: filteredPurchases(action.purchases, 'any', 'any'),
                salesStateFilter: 'any',
                salesFeedbackFilter: 'any',
                filteredSales: filteredSales(action.sales, 'any', 'any'),
            });

        case TRADING_SET_ERR:
            return Object.assign({}, state, {
                errorCondition: true,
                errWhat: action.what,
                isFetching: false,
            });

        case TRADING_PURCHASES_PARTIAL_UPDATE:
            return Object.assign({}, state, {
                isFetching: false,
                purchases: state.purchases.map(item => item.id === action.purchaseUpdate.id ? action.purchaseUpdate : item),
                filteredPurchases: filteredPurchases(state.purchases.map(item => item.id === action.purchaseUpdate.id ? action.purchaseUpdate : item), state.purchasesStateFilter, state.purchasesFeedbackFilter),
            });

        case TRADING_PURCHASES_STATE_FILTER_CHANGED:
            return Object.assign({}, state, {
                purchasesStateFilter: action.filter,
                filteredPurchases: filteredPurchases(state.purchases, action.filter, state.purchasesFeedbackFilter),
            });

        case TRADING_PURCHASES_FB_FILTER_CHANGED:
            return Object.assign({}, state, {
                purchasesFeedbackFilter: action.filter,
                filteredPurchases: filteredPurchases(state.purchases, state.purchasesStateFilter, action.filter),
            });










        case TRADING_SALES_PARTIAL_UPDATE:
            return Object.assign({}, state, {
                isFetching: false,
                sales: state.sales.map(item => item.id === action.saleUpdate.id ? action.saleUpdate : item),
                filteredSales: filteredSales(state.sales.map(item => item.id === action.saleUpdate.id ? action.saleUpdate : item), state.salesStateFilter, state.salesFeedbackFilter),
            });

        case TRADING_SALES_STATE_FILTER_CHANGED:
            return Object.assign({}, state, {
                salesStateFilter: action.filter,
                filteredSales: filteredSales(state.sales, action.filter, state.salesFeedbackFilter),
            });

        case TRADING_SALES_FB_FILTER_CHANGED:
            return Object.assign({}, state, {
                salesFeedbackFilter: action.filter,
                filteredSales: filteredSales(state.sales, state.salesStateFilter, action.filter),
            });





        case LOG_REG_LOGOUT:
            return Object.assign({}, state, initialState);

        default:
            return state;
    }
};

export const tradingSetFetchingAC = (isFetching) => ({
    type: TRADING_SET_FETCHING,
    isFetching: isFetching
})

export const tradingReceiveUpdates = (data) => ({
    type: TRADING_DATA_UPDATED,
    purchasesTotal: data.purchasesTotal,
    purchases: data.purchases,
    salesTotal: data.salesTotal,
    sales: data.sales,
})

export const tradingPurchasePartialUpdate = (data) => ({
    type: TRADING_PURCHASES_PARTIAL_UPDATE,
    purchaseUpdate: data.purchaseUpdate,
})

export const tradingSalePartialUpdate = (data) => ({
    type: TRADING_SALES_PARTIAL_UPDATE,
    saleUpdate: data.saleUpdate,
})


export const tradingPurchasesStateFilterChanged = (filter) => ({
    type: TRADING_PURCHASES_STATE_FILTER_CHANGED,
    filter: filter,
})

export const tradingPurchasesFbFilterChanged = (filter) => ({
    type: TRADING_PURCHASES_FB_FILTER_CHANGED,
    filter: filter,
})

export const tradingSalesStateFilterChanged = (filter) => ({
    type: TRADING_SALES_STATE_FILTER_CHANGED,
    filter: filter,
})

export const tradingSalesFbFilterChanged = (filter) => ({
    type: TRADING_SALES_FB_FILTER_CHANGED,
    filter: filter,
})


export const tradingSetError = (what) => ({
    type: TRADING_SET_ERR,
    what: what,
})

export function fetchTradingActivity() {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + `/api/listings/trading`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(tradingReceiveUpdates(json.data));
                    } else {
                        dispatch(tradingSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function setBuyerConfirm(deal_id) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/buyerconfirm', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                dealId: deal_id,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(tradingPurchasePartialUpdate(json.data));
                    } else {
                        dispatch(tradingSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function setSellerConfirm(deal_id) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/sellerconfirm', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                dealId: deal_id,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(tradingSalePartialUpdate(json.data));
                    } else {
                        dispatch(tradingSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function setBuyerDiscard(deal_id) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/buyerdiscard', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                dealId: deal_id,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(tradingPurchasePartialUpdate(json.data));
                    } else {
                        dispatch(tradingSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function setSellerDiscard(deal_id) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/sellerdiscard', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                dealId: deal_id,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(tradingSalePartialUpdate(json.data));
                    } else {
                        dispatch(tradingSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function sellerSoldLast(deal_id, listing_id) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/soldlast', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                dealId: deal_id,
                listingId: listing_id,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(tradingSalePartialUpdate(json.data));
                    } else {
                        dispatch(tradingSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function addBuyerFeedback(deal_id, fbType, fbText) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/buyeraddfb', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                dealId: deal_id,
                fbType: fbType,
                fbText: fbText,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(tradingPurchasePartialUpdate(json.data));
                    } else {
                        dispatch(tradingSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function addSellerFeedback(deal_id, fbType, fbText) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/selleraddfb', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                dealId: deal_id,
                fbType: fbType,
                fbText: fbText,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(tradingSalePartialUpdate(json.data));
                    } else {
                        dispatch(tradingSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}


export default myTradingActivityReducer;