import { combineReducers, createStore, applyMiddleware } from 'redux';
import compositionReducer from "./composition-reducer";
import usagesReducer from "./usages-reducer";
import userReducer from "./user-reducer";
import addCompositionReducer from "./add-composition-reducer";
import listingReducer from './listing-reducer';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import anotherUserReducer from './another-user-reducer';
import myListingsReducer from './my-listings-reducer';
import myFeedbackReducer from './my-feedback-reducer';
import myFavoritesReducer from './my-favorites';
import mySettingsReducer from './my-settings-reducer';
import loginRegisterReducer from './login-reg-reducer';
import addSingleListingReducer from './add-1-listing-reducer';
import addListingsPack from './add-multi-listings-reducer';
import listingsSearchReducer from './listings-search-reducer';
import mySubscriptionsReducer from './my-subscriptions-reducer';
import subscriptionsUpdatesReducer from './subscription-updates-reducer';
import myTradingActivityReducer from './my-trading-activity-reducer';
import exactPurchaseReducer from './exact-purchase-reducer';
import exactSaleReducer from './exact-sale-reducer';
import eventsReducer from './events-reducer';


let reducers = combineReducers({
    compositionPage: compositionReducer,
    usagesPage: usagesReducer,
    userState: userReducer,
    addCompositionPage: addCompositionReducer,
    listingPage: listingReducer,
    anotherUserPage: anotherUserReducer,

    myListingsPage: myListingsReducer,
    myFeedbackPage: myFeedbackReducer,
    myFavoritesPage: myFavoritesReducer,
    settings: mySettingsReducer,

    loginReg: loginRegisterReducer,
    addSingle: addSingleListingReducer,
    addPack: addListingsPack,
    listingsSearch: listingsSearchReducer,
    subscriptionsPage: mySubscriptionsReducer,
    subscriptionUpdates: subscriptionsUpdatesReducer,

    tradingActivity: myTradingActivityReducer,
    exactPurchase: exactPurchaseReducer,
    exactSale: exactSaleReducer,
    events: eventsReducer,
});

const loggerMiddleware = createLogger();
let store = createStore(
    reducers,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    )
);

export default store;

window.store = store;