import React from 'react'
import { Grid, Link } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box } from '@mui/system';
import transliterate from '../../utils/transliterate';

const useStyles = makeStyles({
    header: {
        fontSize: 16,
        fontWeight: 700,
        color: 'black',
        marginBottom: 6,
        margin: 5,
        alignSelf: "stretch"
    },
    content: {
        fontSize: 15,
        fontWeight: 500,
        color: 'black',
        marginBottom: 4,
        paddingLeft: 5,
    },
});

/*
    Props:
        - imgUrl - URL of the thumbnail
        - title - title
        - submitter - submitter login
        - date - submission date
        - price - price
        - type - type of the HW
        - pns - array of the associated part numbers
        - id - id of the listing
        - region - region of the submitter
        - city - submitter city
*/

const AnotherUserListingPreview = (props) => {
    const classes = useStyles();

    return (
        <>
            <Box
                classes={classes.root}
                sx={{
                    border: '1px solid #f6f8f1',
                    borderRadius: '4px',
                    marginBottom: 1,
                    bgcolor: '#f3f3f3'
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    wrap='wrap'
                    sx={{ flexGrow: 1 }}
                >
                    <Grid item sx={{ order: { sm: 1, md: 1 } }}>
                        <Link href='#'>
                            <Avatar
                                alt="Thumbnail"
                                src={props.imgUrl}
                                sx={{ width: 150, height: 150 }}
                                variant="square"
                            />
                        </Link>
                    </Grid>

                    <Grid item xs={7}>
                        <Link href={`/buy/${transliterate(props.title.split(' ').join('_'))}-${props.id}`}>
                            <Typography className={classes.header} gutterBottom>
                                {props.title}
                            </Typography>
                        </Link>

                        <Typography className={classes.content} >
                            {props.submitter}  {props.date}
                        </Typography>
                        <Typography className={classes.content} >
                            {props.region}, {props.city}
                        </Typography>
                    </Grid>

                    <Grid item >
                        <Typography className={classes.header} gutterBottom>
                            {props.price}р.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default AnotherUserListingPreview;