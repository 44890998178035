import { React, useEffect } from 'react';
import Header from './components/Header';
import Footer from './components/Footer'
import { Route, Switch } from "react-router";
import Home from "./pages/Home";
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles';
import { Container } from "@material-ui/core";
import CompositionContainer from "./pages/Composition";
import UsagesContainer from "./pages/Usages";
import AddCompositionContainer from "./pages/AddComposition";
import Page404 from "./pages/404";
import usePageTracking from "./history/analyticsTracker";
import { initialSync } from "./redux/login-reg-reducer";
import { connect } from "react-redux";
import PersonalContainer from './pages/Personal';
import UnderConstruction from './pages/UnderConstruction';
import UserSettingsContainer from './pages/personal/Settings';
import MyListingsPageContainer from './pages/personal/MyListings';
import './App.css';
import './style/style.css';
import FavoritePageContainer from './pages/personal/Favorite';
import SubscriptionsPageContainer from './pages/personal/Subscriptions';
import AddSingleListingContainer from './pages/listings/AddSingle';
import AddDisassembly from './pages/listings/DisassembleTv';
import ListingContainer from './pages/listings/Listing';
import UserProfileContainer from './pages/UserProfile';
import FeedbackPageContainer from './pages/personal/Feedback';
import SearchListingsContainer from './pages/listings/Search';
import SubscriptionUpdatesContainer from './pages/listings/SubscriptionUpdates';
import MyPurchasesPageContainer from './pages/personal/MyPurchases';
import MySalesPageContainer from './pages/personal/MySales';
import ExactPurchaseContainer from './pages/personal/ExactPurchase';
import ExactSaleContainer from './pages/personal/ExactSale';
import CookieConsent from "react-cookie-consent";
import { Link } from '@material-ui/core';

const App = (props) => {
    usePageTracking();
    useEffect(() => {
        props.sync();
    }, []);

    const theme = createTheme({
        typography: {
            allVariants: {
                fontFamily: ['Jura', 'Arial'].join(','),
            },
        },
        palette: {
            type: 'light',
            primary: {
                main: '#202938',
            },
            secondary: {
                main: '#6dc353',
            },
        },
    });

    return (
        <>
            <ThemeProvider theme={theme}>
                <div className="wrapper">
                    <Header />
                    <Container maxWidth="md">
                        <CookieConsent
                            location="bottom"
                            buttonText="Ок"
                            cookieName="ntxCookieConsent"
                            style={{ background: "#202937", fontWeight: 500, }}
                            buttonStyle={{
                                fontFamily: 'Jura',
                                color: "#202937",
                                fontSize: "18px",
                                fontWeight: 700,
                                backgroundColor: '#6dc353'
                            }}
                        >
                            Пользуясь нашим сайтом, вы соглашаетесь с тем, что <Link href="/#" style={{ color: '#6dc353' }} >мы используем cookies</Link>
                            <br /> Запретить обработку Вы всегда можете через браузер
                        </CookieConsent>

                        <div className='app-wrapper-content'>
                            <Switch>
                                <Route exact path="/" render={() => <Home />} />
                                <Route exact path="/services" render={() => <Home />} />
                                <Route exact path="/services/composition/tv/add"
                                    render={() => <AddCompositionContainer />} />
                                <Route exact path="/services/composition/tv/:searchedItem"
                                    render={(props) => <CompositionContainer {...props} />} />
                                <Route exact path="/services/usages/tv/:searchedItem"
                                    render={(props) => <UsagesContainer {...props} />} />
                                <Route exact path="/services/composition/tv/"
                                    render={(props) => <CompositionContainer {...props} />} />
                                <Route exact path="/services/usages/tv/"
                                    render={(props) => <UsagesContainer {...props} />} />

                                <Route exact path="/personal" render={() => <PersonalContainer {...props} />} />
                                <Route exact path="/personal/favorites" render={() => <FavoritePageContainer {...props} />} />
                                <Route exact path="/personal/subscriptions" render={() => <SubscriptionsPageContainer {...props} />} />
                                <Route exact path="/personal/feedback" render={() => <FeedbackPageContainer {...props} />} />
                                <Route exact path="/personal/settings" render={() => <UserSettingsContainer {...props} />} />
                                <Route exact path="/personal/mylistings" render={() => <MyListingsPageContainer {...props} />} />
                                <Route exact path="/personal/mylistings/new" render={() => <AddSingleListingContainer {...props} />} />
                                <Route exact path="/personal/mylistings/tvdisasm" render={() => <AddDisassembly {...props} />} />
                                <Route exact path="/personal/mypurchases" render={() => <MyPurchasesPageContainer {...props} />} />
                                <Route exact path="/personal/mypurchase/:reqId" render={(props) => <ExactPurchaseContainer {...props} />} />
                                <Route exact path="/personal/mysales" render={() => <MySalesPageContainer {...props} />} />
                                <Route exact path="/personal/mysale/:reqId" render={(props) => <ExactSaleContainer {...props} />} />

                                <Route exact path="/buy/:humanReadablePlusId"
                                    render={(props) => <ListingContainer {...props} />} />
                                <Route exact path="/profile/:userLogin"
                                    render={(props) => <UserProfileContainer {...props} />} />

                                <Route exact path="/services/search/tv/"
                                    render={(props) => <SearchListingsContainer {...props} />} />
                                <Route exact path="/services/search/tv/:requestFromUrl"
                                    render={(props) => <SearchListingsContainer {...props} />} />

                                <Route exact path="/personal/subscription-update/:subscription_id"
                                    render={(props) => <SubscriptionUpdatesContainer {...props} />} />

                                {/*<Route exact path="/personal/messages" render={() => <UnderConstruction {...props} />} />
                                <Route exact path="/personal/billing" render={() => <UnderConstruction {...props} />} />
                                <Route exact path="/personal/bonuses" render={() => <UnderConstruction {...props} />} />
                                */}

                                {<Route path="*" component={Page404} />}
                            </Switch>
                        </div>
                    </Container>
                    <Footer />
                </div>
            </ThemeProvider>


        </>
    );
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        sync: () => {
            dispatch(initialSync());
        },
    }
}

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
export default AppContainer;
