import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import StyledButton from '../entities/StyledButton';
import EditIcon from '@mui/icons-material/Edit';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { connect } from "react-redux";
import { createListingNote, updateListingNote, setFetchingAC } from '../../redux/listing-reducer'
import StyledDarkTextField from '../entities/StyledDarkTextField';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import AddCommentIcon from '@mui/icons-material/AddComment';

/*
 * Props:
 * buttonText
 * buttonIcon
 * onConfirm - cb if user decide to proceed
 * titleText - ...
 * descriptionText - ...
 * cancelText - ...
 * proceedText - ...
 * updateExisting - bool
*/
const useStyles = makeStyles({
    iconButton: {
        color: '#202938',
        padding: '3px',
    },
});

const PriceChangeDialog = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [text, setText] = useState(props.price);

    const maxLen = 7;
    const textChanged = (e) => {
        if (e.target.value.length > maxLen)
            return;

        let isnum = /^\d+$/.test(e.target.value);
        if (isnum) {
            setText(e.target.value);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const renderButton = () => {
        if (props.rowMode) {
            return (
                <>
                    <Tooltip
                        title="Изменить цену"
                        placement="bottom"
                    >
                        <IconButton
                            aria-label="change"
                            onClick={handleClickOpen}
                        >
                            <EditIcon
                                className={classes.iconButton}
                                fontSize='small'
                            />
                        </IconButton>
                    </Tooltip>
                </>
            )
        }

        return (
            <>
                <StyledButton
                    icon={<EditIcon />}
                    size={'small'}
                    onClick={handleClickOpen}
                    label={"Изменить цену"}
                />
            </>
        )
    };

    return (
        <React.Fragment>
            {renderButton()}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                        fontFamily: 'Jura',
                        bgcolor: '#262831',
                        color: '#6dc353',
                    }}
                >
                    {"Изменение цены"}
                </DialogTitle>

                <DialogContent
                    sx={{
                        fontFamily: 'Jura',
                        fontWeight: '600',
                        bgcolor: '#262831',
                        color: '#6dc353',
                    }}
                >
                    <DialogContentText id="alert-dialog-description" sx={{
                        fontFamily: 'Jura',
                        fontWeight: '500',
                        bgcolor: '#262831',
                        color: '#cccccc',
                    }}>
                        Измените цену и нажмите кнопку "Сохранить".
                    </DialogContentText>

                    <StyledDarkTextField
                        value={text}
                        onChange={textChanged}
                        multiline={false}
                        placeholder={'Введите новую цену'}
                    />
                </DialogContent>

                <DialogActions sx={{
                    bgcolor: '#262831',
                }}>
                    <StyledButton
                        onClick={() => {
                            props.updatePrice(props.listingId, props.price, text, props.summary);
                        }}
                        label={"Сохранить"}
                    />
                    <StyledButton
                        onClick={handleClose}
                        label={"Отмена"}
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}

export const PriceChangeDialogContainer = connect(
    (state, ownProps) => {
        return {
            price: ownProps.price,
            listingId: ownProps.listingId,
            rowMode: ownProps.rowMode,
            summary: ownProps.summary,
        }
    },
    (dispatch, ownProps) => {
        return {
            updatePrice: (id, oldPrice, newPrice, summary) => {
                dispatch(ownProps.setFetchingCb(true));
                dispatch(ownProps.updatePriceCb(id, oldPrice, newPrice, summary));
            }
        }
    },
)(PriceChangeDialog);
