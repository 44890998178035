import React from 'react'
import Pagination from '@mui/material/Pagination';
import { Box } from '@material-ui/core';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';

/*
Props:
    pagesize: num of items on one page,
    renderItem(index, value): callback to render item
    items: array of items
    darkBg: true/false
*/
const useStyles = makeStyles((theme) => ({
    root: {
        borderColor: '#6dc353',
        borderRadius: 4,
        '&:hover': {
            borderColor: '#6dc353',
        },
        '&:focus': {
            borderColor: '#6dc353',
        },
        '&.Mui-active': {
            borderColor: '#6dc353',
        },
        fontWeight: 600,
    },
    button: {
        margin: theme.spacing(1),
    },
    label: {
        color: '#6dc353',
        textTransform: 'capitalize',
    },
}));

const Paginated = (props) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(1);
    const theme = useTheme();
    const size = useMediaQuery(theme.breakpoints.down("sm")) ? "small" : "medium";

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const paginate = (array, pageSize, pageNumber) => {
        return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    }

    const renderData = (page, rowsPerPage) => {
        let elements = paginate(props.items, rowsPerPage, page);
        const items = []
        for (const [index, value] of elements.entries()) {
            items.push(props.renderItem(index, value));
        }
        return items;
    }

    return (
        <>
            {renderData(page, props.pagesize)}

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination
                    variant="outlined"
                    shape="rounded"
                    count={Math.ceil(props.items.length / props.pagesize)}
                    page={page}
                    //pageSize={props.pagesize}
                    onChange={handleChangePage}
                    boundaryCount={1}
                    size={size}
                    //classes={classes.root}
                    sx={props.darkBg ? {
                        marginBottom: 2,
                        '& .MuiButtonBase-root': {
                            color: '#6dc353',
                            borderColor: '#6dc353',
                        },
                        '& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected': {
                            backgroundColor: '#6dc353',
                            color: 'white',
                        }
                    } : {
                        marginBottom: 2,
                    }}
                />
            </Box>
        </>
    )
}

export default Paginated;