import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    cssFocused: {
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: '#202938 !important',
    },
    input: {
        color: '#202938',
        fontWeight: 600,
        maxWidth: '600px',
    },
    error: props => ({
        margin: props.margin,
        marginBottom: props.marginBottom,
        borderWidth: '1px',
        borderColor: '#FF2938 !important',
        color: '#FF2938',
        fontWeight: 600,
        maxWidth: '600px',
    }),
    outlinedInput: props => ({
        margin: props.margin,
        marginBottom: props.marginBottom,
    }),
}));

const StyledTextField = (props) => {
    const classes = useStyles(props.noMargins ? { margin: 0 } : { marginBottom: 10, margin: 5, });

    return (
        <>
            <TextField
                label={props.label}
                type={props.type}
                variant="outlined"
                fullWidth={props.noFullWidth ? false : true}
                onChange={props.onChange}
                value={props.value}
                placeholder={props.placeholder}
                multiline={props.multiline}
                size="small"
                InputProps={{
                    classes: {
                        root: props.error ? classes.error : classes.outlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                        input: props.error ? classes.error : classes.input,
                    },
                    startAdornment: props.icon ? (
                        <InputAdornment position="start">
                            {props.icon}
                        </InputAdornment>
                    ) : (<></>),
                }}
                InputLabelProps={{
                    style: {
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        width: '100%',
                        color: '#202938',
                        fontWeight: '600',
                    }
                }}
                error={props.err}
                helperText={props.errWhat}
                disabled={props.disabled}
            />
        </>
    )
}

export default StyledTextField;