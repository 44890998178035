import React from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { confirmBaseInfoAC } from "../../redux/add-1-listing-reducer";
import StyledButton from "../entities/StyledButton";
import { Box, Grid, Typography } from '@material-ui/core';
import DoneIcon from '@mui/icons-material/Done';
import StyledTextField from "../entities/StyledTextField";
import HelperCard from "../settings/HelperCard";
import { packSetBaseInfoAC, packConfirmStageIfLastBaseInfoAC } from '../../redux/add-multi-listings-reducer'
import { Divider } from "@mui/material";

const useStyles = makeStyles({
    content: {
        fontSize: 16,
        fontWeight: 600,
        color: 'black',
        marginBottom: 4,
        paddingLeft: 5,
    },
});

const BaseInfo = (props) => {
    const classes = useStyles();
    const [summaryText, setSummaryText] = React.useState(props.summary === "" ? props.hwType + " для ТВ " + props.vendor + ' ' + props.model + ' ' + props.pns : props.summary);
    const [priceText, setPriceText] = React.useState(props.price);
    const [descText, setDescText] = React.useState(props.description === "" ? props.hwType + " для ТВ " + props.vendor + ' ' + props.model + '\nПартнамберы: ' + props.pns + '\n' : props.description);

    const summaryMaxLen = 300;
    const descriptionMaxLen = 1200; // +300 from settings.descriptionAddon; Corresponding DB field is limited with 1500 symbols

    React.useEffect(() => {
        if (summaryText === '' && priceText === '' && descText === '') {
            setSummaryText(props.summary === "" ? props.hwType + " для ТВ " + props.vendor + ' ' + props.model + ' ' + props.pns : props.summary);
            setPriceText(props.price);
            setDescText(props.description === "" ? props.hwType + " для ТВ " + props.vendor + ' ' + props.model + '\nПартнамберы: ' + props.pns + '\n' : props.description);
        }
    }, [summaryText, priceText, descText, props]);

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent='center'
            >
                <HelperCard
                    summary="Внимание!"
                    helperText={
                        "Если Вы видите опечатку в партнамберах - нужно вернуться на шаг 2 (\"Партнамберы запчасти\") и исправить её там - используйте кнопку \"К предыдущему\" внизу страницы. Менять партнамберы в заголовке и описании (ниже) нельзя - это приведет к тому что Ваше объявление не будет отображаться в поисковой выдаче, т.к. поисковый движок сопоставляет поисковый запрос именно с партнамбером, указанным на шаге 2. "
                    } />

                <Grid item xs={12} sm={10}>
                    <h2>{props.hwType}</h2>
                </Grid>

                <Grid item xs={12} sm={10}>
                    <Box sx={{ marginBottom: 4 }}>
                        Отредактируйте, если нужно заголовок объявления:
                    </Box>
                    <StyledTextField
                        label='Заголовок'
                        value={summaryText}
                        onChange={(e) => {
                            if (e.target.value.length > summaryMaxLen)
                                return;
                            setSummaryText(e.target.value);
                        }}
                        multiline={false}
                    />
                </Grid>

                <Grid item xs={12} sm={10}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        alignContent='center'
                    >
                        <Grid item>
                            <Box sx={{ marginBottom: 5 }}>
                                Укажите цену в рублях:
                            </Box>
                        </Grid>

                        <Grid item>
                            <StyledTextField
                                label='Цена'
                                value={priceText}
                                onChange={(e) => {
                                    if (/^\d+$/.test(e.target.value) || e.target.value === "")
                                        setPriceText(e.target.value)
                                }}
                                multiline={false}
                                noFullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={10}>
                    <Box sx={{ marginBottom: 4 }}>
                        Опишите товар:
                    </Box>
                    <StyledTextField
                        label='Описание'
                        value={descText}
                        onChange={(e) => {
                            if (e.target.value.length > descriptionMaxLen)
                                return;
                            setDescText(e.target.value)
                        }}
                        multiline={true}
                    />

                    {
                        props.descAddon === "" ? (<></>) : (
                            <>
                                <Divider sx={{ marginBottom: 2 }} />
                                <StyledTextField
                                    label='Авто-дополнение'
                                    value={props.descAddon}
                                    multiline={true}
                                    disabled
                                />
                                <Typography gutterBottom>
                                    Текст из поля 'Авто-дополнение' будет автоматически добавлен к указанному описанию при публикации объявления. Изменить этот текст можно через Настройки/Шаблоны в объявлениях/Автоматическое дополнение описания
                                </Typography>
                            </>
                        )
                    }
                </Grid>
            </Grid >

            <Box display={'flex'} justifyContent={'center'}>
                <StyledButton
                    icon={<DoneIcon />}
                    label="Готово"
                    onClick={() => {
                        if (summaryText !== "" && priceText !== "" && descText !== "") {
                            props.confirm(summaryText, descText, priceText);
                            setSummaryText('');
                            setDescText('');
                            setPriceText('');
                        }
                    }}
                />
            </Box>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    if (ownProps.multi) {
        const idx = ownProps.index;
        return {
            hwType: state.addPack.listingsData[idx].text.split("/", 1),
            pns: state.addPack.listingsData[idx].partnumbers,
            model: state.addPack.model,
            vendor: state.addPack.vendor,
            descAddon: state.settings.descriptionAddon,
            index: ownProps.index,

            summary: state.addPack.listingsData[idx].summary,
            description: state.addPack.listingsData[idx].description,
            price: state.addPack.listingsData[idx].price,
        }
    }
    return {
        hwType: state.addSingle.hwType.split("/", 1),
        pns: state.addSingle.partnumbers,
        model: state.addSingle.model,
        vendor: state.addSingle.vendor,
        descAddon: state.settings.descriptionAddon,

        summary: state.addSingle.summary,
        description: state.addSingle.description,
        price: state.addSingle.price,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    if (ownProps.multi) {
        return {
            confirm: (summary, description, price) => {
                ownProps.onConfirm(ownProps.index);
                dispatch(packSetBaseInfoAC(ownProps.index, summary, description, price));
                dispatch(packConfirmStageIfLastBaseInfoAC());
            }
        }
    }
    return {
        confirm: (summary, description, price) => {
            dispatch(confirmBaseInfoAC(summary, description, price));
        }
    }
}

const BaseInfoContainer = connect(mapStateToProps, mapDispatchToProps)(BaseInfo);
export default BaseInfoContainer;