
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import AddCommentIcon from '@mui/icons-material/AddComment';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import PhishingIcon from '@mui/icons-material/Phishing';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

export const eventIconByType = function (type) {
    switch (type) {
        case 'listings_added': return <PhishingIcon color='black' />;
        case 'new_subscription_results': return <NotificationImportantIcon color='black' />;
        case 'new_purchase': return <LocalMallIcon color='black' />;
        case 'new_sale': return <PointOfSaleIcon color='black' />;
        case 'buyer_confirmed': return <CheckIcon color='black' />;
        case 'seller_confirmed': return <CheckIcon color='black' />;
        case 'sale_completed': return <DoneAllIcon color='black' />;
        case 'purchase_completed': return <DoneAllIcon color='black' />;
        case 'deal_discarded_by_buyer': return <RemoveDoneIcon color='black' />;
        case 'deal_discarded_by_seller': return <RemoveDoneIcon color='black' />;
        case 'new_buyer_feedback': return <AddCommentIcon color='black' />;
        case 'new_seller_feedback': return <AddCommentIcon color='black' />;
        case 'listings_expired': return <TimerOffIcon color='black' />;
        case 'tv_composition_added': return <AddToQueueIcon color='black' />;
        case 'favorite_price_changed': return <TrendingUpIcon color='black' />;
        default: break;
    }
};

export const eventTextByType = (type, param) => {
    switch (type) {
        case 'listings_added': return 'Добавлено одно или несколько новых объявлений.';
        case 'new_subscription_results': return 'По одной или нескольким Вашим подпискам есть новые объявления.';
        case 'new_purchase': return 'Новая покупка!';
        case 'new_sale': return 'Новая продажа!';
        case 'buyer_confirmed': return 'Покупатель подтвердил получение товара.';
        case 'seller_confirmed': return 'Продавец подтвердил получение оплаты.';
        case 'sale_completed': return 'Сделка закрыта. Можно оставить отзыв.';
        case 'purchase_completed': return 'Сделка закрыта. Можно оставить отзыв.';
        case 'deal_discarded_by_buyer': return 'Сделка отменена покупателем.';
        case 'deal_discarded_by_seller': return 'Сделка отменена продавцом.';
        case 'new_buyer_feedback': return 'Новый отзыв от покупателя!';
        case 'new_seller_feedback': return 'Новый отзыв от продавца!';
        case 'listings_expired': return 'Одно или несколько Ваших объявлений устарело.';
        case 'tv_composition_added': return 'Вы добавили ранее неизвестный состав ТВ.';
        case 'favorite_price_changed': return `У избранного объявления (${param.split('/')[0]}) изменилась цена: ${param.split('/')[2]}`
        default: return 'unknown'; break;
    }
};

export const linkHrefByType = function (type, param) {
    switch (type) {
        case 'listings_added': return '/personal/mylistings';
        case 'new_subscription_results': return '/personal/subscriptions';
        case 'new_purchase': return `/personal/mypurchase/${param}`;
        case 'new_sale': return `/personal/mysale/${param}`;
        case 'buyer_confirmed': return `/personal/mysale/${param}`;
        case 'seller_confirmed': return `/personal/mypurchase/${param}`;
        case 'sale_completed': return `/personal/mysale/${param}`;
        case 'purchase_completed': return `/personal/mypurchase/${param}`;
        case 'deal_discarded_by_buyer': return `/personal/mysale/${param}`;
        case 'deal_discarded_by_seller': return `/personal/mypurchase/${param}`;
        case 'new_buyer_feedback': return `/personal/mysale/${param}`;
        case 'new_seller_feedback': return `/personal/mypurchase/${param}`;
        case 'listings_expired': return '/personal/mylistings';
        case 'tv_composition_added': return `/services/composition/tv/${param}`;
        case 'favorite_price_changed': return `/buy/${param.split('/')[1]}`
        default: return 'unknown'; break;
    }
};