import { FormGroup2pn } from "../../FormGroup";
import React from "react";
import {
    updateLdPn1AC, updateLdPn2AC,
} from "../../../redux/add-composition-reducer";
import { connect } from "react-redux";

const LdAddForm = (props) => {

    return (
        <>
            <FormGroup2pn
                title={"Led-драйвер"}
                description={"Внимательно осмотрите плату и наклейки на ней. После этого укажите здесь партнамберы, которые видите.\n" +
                    "                            Каждый партнамбер указывайте в отдельном поле.\n" +
                    "                            Например, на плате вы видите \"BN44-00902A\", а на наклейке \"L65E8NC_MSM\".\n" +
                    "                            В поле \"Партнамбер 1\" укажите \"BN44-00902A\", а в поле \"Партнамбер 2\" - \"L65E8NC_MSM\"."}
                val_pn1={props.ld1}
                val_pn2={props.ld2}
                cb_pn1={props.updateLdPn1}
                cb_pn2={props.updateLdPn2}
                pn1_err={props.ld1Err}
                pn2_err={props.ld2Err}
                pn1_errDesc={props.ld1ErrWhat}
                pn2_errDesc={props.ld2ErrWhat}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        ld1: state.addCompositionPage.ld_pn1,
        ld2: state.addCompositionPage.ld_pn2,
        ld1Err: state.addCompositionPage.ld_pn1Err,
        ld2Err: state.addCompositionPage.ld_pn2Err,
        ld1ErrWhat: state.addCompositionPage.ld_pn1ErrWhat,
        ld2ErrWhat: state.addCompositionPage.ld_pn2ErrWhat,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateLdPn1: (val, err) => { dispatch(updateLdPn1AC(val, err)); },
        updateLdPn2: (val, err) => { dispatch(updateLdPn2AC(val, err)); },
    }
}
const LdAddFormContainer = connect(mapStateToProps, mapDispatchToProps)(LdAddForm);
export default LdAddFormContainer;