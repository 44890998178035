import React from 'react'
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import { styled } from "@mui/material/styles";
import { Container } from "@material-ui/core";
import ProfileCardContainer from './profile/ProfileCard';
import TemporaryDrawer from './profile/Drawer';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        margin: 0,
        background: '#202938',
        padding: 0,
        marginBottom: 5,
    },
    drawer: {
        backgroundColor: '#202938',
        color: '#6dc353',
        fontFamily: 'Jura, Arial',
    }
});

const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 0,
    paddingTop: 0,
}));

const ProfileHeader = (props) => {
    const classes = useStyles();

    return (
        <>
            <Card className={classes.root} variant="outlined">
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    alignContent='center'
                >
                    <Grid item xs={10} sm={11}>
                        <ProfileCardContainer />
                    </Grid>
                    <Grid item xs={2} sm={1}>
                        <TemporaryDrawer classes={{ root: classes.drawer }} mainIconSize='large' />
                    </Grid>
                </Grid>
                {/*<StyledContainer maxWidth="md">
                    <ProfileCardContainer />
                    <TemporaryDrawer classes={{ root: classes.drawer }} />
    </StyledContainer>*/}
            </Card>
        </>
    )
}

const mapStateToProps = (state) => {
    return {}
}

const ProfileHeaderContainer = connect(mapStateToProps, {})(ProfileHeader);
export default ProfileHeaderContainer;