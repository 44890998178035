import React from 'react'
import { connect } from "react-redux";
import { Grid } from '@material-ui/core';
import ListingsSummaryCardContainer from './ListingsSummaryCard';
import { Container } from '@mui/material';
import MyListingsFiltersContainer from './MyListingsFilters';
import MyListingsActionsContainer from './MyListingsActions';


const MyListingsHeader = (props) => {

    const renderError = () => {
        if (props.isErr) {
            return (
                <>
                    <h2>{props.errWhat}</h2>
                </>
            )
        }
    }

    return (
        <>
            <Container disableGutters >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                >
                    <Grid item >
                        <ListingsSummaryCardContainer />
                    </Grid>

                    <Grid item >
                        <MyListingsFiltersContainer />
                    </Grid>

                    <Grid item xs={12}>
                        <MyListingsActionsContainer />
                    </Grid>
                </Grid>

                {renderError()}
            </Container>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isErr: state.myListingsPage.errorCondition,
        errWhat: state.myListingsPage.errWhat,
    }
}

const MyListingsHeaderContainer = connect(mapStateToProps, {})(MyListingsHeader);
export default MyListingsHeaderContainer;