import { React, useEffect } from 'react';
import { connect } from "react-redux";
import { Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/system';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import { rmSubscription } from '../../redux/my-subscriptions-reducer';
import Tooltip from "@mui/material/Tooltip";
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import history from '../../history/history';

const useStyles = makeStyles({
    header: {
        fontSize: 16,
        fontWeight: 700,
        color: 'black',
        marginBottom: 6,
        margin: 5,
        alignSelf: "stretch"
    },
    content: {
        fontSize: 15,
        fontWeight: 500,
        color: 'black',
        marginBottom: 4,
        paddingLeft: 5,
    },
    contentBold: {
        fontSize: 15,
        fontWeight: 700,
        color: 'black',
        marginBottom: 4,
        paddingLeft: 5,
    },
    iconButtons: {
        color: '#262831'
    },
    badge: {
        bgcolor: '#262831'
    }
});

const SubscriptionCard = (props) => {
    const classes = useStyles();

    const deleteMe = () => {
        props.rmCurrent(props.id);
    }

    const observeNew = () => {
        history.push(`/personal/subscription-update/${props.id}`);
    }

    const buildTitle = () => {
        let subscriptionTitle = '';
        if (props.searchType === 'bytype') {
            subscriptionTitle = `${props.typeSearchTypeRu} для ТВ ${props.typeSearchModel}`;
        }
        else if (props.searchType === 'bypn') {
            subscriptionTitle = `${props.pnSearchPn} для ${props.pnSearchModel === null || props.pnSearchModel === '' ? 'любых моделей ТВ' : ('ТВ ' + props.pnSearchModel)}`;
        }
        return subscriptionTitle;
    }

    const renderIcon = () => {
        if (props.updates > 0) {
            return <NotificationsActiveIcon className={classes.iconButtons} />;
        }
        return <NotificationsIcon className={classes.iconButtons} />;
    }

    return (
        <>
            <Box
                classes={classes.root}
                sx={{
                    border: '1px solid #f6f8f1',
                    borderRadius: '4px',
                    marginBottom: 1,
                    bgcolor: props.updates > 0 ? '#badbad' : '#f3f3f3'
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    wrap='wrap'
                    sx={{ flexGrow: 1 }}
                >
                    <Grid item>
                        {renderIcon()}
                    </Grid>

                    <Grid item xs={7}>
                        <Link href={`/personal/subscription-update/${props.id}`}>
                            <Typography className={classes.header} gutterBottom>
                                {buildTitle()}
                            </Typography>
                        </Link>

                        <Typography className={classes.content} >
                            Активна с {props.date}
                        </Typography>

                        <Typography className={props.updates > 0 ? classes.contentBold : classes.content} >
                            {props.updates > 0 ?
                                ('Непросмотренных объявлений: ' + props.updates) :
                                'Непросмотренных объявлений пока нет'}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Tooltip title="Удалить подписку" placement="bottom">
                            <IconButton
                                aria-label="check"
                                size="large"
                                onClick={deleteMe}
                            >
                                <DeleteIcon className={classes.iconButtons} />
                            </IconButton>
                        </Tooltip>
                    </Grid>

                    <Grid item>
                        <Badge badgeContent={props.updates} max={99}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            color={props.updates > 0 ? "success" : ''}
                        >
                            <Tooltip title="Перейти к непросмотренным результатам" placement="bottom">
                                <IconButton
                                    aria-label="check"
                                    size="large"
                                    onClick={observeNew}
                                >
                                    <ArrowForwardIcon className={classes.iconButtons} />
                                </IconButton>
                            </Tooltip>
                        </Badge>
                    </Grid>
                </Grid>
            </Box >
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        id: ownProps.id,
        searchType: ownProps.searchType,
        typeSearchType: ownProps.typeSearchType,
        typeSearchTypeRu: ownProps.typeSearchTypeRu,
        typeSearchModel: ownProps.typeSearchModel,
        pnSearchPn: ownProps.pnSearchPn,
        pnSearchModel: ownProps.pnSearchModel,
        date: ownProps.date,
        updates: ownProps.updates,
        odd: ownProps.odd,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        rmCurrent: (id) => {
            dispatch(rmSubscription(id));
        },
    }
}

const SubscriptionCardContainer = connect(mapStateToProps, mapDispatchToProps)(SubscriptionCard);
export default SubscriptionCardContainer;