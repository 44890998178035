import { FormGroup3pn } from "../../FormGroup";
import React from "react";
import {
    updateWlPn1AC, updateWlPn2AC, updateWlPn3AC,
} from "../../../redux/add-composition-reducer";
import { connect } from "react-redux";

const WlAddForm = (props) => {

    return (
        <>
            <FormGroup3pn
                title={"WiFi модуль"}
                description={"Внимательно осмотрите плату и наклейки на ней. После этого укажите здесь партнамберы, которые видите.\n" +
                    "                            Каждый партнамбер указывайте в отдельном поле.\n" +
                    "                            Например, на плате вы видите \"BN41-01976B\", а на наклейке \"BN96-26401N\".\n" +
                    "                            В поле \"Партнамбер 1\" укажите \"BN41-01976B\", а в поле \"Партнамбер 2\" - \"BN96-26401N\"."}
                val_pn1={props.wl1}
                val_pn2={props.wl2}
                val_pn3={props.wl3}
                cb_pn1={props.updateWlPn1}
                cb_pn2={props.updateWlPn2}
                cb_pn3={props.updateWlPn3}
                pn1_err={props.wl1Err}
                pn2_err={props.wl2Err}
                pn3_err={props.wl3Err}
                pn1_errDesc={props.wl1ErrWhat}
                pn2_errDesc={props.wl2ErrWhat}
                pn3_errDesc={props.wl3ErrWhat}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        wl1: state.addCompositionPage.wl_pn1,
        wl2: state.addCompositionPage.wl_pn2,
        wl3: state.addCompositionPage.wl_pn3,
        wl1Err: state.addCompositionPage.wl_pn1Err,
        wl2Err: state.addCompositionPage.wl_pn2Err,
        wl3Err: state.addCompositionPage.wl_pn3Err,
        wl1ErrWhat: state.addCompositionPage.wl_pn1ErrWhat,
        wl2ErrWhat: state.addCompositionPage.wl_pn2ErrWhat,
        wl3ErrWhat: state.addCompositionPage.wl_pn3ErrWhat,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateWlPn1: (val, err) => { dispatch(updateWlPn1AC(val, err)); },
        updateWlPn2: (val, err) => { dispatch(updateWlPn2AC(val, err)); },
        updateWlPn3: (val, err) => { dispatch(updateWlPn3AC(val, err)); },
    }
}
const WlAddFormContainer = connect(mapStateToProps, mapDispatchToProps)(WlAddForm);
export default WlAddFormContainer;