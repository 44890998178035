import React from 'react'
import ProfileHeaderContainer from '../../components/ProfileHeader';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import MetaTags from 'react-meta-tags';
import MySubscriptionsContainer from '../../components/subscriptions/MySubscriptions';
import { connect } from "react-redux";
import Unauthorized from '../../components/entities/Unauthed';
import { showLoginDialogAC } from "../../redux/login-reg-reducer";

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
        marginTop: 10,
        marginBottom: 10,
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        spacing: 2,
    }
}));

const Subscriptions = (props) => {
    const classes = useStyles();

    if (!props.isLoggedIn) {
        props.showLoginDialog();
        return (
            <>
                <Unauthorized authCb={props.showLoginDialog} />
            </>
        )
    }

    return (
        <>
            <MetaTags>
                <title>Подписки - naTextolite</title>
            </MetaTags>

            <Breadcrumbs aria-label="breadcrumb">
                <Link color="textPrimary" href="/" className={classes.link}>
                    <HomeIcon className={classes.icon} />
                    naTeXtolite
                </Link>
                <Link color="textPrimary" href="/personal" className={classes.link}>
                    <AccountBoxIcon className={classes.icon} />
                    Личный кабинет
                </Link>
                <Typography color="textPrimary" className={classes.link}>
                    <NotificationsActiveIcon className={classes.icon} />
                    Подписки
                </Typography>
            </Breadcrumbs>

            <h1>Подписки</h1>

            <ProfileHeaderContainer />

            <MySubscriptionsContainer />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.loginReg.isLoggedIn,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showLoginDialog: () => {
            dispatch(showLoginDialogAC());
        },
    }
}

const SubscriptionsPageContainer = connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
export default SubscriptionsPageContainer;