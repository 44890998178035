import React, { useEffect } from 'react'
import { connect } from "react-redux";
import { Grid } from '@material-ui/core';
import StyledButton from '../../components/entities/StyledButton';
import { addSubscription, listSearchSetSubscriptionAddedAC } from '../../redux/listings-search-reducer';
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from '@mui/material';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import Snackbar from '@mui/material/Snackbar';
import DialogAlert from '../entities/DialogAlert';

const useStyles = makeStyles({
    content: {
        '&.MuiTypography-root': {
            fontFamily: 'Jura',
            fontWeight: 600,
        },
        color: 'black',
    },
});

const SubscriptionAdd = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    useEffect(() => {
        if (props.subscriptionAdded) {
            setOpen(true);
        }
        props.resetSnackbar();
    }, [props.subscriptionAdded])

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);

    };

    const addSubscription = () => {
        console.log("AddSubscription")
        if (props.searchType === 'bytype') {
            props.subscribe(
                props.searchType,
                props.typeModel,
                props.type,
                null,
                null
            );
        }
        else {
            props.subscribe(
                props.searchType,
                null,
                null,
                props.pnModel,
                props.pn
            );
        }
    }

    if (props.subscriptionAdded) {

        //
    }

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent='center'
            >
                <Grid item>
                    <Typography className={classes.content}>
                        Подписаться на новые объявления:
                    </Typography>
                </Grid>

                <Grid item>
                    <DialogAlert
                        buttonText='Добавить подписку'
                        buttonIcon={<NotificationAddIcon />}
                        onConfirm={addSubscription}
                        titleText={`Проверьте параметры подписки`}
                        descriptionText={props.searchType === 'bytype' ?
                            `Будет добавлена подписка на ${props.typeRu} для ТВ ${props.typeModel}. Всё верно?` :
                            `Будет добавлена подписка на ${props.pn} для ${props.pnModel === '' ? 'любой модели ТВ' : 'ТВ ' + props.pnModel}. Всё верно?`
                        }
                        cancelText='Отмена'
                        proceedText='Всё верно'
                    />
                </Grid>

                <Snackbar
                    open={open}
                    autoHideDuration={4000}
                    onClose={handleSnackBarClose}
                    message={"Подписка успешно добавлена. Все подписки в Личном кабинете, в разделе Подписки"}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                />
            </Grid>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        searchType: state.listingsSearch.lastSearchType,
        // bytype:
        type: state.listingsSearch.hwType,
        typeModel: state.listingsSearch.lastSearchedByTypeModel,
        // by pn:
        pn: state.listingsSearch.lastSearchPn,
        pnModel: state.listingsSearch.lastSearchedByPnModel,
        // result:
        subscriptionAdded: state.listingsSearch.subscriptionAdded,
        typeRu: state.listingsSearch.hwTypeRu,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        subscribe: (
            search_type,
            type_search_model,
            type_search_type,
            pn_search_model,
            pn_search_pn,
            lastId
        ) => {
            dispatch(addSubscription(
                search_type,
                type_search_model,
                type_search_type,
                pn_search_model,
                pn_search_pn,
                lastId
            ));
        },
        resetSnackbar: () => {
            dispatch(listSearchSetSubscriptionAddedAC(false));
        }
    }
}

const SubscriptionAddContainer = connect(mapStateToProps, mapDispatchToProps)(SubscriptionAdd);
export default SubscriptionAddContainer;