import fetch from 'cross-fetch';

const ADD_COMP_REQUEST_COMPOSITION = 'ADD_COMP_REQUEST_COMPOSITION';
const ADD_COMP_RECEIVE_COMPOSITION = 'ADD_COMP_RECEIVE_COMPOSITION';
const ADD_COMP_UPDATE_SEARCH_MODEL_TEXT = 'ADD_COMP_UPDATE_SEARCH_MODEL_TEXT';
const ADD_COMP_RECEIVE_SEND_RESULT = 'ADD_COMP_RECEIVE_SEND_RESULT';
const ADD_COMP_SENDING_STARTED = 'ADD_COMP_SENDING_STARTED';
const ADD_COMP_RESET_FORM_DATA = 'ADD_COMP_RESET_FORM_DATA';

// COMMON
const ADD_COMP_UPDATE_VENDOR = "ADD_COMP_UPDATE_VENDOR";
const ADD_COMP_UPDATE_MODEL = "ADD_COMP_UPDATE_MODEL";
const ADD_COMP_UPDATE_CHASIS = "ADD_COMP_UPDATE_CHASIS";

// SSB
const ADD_COMP_UPDATE_SSB_PN1 = "ADD_COMP_UPDATE_SSB_PN1";
const ADD_COMP_UPDATE_SSB_PN2 = "ADD_COMP_UPDATE_SSB_PN2";
const ADD_COMP_UPDATE_SSB_PN3 = "ADD_COMP_UPDATE_SSB_PN3";

//PSU
const ADD_COMP_UPDATE_PSU_PN1 = "ADD_COMP_UPDATE_PSU_PN1";
const ADD_COMP_UPDATE_PSU_PN2 = "ADD_COMP_UPDATE_PSU_PN2";
const ADD_COMP_UPDATE_PSU_PN3 = "ADD_COMP_UPDATE_PSU_PN3";

// TCN
const ADD_COMP_UPDATE_TCN_PN1 = "ADD_COMP_UPDATE_TCN_PN1";
const ADD_COMP_UPDATE_TCN_PN2 = "ADD_COMP_UPDATE_TCN_PN2";
const ADD_COMP_UPDATE_TCN_PN3 = "ADD_COMP_UPDATE_TCN_PN3";

// IR
const ADD_COMP_UPDATE_IR_PN1 = "ADD_COMP_UPDATE_IR_PN1";
const ADD_COMP_UPDATE_IR_PN2 = "ADD_COMP_UPDATE_IR_PN2";
const ADD_COMP_UPDATE_IR_PN3 = "ADD_COMP_UPDATE_IR_PN3";

// BTN
const ADD_COMP_UPDATE_BTN_PN1 = "ADD_COMP_UPDATE_BTN_PN1";
const ADD_COMP_UPDATE_BTN_PN2 = "ADD_COMP_UPDATE_BTN_PN2";
const ADD_COMP_UPDATE_BTN_PN3 = "ADD_COMP_UPDATE_BTN_PN3";

// WL
const ADD_COMP_UPDATE_WL_PN1 = "ADD_COMP_UPDATE_WL_PN1";
const ADD_COMP_UPDATE_WL_PN2 = "ADD_COMP_UPDATE_WL_PN2";
const ADD_COMP_UPDATE_WL_PN3 = "ADD_COMP_UPDATE_WL_PN3";

// BT
const ADD_COMP_UPDATE_BT_PN1 = "ADD_COMP_UPDATE_BT_PN1";
const ADD_COMP_UPDATE_BT_PN2 = "ADD_COMP_UPDATE_BT_PN2";
const ADD_COMP_UPDATE_BT_PN3 = "ADD_COMP_UPDATE_BT_PN3";

// DYN
const ADD_COMP_UPDATE_DYN_PN1 = "ADD_COMP_UPDATE_DYN_PN1";
const ADD_COMP_UPDATE_DYN_PN2 = "ADD_COMP_UPDATE_DYN_PN2";
const ADD_COMP_UPDATE_DYN_PN3 = "ADD_COMP_UPDATE_DYN_PN3";

// CAM
const ADD_COMP_UPDATE_CAM_PN1 = "ADD_COMP_UPDATE_CAM_PN1";
const ADD_COMP_UPDATE_CAM_PN2 = "ADD_COMP_UPDATE_CAM_PN2";
const ADD_COMP_UPDATE_CAM_PN3 = "ADD_COMP_UPDATE_CAM_PN3";

// BKL
const ADD_COMP_UPDATE_BKL_PN1 = "ADD_COMP_UPDATE_BKL_PN1";
const ADD_COMP_UPDATE_BKL_PN2 = "ADD_COMP_UPDATE_BKL_PN2";
const ADD_COMP_UPDATE_BKL_PN3 = "ADD_COMP_UPDATE_BKL_PN3";
const ADD_COMP_UPDATE_BKL_PN4 = "ADD_COMP_UPDATE_BKL_PN4";
const ADD_COMP_UPDATE_BKL_PN5 = "ADD_COMP_UPDATE_BKL_PN5";
const ADD_COMP_UPDATE_BKL_PN6 = "ADD_COMP_UPDATE_BKL_PN6";

// TN
const ADD_COMP_UPDATE_TN_PN1 = "ADD_COMP_UPDATE_TN_PN1";
const ADD_COMP_UPDATE_TN_PN2 = "ADD_COMP_UPDATE_TN_PN2";

// INV
const ADD_COMP_UPDATE_INV_PN1 = "ADD_COMP_UPDATE_INV_PN1";
const ADD_COMP_UPDATE_INV_PN2 = "ADD_COMP_UPDATE_INV_PN2";
const ADD_COMP_UPDATE_INV_PN3 = "ADD_COMP_UPDATE_INV_PN3";

// PNL
const ADD_COMP_UPDATE_PNL_PN1 = "ADD_COMP_UPDATE_PNL_PN1";
const ADD_COMP_UPDATE_PNL_PN2 = "ADD_COMP_UPDATE_PNL_PN2";

// LVDS
const ADD_COMP_UPDATE_LVDS_PN1 = "ADD_COMP_UPDATE_LVDS_PN1";
const ADD_COMP_UPDATE_LVDS_PN2 = "ADD_COMP_UPDATE_LVDS_PN2";

// MTX
const ADD_COMP_UPDATE_MTX_PN1 = "ADD_COMP_UPDATE_MTX_PN1";
const ADD_COMP_UPDATE_MTX_PN2 = "ADD_COMP_UPDATE_MTX_PN2";

// LD
const ADD_COMP_UPDATE_LD_PN1 = "ADD_COMP_UPDATE_LD_PN1";
const ADD_COMP_UPDATE_LD_PN2 = "ADD_COMP_UPDATE_LD_PN2";

const LOG_REG_LOGOUT = 'LOG_REG_LOGOUT';

let initialState = {
    searchModelText: '',
    searchedItem: '',
    tried: false,
    isFetching: false,
    isFinished: false,
    isSuccess: false,
    tv_composition: {
        empty: true,
        vendor: '',
        model: '',
        chasis: '',
        pseudonim: '',

        ssb: [ // alternatives
            { id: '', pns: [] }, // part-numbers of the current alt
        ],
        psu: [
            { id: '', pns: [] },
        ],
        tcn: [
            { id: '', pns: [] },
        ],
        bkl: [
            { id: '', pns: [] },
        ],
        pnl: [
            { id: '', pns: [] },
        ],
        ld: [
            { id: '', pns: [] },
        ],
        ir: [
            { id: '', pns: [] },
        ],
        btn: [
            { id: '', pns: [] },
        ],
        wlan: [
            { id: '', pns: [] },
        ],
        bt: [
            { id: '', pns: [] },
        ],
        dyn: [
            { id: '', pns: [] },
        ],
        tun: [
            { id: '', pns: [] },
        ],
        lvds: [
            { id: '', pns: [] },
        ],
        mtx: [
            { id: '', pns: [] },
        ],
        cam: [
            { id: '', pns: [] },
        ],
        inv: [
            { id: '', pns: [] },
        ],
    },

    vendor: '',
    model: '',
    chasis: '',
    vendorErr: true,
    modelErr: false,
    chasisErr: false,

    ssb_pn1: '',
    ssb_pn2: '',
    ssb_pn3: '',
    ssb_pn1Err: false,
    ssb_pn2Err: false,
    ssb_pn3Err: false,
    ssb_pn1ErrWhat: '',
    ssb_pn2ErrWhat: '',
    ssb_pn3ErrWhat: '',

    psu_pn1: '',
    psu_pn2: '',
    psu_pn3: '',
    psu_pn1Err: false,
    psu_pn2Err: false,
    psu_pn3Err: false,

    tcn_pn1: '',
    tcn_pn2: '',
    tcn_pn3: '',
    tcn_pn1Err: false,
    tcn_pn2Err: false,
    tcn_pn3Err: false,

    ir_pn1: '',
    ir_pn2: '',
    ir_pn3: '',
    ir_pn1Err: false,
    ir_pn2Err: false,
    ir_pn3Err: false,

    btn_pn1: '',
    btn_pn2: '',
    btn_pn3: '',
    btn_pn1Err: false,
    btn_pn2Err: false,
    btn_pn3Err: false,

    wl_pn1: '',
    wl_pn2: '',
    wl_pn3: '',
    wl_pn1Err: false,
    wl_pn2Err: false,
    wl_pn3Err: false,

    bt_pn1: '',
    bt_pn2: '',
    bt_pn3: '',
    bt_pn1Err: false,
    bt_pn2Err: false,
    bt_pn3Err: false,

    dyn_pn1: '',
    dyn_pn2: '',
    dyn_pn3: '',
    dyn_pn1Err: false,
    dyn_pn2Err: false,
    dyn_pn3Err: false,

    cam_pn1: '',
    cam_pn2: '',
    cam_pn3: '',
    cam_pn1Err: false,
    cam_pn2Err: false,
    cam_pn3Err: false,

    bkl_pn1: '',
    bkl_pn2: '',
    bkl_pn3: '',
    bkl_pn4: '',
    bkl_pn5: '',
    bkl_pn6: '',
    bkl_pn1Err: false,
    bkl_pn2Err: false,
    bkl_pn3Err: false,
    bkl_pn4Err: false,
    bkl_pn5Err: false,
    bkl_pn6Err: false,

    tn_pn1: '',
    tn_pn2: '',
    tn_pn1Err: false,
    tn_pn2Err: false,

    inv_pn1: '',
    inv_pn2: '',
    inv_pn3: '',
    inv_pn1Err: false,
    inv_pn2Err: false,
    inv_pn3Err: false,

    pnl_pn1: '',
    pnl_pn2: '',
    pnl_pn1Err: false,
    pnl_pn2Err: false,

    lvds_pn1: '',
    lvds_pn2: '',
    lvds_pn1Err: false,
    lvds_pn2Err: false,

    mtx_pn1: '',
    mtx_pn2: '',
    mtx_pn1Err: false,
    mtx_pn2Err: false,

    ld_pn1: '',
    ld_pn2: '',
    ld_pn1Err: false,
    ld_pn2Err: false,
};

let checkCyrillic = (text) => {
    let ru = /[а-яё]+/i.test(text);
    return ru;
}

const addCompositionReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_COMP_UPDATE_SEARCH_MODEL_TEXT:
            return Object.assign({}, state, {
                searchModelText: action.newText
            });
        case ADD_COMP_REQUEST_COMPOSITION:
            return Object.assign({}, state, {
                isFetching: true,
                searchedItem: state.searchModelText,
                searchModelText: '',
            });
        case ADD_COMP_RECEIVE_COMPOSITION:
            return Object.assign({}, state, {
                isFetching: false,
                isFinished: false,
                isSuccess: false,
                tried: true,
                tv_composition: action.composition,
                model: state.searchedItem,
                modelErr: checkCyrillic(state.searchedItem),
            });
        case ADD_COMP_RECEIVE_SEND_RESULT:
            return Object.assign({}, state, {
                isFetching: false,
                isFinished: true,
                isSuccess: action.success,
            });
        case ADD_COMP_SENDING_STARTED:
            return Object.assign({}, state, {
                isFetching: true,
            });


        case ADD_COMP_UPDATE_VENDOR: return Object.assign({}, state, {
            vendor: action.newText,
            vendorErr: action.newText === '',
        });
        case ADD_COMP_UPDATE_MODEL: return Object.assign({}, state, { model: action.newText });
        case ADD_COMP_UPDATE_CHASIS: return Object.assign({}, state, { chasis: action.newText });

        case ADD_COMP_UPDATE_SSB_PN1: return Object.assign({}, state, {
            ssb_pn1: action.newText,
            ssb_pn1Err: action.err.isErr,
            ssb_pn1ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_SSB_PN2: return Object.assign({}, state, {
            ssb_pn2: action.newText,
            ssb_pn2Err: action.err.isErr,
            ssb_pn2ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_SSB_PN3: return Object.assign({}, state, {
            ssb_pn3: action.newText,
            ssb_pn3Err: action.err.isErr,
            ssb_pn3ErrWhat: action.err.what
        });

        case ADD_COMP_UPDATE_PSU_PN1: return Object.assign({}, state, {
            psu_pn1: action.newText,
            psu_pn1Err: action.err.isErr,
            psu_pn1ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_PSU_PN2: return Object.assign({}, state, {
            psu_pn2: action.newText,
            psu_pn2Err: action.err.isErr,
            psu_pn2ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_PSU_PN3: return Object.assign({}, state, {
            psu_pn3: action.newText,
            psu_pn3Err: action.err.isErr,
            psu_pn3ErrWhat: action.err.what
        });

        case ADD_COMP_UPDATE_TCN_PN1: return Object.assign({}, state, {
            tcn_pn1: action.newText,
            tcn_pn1Err: action.err.isErr,
            tcn_pn1ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_TCN_PN2: return Object.assign({}, state, {
            tcn_pn2: action.newText,
            tcn_pn2Err: action.err.isErr,
            tcn_pn2ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_TCN_PN3: return Object.assign({}, state, {
            tcn_pn3: action.newText,
            tcn_pn3Err: action.err.isErr,
            tcn_pn3ErrWhat: action.err.what
        });

        case ADD_COMP_UPDATE_IR_PN1: return Object.assign({}, state, {
            ir_pn1: action.newText,
            ir_pn1Err: action.err.isErr,
            ir_pn1ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_IR_PN2: return Object.assign({}, state, {
            ir_pn2: action.newText,
            ir_pn2Err: action.err.isErr,
            ir_pn2ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_IR_PN3: return Object.assign({}, state, {
            ir_pn3: action.newText,
            ir_pn3Err: action.err.isErr,
            ir_pn3ErrWhat: action.err.what
        });

        case ADD_COMP_UPDATE_BTN_PN1: return Object.assign({}, state, {
            btn_pn1: action.newText,
            btn_pn1Err: action.err.isErr,
            btn_pn1ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_BTN_PN2: return Object.assign({}, state, {
            btn_pn2: action.newText,
            btn_pn2Err: action.err.isErr,
            btn_pn2ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_BTN_PN3: return Object.assign({}, state, {
            btn_pn3: action.newText,
            btn_pn3Err: action.err.isErr,
            btn_pn3ErrWhat: action.err.what
        });

        case ADD_COMP_UPDATE_WL_PN1: return Object.assign({}, state, {
            wl_pn1: action.newText,
            wl_pn1Err: action.err.isErr,
            wl_pn1ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_WL_PN2: return Object.assign({}, state, {
            wl_pn2: action.newText,
            wl_pn2Err: action.err.isErr,
            wl_pn2ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_WL_PN3: return Object.assign({}, state, {
            wl_pn3: action.newText,
            wl_pn3Err: action.err.isErr,
            wl_pn3ErrWhat: action.err.what
        });

        case ADD_COMP_UPDATE_BT_PN1: return Object.assign({}, state, {
            bt_pn1: action.newText,
            bt_pn1Err: action.err.isErr,
            bt_pn1ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_BT_PN2: return Object.assign({}, state, {
            bt_pn2: action.newText,
            bt_pn2Err: action.err.isErr,
            bt_pn2ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_BT_PN3: return Object.assign({}, state, {
            bt_pn3: action.newText,
            bt_pn3Err: action.err.isErr,
            bt_pn3ErrWhat: action.err.what
        });

        case ADD_COMP_UPDATE_DYN_PN1: return Object.assign({}, state, {
            dyn_pn1: action.newText,
            dyn_pn1Err: action.err.isErr,
            dyn_pn1ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_DYN_PN2: return Object.assign({}, state, {
            dyn_pn2: action.newText,
            dyn_pn2Err: action.err.isErr,
            dyn_pn2ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_DYN_PN3: return Object.assign({}, state, {
            dyn_pn3: action.newText,
            dyn_pn3Err: action.err.isErr,
            dyn_pn3ErrWhat: action.err.what
        });

        case ADD_COMP_UPDATE_CAM_PN1: return Object.assign({}, state, {
            cam_pn1: action.newText,
            cam_pn1Err: action.err.isErr,
            cam_pn1ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_CAM_PN2: return Object.assign({}, state, {
            cam_pn2: action.newText,
            cam_pn2Err: action.err.isErr,
            cam_pn2ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_CAM_PN3: return Object.assign({}, state, {
            cam_pn3: action.newText,
            cam_pn3Err: action.err.isErr,
            cam_pn3ErrWhat: action.err.what
        });

        case ADD_COMP_UPDATE_BKL_PN1: return Object.assign({}, state, {
            bkl_pn1: action.newText,
            bkl_pn1Err: action.err.isErr,
            bkl_pn1ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_BKL_PN2: return Object.assign({}, state, {
            bkl_pn2: action.newText,
            bkl_pn2Err: action.err.isErr,
            bkl_pn2ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_BKL_PN3: return Object.assign({}, state, {
            bkl_pn3: action.newText,
            bkl_pn3Err: action.err.isErr,
            bkl_pn3ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_BKL_PN4: return Object.assign({}, state, {
            bkl_pn4: action.newText,
            bkl_pn4Err: action.err.isErr,
            bkl_pn4ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_BKL_PN5: return Object.assign({}, state, {
            bkl_pn5: action.newText,
            bkl_pn5Err: action.err.isErr,
            bkl_pn5ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_BKL_PN6: return Object.assign({}, state, {
            bkl_pn6: action.newText,
            bkl_pn6Err: action.err.isErr,
            bkl_pn6ErrWhat: action.err.what
        });

        case ADD_COMP_UPDATE_TN_PN1: return Object.assign({}, state, {
            tn_pn1: action.newText,
            tn_pn1Err: action.err.isErr,
            tn_pn1ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_TN_PN2: return Object.assign({}, state, {
            tn_pn2: action.newText,
            tn_pn2Err: action.err.isErr,
            tn_pn2ErrWhat: action.err.what
        });

        case ADD_COMP_UPDATE_INV_PN1: return Object.assign({}, state, {
            inv_pn1: action.newText,
            inv_pn1Err: action.err.isErr,
            inv_pn1ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_INV_PN2: return Object.assign({}, state, {
            inv_pn2: action.newText,
            inv_pn2Err: action.err.isErr,
            inv_pn2ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_INV_PN3: return Object.assign({}, state, {
            inv_pn3: action.newText,
            inv_pn3Err: action.err.isErr,
            inv_pn3ErrWhat: action.err.what
        });

        case ADD_COMP_UPDATE_PNL_PN1: return Object.assign({}, state, {
            pnl_pn1: action.newText,
            pnl_pn1Err: action.err.isErr,
            pnl_pn1ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_PNL_PN2: return Object.assign({}, state, {
            pnl_pn2: action.newText,
            pnl_pn2Err: action.err.isErr,
            pnl_pn2ErrWhat: action.err.what
        });

        case ADD_COMP_UPDATE_LVDS_PN1: return Object.assign({}, state, {
            lvds_pn1: action.newText,
            lvds_pn1Err: action.err.isErr,
            lvds_pn1ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_LVDS_PN2: return Object.assign({}, state, {
            lvds_pn2: action.newText,
            lvds_pn2Err: action.err.isErr,
            lvds_pn2ErrWhat: action.err.what
        });

        case ADD_COMP_UPDATE_MTX_PN1: return Object.assign({}, state, {
            mtx_pn1: action.newText,
            mtx_pn1Err: action.err.isErr,
            mtx_pn1ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_MTX_PN2: return Object.assign({}, state, {
            mtx_pn2: action.newText,
            mtx_pn2Err: action.err.isErr,
            mtx_pn2ErrWhat: action.err.what
        });

        case ADD_COMP_UPDATE_LD_PN1: return Object.assign({}, state, {
            ld_pn1: action.newText,
            ld_pn1Err: action.err.isErr,
            ld_pn1ErrWhat: action.err.what
        });
        case ADD_COMP_UPDATE_LD_PN2: return Object.assign({}, state, {
            ld_pn2: action.newText,
            ld_pn2Err: action.err.isErr,
            ld_pn2ErrWhat: action.err.what
        });

        case ADD_COMP_RESET_FORM_DATA: return Object.assign({}, state, {
            vendor: '',
            model: '',
            chasis: '',
            vendorErr: true,
            modelErr: false,
            chasisErr: false,
            ssb_pn1: '',
            ssb_pn2: '',
            ssb_pn3: '',
            ssb_pn1Err: false,
            ssb_pn2Err: false,
            ssb_pn3Err: false,
            ssb_pn1ErrWhat: '',
            ssb_pn2ErrWhat: '',
            ssb_pn3ErrWhat: '',
            psu_pn1: '',
            psu_pn2: '',
            psu_pn3: '',
            psu_pn1Err: false,
            psu_pn2Err: false,
            psu_pn3Err: false,
            tcn_pn1: '',
            tcn_pn2: '',
            tcn_pn3: '',
            tcn_pn1Err: false,
            tcn_pn2Err: false,
            tcn_pn3Err: false,
            ir_pn1: '',
            ir_pn2: '',
            ir_pn3: '',
            ir_pn1Err: false,
            ir_pn2Err: false,
            ir_pn3Err: false,
            btn_pn1: '',
            btn_pn2: '',
            btn_pn3: '',
            btn_pn1Err: false,
            btn_pn2Err: false,
            btn_pn3Err: false,
            wl_pn1: '',
            wl_pn2: '',
            wl_pn3: '',
            wl_pn1Err: false,
            wl_pn2Err: false,
            wl_pn3Err: false,
            bt_pn1: '',
            bt_pn2: '',
            bt_pn3: '',
            bt_pn1Err: false,
            bt_pn2Err: false,
            bt_pn3Err: false,
            dyn_pn1: '',
            dyn_pn2: '',
            dyn_pn3: '',
            dyn_pn1Err: false,
            dyn_pn2Err: false,
            dyn_pn3Err: false,
            cam_pn1: '',
            cam_pn2: '',
            cam_pn3: '',
            cam_pn1Err: false,
            cam_pn2Err: false,
            cam_pn3Err: false,
            bkl_pn1: '',
            bkl_pn2: '',
            bkl_pn3: '',
            bkl_pn4: '',
            bkl_pn5: '',
            bkl_pn6: '',
            bkl_pn1Err: false,
            bkl_pn2Err: false,
            bkl_pn3Err: false,
            bkl_pn4Err: false,
            bkl_pn5Err: false,
            bkl_pn6Err: false,
            tn_pn1: '',
            tn_pn2: '',
            tn_pn1Err: false,
            tn_pn2Err: false,
            inv_pn1: '',
            inv_pn2: '',
            inv_pn3: '',
            inv_pn1Err: false,
            inv_pn2Err: false,
            inv_pn3Err: false,
            pnl_pn1: '',
            pnl_pn2: '',
            pnl_pn1Err: false,
            pnl_pn2Err: false,
            lvds_pn1: '',
            lvds_pn2: '',
            lvds_pn1Err: false,
            lvds_pn2Err: false,
            mtx_pn1: '',
            mtx_pn2: '',
            mtx_pn1Err: false,
            mtx_pn2Err: false,
            ld_pn1: '',
            ld_pn2: '',
            ld_pn1Err: false,
            ld_pn2Err: false,
        });

        case LOG_REG_LOGOUT:
            return Object.assign({}, state, initialState);

        default:
            return state;
    }
};

export const requestCompositionAC = () => ({ type: ADD_COMP_REQUEST_COMPOSITION })
export const sendingStartedAC = () => ({ type: ADD_COMP_SENDING_STARTED })
export const resetFormDataAC = () => ({ type: ADD_COMP_RESET_FORM_DATA })

export function fetchComposition(item) {
    let what = item.split("/").join("____");
    return function (dispatch) {
        dispatch(requestCompositionAC());
        dispatch(resetFormDataAC());
        return fetch(process.env.REACT_APP_ORIGIN + `/api/tech/tv/composition/${what}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                json => dispatch(addCompReceiveCompositionAC(json))
            )
    }
}

export function sendComposition(state) {
    return function (dispatch) {
        dispatch(sendingStartedAC());
        const reqOptions = {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({
                "vendor": state.vendor,
                "model": state.model,
                "chasis": state.chasis,
                "ssb_pn1": state.ssb_pn1,
                "ssb_pn2": state.ssb_pn2,
                "ssb_pn3": state.ssb_pn3,
                "psu_pn1": state.psu_pn1,
                "psu_pn2": state.psu_pn2,
                "psu_pn3": state.psu_pn3,
                "tcn_pn1": state.tcn_pn1,
                "tcn_pn2": state.tcn_pn2,
                "tcn_pn3": state.tcn_pn3,
                "ir_pn1": state.ir_pn1,
                "ir_pn2": state.ir_pn2,
                "ir_pn3": state.ir_pn3,
                "btn_pn1": state.btn_pn1,
                "btn_pn2": state.btn_pn2,
                "btn_pn3": state.btn_pn3,
                "wl_pn1": state.wl_pn1,
                "wl_pn2": state.wl_pn2,
                "wl_pn3": state.wl_pn3,
                "bt_pn1": state.bt_pn1,
                "bt_pn2": state.bt_pn2,
                "bt_pn3": state.bt_pn3,
                "dyn_pn1": state.dyn_pn1,
                "dyn_pn2": state.dyn_pn2,
                "dyn_pn3": state.dyn_pn3,
                "cam_pn1": state.cam_pn1,
                "cam_pn2": state.cam_pn2,
                "cam_pn3": state.cam_pn3,
                "bkl_pn1": state.bkl_pn1,
                "bkl_pn2": state.bkl_pn2,
                "bkl_pn3": state.bkl_pn3,
                "bkl_pn4": state.bkl_pn4,
                "bkl_pn5": state.bkl_pn5,
                "bkl_pn6": state.bkl_pn6,
                "tn_pn1": state.tn_pn1,
                "tn_pn2": state.tn_pn2,
                "inv_pn1": state.inv_pn1,
                "inv_pn2": state.inv_pn2,
                "inv_pn3": state.inv_pn3,
                "pnl_pn1": state.pnl_pn1,
                "pnl_pn2": state.pnl_pn2,
                "lvds_pn1": state.lvds_pn1,
                "lvds_pn2": state.lvds_pn2,
                "mtx_pn1": state.mtx_pn1,
                "mtx_pn2": state.mtx_pn2,
                "ld_pn1": state.ld_pn1,
                "ld_pn2": state.ld_pn2
            })
        };

        return fetch(process.env.REACT_APP_ORIGIN + '/api/tech/tv/composition/tv/add', reqOptions)
            .then(
                response => response.json()
            )
            .then(
                json => dispatch(receiveSendResultAC(json))
            )
    }
}

export const addCompReceiveCompositionAC = (json) => ({
    type: ADD_COMP_RECEIVE_COMPOSITION,
    composition: json.data.tv_composition
})

export const receiveSendResultAC = (json) => ({
    type: ADD_COMP_RECEIVE_SEND_RESULT,
    success: json.statusCode === 200,
})

export const updateSearchModelTextActionCreator = (text) =>
    ({ type: ADD_COMP_UPDATE_SEARCH_MODEL_TEXT, newText: text })


export const updateVendorAC = (text) => ({ type: ADD_COMP_UPDATE_VENDOR, newText: text })
export const updateModelAC = (text) => ({ type: ADD_COMP_UPDATE_MODEL, newText: text })
export const updateChasisAC = (text) => ({ type: ADD_COMP_UPDATE_CHASIS, newText: text })

export const updateSsbPn1AC = (text, err) => ({ type: ADD_COMP_UPDATE_SSB_PN1, newText: text, err: err })
export const updateSsbPn2AC = (text, err) => ({ type: ADD_COMP_UPDATE_SSB_PN2, newText: text, err: err })
export const updateSsbPn3AC = (text, err) => ({ type: ADD_COMP_UPDATE_SSB_PN3, newText: text, err: err })

export const updatePsuPn1AC = (text, err) => ({ type: ADD_COMP_UPDATE_PSU_PN1, newText: text, err: err })
export const updatePsuPn2AC = (text, err) => ({ type: ADD_COMP_UPDATE_PSU_PN2, newText: text, err: err })
export const updatePsuPn3AC = (text, err) => ({ type: ADD_COMP_UPDATE_PSU_PN3, newText: text, err: err })

export const updateTcnPn1AC = (text, err) => ({ type: ADD_COMP_UPDATE_TCN_PN1, newText: text, err: err })
export const updateTcnPn2AC = (text, err) => ({ type: ADD_COMP_UPDATE_TCN_PN2, newText: text, err: err })
export const updateTcnPn3AC = (text, err) => ({ type: ADD_COMP_UPDATE_TCN_PN3, newText: text, err: err })

export const updateIrPn1AC = (text, err) => ({ type: ADD_COMP_UPDATE_IR_PN1, newText: text, err: err })
export const updateIrPn2AC = (text, err) => ({ type: ADD_COMP_UPDATE_IR_PN2, newText: text, err: err })
export const updateIrPn3AC = (text, err) => ({ type: ADD_COMP_UPDATE_IR_PN3, newText: text, err: err })

export const updateBtnPn1AC = (text, err) => ({ type: ADD_COMP_UPDATE_BTN_PN1, newText: text, err: err })
export const updateBtnPn2AC = (text, err) => ({ type: ADD_COMP_UPDATE_BTN_PN2, newText: text, err: err })
export const updateBtnPn3AC = (text, err) => ({ type: ADD_COMP_UPDATE_BTN_PN3, newText: text, err: err })

export const updateWlPn1AC = (text, err) => ({ type: ADD_COMP_UPDATE_WL_PN1, newText: text, err: err })
export const updateWlPn2AC = (text, err) => ({ type: ADD_COMP_UPDATE_WL_PN2, newText: text, err: err })
export const updateWlPn3AC = (text, err) => ({ type: ADD_COMP_UPDATE_WL_PN3, newText: text, err: err })

export const updateBtPn1AC = (text, err) => ({ type: ADD_COMP_UPDATE_BT_PN1, newText: text, err: err })
export const updateBtPn2AC = (text, err) => ({ type: ADD_COMP_UPDATE_BT_PN2, newText: text, err: err })
export const updateBtPn3AC = (text, err) => ({ type: ADD_COMP_UPDATE_BT_PN3, newText: text, err: err })

export const updateDynPn1AC = (text, err) => ({ type: ADD_COMP_UPDATE_DYN_PN1, newText: text, err: err })
export const updateDynPn2AC = (text, err) => ({ type: ADD_COMP_UPDATE_DYN_PN2, newText: text, err: err })
export const updateDynPn3AC = (text, err) => ({ type: ADD_COMP_UPDATE_DYN_PN3, newText: text, err: err })

export const updateCamPn1AC = (text, err) => ({ type: ADD_COMP_UPDATE_CAM_PN1, newText: text, err: err })
export const updateCamPn2AC = (text, err) => ({ type: ADD_COMP_UPDATE_CAM_PN2, newText: text, err: err })
export const updateCamPn3AC = (text, err) => ({ type: ADD_COMP_UPDATE_CAM_PN3, newText: text, err: err })

export const updateBklPn1AC = (text, err) => ({ type: ADD_COMP_UPDATE_BKL_PN1, newText: text, err: err })
export const updateBklPn2AC = (text, err) => ({ type: ADD_COMP_UPDATE_BKL_PN2, newText: text, err: err })
export const updateBklPn3AC = (text, err) => ({ type: ADD_COMP_UPDATE_BKL_PN3, newText: text, err: err })
export const updateBklPn4AC = (text, err) => ({ type: ADD_COMP_UPDATE_BKL_PN4, newText: text, err: err })
export const updateBklPn5AC = (text, err) => ({ type: ADD_COMP_UPDATE_BKL_PN5, newText: text, err: err })
export const updateBklPn6AC = (text, err) => ({ type: ADD_COMP_UPDATE_BKL_PN6, newText: text, err: err })

export const updateTnPn1AC = (text, err) => ({ type: ADD_COMP_UPDATE_TN_PN1, newText: text, err: err })
export const updateTnPn2AC = (text, err) => ({ type: ADD_COMP_UPDATE_TN_PN2, newText: text, err: err })

export const updateInvPn1AC = (text, err) => ({ type: ADD_COMP_UPDATE_INV_PN1, newText: text, err: err })
export const updateInvPn2AC = (text, err) => ({ type: ADD_COMP_UPDATE_INV_PN2, newText: text, err: err })
export const updateInvPn3AC = (text, err) => ({ type: ADD_COMP_UPDATE_INV_PN3, newText: text, err: err })

export const updatePnlPn1AC = (text, err) => ({ type: ADD_COMP_UPDATE_PNL_PN1, newText: text, err: err })
export const updatePnlPn2AC = (text, err) => ({ type: ADD_COMP_UPDATE_PNL_PN2, newText: text, err: err })

export const updateLvdsPn1AC = (text, err) => ({ type: ADD_COMP_UPDATE_LVDS_PN1, newText: text, err: err })
export const updateLvdsPn2AC = (text, err) => ({ type: ADD_COMP_UPDATE_LVDS_PN2, newText: text, err: err })

export const updateMtxPn1AC = (text, err) => ({ type: ADD_COMP_UPDATE_MTX_PN1, newText: text, err: err })
export const updateMtxPn2AC = (text, err) => ({ type: ADD_COMP_UPDATE_MTX_PN2, newText: text, err: err })

export const updateLdPn1AC = (text, err) => ({ type: ADD_COMP_UPDATE_LD_PN1, newText: text, err: err })
export const updateLdPn2AC = (text, err) => ({ type: ADD_COMP_UPDATE_LD_PN2, newText: text, err: err })

export default addCompositionReducer;

