import React from 'react'
import { connect } from "react-redux";
import { setShowContactsAC } from '../../redux/listing-reducer';
import DescriptionCard from '../../components/listings/DescriptionCard';

const Description = (props) => {

    return (
        <>
            <DescriptionCard
                header='Описание'
                content={props.desc}
            />

            <DescriptionCard
                header='Варианты оплаты'
                content={props.payOptions}
            />

            <DescriptionCard
                header='Самовывоз'
                content={props.pickupOptions}
            />

            <DescriptionCard
                header='Доставка'
                content={props.deliveryOptions}
            />

            <DescriptionCard
                header='Гарантия и условия возврата'
                content={props.warrantyOptions}
            />
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    if (ownProps.preview) {
        return {
            desc: (ownProps.multi ?
                state.addPack.listingsData[ownProps.index].description :
                state.addSingle.description)
                + '\n' + state.settings.descriptionAddon,
            payOptions: state.settings.paymentOptions,
            pickupOptions: state.settings.pickupAdress,
            deliveryOptions: state.settings.deliveryOptions,
            warrantyOptions: state.settings.warrantyOptions,
        }
    }
    return {
        desc: state.listingPage.desc,
        payOptions: state.listingPage.payOptions,
        pickupOptions: state.listingPage.pickupAdress,
        deliveryOptions: state.listingPage.deliveryOptions,
        warrantyOptions: state.listingPage.warrantyOptions,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setContactsVisible: (visible) => {
            dispatch(setShowContactsAC(visible));
        },
    }
}



const DescriptionContainer = connect(mapStateToProps, mapDispatchToProps)(Description);
export default DescriptionContainer;