import React from 'react'
import ProfileHeaderContainer from '../../components/ProfileHeader';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import SellIcon from '@mui/icons-material/Sell';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import ModelConfirmContainer from '../../components/new-listing-pack/ModelConfirm';
import { connect } from "react-redux";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import StyledButton from '../../components/entities/StyledButton';
import { packDropModelAC, dropHwTypesAC, packResetAC, dropPnsAC, dropPhotosAC, dropBaseInfoAC } from '../../redux/add-multi-listings-reducer';
import { Stack } from '@mui/material';
import HwTypesPickerContainer from '../../components/new-listing-pack/HwTypesPicker';
import PnsSelectorContainer from '../../components/new-listing-pack/PnSelector';
import BaseInfoQueueContainer from '../../components/new-listing-pack/BaseInfoQueue';
import PhotosQueueContainer from '../../components/new-listing-pack/PhotosQueue';
import PreviewQueueContainer from '../../components/new-listing-pack/PreviewQueue';
import Unauthorized from '../../components/entities/Unauthed';
import { showLoginDialogAC } from "../../redux/login-reg-reducer";
import { Box } from '@material-ui/core';
import RequiredActionsContainer from '../personal/RequiredActions';

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
        marginTop: 10,
        marginBottom: 10,
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
}));

const AddDisassembly = (props) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);

    const totalSteps = 6;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {

        switch (activeStep) {
            case 1:
                props.dropStep1Confirm();
                break;
            case 2:
                props.dropStep2Confirm();
                break;
            case 3:
                props.dropStep3Confirm();
                break;
            case 4:
                props.dropStep4Confirm();
                break;
            case 5:
                props.dropStep5Confirm();
                break;
            default: break;
        }

        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        props.reset();
    };


    const renderStep1 = () => {
        if (!(props.modelKnown && props.modelConfirmed)) {
            return <ModelConfirmContainer />
        }
        else {
            if (activeStep === 0) {
                handleNext(activeStep);
            }
        }
    }

    const renderStep2 = () => {
        if (!(props.hwTypesConfirmed)) {
            return <HwTypesPickerContainer />
        }
        else {
            if (activeStep === 1) {
                handleNext(activeStep);
            }
        }
    }

    const renderStep3 = () => {
        if (!props.partnumbersConfirmed) {
            return <PnsSelectorContainer />
        }
        else {
            if (activeStep === 2) {
                handleNext(activeStep);
            }
        }
    }

    const renderStep4 = () => {
        if (!props.baseInfoConfirmed) {
            return <BaseInfoQueueContainer />
        }
        else {
            if (activeStep === 3) {
                handleNext(activeStep);
            }
        }
    }

    const renderStep5 = () => {
        if (!props.photosConfirmed) {
            return <PhotosQueueContainer />
        }
        else {
            if (activeStep === 4) {
                handleNext(activeStep);
            }
        }
    }

    const renderStep6 = () => {
        if (!props.previewConfirmed) {
            return <PreviewQueueContainer />
        }
        else {
            if (activeStep === 5) {
                handleNext(activeStep);
            }
        }
    }

    const styleOverride = {
        '& .MuiStepIcon-root.Mui-active': {
            color: '#262831',
        },
        '& .MuiStepIcon-root.Mui-completed': {
            color: '#6dc353',
        },
        '& .MuiStepLabel-label': {
            fontFamily: 'Jura',
            fontWeight: 600,
        },
    }

    if (!props.isLoggedIn) {
        props.showLoginDialog();
        return (
            <>
                <Unauthorized authCb={props.showLoginDialog} />
            </>
        )
    }

    const profileIncomplete = () => {
        return (props.city === '' || props.region === '' ||
            props.phone === '' ||
            props.pickupAdress === '' || props.paymentOptions === '' ||
            props.deliveryOptions === '' || props.warrantyOptions === '' ||
            props.descriptionAddon === '');
    }

    const optionalRenderProfileIncompleteWarn = () => {
        if (profileIncomplete()) {
            return (
                <>
                    <p>Настройки Вашего профиля не заполнены, что будет негативно влиять на внешний вид Ваших объявлений</p>
                    <p>Пожалуйста, исправьте проблемы ниже перед созданием объявлений:</p>
                    <RequiredActionsContainer listingsAddingMode />
                    <p>Для создания объявлений достаточно чтобы были заполнены:</p>
                    <p>
                        - Информация о населенном пункте<br />
                        - Контактный телефон для связи<br />
                        - Инструкции по самовывозу для покупателей из Вашего города<br />
                        - Все пункты в разделе "Шаблоны в объявлениях"<br />
                    </p>
                </>
            )
        }
    }

    const renderListingAddForm = () => {
        if (profileIncomplete()) {
            return null;
        }
        else {
            return (
                <>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        <Step key='Производитель и модель ТВ' >
                            <StepLabel sx={styleOverride}>
                                Производитель и модель ТВ
                            </StepLabel>
                            <StepContent>
                                {renderStep1()}
                            </StepContent>
                        </Step>

                        <Step key='Список запчастей'>
                            <StepLabel sx={styleOverride}>
                                Список запчастей
                            </StepLabel>
                            <StepContent>
                                {renderStep2()}
                            </StepContent>
                        </Step>

                        <Step key='Партнамберы запчастей'>
                            <StepLabel sx={styleOverride}>
                                Партнамберы запчастей
                            </StepLabel>
                            <StepContent>
                                {renderStep3()}
                            </StepContent>
                        </Step>

                        <Step key='Цена, описание и фото'>
                            <StepLabel sx={styleOverride}>
                                Цена и описание
                            </StepLabel>
                            <StepContent>
                                {renderStep4()}
                            </StepContent>
                        </Step>

                        <Step key='Фотографии'>
                            <StepLabel sx={styleOverride}>
                                Фотографии
                            </StepLabel>
                            <StepContent>
                                {renderStep5()}
                            </StepContent>
                        </Step>

                        <Step key='Предпросмотр'>
                            <StepLabel sx={styleOverride}>
                                Предпросмотр
                            </StepLabel>
                            <StepContent>
                                {renderStep6()}
                            </StepContent>
                        </Step>

                    </Stepper >
                    {activeStep < totalSteps && activeStep !== 0 && (
                        <Box maxWidth={200}>
                            <StyledButton
                                label="К предыдущему"
                                onClick={handleBack}
                            />
                        </Box>
                    )}

                    {activeStep === totalSteps && (
                        <>
                            <h3>Объявления созданы, отличная работа!</h3>

                            <Stack direction="column" alignItems="center" >
                                <p>Найти его можно в разделе "Мои объявления" личного кабинета</p>

                                <StyledButton
                                    label="Добавить ещё"
                                    onClick={handleReset}
                                />
                            </Stack>
                        </>
                    )}
                </>
            )
        }
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="textPrimary" href="/" className={classes.link}>
                    <HomeIcon className={classes.icon} />
                    naTeXtolite
                </Link>
                <Link color="textPrimary" href="/personal" className={classes.link}>
                    <AccountBoxIcon className={classes.icon} />
                    Личный кабинет
                </Link>
                <Link color="textPrimary" href="/personal/mylistings" className={classes.link}>
                    <SellIcon className={classes.icon} />
                    Мои объявления
                </Link>
                <Typography color="textPrimary" className={classes.link}>
                    <QueuePlayNextIcon className={classes.icon} />
                    Генерация объявлений по модели ТВ
                </Typography>
            </Breadcrumbs>

            <h1>Генерация объявлений по модели ТВ</h1>
            <ProfileHeaderContainer />

            {optionalRenderProfileIncompleteWarn()}
            {renderListingAddForm()}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        modelConfirmed: state.addPack.modelConfirmed,
        modelKnown: !state.addPack.modelUnknown,
        hwTypesConfirmed: state.addPack.hwTypesConfirmed,
        partnumbersConfirmed: state.addPack.allPnsConfirmed,
        baseInfoConfirmed: state.addPack.wholeBaseInfoConfirmed,
        photosConfirmed: state.addPack.allPhotosConfirmed,
        previewConfirmed: state.addPack.allListingsPosted,
        isLoggedIn: state.loginReg.isLoggedIn,

        city: state.userState.city,
        region: state.userState.region,
        phone: state.userState.phone,
        tg: state.settings.tgUserName,
        useWa: state.settings.useWa,
        waPhone: state.settings.waPhoneNum,
        storeDesc: state.settings.storeDescription,
        paymentOptions: state.settings.paymentOptions,
        pickupAdress: state.settings.pickupAdress,
        deliveryOptions: state.settings.deliveryOptions,
        warrantyOptions: state.settings.warrantyOptions,
        descriptionAddon: state.settings.descriptionAddon,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dropStep1Confirm: () => { dispatch(packDropModelAC()) },
        dropStep2Confirm: () => { dispatch(dropHwTypesAC()) },
        dropStep3Confirm: () => { dispatch(dropPnsAC()) },
        dropStep4Confirm: () => { dispatch(dropBaseInfoAC()) },
        dropStep5Confirm: () => { dispatch(dropPhotosAC()) },
        reset: () => { dispatch(packResetAC()) },
        showLoginDialog: () => {
            dispatch(showLoginDialogAC());
        },
    }
}

const AddDisassemblyContainer = connect(mapStateToProps, mapDispatchToProps)(AddDisassembly);
export default AddDisassemblyContainer;