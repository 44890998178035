import React from 'react'
import { connect } from "react-redux";
import { showLoginDialogAC } from "../redux/login-reg-reducer"
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LoginDialogContainer from "./login/Login";
import HeaderMenuContainer from './HeaderMenu';

const useStyles = makeStyles((theme) => ({
    iconButton: {
        color: '#6dc353',
        padding: '3px',
    },
    login: {
        fontSize: 17,
        fontWeight: 500,
        color: '#6dc353',
    },
    drawer: {
        backgroundColor: '#202938',
        color: '#6dc353',
        fontFamily: 'Jura, Arial',
    }
}));

const HeaderToolbar = (props) => {
    const classes = useStyles();

    const renderToolbar = () => {
        if (props.isLoggedIn) {
            return (
                <>
                    <Toolbar>
                        <Tooltip title="Это Вы" placement="bottom">
                            <Typography className={classes.login}> {props.login} </Typography>
                        </Tooltip>

                        <HeaderMenuContainer />
                    </Toolbar>
                </>
            )
        }
        else {
            return (
                <>
                    <LoginDialogContainer />
                    <Toolbar>
                        <Tooltip title="Вход/Регистрация" placement="bottom">
                            <Link to="#">
                                <AccountBoxIcon
                                    className={classes.iconButton}
                                    sx={{ width: 32, height: 32 }}
                                    onClick={props.showLoginDialog}
                                />
                            </Link>
                        </Tooltip>
                    </Toolbar>
                </>
            )
        }
    }

    return (
        <>
            {renderToolbar()}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.loginReg.isLoggedIn,
        login: state.userState.login,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showLoginDialog: () => {
            dispatch(showLoginDialogAC());
        },
    }
}

const HeaderToolbarContainer = connect(mapStateToProps, mapDispatchToProps)(HeaderToolbar);
export default HeaderToolbarContainer;