import React from 'react'
import { AddressSuggestions } from 'react-dadata';
import '../../style/dadata.css';
import { useState } from 'react';
import { Grid } from '@material-ui/core';
import { connect } from "react-redux";
import { genUpdateGeoDataAC } from "../../redux/user-reducer.js";
import StyledButton from "../../components/entities/StyledButton"

const CitySelector = (props) => {
    const [value] = useState(props.city);

    const chooseCity = (result) => {
        props.updateGeo((result.data.city ? result.data.city : result.data.settlement),
            (result.data.city_type_full ? result.data.city_type_full : result.data.settlement_type_full),
            (result.data.region + ' ' + result.data.region_type_full),
            result.data.geo_lat ? result.data.geo_lat : "",
            result.data.geo_lon ? result.data.geo_lon : "");
    }

    async function postGeoData(geo) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/users/setgeodata', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(geo)
        })
            .then(data => data.json())
    }

    const sendGeo = async () => {
        console.log("Sending geo")
        await postGeoData({
            city: props.city,
            city_type: props.cityType,
            region: props.region,
            lat: props.lat,
            lon: props.lon,
        });
    }

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={6}>
                    <AddressSuggestions
                        token="8ce927cc5cfe26059f5e382529e15269275a7f21"
                        value={value}
                        defaultQuery={props.city}
                        onChange={chooseCity}
                        delay={500}
                        minChars={3}
                        filterFromBound="city"
                        filterToBound="settlement"
                    />
                </Grid>
                <Grid item>
                    <StyledButton
                        label={"Сохранить"}
                        type="submit"
                        onClick={sendGeo}
                    />
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        city: state.userState.city,
        cityType: state.userState.cityType,
        region: state.userState.region,
        lat: state.userState.geoLat,
        lon: state.userState.geoLon,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateGeo: (city, city_type, region, lat, lon) => {
            dispatch(genUpdateGeoDataAC(city, city_type, region, lat, lon));
        },
    }
}

const CitySelectorContainer = connect(mapStateToProps, mapDispatchToProps)(CitySelector);
export default CitySelectorContainer;