import React from 'react'
import { connect } from "react-redux";
import FeedbackRow from '../../components/entities/FeedbackRow';
import Paginated from '../entities/Paginated';
import Spinner from '../Spinner';
import { Container, Stack } from '@mui/material';
import { Grid, Typography } from '@material-ui/core';
import Box from '@mui/material/Box';
import StyledSelect from '../../components/entities/StyledSelect';
import { makeStyles } from '@material-ui/core/styles';
import { fbFilterFromChangedAC, fbFilterTypeChangedAC } from '../../redux/my-feedback-reducer';

const useStyles = makeStyles({
    text: {
        color: 'black',
        fontWeight: 600,
        padding: '5px',
        paddingBottom: '13px'
    },
});

const typeFilter = [
    { text: 'Любые', filter: 'any' },
    { text: 'Положительные', filter: 'positive' },
    { text: 'Отрицательные', filter: 'negative' },
    { text: 'Нейтральные', filter: 'neutral' },
];

const fromFilter = [
    { text: 'От кого угодно', filter: 'any' },
    { text: 'От покупателей', filter: 'buyer' },
    { text: 'От продавцов', filter: 'seller' },
];

const MyFeedback = (props) => {
    const classes = useStyles();
    const renderFeedbackRow = (index, value) => {
        return <FeedbackRow
            fromUser={value.fromUser}
            variant={value.type}
            feedback={value.feedback}
            date={value.date}
            fromRole={value.fromRole}
            listingSummary={value.summary}
            listingId={value.listing_id}
        />
    }

    if (props.isFetching) {
        return <Spinner />
    }

    if (props.feedbackTotal === 0) {
        return (
            <>
                <h2>¯\_(ツ)_/¯</h2>
                <h2>У Вас пока нет отзывов</h2>
            </>
        )
    }

    const renderFilteredResults = () => {
        if (props.filteredTotal > 0) {
            return <Paginated
                pagesize={props.pageSize}
                renderItem={renderFeedbackRow}
                items={props.feedbackArr}
            />;

        }
        else {
            return (
                <>
                    <h2>¯\_(ツ)_/¯</h2>
                    <h2>Нет отзывов удовлетворяющим критериям фильтра</h2>
                </>
            )
        }
    }

    return (
        <>
            <Box sx={{
                marginBottom: '10px',
                bgcolor: '#cccccc',
                border: '1px solid black',
                borderRadius: '4px',
                bgcolor: '#f3f3f3',
                paddingTop: '8px',
                paddingBottom: '5px',
            }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    alignContent='center'
                >

                    <Grid item xs={12} sm={'auto'}>
                        <Typography className={classes.text}>Фильтры: </Typography>
                    </Grid>

                    <Grid item xs={12} sm={'auto'}>
                        <Container sx={{ minWidth: 230, marginBottom: '5px' }}>
                            <StyledSelect
                                label={'Отзывы от...'}
                                items={fromFilter.map(a => a.text)}
                                cb={props.changeFilterFrom}
                            />
                        </Container>
                    </Grid>

                    <Grid item xs={12} sm={'auto'}>
                        <Container sx={{ minWidth: 230, marginBottom: '5px' }}>
                            <StyledSelect
                                label={'Какие'}
                                items={typeFilter.map(a => a.text)}
                                cb={props.changeFilterType}
                            />
                        </Container>
                    </Grid>
                </Grid>
            </Box>

            {renderFilteredResults()}
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching: state.myFeedbackPage.isFetching,
        feedbackTotal: state.myFeedbackPage.feedbackTotal,
        filteredTotal: state.myFeedbackPage.filteredFeedback.length,
        feedbackArr: state.myFeedbackPage.filteredFeedback,
        pageSize: ownProps.pageSize ? ownProps.pageSize : 10,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeFilterFrom: (type, index) => {
            dispatch(fbFilterFromChangedAC(fromFilter[index].filter));
        },
        changeFilterType: (type, index) => {
            dispatch(fbFilterTypeChangedAC(typeFilter[index].filter));
        },
    }
}

const MyFeedbackContainer = connect(mapStateToProps, mapDispatchToProps)(MyFeedback);
export default MyFeedbackContainer;