import React from 'react'
import { connect } from "react-redux";
import CompositionTree from "./CompositionTree";
import Spinner from "./Spinner";
import {
    fetchSecondaryUsages,
} from "../redux/composition-reducer";
import DynamicUsagesLinksCardContainer from "./DynamicUsagesLinksCard";
import StaticUsagesLinksCard from "./StaticUsagesLinksCard";
import MetaTags from "react-meta-tags";
import logo from "./logo.svg";
import { Divider, Grid, Link } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    authorNotice: {
        fontSize: 15,
        fontWeight: 600,
        color: 'black',
        marginBottom: 4,
        paddingLeft: 5,
    },
    authorLink: {
        fontSize: 16,
        fontWeight: 500,
        color: 'black',
        marginBottom: 4,
        paddingLeft: 5,
    },
});

const CompositionResult = (props) => {
    const classes = useStyles();

    const renderResults = () => {
        if (props.isFetching) {
            return (
                <Spinner />
            )
        }

        if (!props.triedToFetch) {
            return null;
        }

        if (props.composition.empty) {
            return (
                <>
                    <h2>Хм.. Странно, но мы пока не слышали про {props.searchedItem} </h2>
                    <h3> ¯\_(ツ)_/¯ </h3>
                </>
            )
        } else {
            return (
                <>
                    <MetaTags>
                        <title>{`Состав ТВ ${props.composition.vendor} ${props.composition.model.split("____").join("/")} (${props.composition.pseudonim})`}</title>
                        <meta name="description" content={`Состав ТВ ${props.composition.vendor} ${props.composition.model.split("____").join("/")} (${props.composition.pseudonim}) - наТекстолите`} />
                        <meta name="keywords" content={`Состав,${props.composition.model.split("____").join("/")},${props.composition.pseudonim.split("____").join("/")}`} />
                        <meta property="og:title" content="naTeXtolite" />
                        <meta property="og:type" content="website" />
                        <meta property="og:url" content={`${window.location.href}`} />
                        <meta property="og:image" content={logo} />
                        <meta property="og:description" content={`Состав ТВ ${props.composition.vendor} ${props.composition.model.split("____").join("/")} (${props.composition.pseudonim}) - наТекстолите`} />
                    </MetaTags>

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                    >
                        <Grid item xs={6}>
                            <Typography className={classes.authorNotice}>
                                Состав добавлен пользователем:
                                <Link href={`/profile/${props.composition.added_by}`} className={classes.authorLink}>
                                    {props.composition.added_by}
                                </Link>
                            </Typography>

                            <Divider />

                            <CompositionTree composition={props.composition} fetchUsagesCb={(arr) => {
                                props.fetchUsages(arr)
                            }} />
                        </Grid>

                        <Grid item xs={6}>
                            <DynamicUsagesLinksCardContainer header="Карта использования" />
                            <StaticUsagesLinksCard composition={props.composition} />
                        </Grid>
                    </Grid >
                </>
            )
        }
    }

    return (
        <>
            {renderResults()}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        searchedItem: state.compositionPage.searchedItem.replace("____", "/"),
        composition: state.compositionPage.tv_composition,
        isFetching: state.compositionPage.isFetching,
        isSecondaryUsagesFetching: state.compositionPage.isSecondaryUsagesFetching,
        secondaryUsages: state.compositionPage.secondaryUsages,
        triedToFetch: state.compositionPage.triedToFetch,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUsages: (arr) => {
            dispatch(fetchSecondaryUsages(arr));
        },
    }
}

const CompositionResultContainer = connect(mapStateToProps, mapDispatchToProps)(CompositionResult);
export default CompositionResultContainer;