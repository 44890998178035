import React from "react";
import { connect } from "react-redux";
import { AddListingPackModelAutocompleteContainer } from "../entities/ModelAutocomplete";
import { packResetAC } from "../../redux/add-multi-listings-reducer";
import StyledButton from "../entities/StyledButton";
import { Container, Grid } from '@material-ui/core';
import HelperCard from "../settings/HelperCard";
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import history from "../../history/history";
import Spinner from "../Spinner";
import RefreshIcon from '@mui/icons-material/Refresh';

const ModelConfirm = (props) => {
    const forwardToCompositionAdd = () => {
        props.reset();
        history.push('/services/composition/tv/add')
    }

    const render = () => {
        if (props.isFetching) {
            return <Spinner />
        }

        if (props.modelConfirmed && props.modelUnknown) {
            return (
                <>
                    <HelperCard
                        summary='Внимание!'
                        helperText='Указанная модель ТВ неизвестна. Необходимо добавить состав ТВ в базу перед продолжением'
                    />

                    <Grid container direction="row" justifyContent="center">
                        <Grid item>
                            <StyledButton
                                label={"Добавить состав"}
                                icon={<QueuePlayNextIcon />}
                                onClick={forwardToCompositionAdd}
                            />
                        </Grid>
                        <Grid item>
                            <StyledButton
                                label={"Начать заново"}
                                icon={<RefreshIcon />}
                                onClick={props.reset}
                            />
                        </Grid>
                    </Grid>
                </>
            )
        }

        return (
            <>
                <Container sx={{ alignContent: 'center', justifyContent: 'center' }}>
                    Укажите модель ТВ с которого сняты запчасти:
                    <AddListingPackModelAutocompleteContainer />
                </Container>
            </>
        )
    }

    return (
        <>
            {render()}
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        modelConfirmed: state.addPack.modelConfirmed,
        modelUnknown: state.addPack.modelUnknown,
        isFetching: state.addPack.isFetching,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reset: () => {
            dispatch(packResetAC());
        }
    }
}

const ModelConfirmContainer = connect(mapStateToProps, mapDispatchToProps)(ModelConfirm);
export default ModelConfirmContainer;


