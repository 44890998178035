import React from 'react'
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Stack } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import Tooltip from "@mui/material/Tooltip";
import HelpIconWithTooltip from '../entities/HelpIconWithTooltip';

const useStyles = makeStyles({
    root: {
        marginRight: 5,
        marginLeft: 5,
        marginBottom: 10,
        background: '#202938',
        paddingBottom: 0,
        paddingTop: 0,
        borderColor: 'white',
    },
    price: {
        fontSize: 25,
        fontWeight: 700,
        color: 'white',
        marginBottom: 10,
        marginTop: 10,
    },
    link: {
        color: '#6dc353',
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 5,
    },
    header: {
        fontSize: 16,
        fontWeight: 700,
        color: '#6dc353',
        marginBottom: 6,
    },
    content: {
        color: 'white',
        marginBottom: 4,
        paddingLeft: 5,
    },
    contentGreen: {
        color: '#6dc353',
    },
    contentRed: {
        color: 'red',
    },
});

const ProfileCard = (props) => {
    const classes = useStyles();

    return (
        <>
            <Card className={classes.root} variant="outlined">
                <CardContent>
                    <Stack direction='row' spacing={0}>
                        <PersonIcon sx={{ color: '#6dc353' }} />
                        <Typography className={classes.link}>
                            {props.login}
                        </Typography>
                    </Stack>

                    <Stack direction='row' spacing={1}>
                        <Stack direction='row' spacing={0}>
                            <HelpIconWithTooltip
                                text="Первое число - это количество положительных минус количество отрицательных отзывов на продавца. Второе - процент положительных отзывов от общего их количества"
                                darkMode
                            />
                            <Typography className={classes.content}>
                                Рейтинг
                            </Typography>
                        </Stack>

                        <Tooltip
                            title="Первое число - это количество положительных минус количество отрицательных отзывов на продавца. Второе - процент положительных отзывов от общего их количества"
                            placement="bottom"
                            arrow
                        >
                            <Typography className={props.ratingsAbs > 0 ? classes.contentGreen : props.ratingsAbs < 0 ? classes.contentRed : classes.content}>
                                {props.ratingsAbs} ( {props.ratingRel}% )
                            </Typography>
                        </Tooltip>
                    </Stack>

                    <Typography className={classes.content}>
                        Активных объявлений: {props.listingsTotal}
                    </Typography>

                    <Typography className={classes.content}>
                        Продавец на сайте с {props.worksSince}
                    </Typography>

                    <Typography className={classes.content}>
                        {props.region}, {props.city}
                    </Typography>
                </CardContent>
            </Card>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        login: state.anotherUserPage.login,
        city: state.anotherUserPage.city,
        cityType: state.anotherUserPage.cityType,
        region: state.anotherUserPage.region,
        ratingsAbs: state.anotherUserPage.ratingAbs,
        ratingRel: state.anotherUserPage.ratingRel,
        worksSince: state.anotherUserPage.worksSince,
        listingsTotal: state.anotherUserPage.listingsTotal,
    }
}

const ProfileCardContainer = connect(mapStateToProps, {})(ProfileCard);
export default ProfileCardContainer;