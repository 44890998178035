import fetch from "cross-fetch";

const LIST_SEARCH_SET_FETCHING = 'LIST_SEARCH_SET_FETCHING';
const LIST_SEARCH_UPDATE_RESULTS = 'LIST_SEARCH_UPDATE_RESULTS';
const LIST_SEARCH_SET_ERROR = 'LIST_SEARCH_SET_ERROR';
const LIST_SEARCH_SET_SEARCH_TEXT = 'LIST_SEARCH_SET_SEARCH_TEXT';
const LIST_SEARCH_RECEIVE_HW_TYPES_LIST = 'LIST_SEARCH_RECEIVE_HW_TYPES_LIST';
const LIST_SEARCH_SET_HW_TYPE = 'LIST_SEARCH_SET_HW_TYPE';
const LIST_SEARCH_SET_HW_TYPE_TEXT = 'LIST_SEARCH_SET_HW_TYPE_TEXT';
const LIST_SEARCH_BY_TYPE_SET_MODEL_TEXT = 'LIST_SEARCH_BY_TYPE_SET_MODEL_TEXT';
const LIST_SEARCH_BY_PN_SET_MODEL_TEXT = 'LIST_SEARCH_BY_PN_SET_MODEL_TEXT';
const LIST_SEARCH_SET_URL_REQUEST = 'LIST_SEARCH_SET_URL_REQUEST';
const LIST_SEARCH_FIX_MODEL = 'LIST_SEARCH_FIX_MODEL';
const LIST_SEARCH_SET_SUBSCRIPTION_ADDED = 'LIST_SEARCH_SET_SUBSCRIPTION_ADDED';

let initialState = {
    isFetching: false,
    didntStartYet: true,
    requestFromUrl: '',

    // base info
    hwTypes: [],

    // search by pns:
    searchedPn: '',
    pnSearchModel: '',

    // search by type:
    hwType: '',
    hwTypeRu: '',
    hwTypeIndex: -1,
    typeSearchModel: '',

    // 'historical' data
    lastSearchType: '', // either 'bytype' or 'bypn'
    lastSearchPn: '',
    lastSearchedByTypeModel: '',
    lastSearchedByPnModel: '',

    // search results
    listingsTotal: 0,
    listings: [],

    // error handling
    errorCondition: false,
    errWhat: '',

    // subscription adding:
    subscriptionAdded: false,
};

const listingsSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case LIST_SEARCH_SET_FETCHING:
            return Object.assign({}, state, {
                isFetching: action.isFetching,
            });

        case LIST_SEARCH_UPDATE_RESULTS:
            return Object.assign({}, state, {
                isFetching: false,
                listings: action.listings,
                listingsTotal: action.listingsTotal,
                lastSearchType: action.lastSearchType,
                hwTypeRu: action.lastSearchType === 'bytype' ? state.hwTypes.find(({ type }) => type === state.hwType).text : '',
                didntStartYet: false,
                // copy search params to history
                lastSearchedByTypeModel: action.lastSearchType === 'bytype' ? state.typeSearchModel : state.lastSearchedByTypeModel,
                lastSearchedByPnModel: action.lastSearchType === 'bytype' ? state.lastSearchedByPnModel : state.pnSearchModel,
                lastSearchPn: action.lastSearchType === 'bytype' ? state.lastSearchPn : state.searchedPn,
            });

        case LIST_SEARCH_SET_ERROR:
            return Object.assign({}, state, {
                errorCondition: true,
                errWhat: action.what,
            });

        case LIST_SEARCH_SET_SEARCH_TEXT:
            return Object.assign({}, state, {
                searchedPn: action.newText,
            });

        case LIST_SEARCH_RECEIVE_HW_TYPES_LIST:
            return Object.assign({}, state, {
                isFetching: false,
                hwTypes: action.types,
                hwTypeRu: state.hwType === '' ? '' : action.types.find(({ type }) => type === state.hwType).text,
            });

        case LIST_SEARCH_BY_TYPE_SET_MODEL_TEXT:
            return Object.assign({}, state, {
                typeSearchModel: action.newText,
            });

        case LIST_SEARCH_BY_PN_SET_MODEL_TEXT:
            return Object.assign({}, state, {
                pnSearchModel: action.newText,
            });

        case LIST_SEARCH_SET_HW_TYPE:
            return Object.assign({}, state, {
                hwTypeIndex: action.index,
                hwType: state.hwTypes[action.index].type,
            });

        case LIST_SEARCH_SET_URL_REQUEST:
            return Object.assign({}, state, {
                requestFromUrl: action.newText,
            });

        case LIST_SEARCH_SET_HW_TYPE_TEXT:
            return Object.assign({}, state, {
                hwType: action.hwType,
                hwTypeIndex: action.hwType === '' ? -1 : state.hwTypes.findIndex((item) => item.type === action.hwType),
            });

        case LIST_SEARCH_FIX_MODEL:
            return Object.assign({}, state, {
                lastSearchedModel: action.lastSearchedModel,
            });

        case LIST_SEARCH_SET_SUBSCRIPTION_ADDED:
            return Object.assign({}, state, {
                subscriptionAdded: action.subscriptionAdded,
            });







        default:
            return state;
    }
};

export const listSearchSetFetchingAC = (isFetching) => ({
    type: LIST_SEARCH_SET_FETCHING,
    isFetching: isFetching
})

export const listSearchResultsUpdated = (data, lastSearchType) => ({
    type: LIST_SEARCH_UPDATE_RESULTS,
    listingsTotal: data.listingsTotal,
    listings: data.listings,
    lastSearchType: lastSearchType,
})

export const listSearchSetError = (what) => ({
    type: LIST_SEARCH_SET_ERROR,
    what: what,
})

export const listSearchSetPnText = (newText) => ({
    type: LIST_SEARCH_SET_SEARCH_TEXT,
    newText: newText,
})

export const listSearchSetUrlRequestText = (newText) => ({
    type: LIST_SEARCH_SET_URL_REQUEST,
    newText: newText,
})

export const listSearchByTypeSetModelText = (newText) => ({
    type: LIST_SEARCH_BY_TYPE_SET_MODEL_TEXT,
    newText: newText,
})

export const listSearchByPnSetModelText = (newText) => ({
    type: LIST_SEARCH_BY_PN_SET_MODEL_TEXT,
    newText: newText,
})

export const listSearchReceiveHwTypesListAC = (json) => ({
    type: LIST_SEARCH_RECEIVE_HW_TYPES_LIST,
    types: json.data.types
})

export const listSearchSetHwTypeAC = (type, index) => ({
    type: LIST_SEARCH_SET_HW_TYPE,
    hwType: type,
    index: index,
})

export const listSearchSetHwTypeTextAC = (text) => ({
    type: LIST_SEARCH_SET_HW_TYPE_TEXT,
    hwType: text,
})

export const listSearchFixModelAC = (text) => ({
    type: LIST_SEARCH_FIX_MODEL,
    lastSearchedModel: text,
})

export const listSearchSetSubscriptionAddedAC = (value) => ({
    type: LIST_SEARCH_SET_SUBSCRIPTION_ADDED,
    subscriptionAdded: value,
})








export function listSearchFetchHwTypes() {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/tech/tv/hwtypes', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    dispatch(listSearchReceiveHwTypesListAC(json))
                }
            )
    }
}

export function searchByTypeModel(searchWhat) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + `/api/listings/search/tv/typemodel/${searchWhat.split("/").join("____")}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(listSearchResultsUpdated(json.data, 'bytype'));
                    } else {
                        dispatch(listSearchSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function searchByPnModel(searchWhat) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + `/api/listings/search/tv/pnmodel/${searchWhat.split("/").join("____")}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(listSearchResultsUpdated(json.data, 'bypn'));
                    } else {
                        dispatch(listSearchSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function addSubscription(search_type,
    type_search_model,
    type_search_type,
    pn_search_model,
    pn_search_pn) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/subscribe', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                search_type: search_type,
                type_search_model: type_search_model,
                type_search_type: type_search_type,
                pn_search_model: pn_search_model,
                pn_search_pn: pn_search_pn,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(listSearchSetSubscriptionAddedAC(true));
                    } else {
                        dispatch(listSearchSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export default listingsSearchReducer;
