import React from 'react'
import { Grid, Link } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box } from '@mui/system';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Stack } from '@mui/material';
import transliterate from '../../utils/transliterate';
import TurnRightIcon from '@mui/icons-material/TurnRight';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import createSpacing from '@material-ui/core/styles/createSpacing';

const useStyles = makeStyles({
    icon: {
        marginLeft: 5,
    },
    link: {
        fontSize: 16,
        fontWeight: 600,
        color: 'black',
        paddingRight: 10,
    },
    content: {
        fontSize: 15,
        fontWeight: 500,
        color: 'black',
    },
});

const EventRow = (props) => {
    const classes = useStyles();

    const calcBgColor = () => {
        if (props.odd)
            return '#FEFDFE';

        return '#F1F1F1';
    }

    return (
        <>
            <Box
                sx={{
                    border: '1px solid #f6f8f1',
                    borderRadius: '4px',
                    marginBottom: 1,
                    bgcolor: calcBgColor(),
                }}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems='center'
            >
                <Box display={'flex'} flexDirection={'row'} >
                    <Box marginX={2} marginY={1}>
                        {props.icon}
                    </Box>

                    <Stack >
                        <Typography className={classes.content} >
                            {props.text}
                        </Typography>

                        <Typography className={classes.content} >
                            {props.appeared}
                        </Typography>
                    </Stack>
                </Box>


                <Box /*display={{ xs: 'block', md: 'none' }}*/>
                    <Stack direction={'row'} spacing={1}>
                        <Link href={props.linkTo}>
                            <Typography className={classes.link} gutterBottom>
                                Подробности
                            </Typography>
                        </Link>

                        <Link href={props.linkTo}>
                            <ArrowForwardIcon />
                        </Link>
                    </Stack>
                </Box>
            </Box>
        </>
    )
}

export default EventRow;