import React from 'react'
import { connect } from "react-redux";
import Paginated from '../entities/Paginated';
import MySalePreviewContainer from './MySalePreview';
import { Grid, Typography } from '@material-ui/core';
import Box from '@mui/material/Box';
import StyledSelect from '../../components/entities/StyledSelect';
import { makeStyles } from '@material-ui/core/styles';
import Spinner from '../Spinner';
import { Container, Stack } from '@mui/material';
import { tradingSalesStateFilterChanged, tradingSalesFbFilterChanged } from '../../redux/my-trading-activity-reducer'

const useStyles = makeStyles({
    text: {
        color: 'black',
        fontWeight: 600,
        padding: '5px',
        paddingBottom: '13px'
    },
});

const stateFilter = [
    { text: 'Все', filter: 'any' },
    { text: 'Завершенные', filter: 'completed' },
    { text: 'Отмененные', filter: 'discarded' },
    { text: 'В процессе', filter: 'inProgress' },
];

const fbFilter = [
    { text: 'Не важно', filter: 'any' },
    { text: 'От меня', filter: 'expectMy' },
    { text: 'От покупателя', filter: 'expectBuyer' },
];

const MySales = (props) => {
    const classes = useStyles();
    const renderListingRow = (index, value) => {
        return <MySalePreviewContainer
            id={value.id}
            listingId={value.listing_id}
            buyerConfirm={value.buyer_confirm}
            sellerConfirm={value.seller_confirm}
            sealed={value.sealed}
            discarded={value.discarded}
            discardedBy={value.discarded_by}
            buyerLogin={value.login}
            buyerEmail={value.email}
            buyerCityType={value.city_type}
            buyerCity={value.city}
            buyerRegion={value.region}
            buyerPhone={value.phone}
            buyerWa={value.whatsapp}
            buyerTg={value.telegram}
            price={value.price}
            title={value.summary}
            type={value.type}
            typeRu={value.text}
            state={value.state}
            vendor={value.vendor}
            model={value.model}
            imgUrl={value.cover}
            odd={index % 2}
            key={value.id}
            buyerFbType={value.buyer_fb_type}
            buyerFbText={value.buyer_fb}
            buyerFbDate={value.buyer_fb_ts}
            myFbType={value.seller_fb_type}
            myFbText={value.seller_fb}
            myFbDate={value.seller_fb_ts}
            feedbackAllowed={value.feedback_allowed}
        />
    }

    if (props.isFetching) {
        return <Spinner />
    }

    if (props.salesTotal == 0) {
        return (
            <>
                <h2>¯\_(ツ)_/¯</h2>
                <h2>Вы пока ничего не продали, но у Вас всё получится!</h2>
            </>
        )
    }

    const renderFilteredResults = () => {
        if (props.filteredTotal > 0) {
            return <Paginated
                pagesize={props.pageSize}
                renderItem={renderListingRow}
                items={props.sales}
            />;

        }
        else {
            return (
                <>
                    <h2>¯\_(ツ)_/¯</h2>
                    <h2>Нет сделок удовлетворяющим критериям фильтра</h2>
                </>
            )
        }
    }

    return (
        <>
            <Box sx={{
                marginBottom: '10px',
                bgcolor: '#cccccc',
                border: '1px solid black',
                borderRadius: '4px',
                bgcolor: '#f3f3f3',
                paddingTop: '8px',
                paddingBottom: '5px',
            }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    alignContent='center'
                >

                    <Grid item xs={12} sm={'auto'}>
                        <Typography className={classes.text}>Фильтры: </Typography>
                    </Grid>

                    <Grid item xs={12} sm={'auto'}>
                        <Container sx={{ minWidth: 250, marginBottom: '5px' }}>
                            <StyledSelect
                                label={'Состояние сделки'}
                                items={stateFilter.map(a => a.text)}
                                cb={props.changeStateFilter}
                            />
                        </Container>
                    </Grid>

                    <Grid item xs={12} sm={'auto'}>
                        <Container sx={{ minWidth: 250, marginBottom: '5px' }}>
                            <StyledSelect
                                label={'Ожидающие отзыва'}
                                items={fbFilter.map(a => a.text)}
                                cb={props.changeFbFilter}
                            />
                        </Container>
                    </Grid>
                </Grid>
            </Box>

            {renderFilteredResults()}
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    const sortIdDesc = (deals) => {
        return deals.sort(function (a, b) {
            return parseInt(b.id, 10) - parseInt(a.id, 10);
        });
    }
    return {
        isFetching: state.tradingActivity.isFetching,
        filteredTotal: state.tradingActivity.filteredSales.length,
        salesTotal: state.tradingActivity.salesTotal,
        sales: sortIdDesc(state.tradingActivity.filteredSales),
        pageSize: ownProps.pageSize ? ownProps.pageSize : 10,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeStateFilter: (type, index) => {
            dispatch(tradingSalesStateFilterChanged(stateFilter[index].filter));
        },
        changeFbFilter: (type, index) => {
            dispatch(tradingSalesFbFilterChanged(fbFilter[index].filter));
        },
    }
}

const MySalesContainer = connect(mapStateToProps, mapDispatchToProps)(MySales);
export default MySalesContainer;