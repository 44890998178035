import { React } from 'react'
import { connect } from "react-redux";
import StyledButton from '../entities/StyledButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArchiveIcon from '@mui/icons-material/Archive';
import CachedIcon from '@mui/icons-material/Cached';
import store from '../../redux/store';
import {
    activateChosen, setFetchingAC, deactivateChosen, removeChosen,
    myListingsChosenKeepExpired, myListingsChosenKeepActive,
    myListingsChosenKeepNonremoved
} from '../../redux/my-listings-reducer';
import DialogAlert from '../entities/DialogAlert';
import { Grid } from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import { Tooltip } from '@mui/material';
import history from "../../history/history";

const MyListingsActions = (props) => {
    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                alignContent='center'
            >
                <Grid item>
                    <Tooltip
                        title="Создать объявление о продаже одной запчасти"
                        placement="bottom"
                        arrow
                    >
                        <span>
                            <StyledButton
                                icon={<AddCircleIcon />}
                                size={'small'}
                                onClick={() => {
                                    const url = '/personal/mylistings/new';
                                    history.push(url);
                                }}
                                label='1 объявление'
                            />
                        </span>
                    </Tooltip>
                </Grid>

                <Grid item>
                    <Tooltip
                        title="В этом режиме можно добавлять сразу несколько объявлений если запчасти с одного ТВ. Полезно когда выбрасываешь на продажу запчасти с только-что разобранного ТВ"
                        placement="bottom"
                        arrow
                    >
                        <span>
                            <StyledButton
                                icon={<AddToQueueIcon />}
                                size={'small'}
                                onClick={() => {
                                    const url = '/personal/mylistings/tvdisasm';
                                    history.push(url);
                                }}
                                label='По модели ТВ'
                            />
                        </span>
                    </Tooltip>
                </Grid>

                <Grid item >
                    <Tooltip
                        title="Снять выбранные объявления с публикации. Объявления становятся 'устаревшими'."
                        placement="bottom"
                        arrow
                    >
                        <span>
                            <StyledButton
                                icon={<ArchiveIcon />}
                                size='small'
                                onClick={() => {
                                    if (props.chosen.length > 0)
                                        props.deactivate();
                                }}
                                label='Снять с публикации'
                                disabled={props.chosen.length === 0}
                            />
                        </span>
                    </Tooltip>
                </Grid>

                <Grid item>
                    <DialogAlert
                        buttonText='Удалить'
                        buttonIcon={<DeleteOutlineIcon />}
                        onConfirm={() => {
                            if (props.chosen.length > 0)
                                props.remove();
                        }}
                        titleText={`Действительно удалить выбранные объявления (${props.chosen.length} шт)?`}
                        descriptionText='Выбранные объявления будут удалены без возможности вернуть их на публикацию. Фото из объявлений будут так-же удалены.'
                        cancelText='Отмена'
                        proceedText='Удалить'
                        startButtonDisabled={props.chosen.length === 0}
                    />
                </Grid>

                <Grid item>
                    <Tooltip
                        title="Опубликовать выбранные устаревшие объявления. Если объявления не устарели, кнопка ничего не делает"
                        placement="bottom"
                        arrow
                    >
                        <span>
                            <StyledButton
                                icon={<CachedIcon />}
                                size='small'
                                onClick={() => {
                                    if (props.chosen.length > 0)
                                        props.renew();
                                }}
                                label='Опубликовать'
                                disabled={props.chosen.length === 0}
                            />
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        chosen: state.myListingsPage.selected,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        renew: () => {
            dispatch(setFetchingAC(true));
            dispatch(myListingsChosenKeepExpired());
            dispatch(activateChosen(store.getState().myListingsPage.selected));
        },
        deactivate: () => {
            dispatch(setFetchingAC(true));
            dispatch(myListingsChosenKeepActive());
            dispatch(deactivateChosen(store.getState().myListingsPage.selected));
        },
        remove: () => {
            dispatch(setFetchingAC(true));
            dispatch(myListingsChosenKeepNonremoved());
            dispatch(removeChosen(store.getState().myListingsPage.selected));
        }
    }
}

const MyListingsActionsContainer = connect(mapStateToProps, mapDispatchToProps)(MyListingsActions);
export default MyListingsActionsContainer;