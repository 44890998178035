import React, { useEffect } from 'react'
import SearchModelFormContainer from "../components/search/SearchModelForm";
import { connect } from "react-redux";
import {
    fetchComposition,
    updateSearchModelTextActionCreator,
    compositionSetFetchingAC
} from "../redux/composition-reducer";
import MetaTags from 'react-meta-tags';

import CompositionResultContainer from "../components/Composition";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import TvIcon from '@material-ui/icons/Tv';
import ClearAllIcon from "@material-ui/icons/ClearAll";
import logo from '../components/logo.svg'

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
        marginTop: 10,
        marginBottom: 10,
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
}));

const Composition = ({ syncState, searchedItem, match }) => {
    const classes = useStyles();

    useEffect(() => {
        syncState(match.params.searchedItem);
    }, [match.params.searchedItem])

    return (
        <>
            <MetaTags>
                <title>{`Состав ТВ ${searchedItem}`}</title>
                <meta name="description" content={`Состав ТВ ${searchedItem} - наТекстолите`} />
                <meta name="keywords" content={`Состав,${searchedItem}`} />
                <meta property="og:title" content="naTeXtolite" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`${window.location.href}`} />
                <meta property="og:image" content={logo} />
                <meta property="og:description" content={`Состав ТВ ${searchedItem} - наТекстолите`} />
            </MetaTags>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="textPrimary" href="/" className={classes.link}>
                    <HomeIcon className={classes.icon} />
                    naTeXtolite
                </Link>
                <Link color="textPrimary" href="/services" className={classes.link}>
                    <ClearAllIcon className={classes.icon} />
                    Сервисы
                </Link>
                <Typography color="textPrimary" className={classes.link}>
                    <TvIcon className={classes.icon} />
                    Состав ТВ
                </Typography>
            </Breadcrumbs>

            <SearchModelFormContainer />
            <h1>Результаты поиска:</h1>
            {/*<div> Тип поиска: {searchTypeName(props.searchType)} </div>
            <div> Объект поиска: {props.searchedItem} </div>*/}

            <CompositionResultContainer />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        currentModelSearchText: state.compositionPage.searchModelText,
        searchedItem: state.compositionPage.searchedItem,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        syncState: (newText) => {
            if (newText === '' ||
                newText === undefined) {
                dispatch(compositionSetFetchingAC(false));
                return;
            }
            dispatch(updateSearchModelTextActionCreator(newText));
            dispatch(fetchComposition(newText));
        },
    }
}

const CompositionContainer = connect(mapStateToProps, mapDispatchToProps)(Composition);
export default CompositionContainer;