import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TelegramQrGenerator from '../../components/settings/TgTelegramQrGen';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import Link from '@material-ui/core/Link';
import { connect } from "react-redux";

const BindTelegramNotificationsBlock = (props) => {
    return (
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <QrCode2Icon sx={{ marginRight: 1 }} />
                    Подключение уведомлений в Telegram
                </AccordionSummary>

                <AccordionDetails>
                    Чтобы подключить уведомления в Telegram отсканируйте QR code, перейдите по ссылке и нажмите /start в диалоге с ботом

                    <TelegramQrGenerator url={props.tgBindUrl} />
                    <Link href={props.tgBindUrl}>Или используйте эту ссылку </Link>
                </AccordionDetails>
            </Accordion>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        tgAlreadyBinded: state.settings.tgAlreadyBinded,
        tgBindUrl: state.settings.tgBindUrl,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

const BindTelegramNotificationsBlockContainer = connect(mapStateToProps, mapDispatchToProps)(BindTelegramNotificationsBlock);
export default BindTelegramNotificationsBlockContainer;