import fetch from "cross-fetch";

const ADD_PACK_SET_MODEL = 'ADD_PACK_SET_MODEL';
const ADD_PACK_SET_FETCHING = 'ADD_PACK_SET_FETCHING';
const ADD_PACK_RECEIVE_COMPOSITION = 'ADD_PACK_RECEIVE_COMPOSITION';

const ADD_PACK_SET_HWTYPES = 'ADD_PACK_SET_HWTYPES';
const ADD_PACK_CONFIRM_HWTYPES = 'ADD_PACK_CONFIRM_HWTYPES';
const ADD_PACK_RECEIVE_HW_TYPES_LIST = 'ADD_PACK_RECEIVE_HW_TYPES_LIST';

const ADD_PACK_SET_PNS = 'ADD_PACK_SET_PNS';
const ADD_PACK_CONFIRM_PNS = 'ADD_PACK_CONFIRM_PNS';

const ADD_PACK_UPDATE_PN1 = "ADD_PACK_UPDATE_PN1";
const ADD_PACK_UPDATE_PN2 = "ADD_PACK_UPDATE_PN2";
const ADD_PACK_UPDATE_PN3 = "ADD_PACK_UPDATE_PN3";
const ADD_PACK_UPDATE_PN4 = "ADD_PACK_UPDATE_PN4";
const ADD_PACK_UPDATE_PN5 = "ADD_PACK_UPDATE_PN5";
const ADD_PACK_UPDATE_PN6 = "ADD_PACK_UPDATE_PN6";

const ADD_PACK_ARE_ALL_PNS_CONFIRMED = 'ADD_PACK_ARE_ALL_PNS_CONFIRMED';
const ADD_PACK_SET_AND_CONFIRM_BASE_INFO = 'ADD_PACK_SET_AND_CONFIRM_BASE_INFO';
const ADD_PACK_WHOLE_BASE_INFO_CONFIRMED = 'ADD_PACK_WHOLE_BASE_INFO_CONFIRMED';

const ADD_PACK_SET_AND_CONFIRM_PHOTOS = 'ADD_PACK_SET_AND_CONFIRM_PHOTOS';
const ADD_PACK_ARE_ALL_PHOTOS_CONFIRMED = 'ADD_PACK_ARE_ALL_PHOTOS_CONFIRMED';


const ADD_PACK_PREVIEW_CONFIRM = 'ADD_PACK_PREVIEW_CONFIRM';
const ADD_PACK_POSTING = 'ADD_PACK_POSTING';
const ADD_PACK_LISTING_CREATED = 'ADD_PACK_LISTING_CREATED';



// backward
const ADD_PACK_DROP_MODEL_CONFIRM = 'ADD_PACK_DROP_VENDOR_MODEL_CONFIRM';
const ADD_PACK_DROP_HWTYPES_CONFIRM = 'ADD_PACK_DROP_HWTYPES_CONFIRM';
const ADD_PACK_DROP_PNS_CONFIRM = 'ADD_PACK_DROP_PNS_CONFIRM';
const ADD_PACK_DROP_BASE_INFO_CONFIRM = 'ADD_PACK_DROP_BASE_INFO_CONFIRM';
const ADD_PACK_DROP_PHOTOS_CONFIRM = 'ADD_PACK_DROP_PHOTOS_CONFIRM';

const ADD_PACK_RESET = 'ADD_PACK_RESET';

const LOG_REG_LOGOUT = 'LOG_REG_LOGOUT';

let initialState = {
    isFetching: false,

    model: "",
    modelId: -1,
    modelConfirmed: false,
    modelUnknown: true,

    vendor: "",
    vendorId: -1,

    composition: {
        empty: true,
        vendor: '',
        model: '',
        chasis: '',
        pseudonim: '',

        ssb: [ // alternatives
            { id: '', pns: [] }, // part-numbers of the current alt
        ],
        psu: [
            { id: '', pns: [] },
        ],
        tcn: [
            { id: '', pns: [] },
        ],
        bkl: [
            { id: '', pns: [] },
        ],
        pnl: [
            { id: '', pns: [] },
        ],
        ld: [
            { id: '', pns: [] },
        ],
        ir: [
            { id: '', pns: [] },
        ],
        btn: [
            { id: '', pns: [] },
        ],
        wlan: [
            { id: '', pns: [] },
        ],
        bt: [
            { id: '', pns: [] },
        ],
        dyn: [
            { id: '', pns: [] },
        ],
        tun: [
            { id: '', pns: [] },
        ],
        lvds: [
            { id: '', pns: [] },
        ],
        mtx: [
            { id: '', pns: [] },
        ],
        cam: [
            { id: '', pns: [] },
        ],
        inv: [
            { id: '', pns: [] },
        ],
    },

    hwTypes: [],
    chosenHwTypes: [],
    hwTypesConfirmed: false,

    pnsProgress: 0,
    listingsData: [
        {
            type: "",
            text: "",
            partnumbersList: [],
            partnumbers: "",
            partnumbersId: -1,
            partnumbersConfirmed: false,
            noKnownPartnumbers: false,

            // injection
            pn1: '',
            pn2: '',
            pn3: '',
            pn4: '',
            pn5: '',
            pn6: '',
            pn1Err: false,
            pn2Err: false,
            pn3Err: false,
            pn4Err: false,
            pn5Err: false,
            pn6Err: false,

            // base info stage:
            price: '',
            summary: '',
            description: '',
            baseInfoConfirmed: false,

            // photos stage:
            photos: [],
            photosConfirmed: false,
            // preview stage:
            previewConfirmed: false,
        },
    ],
    allPnsConfirmed: false,
    wholeBaseInfoConfirmed: false,
    allPhotosConfirmed: false,
    allPreviewsConfirmed: false,
    allListingsPosted: false,
    isPosting: false,
    posted: 0,
};

const partnumbersOfType = (hwType, composition) => {
    switch (hwType) {
        case "ssb": return composition.ssb || [];
        case "psu": return composition.psu || [];
        case "tcn": return composition.tcn || [];
        case "bkl": return composition.bkl || [];
        case "pnl": return composition.pnl || [];
        case "ld": return composition.ld || [];
        case "ir": return composition.ir || [];
        case "btn": return composition.btn || [];
        case "wlan": return composition.wlan || [];
        case "bt": return composition.bt || [];
        case "dyn": return composition.dyn || [];
        case "lvds": return composition.lvds || [];
        case "mtx": return composition.mtx || [];
        case "cam": return composition.cam || [];
        case "inv": return composition.inv || [];
        default: return [];
    }
}

const partnumbersOfTypes = (chosenTypes, composition) => {
    let results = [];
    chosenTypes.forEach((item) => {
        const pnsArr = partnumbersOfType(item.type, composition);

        results.push({
            type: item.type,
            text: item.text,
            typeId: item.id,
            partnumbersList: pnsArr,
            partnumbers: "",
            partnumbersId: -1,
            partnumbersConfirmed: false,
            noKnownPartnumbers: pnsArr.length === 0,
            // injection data
            pn1: "", pn2: "", pn3: "", pn4: "", pn5: "", pn6: "",
            pn1Err: false, pn2Err: false, pn3Err: false, pn4Err: false, pn5Err: false, pn6Err: false,
            pn1ErrWhat: "", pn2ErrWhat: "", pn3ErrWhat: "",
            pn4ErrWhat: "", pn5ErrWhat: "", pn6ErrWhat: "",
            // base info data
            price: '',
            summary: '',
            description: '',
            baseInfoConfirmed: false,
            // photos stage:
            photos: [],
            photosConfirmed: false,
            previewConfirmed: false,
        });
    });

    return results;
}

const addListingsPack = (state = initialState, action) => {
    switch (action.type) {
        case ADD_PACK_SET_MODEL:
            return Object.assign({}, state, {
                model: action.model,
                modelConfirmed: true,
                isFetching: true,
            });

        case ADD_PACK_SET_FETCHING:
            return Object.assign({}, state, {
                isFetching: action.isFetching,
            });

        case ADD_PACK_RECEIVE_COMPOSITION:
            return Object.assign({}, state, {
                isFetching: false,
                composition: action.composition,
                vendor: action.composition.empty ? "" : action.composition.vendor,
                vendorId: action.composition.empty ? -1 : action.composition.vendor_id,
                modelUnknown: action.composition.empty,
                modelId: action.composition.empty ? -1 : action.composition.id,
            });

        case ADD_PACK_SET_HWTYPES:
            return Object.assign({}, state, {
                chosenHwTypes: action.chosenHwTypes,
            });

        case ADD_PACK_CONFIRM_HWTYPES:
            return Object.assign({}, state, {
                hwTypesConfirmed: true,
                listingsData: partnumbersOfTypes(state.chosenHwTypes, state.composition),
            });

        case ADD_PACK_RECEIVE_HW_TYPES_LIST:
            return Object.assign({}, state, {
                isFetching: false,
                hwTypes: action.types,
            });


        case ADD_PACK_UPDATE_PN1: return Object.assign({}, state, {
            listingsData: state.listingsData.map((injection, i) => i === action.index ? {
                ...injection,
                pn1: action.newText,
                pn1Err: action.err.isErr,
                pn1ErrWhat: action.err.what
            } : injection)
        });

        case ADD_PACK_UPDATE_PN2: return Object.assign({}, state, {
            listingsData: state.listingsData.map((injection, i) => i === action.index ? {
                ...injection,
                pn2: action.newText,
                pn2Err: action.err.isErr,
                pn2ErrWhat: action.err.what
            } : injection)
        });
        case ADD_PACK_UPDATE_PN3: return Object.assign({}, state, {
            listingsData: state.listingsData.map((injection, i) => i === action.index ? {
                ...injection,
                pn3: action.newText,
                pn3Err: action.err.isErr,
                pn3ErrWhat: action.err.what
            } : injection)
        });
        case ADD_PACK_UPDATE_PN4: return Object.assign({}, state, {
            listingsData: state.listingsData.map((injection, i) => i === action.index ? {
                ...injection,
                pn4: action.newText,
                pn4Err: action.err.isErr,
                pn4ErrWhat: action.err.what
            } : injection)
        });
        case ADD_PACK_UPDATE_PN5: return Object.assign({}, state, {
            listingsData: state.listingsData.map((injection, i) => i === action.index ? {
                ...injection,
                pn5: action.newText,
                pn5Err: action.err.isErr,
                pn5ErrWhat: action.err.what
            } : injection)
        });
        case ADD_PACK_UPDATE_PN6: return Object.assign({}, state, {
            listingsData: state.listingsData.map((injection, i) => i === action.index ? {
                ...injection,
                pn6: action.newText,
                pn6Err: action.err.isErr,
                pn6ErrWhat: action.err.what
            } : injection)
        });

        case ADD_PACK_SET_PNS:
            return Object.assign({}, state, {
                listingsData: state.listingsData.map((listing, i) => i === action.index ? {
                    ...listing,
                    partnumbers: action.pns,
                    partnumbersId: action.pnsIndex === -1 ? -1 : listing.partnumbersList[action.pnsIndex].id,

                    summary: action.pns !== listing.partnumbers ? '' : listing.summary,
                    description: action.pns !== listing.partnumbers ? '' : listing.description,
                } : listing)
            });

        case ADD_PACK_CONFIRM_PNS:
            return Object.assign({}, state, {
                listingsData: state.listingsData.map((listing, i) => i === action.index ? {
                    ...listing,
                    partnumbersConfirmed: true,
                    injection: listing.partnumbersId === -1,
                } : listing),
                pnsProgress: state.pnsProgress + 1,
            });

        case ADD_PACK_ARE_ALL_PNS_CONFIRMED:
            return Object.assign({}, state, {
                allPnsConfirmed: state.listingsData.every((val, i, arr) => val.partnumbersConfirmed === true)
            });

        case ADD_PACK_SET_AND_CONFIRM_BASE_INFO:
            return Object.assign({}, state, {
                listingsData: state.listingsData.map((listing, i) => i === action.index ? {
                    ...listing,
                    summary: action.summary,
                    description: action.description,
                    price: action.price,
                    baseInfoConfirmed: true,
                } : listing),
            });

        case ADD_PACK_WHOLE_BASE_INFO_CONFIRMED:
            return Object.assign({}, state, {
                wholeBaseInfoConfirmed: state.listingsData.every((val, i, arr) => val.baseInfoConfirmed === true)
            });


        case ADD_PACK_SET_AND_CONFIRM_PHOTOS:
            return Object.assign({}, state, {
                listingsData: state.listingsData.map((listing, i) => i === action.index ? {
                    ...listing,
                    photos: action.photos,
                    photosConfirmed: true,
                } : listing),
            });

        case ADD_PACK_ARE_ALL_PHOTOS_CONFIRMED:
            return Object.assign({}, state, {
                allPhotosConfirmed: state.listingsData.every((val, i, arr) => val.photosConfirmed === true)
            });

        case ADD_PACK_PREVIEW_CONFIRM:
            return Object.assign({}, state, {
                listingsData: state.listingsData.map((listing, i) => i === action.index ? {
                    ...listing,
                    previewConfirmed: true,
                } : listing),

                allPreviewsConfirmed: action.index === (state.listingsData.length - 1),
            });

        case ADD_PACK_POSTING:
            return Object.assign({}, state, {
                isFetching: true,
                isPosting: true,
            });

        case ADD_PACK_LISTING_CREATED:
            return Object.assign({}, state, {
                posted: state.posted + 1,
                allListingsPosted: state.listingsData.length === state.posted + 1,
            });







        case ADD_PACK_DROP_MODEL_CONFIRM:
            return Object.assign({}, state, {
                modelConfirmed: false,
                modelUnknown: true,
                composition: {},
            });

        case ADD_PACK_DROP_HWTYPES_CONFIRM:
            return Object.assign({}, state, {
                hwTypesConfirmed: false,
                chosenHwTypes: [],
                listingsData: [],
            });

        case ADD_PACK_DROP_PNS_CONFIRM:
            return Object.assign({}, state, {
                allPnsConfirmed: false,
                pnsProgress: 0,
                listingsData: state.listingsData.map(listing => {
                    return {
                        ...listing,
                        partnumbersConfirmed: false,
                        baseInfoConfirmed: false,

                        /*partnumbers: '',
                        partnumbersId: -1,
                        injection: false,
                        pn1: '',
                        pn2: '',
                        pn3: '',
                        pn4: '',
                        pn5: '',
                        pn6: '',
                        pn1Err: false,
                        pn2Err: false,
                        pn3Err: false,
                        pn4Err: false,
                        pn5Err: false,
                        pn6Err: false,
                        pn1ErrWhat: "",
                        pn2ErrWhat: "",
                        pn3ErrWhat: "",
                        pn4ErrWhat: "",
                        pn5ErrWhat: "",
                        pn6ErrWhat: "",*/
                    }
                })
            });

        case ADD_PACK_DROP_BASE_INFO_CONFIRM:
            return Object.assign({}, state, {
                wholeBaseInfoConfirmed: false,
                listingsData: state.listingsData.map(listing => {
                    return {
                        ...listing,
                        baseInfoConfirmed: false,
                        photosConfirmed: false,
                    }
                })
            });

        case ADD_PACK_DROP_PHOTOS_CONFIRM:
            return Object.assign({}, state, {
                allPhotosConfirmed: false,
                listingsData: state.listingsData.map(listing => {
                    return {
                        ...listing,
                        photosConfirmed: false,
                        previewConfirmed: false,
                    }
                })
            });



        case ADD_PACK_RESET:
            return Object.assign({}, state, initialState);

        case LOG_REG_LOGOUT:
            return Object.assign({}, state, initialState);

        default:
            return state;
    }
};

export const packSetIsFetchingAC = (isFetching) => ({
    type: ADD_PACK_SET_FETCHING,
    isFetching: isFetching,
})

export const packSetModelAC = (model) => ({
    type: ADD_PACK_SET_MODEL,
    model: model,
})

export function packFetchComposition(item) {
    let what = item.split("/").join("____");
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + `/api/tech/tv/composition/${what}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    dispatch(packReceiveCompositionAC(json))
                }
            )
    }
}

export function addPackFetchHwTypes() {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/tech/tv/hwtypes', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    dispatch(receiveHwTypesListAC(json))
                }
            )
    }
}

export const packReceiveCompositionAC = (json) => ({
    type: ADD_PACK_RECEIVE_COMPOSITION,
    composition: json.data.tv_composition
})

export const packSetHwTypesAC = (types) => ({
    type: ADD_PACK_SET_HWTYPES,
    chosenHwTypes: types,
})

export const packSetHwTypesConfirmAC = () => ({
    type: ADD_PACK_CONFIRM_HWTYPES,
})

export const receiveHwTypesListAC = (json) => ({
    type: ADD_PACK_RECEIVE_HW_TYPES_LIST,
    types: json.data.types
})


export const packSetPnsAC = (index, pns, pnsIndex) => ({
    type: ADD_PACK_SET_PNS,
    index: index,
    pns: pns,
    pnsIndex: pnsIndex
})

export const packPnsConfirmAC = (index) => ({
    type: ADD_PACK_CONFIRM_PNS,
    index: index,
})

export const packConfirmStageIfLastPnAC = () => ({
    type: ADD_PACK_ARE_ALL_PNS_CONFIRMED,
})

export const packConfirmStageIfLastBaseInfoAC = () => ({
    type: ADD_PACK_WHOLE_BASE_INFO_CONFIRMED,
})

export const packConfirmStageIfLastPhotoAC = () => ({
    type: ADD_PACK_ARE_ALL_PHOTOS_CONFIRMED,
})



export const addPackUpdatePn1AC = (idx, text, err) => ({ type: ADD_PACK_UPDATE_PN1, index: idx, newText: text, err: err })
export const addPackUpdatePn2AC = (idx, text, err) => ({ type: ADD_PACK_UPDATE_PN2, index: idx, newText: text, err: err })
export const addPackUpdatePn3AC = (idx, text, err) => ({ type: ADD_PACK_UPDATE_PN3, index: idx, newText: text, err: err })
export const addPackUpdatePn4AC = (idx, text, err) => ({ type: ADD_PACK_UPDATE_PN4, index: idx, newText: text, err: err })
export const addPackUpdatePn5AC = (idx, text, err) => ({ type: ADD_PACK_UPDATE_PN5, index: idx, newText: text, err: err })
export const addPackUpdatePn6AC = (idx, text, err) => ({ type: ADD_PACK_UPDATE_PN6, index: idx, newText: text, err: err })


export const packSetBaseInfoAC = (index, summary, description, price) => ({
    type: ADD_PACK_SET_AND_CONFIRM_BASE_INFO,
    index: index,
    summary: summary,
    description: description,
    price: price,
})


export const packConfirmPhotosAC = (index, photos) => ({
    type: ADD_PACK_SET_AND_CONFIRM_PHOTOS,
    index: index,
    photos: photos,
})


export const packPreviewConfirmAC = (index) => ({
    type: ADD_PACK_PREVIEW_CONFIRM,
    index: index,
})

export const addPackPostingAC = () => ({
    type: ADD_PACK_POSTING,
})


export const addPackListingCreatedAC = () => ({
    type: ADD_PACK_LISTING_CREATED,
})




export const packDropModelAC = () => ({
    type: ADD_PACK_DROP_MODEL_CONFIRM,
})

export const dropHwTypesAC = () => ({
    type: ADD_PACK_DROP_HWTYPES_CONFIRM,
})

export const dropPnsAC = () => ({
    type: ADD_PACK_DROP_PNS_CONFIRM,
})

export const dropBaseInfoAC = () => ({
    type: ADD_PACK_DROP_BASE_INFO_CONFIRM,
})

export const dropPhotosAC = () => ({
    type: ADD_PACK_DROP_PHOTOS_CONFIRM,
})




export const packResetAC = () => ({
    type: ADD_PACK_RESET,
})

export default addListingsPack;
