const USER_SIGNED_IN = 'SIGNED_IN';
const USER_GEO_DATA_UPDATE = 'GEO_DATA_UPDATE';
const USER_PHONE_UPDATE = 'PHONE_UPDATE';
const LOG_REG_LOGOUT = 'LOG_REG_LOGOUT';


let initialState = {
    userId: 0,

    login: "",
    email: "",
    city: "",
    cityType: "",
    region: "",
    geoLat: "",
    geoLon: "",

    phone: "",
    registeredSince: "",
    absRating: 0, //pos-neg
    relRating: 0, //percentage

};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_SIGNED_IN:
            return Object.assign({}, state, {
                login: action.login,
                city: action.city,
                cityType: action.cityType,
                region: action.region,
                userId: action.id,
                phone: action.phone,
                email: action.email,
            });

        case USER_GEO_DATA_UPDATE:
            return Object.assign({}, state, {
                city: action.city,
                cityType: action.city_type,
                region: action.region,
                geoLat: action.lat,
                geoLon: action.lon,
            });

        case USER_PHONE_UPDATE:
            return Object.assign({}, state, {
                phone: action.phone,
            });

        case USER_SIGNED_IN:
            return Object.assign({}, state, {
                login: action.login,
                city: action.city,
                cityType: action.cityType,
                region: action.region,
                userId: action.id,
                phone: action.phone,
            });

        case LOG_REG_LOGOUT:
            return Object.assign({}, state, initialState);




        default:
            return state;
    }
};

export const signedInAC = (data) => ({
    type: USER_SIGNED_IN,
    id: data.id,
    login: data.login,
    city: data.city,
    cityType: data.city_type,
    region: data.region,
    phone: data.settings.phone,
    email: data.email,
})

export const genUpdateGeoDataAC = (city, city_type, region, lat, lon) => ({
    type: USER_GEO_DATA_UPDATE,
    city: city,
    city_type: city_type,
    region: region,
    lat: lat,
    lon: lon,
})

export const phoneUpdateAC = (phone) => ({
    type: USER_PHONE_UPDATE,
    phone: phone,
})

export const logoutAC = () => ({ type: LOG_REG_LOGOUT })





export default userReducer;
