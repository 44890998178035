import fetch from "cross-fetch";

const EVENTS_SET_FETCHING = 'EVENTS_SET_FETCHING';
const EVENTS_DATA_UPDATED = 'EVENTS_DATA_UPDATED';

const EVENTS_FETCH_ERROR = 'EVENTS_FETCH_ERROR';
const LOG_REG_LOGOUT = 'LOG_REG_LOGOUT';

let initialState = {
    isFetching: false,

    // events data
    events: [],
    eventsTotal: 0,
};

const eventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case EVENTS_SET_FETCHING:
            return Object.assign({}, state, {
                isFetching: action.isFetching,
            });

        case EVENTS_DATA_UPDATED:
            return Object.assign({}, state, {
                isFetching: false,
                events: action.events,
                eventsTotal: action.eventsTotal,
            });

        case LOG_REG_LOGOUT:
            return Object.assign({}, state, initialState);

        default:
            return state;
    }
};

export const eventsSetFetchingAC = (isFetching) => ({
    type: EVENTS_SET_FETCHING,
    isFetching: isFetching
})

export const updatedEventsData = (data) => ({
    type: EVENTS_DATA_UPDATED,
    events: data.events,
    eventsTotal: data.eventsTotal
})

export const eventsFetchErrorAC = (what) => ({
    type: EVENTS_FETCH_ERROR,
    what: what
})




export function fetchMyEvents() {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/users/events', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(updatedEventsData(json.data))
                    } else {
                        dispatch(eventsFetchErrorAC('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export default eventsReducer;