import React from 'react'
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@mui/system';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import SellIcon from '@mui/icons-material/Sell';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SettingsIcon from '@mui/icons-material/Settings';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BadgeIcon from '@mui/icons-material/Badge';
import store from '../../redux/store';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    iconButton: {
        color: '#6dc353',
    },
    menu: {
        background: '#202938',
        color: '#6dc353',
        fontFamily: 'Jura, Arial',
    },
    menuIcon: {
        color: '#6dc353',
    }
}));

const NavigationMenu = (props) => {
    const classes = useStyles();


    return (
        <>
            <Box
                display={{ xs: 'none', md: 'block' }}
                role="presentation"
                className={classes.menu}
                sx={{
                    borderRadius: '4px',
                }}
            >
                <List>
                    <ListItem key='Мои объявления'
                        component={Link}
                        to="/personal/mylistings"
                        style={{
                            textDecoration: "none",
                            color: "#6dc353"
                        }}
                        disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <SellIcon className={classes.menuIcon} />
                            </ListItemIcon>
                            <ListItemText primary='Мои объявления' disableTypography />
                        </ListItemButton>
                    </ListItem>

                    <ListItem key='Избранное'
                        component={Link}
                        to="/personal/favorites"
                        style={{
                            textDecoration: "none",
                            color: "#6dc353"
                        }}
                        disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <ShoppingCartIcon className={classes.menuIcon} />
                            </ListItemIcon>
                            <ListItemText primary='Избранное' disableTypography />
                        </ListItemButton>
                    </ListItem>

                    <ListItem key='Подписки' component={Link}
                        to="/personal/subscriptions"
                        style={{
                            textDecoration: "none",
                            color: "#6dc353"
                        }}
                        disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <NotificationsActiveIcon className={classes.menuIcon} />
                            </ListItemIcon>
                            <ListItemText primary='Подписки' disableTypography />
                        </ListItemButton>
                    </ListItem>

                    <ListItem key='Купленное' component={Link}
                        to="/personal/mypurchases"
                        style={{
                            textDecoration: "none",
                            color: "#6dc353"
                        }}
                        disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <ShoppingBagIcon className={classes.menuIcon} />
                            </ListItemIcon>
                            <ListItemText primary='Купленное' disableTypography />
                        </ListItemButton>
                    </ListItem>

                    <ListItem key='Проданное' component={Link}
                        to="/personal/mysales"
                        style={{
                            textDecoration: "none",
                            color: "#6dc353"
                        }}
                        disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <PointOfSaleIcon className={classes.menuIcon} />
                            </ListItemIcon>
                            <ListItemText primary='Проданное' disableTypography />
                        </ListItemButton>
                    </ListItem>

                    <ListItem key='Отзывы' component={Link}
                        to="/personal/feedback"
                        style={{
                            textDecoration: "none",
                            color: "#6dc353"
                        }}
                        disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <ThumbUpIcon className={classes.menuIcon} />
                            </ListItemIcon>
                            <ListItemText primary='Отзывы' disableTypography />
                        </ListItemButton>
                    </ListItem>

                    <ListItem key='Моя страница' component={Link}
                        to={`/profile/${store.getState().userState.login}`}
                        style={{
                            textDecoration: "none",
                            color: "#6dc353"
                        }}
                        disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <BadgeIcon className={classes.menuIcon} />
                            </ListItemIcon>
                            <ListItemText primary='Моя страница' disableTypography />
                        </ListItemButton>
                    </ListItem>

                </List>

                <Divider />

                <List>

                    <ListItem key='Настройки' component={Link}
                        to="/personal/settings"
                        style={{
                            textDecoration: "none",
                            color: "#6dc353"
                        }}
                        disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <SettingsIcon className={classes.menuIcon} />
                            </ListItemIcon>
                            <ListItemText primary='Настройки' disableTypography />
                        </ListItemButton>
                    </ListItem>

                </List>

            </Box>
        </>
    )
}

export default NavigationMenu;