import { React, useEffect } from 'react';
import ProfileHeaderContainer from '../../components/ProfileHeader';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import MyFeedbackContainer from '../../components/profile/MyFeedback';
import { Grid } from '@material-ui/core';
import { MyFeedbackFromContainer } from '../../components/feedback/FeedbackFrom';
import MetaTags from 'react-meta-tags';
import { fbSetFetchingAC, fetchMyFeedback } from '../../redux/my-feedback-reducer';
import Spinner from '../../components/Spinner';
import { connect } from "react-redux";
import Unauthorized from '../../components/entities/Unauthed';
import { showLoginDialogAC } from "../../redux/login-reg-reducer";

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
        marginTop: 10,
        marginBottom: 10,
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        spacing: 2,
    }
}));

const Feedback = (props) => {
    const classes = useStyles();
    useEffect(() => {
        props.sync();
    }, []);

    if (!props.isLoggedIn) {
        props.showLoginDialog();
        return (
            <>
                <Unauthorized authCb={props.showLoginDialog} />
            </>
        )
    }

    if (props.isFetching) {
        return <Spinner />
    }

    return (
        <>
            <MetaTags>
                <title>Мои отзывы - naTextolite</title>
            </MetaTags>

            <Breadcrumbs aria-label="breadcrumb">
                <Link color="textPrimary" href="/" className={classes.link}>
                    <HomeIcon className={classes.icon} />
                    naTeXtolite
                </Link>
                <Link color="textPrimary" href="/personal" className={classes.link}>
                    <AccountBoxIcon className={classes.icon} />
                    Личный кабинет
                </Link>
                <Typography color="textPrimary" className={classes.link}>
                    <ThumbUpIcon className={classes.icon} />
                    Мои отзывы
                </Typography>
            </Breadcrumbs>

            <h1>Мои отзывы</h1>

            <ProfileHeaderContainer />

            <Grid container direction="row" justifyContent="center" alignItems="stretch" alignContent='center'>
                <Grid item xs={12} sm={6}>
                    <MyFeedbackFromContainer fromRole="buyer" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MyFeedbackFromContainer fromRole="seller" />
                </Grid>
            </Grid>

            <MyFeedbackContainer
                pageSize={10}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isFetching: state.myFavoritesPage.isFetching,
        isLoggedIn: state.loginReg.isLoggedIn,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sync: () => {
            dispatch(fbSetFetchingAC(true));
            dispatch(fetchMyFeedback());
        },
        showLoginDialog: () => {
            dispatch(showLoginDialogAC());
        },
    }
}

const FeedbackPageContainer = connect(mapStateToProps, mapDispatchToProps)(Feedback);
export default FeedbackPageContainer;