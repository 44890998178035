import { React } from 'react'
import { connect } from "react-redux";
import Paginated from '../entities/Paginated';
import FavoriteListingPreviewContainer from './FavoriteListingPreview';

const MyFavorites = (props) => {
    const renderListingRow = (index, value) => {
        return <FavoriteListingPreviewContainer
            submitter={value.login}
            city={value.city}
            region={value.region}
            id={value.id}
            imgUrl={value.cover}
            title={value.summary}
            date={value.created_at}
            price={value.price}
            stateRu={value.stateRu}
            state={value.state}
            noteExist={value.note_exist}
            noteText={value.note_text}
            noteUpdated={value.note_updated}
            odd={index % 2}
            key={index}
        />
    }

    return (
        <>
            <Paginated
                pagesize={props.pageSize}
                renderItem={renderListingRow}
                items={props.favorites}
            />
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching: state.myFavoritesPage.isFetching,
        favorites: state.myFavoritesPage.filteredFavorites,
        pageSize: ownProps.pageSize ? ownProps.pageSize : 10,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

const MyFavoritesContainer = connect(mapStateToProps, mapDispatchToProps)(MyFavorites);
export default MyFavoritesContainer;