import * as React from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

/*
Props:
    formLabel - top-level label
    emailChecked - initial state
    tgChecked - initial state
    onEmailChanged - callback 
    onTgChanged - callback 
*/

const NotificationsSelector = (props) => {
    const [mailChecked, setMailChecked] = React.useState(props.emailChecked);
    const [tgChecked, setTgChecked] = React.useState(props.tgChecked);

    React.useEffect( () => {
        props.onEmailChanged(mailChecked);
    }, [mailChecked, props]);

    React.useEffect( () => {
        props.onTgChanged(tgChecked);
    }, [tgChecked, props]);

    const handleMailToggle = () => {
        setMailChecked(!mailChecked);
    };

    const handleTgToggle = () => {
        setTgChecked(!tgChecked);
    };

    return (
        <>
            <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend" sx={{ fontFamily: 'Jura', color: 'black', fontWeight: 700 }}>{props.formLabel}</FormLabel>
                <FormGroup>
                    <FormControlLabel disableTypography
                        control={
                            <Checkbox
                                checked={mailChecked}
                                onChange={handleMailToggle}
                                sx={{
                                    '&.Mui-checked': {
                                        color: '#6dc353',
                                        backgroundColor: 'white',
                                    },
                                }} />
                        }
                        label="Email"
                        sx={{ fontFamily: 'Jura', color: 'black', fontWeight: 600 }}
                    />

                    <FormControlLabel disableTypography
                        control={
                            <Checkbox
                                checked={tgChecked}
                                onChange={handleTgToggle}
                                sx={{
                                    '&.Mui-checked': {
                                        color: '#6dc353',
                                        backgroundColor: 'white',
                                    },
                                }} />
                        }
                        label="Telegram"
                        sx={{ fontFamily: 'Jura', color: 'black', fontWeight: 600 }}
                    />
                </FormGroup>
            </FormControl>
        </>
    );
}

export default NotificationsSelector;