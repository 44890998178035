import fetch from "cross-fetch";

const LISTING_SET_FETCHING = 'LISTING_SET_FETCHING';
const LISTING_DATA_UPD = 'LISTING_DATA_UPD';
const LISTING_SHOW_CONTACTS = 'LISTING_SHOW_CONTACTS';
const LISTING_ADDED_TO_FAV = 'LISTING_ADDED_TO_FAV';
const LISTING_REMOVED_FROM_FAV = 'LISTING_REMOVED_FROM_FAV';
const LISTING_SET_ERR = 'LISTING_SET_ERR';
const LISTING_FORCE_RESIZE_PHOTOS = 'LISTING_FORCE_RESIZE_PHOTOS';
const LISTING_DOESNT_EXIST = 'LISTING_DOESNT_EXIST';

const LOG_REG_LOGOUT = 'LOG_REG_LOGOUT';

let initialState = {
    isFetching: false,
    gotData: false,
    listingDoesntExist: false,

    listingId: '',
    showContacts: false,

    // submitter data
    submitterId: '',
    submitterLogin: '',
    submitterCity: '',
    submitterCityType: "",
    submitterRegion: "",
    submitterRatingAbs: 0,
    submitterRatingRel: 0,
    submitterWorksSince: "",

    // submitter contacts
    submitterPhone: '',
    submitterEmail: '',
    submitterWhatsAppPhone: '',
    submitterTgUserName: '',

    // submitter settings
    showCompatibilityBlock: true,
    pickupAdress: "",
    deliveryOptions: "",
    payOptions: '',
    warrantyOptions: '',

    //listing metadata
    pns: [],
    type: "",
    typeHumanReadable: '',
    vendor: '',
    model: '',
    listingState: '',

    // listing data
    summary: '',
    price: '',
    date: '',
    desc: '',
    bareDesc: '',

    // image data 
    imgs: [],

    // fav
    inFavorite: false,

    // errors handling
    errorCondition: false,
    errWhat: '',

    // note-related
    noteExist: false,
    noteText: '',
    noteUpdatedAt: '',
};

const listingReducer = (state = initialState, action) => {
    switch (action.type) {
        case LISTING_SET_FETCHING:
            return Object.assign({}, state, {
                isFetching: action.isFetching,
            });

        case LISTING_DATA_UPD:
            return Object.assign({}, state, {
                isFetching: false,
                showContacts: false,
                gotData: true,
                listingDoesntExist: false,
                listingId: action.data.id,

                submitterId: action.data.submitterId,
                submitterLogin: action.data.submitterLogin,
                submitterCity: action.data.submitterCity,
                submitterCityType: action.data.submitterCityType,
                submitterRegion: action.data.submitterRegion,
                submitterRatingAbs: action.data.submitterRatingAbs,
                submitterRatingRel: action.data.submitterRatingRel,
                submitterWorksSince: action.data.submitterWorksSince,
                submitterPhone: action.data.submitterPhone,
                submitterEmail: action.data.submitterEmail,
                submitterWhatsAppPhone: action.data.submitterWhatsAppPhone,
                submitterTgUserName: action.data.submitterTgUserName,
                showCompatibilityBlock: action.data.showCompatibilityBlock,

                pickupAdress: action.data.pickupAdress,
                deliveryOptions: action.data.deliveryOptions,
                payOptions: action.data.payOptions,
                warrantyOptions: action.data.warrantyOptions,

                pns: action.data.pns,
                type: action.data.hwType,
                typeHumanReadable: action.data.hwTypeHumanReadable,
                vendor: action.data.vendor,
                model: action.data.model,
                listingState: action.data.listingState,

                summary: action.data.summary,
                price: action.data.price,
                date: action.data.date,
                desc: action.data.desc + '\n' + action.data.descriptionAddon,
                bareDesc: action.data.desc,

                imgs: action.data.photos.map(x => { x.originalHeight = 450; return x; }),
                inFavorite: action.favorite,

                noteExist: action.note.note_exist,
                noteText: action.note.note_text,
                noteUpdatedAt: action.note.note_updated,
            });


        case LISTING_SHOW_CONTACTS:
            return Object.assign({}, state, {
                showContacts: action.show,
            });

        case LISTING_ADDED_TO_FAV:
            return Object.assign({}, state, {
                inFavorite: true,
            });

        case LISTING_REMOVED_FROM_FAV:
            return Object.assign({}, state, {
                inFavorite: false,
            });

        case LISTING_SET_ERR:
            return Object.assign({}, state, {
                errorCondition: true,
                errWhat: action.what,
                isFetching: false,
            });

        case LISTING_FORCE_RESIZE_PHOTOS:
            return Object.assign({}, state, {
                imgs: action.fullScreen ?
                    state.imgs.map(x => { delete x.originalHeight; return x; }) :
                    state.imgs.map(x => { x.originalHeight = 450; return x; }),
            });



        case LOG_REG_LOGOUT:
            return Object.assign({}, state, initialState);


        case LISTING_DOESNT_EXIST:
            return Object.assign({}, state, {
                isFetching: false,
                listingDoesntExist: true,
            });




        default:
            return state;
    }
};

export const setFetchingAC = (isFetching) => ({
    type: LISTING_SET_FETCHING,
    isFetching: isFetching
})

export const updateListingData = (data) => ({
    type: LISTING_DATA_UPD,
    data: data.listing,
    favorite: data.favorite,
    note: data.note,
})

export const setShowContactsAC = (show) => ({
    type: LISTING_SHOW_CONTACTS,
    show: show,
})

export const listingAddedToFavAC = () => ({
    type: LISTING_ADDED_TO_FAV,
})

export const listingRemovedFromFavAC = () => ({
    type: LISTING_REMOVED_FROM_FAV,
})

export const listingSetError = (what) => ({
    type: LISTING_SET_ERR,
    what: what,
})

export const listingGalleryFullScreened = (fullScreen) => ({
    type: LISTING_FORCE_RESIZE_PHOTOS,
    fullScreen: fullScreen,
})

export const listingDoesntExistAC = () => ({
    type: LISTING_DOESNT_EXIST,
})



export function fetchListingData(id) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + `/api/listings/get/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.data.listing.submitterId === null) {
                        dispatch(listingDoesntExistAC())
                    }
                    else {
                        dispatch(updateListingData(json.data))
                    }
                }
            )
    }
}

export function addToFav(listingId) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/tofav', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                id: listingId,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(listingAddedToFavAC());
                    }
                }
            )
    }
}

export function rmFromFav(listingId) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/fromfav', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                id: listingId,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(listingRemovedFromFavAC());
                    }
                }
            )
    }
}

export function activateCurrent(id) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/activate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                ids: [
                    id,
                ],
                silent: true,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(fetchListingData(id));
                    } else {
                        dispatch(listingSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function deactivateCurrent(id) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/deactivate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                ids: [
                    id,
                ],
                silent: true,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(fetchListingData(id));
                    } else {
                        dispatch(listingSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function removeCurrent(id) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/remove', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                ids: [
                    id,
                ],
                silent: true,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(fetchListingData(id));
                    } else {
                        dispatch(listingSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function createListingNote(id, newText) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/addnote', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                listing_id: id,
                note_text: newText,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(fetchListingData(id));
                    } else {
                        dispatch(listingSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function updateListingNote(id, newText) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/updatenote', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                listing_id: id,
                note_text: newText,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(fetchListingData(id));
                    } else {
                        dispatch(listingSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function removeListingNote(id) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/removenote', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                listing_id: id,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(fetchListingData(id));
                    } else {
                        dispatch(listingSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function sealTheDeal(sellerId, listingId) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/createdeal', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                listing_id: listingId,
                submitter_id: sellerId,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(fetchListingData(listingId));
                    } else {
                        dispatch(listingSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function updatePrice(id, oldPrice, newPrice, summary) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/updateprice', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                listing_id: id,
                old_price: oldPrice,
                new_price: newPrice,
                summary: summary,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(fetchListingData(id));
                    } else {
                        dispatch(listingSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function updateListingDesc(id, newText) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/updatedesc', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                listing_id: id,
                desc: newText,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(fetchListingData(id));
                    } else {
                        dispatch(listingSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}


export default listingReducer;