import { React, useEffect } from 'react'
import ProfileHeaderContainer from '../../components/ProfileHeader';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { connect } from 'react-redux';
import { purchaseSetFetchingAC, fetchExactPurchase } from '../../redux/exact-purchase-reducer';
import MyPurchasePreviewContainer from '../../components/trading/MyPurchasePreview';
import MetaTags from 'react-meta-tags';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import Spinner from '../../components/Spinner';
import Unauthorized from '../../components/entities/Unauthed';
import { showLoginDialogAC } from "../../redux/login-reg-reducer";

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
        marginTop: 10,
        marginBottom: 10,
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        spacing: 2,
    }
}));


const ExactPurchase = ({ sync, match, ...props }) => {
    useEffect(() => {
        if (/*requestFromUrl === "" &&*/
            match.params.reqId !== '' &&
            match.params.reqId !== undefined) {
            sync(match.params.reqId);
        }
    }, [match.params.reqId]);

    const classes = useStyles();

    if (!props.isLoggedIn) {
        props.showLoginDialog();
        return (
            <>
                <Unauthorized authCb={props.showLoginDialog} />
            </>
        )
    }

    if (props.isFetching) {
        return <Spinner />
    }

    return (
        <>
            <MetaTags>
                <title>Моя покупка - naTextolite</title>
            </MetaTags>

            <Breadcrumbs aria-label="breadcrumb">
                <Link color="textPrimary" href="/" className={classes.link}>
                    <HomeIcon className={classes.icon} />
                    naTeXtolite
                </Link>
                <Link color="textPrimary" href="/personal" className={classes.link}>
                    <AccountBoxIcon className={classes.icon} />
                    Личный кабинет
                </Link>
                <Link color="textPrimary" href="/personal/mypurchases" className={classes.link}>
                    <ShoppingBagIcon className={classes.icon} />
                    Мои покупки
                </Link>
                <Typography color="textPrimary" className={classes.link}>
                    Детали сделки
                </Typography>
            </Breadcrumbs>

            <ProfileHeaderContainer />

            <MyPurchasePreviewContainer
                id={props.id}
                listingId={props.listing_id}
                sellerId={props.seller_id}
                buyerConfirm={props.buyer_confirm}
                sellerConfirm={props.seller_confirm}
                sealed={props.sealed}
                discarded={props.discarded}
                discardedBy={props.discarded_by}
                sellerLogin={props.login}
                sellerEmail={props.email}
                sellerCityType={props.city_type}
                sellerCity={props.city}
                sellerRegion={props.region}
                sellerPhone={props.phone}
                sellerWa={props.whatsapp}
                sellerTg={props.telegram}
                sellerPickUpAddr={props.pickup_addr}
                price={props.price}
                title={props.summary}
                type={props.type}
                typeRu={props.text}
                vendor={props.vendor}
                model={props.model}
                imgUrl={props.cover}
                sellerFbType={props.seller_fb_type}
                sellerFbText={props.seller_fb}
                sellerFbDate={props.seller_fb_ts}
                myFbType={props.buyer_fb_type}
                myFbText={props.buyer_fb}
                myFbDate={props.buyer_fb_ts}
                feedbackAllowed={props.feedback_allowed}
                exclusiveMode
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isFetching: state.exactPurchase.isFetching,
        id: state.exactPurchase.purchase.id,
        listing_id: state.exactPurchase.purchase.listing_id,
        seller_id: state.exactPurchase.purchase.seller_id,
        buyer_confirm: state.exactPurchase.purchase.buyer_confirm,
        seller_confirm: state.exactPurchase.purchase.seller_confirm,
        sealed: state.exactPurchase.purchase.sealed,
        discarded: state.exactPurchase.purchase.discarded,
        discarded_by: state.exactPurchase.purchase.discarded_by,
        login: state.exactPurchase.purchase.login,
        email: state.exactPurchase.purchase.email,
        city_type: state.exactPurchase.purchase.city_type,
        city: state.exactPurchase.purchase.city,
        region: state.exactPurchase.purchase.region,
        phone: state.exactPurchase.purchase.phone,
        whatsapp: state.exactPurchase.purchase.whatsapp,
        telegram: state.exactPurchase.purchase.telegram,
        pickup_addr: state.exactPurchase.purchase.pickup_addr,
        price: state.exactPurchase.purchase.price,
        summary: state.exactPurchase.purchase.summary,
        type: state.exactPurchase.purchase.type,
        text: state.exactPurchase.purchase.text,
        vendor: state.exactPurchase.purchase.vendor,
        model: state.exactPurchase.purchase.model,
        cover: state.exactPurchase.purchase.cover,
        seller_fb_type: state.exactPurchase.purchase.seller_fb_type,
        seller_fb: state.exactPurchase.purchase.seller_fb,
        seller_fb_ts: state.exactPurchase.purchase.seller_fb_ts,
        buyer_fb_type: state.exactPurchase.purchase.buyer_fb_type,
        buyer_fb: state.exactPurchase.purchase.buyer_fb,
        buyer_fb_ts: state.exactPurchase.purchase.buyer_fb_ts,
        feedback_allowed: state.exactPurchase.purchase.feedback_allowed,
        isLoggedIn: state.loginReg.isLoggedIn,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sync: (id) => {
            dispatch(purchaseSetFetchingAC(true));
            dispatch(fetchExactPurchase(id));
        },
        showLoginDialog: () => {
            dispatch(showLoginDialogAC());
        },
    }
}

const ExactPurchaseContainer = connect(mapStateToProps, mapDispatchToProps)(ExactPurchase);
export default ExactPurchaseContainer;