import fetch from "cross-fetch";


const FAV_SET_FETCHING = 'FAV_SET_FETCHING';
const FAV_DATA_UPDATED = 'FAV_DATA_UPDATED';
const FAV_STATE_FILTER_CHANGED = 'FAV_FILTER_CHANGED';
const FAV_SET_ERR = 'FAV_SET_ERR';

const LOG_REG_LOGOUT = 'LOG_REG_LOGOUT';

let initialState = {
    isFetching: false,

    //listings data:...
    listingsTotal: 0,
    favorites: [
        /* e.g.:
        {
            "id": "14",
            "listing_id": "14",
            "user_id": "13",
            "price": "3200",
            "created_at": "2024-04-09 03:14:26",
            "summary": "\u041c\u0430\u0442\u0435\u0440\u0438\u043d\u0441\u043a\u0430\u044f \u043f\u043b\u0430\u0442\u0430 \u0434\u043b\u044f \u0422\u0412 SAMSUNG UE40F6400AK BN41-01958A, BN94-06555H",
            "state": "active",
            "stateRu": "\u0410\u043a\u0442\u0438\u0432\u043d\u043e",
            "login": "Failure",
            "city": "\u0412\u043b\u0430\u0434\u0438\u0432\u043e\u0441\u0442\u043e\u043a",
            "region": "\u041f\u0440\u0438\u043c\u043e\u0440\u0441\u043a\u0438\u0439 \u043a\u0440\u0430\u0439",
            "cover": "https:\/\/storage.yandexcloud.net\/ntx-public-staging\/7ac66481-caf7-4885-b23f-6a80f525034a\/0_thm.jpeg",
            "note_exist": true,
            "note_text": "\u042d\u0442\u043e \u043d\u0435\u0440\u0435\u0430\u043b\u044c\u043d\u043e \u0441\u0435\u0440\u044c\u0435\u0437\u043d\u044b\u0439 \u043d\u043e\u0442 \u043a \u044d\u0442\u043e\u0439 \u043e\u0431\u044a\u044f\u0432\u0435",
            "note_updated": "2024-05-07 04:50:01"
        }, ... */
    ],

    stateFilter: 'any',
    filteredFavorites: [],

    errorCondition: false,
    errWhat: '',
};

const filterByState = (stateFilter, listings) => {
    let result = listings;
    if (stateFilter !== 'any') {
        result = result.filter(function (item) { return item.state === stateFilter; });
    }
    return result;
}

const myFavoritesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FAV_SET_FETCHING:
            return Object.assign({}, state, {
                isFetching: action.isFetching,
            });

        case FAV_DATA_UPDATED:
            return Object.assign({}, state, {
                isFetching: false,
                favorites: action.listings,
                listingsTotal: action.listingsTotal,
                filteredFavorites: filterByState('any', action.listings),
                stateFilter: 'any',
            });

        case FAV_SET_ERR:
            return Object.assign({}, state, {
                errorCondition: true,
                errWhat: action.what,
                isFetching: false,
            });

        case FAV_STATE_FILTER_CHANGED:
            return Object.assign({}, state, {
                stateFilter: action.stateFilter,
                filteredFavorites: filterByState(action.stateFilter, state.favorites),
            });




        case LOG_REG_LOGOUT:
            return Object.assign({}, state, initialState);

        default:
            return state;
    }
};

export const favSetFetchingAC = (isFetching) => ({
    type: FAV_SET_FETCHING,
    isFetching: isFetching
})

export const updatedFavData = (data) => ({
    type: FAV_DATA_UPDATED,
    listingsTotal: data.listingsTotal,
    listings: data.listings,
})

export const favSetError = (what) => ({
    type: FAV_SET_ERR,
    what: what,
})

export const favStateFilterChanged = (stateFilter) => ({
    type: FAV_STATE_FILTER_CHANGED,
    stateFilter: stateFilter,
})


export function fetchFavorites() {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/myfavorites', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(updatedFavData(json.data));
                    } else {
                        dispatch(favSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function rmFromFavAndFetchRemaining(listingId) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/fromfav', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                id: listingId,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(fetchFavorites());
                    }
                }
            )
    }
}

export function favCreateListingNote(id, newText) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/addnote', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                listing_id: id,
                note_text: newText,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(fetchFavorites());
                    } else {
                        //dispatch(listingSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function favUpdateListingNote(id, newText) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/updatenote', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                listing_id: id,
                note_text: newText,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(fetchFavorites());
                    } else {
                        //dispatch(listingSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function favRemoveListingNote(id) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/removenote', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                listing_id: id,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(fetchFavorites());
                    } else {
                        //dispatch(listingSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export default myFavoritesReducer;