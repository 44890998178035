import fetch from 'cross-fetch';

const USAGES_REQUEST_USAGES = 'REQUEST_USAGES';
const USAGES_RECEIVE_USAGES = 'RECEIVE_USAGES';
const USAGES_SET_FETCHING = 'USAGES_SET_FETCHING';

const USAGES_UPDATE_SEARCH_PN_TEXT = 'UPDATE_SEARCH_PN_TEXT';

const USAGES_SEARCH_TYPE_PN = 'SEARCH_TYPE_PN';

let initialState = {
    searchPnText: '',
    searchType: '',
    searchedItem: '',
    isFetching: true,
    usages: {
        empty: true,
        usages: [
        ],
    }
};

const usagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case USAGES_UPDATE_SEARCH_PN_TEXT:
            return Object.assign({}, state, {
                searchPnText: action.newText
            });

        case USAGES_REQUEST_USAGES:
            return Object.assign({}, state, {
                isFetching: true,
                searchedItem: state.searchPnText.split("/").join("____"),
                searchType: USAGES_SEARCH_TYPE_PN,
                searchPnText: '',
            });

        case USAGES_RECEIVE_USAGES:
            return Object.assign({}, state, {
                isFetching: false,
                usages: {
                    empty: action.usages.empty,
                    usages: action.usages.usages,
                }
            });

        case USAGES_SET_FETCHING:
            return Object.assign({}, state, {
                isFetching: action.isFetching,
            });

        default:
            return state;
    }
};

export const requestUsagesAC = () => ({ type: USAGES_REQUEST_USAGES })

export const receiveUsagesAC = (json) => ({
    type: USAGES_RECEIVE_USAGES,
    usages: json.data
})

export const usagesSetFetchingAC = (fetching) => ({
    type: USAGES_SET_FETCHING,
    isFetching: fetching
})

export function fetchUsages(item) {
    let what = item.split("/").join("____");
    return function (dispatch) {
        dispatch(requestUsagesAC());
        return fetch(process.env.REACT_APP_ORIGIN + `/api/tech/tv/usages/${what}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                json => dispatch(receiveUsagesAC(json))
            )
    }
}

export const updateSearchPnTextActionCreator = (text) =>
    ({ type: USAGES_UPDATE_SEARCH_PN_TEXT, newText: text })

export default usagesReducer;
