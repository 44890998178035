import {
    fetchComposition,
    updateSearchModelTextActionCreator
} from "../redux/add-composition-reducer";
import { connect } from "react-redux";
import SearchForm from "./search/SearchForm";

const mapStateToProps = (state) => {
    return {
        currentSearchText: state.addCompositionPage.searchModelText,
        placeholder: 'UE40F6400AK',
        searchType: 'Полная модель ТВ',
        stype: 'check',
        redirectToResults: false,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSearch: (item) => {
            dispatch(fetchComposition(item));
        },
        updateSearchText: (newText) => {
            let action = updateSearchModelTextActionCreator(newText);
            dispatch(action);
        },
    }
}

const CompositionCheckerContainer = connect(mapStateToProps, mapDispatchToProps)(SearchForm);
export default CompositionCheckerContainer;