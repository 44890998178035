import * as React from 'react';
import TelegramIcon from '@mui/icons-material/Telegram';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@material-ui/core';
import HelperCard from '../../components/settings/HelperCard';
import StyledButton from '../entities/StyledButton';
import StyledTextField from '../entities/StyledTextField';

import { tgNameUpdateAC } from '../../redux/my-settings-reducer';
import { connect } from "react-redux";
import { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';

const BindTelegramBlock = (props) => {
    const [name, setName] = useState(props.tgName);
    const [err, setErr] = React.useState('');
    const [open, setOpen] = React.useState(false);

    const nameChanged = (e) => {
        console.log(e.target.value);
        setName(e.target.value);
    };

    async function post(descData) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/users/setsettings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(descData)
        })
            .then(data => data.json())
    }

    const sendTgName = async () => {
        console.log("Sending name");
        console.log(name);

        const response = await post({
            id: props.id,
            telegram: name,
        });

        setErr(response.statusCode === 200 ? "Сделано!" : "Что-то пошло не так. Извините и попробуйте еще раз позже пожалуйста");
        setOpen(true);
    }

    const submitName = () => {
        props.updateName(name);
        sendTgName();
    };

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <TelegramIcon sx={{ marginRight: 1 }} />
                    Telegram
                </AccordionSummary>

                <AccordionDetails>
                    Ваше имя пользователя в Telegram:
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                        alignContent='center'
                    >
                        <Grid item >
                            <StyledTextField
                                type="text"
                                icon={<TelegramIcon />}
                                value={name}
                                onChange={nameChanged}
                            />
                        </Grid>

                        <Grid item >
                            <StyledButton
                                icon={<TelegramIcon />}
                                label="Сохранить"
                                onClick={submitName}
                            />
                        </Grid>
                    </Grid>

                    <HelperCard helperText={
                        "Рекомендуется заполнить это поле, хотя это необязательно. Зная имя пользователя Telegram, мы сможем автоматически подставлять в Ваши объявления ссылку, нажимая на которую, покупатель, на своем устройстве, перейдет в Telegram-чат с Вами"
                    } />

                    <Snackbar
                        open={open}
                        autoHideDuration={10000}
                        onClose={handleSnackBarClose}
                        message={err}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    />
                </AccordionDetails>
            </Accordion>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        id: state.userState.userId,
        tgName: state.settings.tgUserName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateName: (name) => {
            dispatch(tgNameUpdateAC(name));
        },
    }
}

const BindTelegramBlockContainer = connect(mapStateToProps, mapDispatchToProps)(BindTelegramBlock);
export default BindTelegramBlockContainer;