import * as React from 'react';
import { connect } from "react-redux";
import { Box, Typography } from '@material-ui/core';
import NewListingPreviewContainer from '../../components/new-listing/Preview';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import ScrollToTop from '../ScrollToTop';
import { useRef } from 'react';

function CircularProgressWithLabel(props) {


    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props}
                sx={{
                    color: '#6dc353',
                }}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
};


const PreviewQueue = (props) => {
    const [progress, setProgress] = React.useState(0);
    const myRef = useRef(null);

    const onConfirm = (idx) => {
        setProgress((100 / props.listings.length) * (idx + 1));
        myRef.current.scrollIntoView();
    }

    const render = () => {
        for (const [index, value] of props.listings.entries()) {
            if (!value.previewConfirmed) {
                return (
                    <>
                        {!props.isPosting && <CircularProgressWithLabel value={progress} />}
                        {!props.isPosting && <h3 ref={myRef}>{value.text.split("/", 1) + ' ' + value.partnumbers}</h3>}
                        <NewListingPreviewContainer
                            multi
                            index={index}
                            onConfirm={onConfirm}
                        />
                        <ScrollToTop />
                    </>
                )
            }
        }
    }

    return (
        <>
            {render()}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        listings: state.addPack.listingsData,
        isPosting: state.addPack.isPosting,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

const PreviewQueueContainer = connect(mapStateToProps, mapDispatchToProps)(PreviewQueue);
export default PreviewQueueContainer;