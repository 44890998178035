import React from 'react'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import { Container, Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import StyledButton from "./StyledButton";
import { Box } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        margin: 5,
        background: '#202938',
    },
    card: {
        padding: '0px',
        paddingBottom: '0px !important',
    },
    title: {
        fontSize: 17,
        fontWeight: 500,
        color: '#6dc353',
        alignContent: 'center',
    },
    iconButton: {
        color: '#6dc353',
        padding: '3px',
    },
}));

const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}));

/*
Stub to be shown instead of protected content when user is not authorized
Props:
1. authCb - callback to be called when auth button is being pressed
*/

const Unauthorized = (props) => {
    const classes = useStyles();

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={10} sm={10}>
                    <Card className={classes.root} variant="outlined">
                        <CardContent className={classes.card}>
                            <StyledContainer maxWidth="md">
                                <FingerprintIcon
                                    className={classes.iconButton}
                                    fontSize={'large'} />
                                <Typography className={classes.title} color="textSecondary" align={'center'} gutterBottom>
                                    Это действие доступно только зарегистрированным пользователям!
                                </Typography>
                                <Typography className={classes.title} color="textSecondary" align={'center'} gutterBottom>
                                    Пожалуйста, войдите или зарегистрируйтесь
                                </Typography>

                                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                    <StyledButton
                                        label={"Вход/Регистрация"}
                                        type={"button"}
                                        onClick={props.authCb}
                                    />
                                </Box>

                            </StyledContainer>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}


export default Unauthorized;