import React from 'react'
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@mui/icons-material/Person';
import { styled } from "@mui/material/styles";
import { Container } from "@material-ui/core";
import Avatar from '@mui/material/Avatar';
import { Box } from '@mui/material';

const useStyles = makeStyles({
    root: {
        background: '#202938',
        padding: 5,
    },
    title: {
        fontSize: 17,
        fontWeight: 500,
        color: '#6dc353',
    },
    icon: {
        color: '#6dc353',
        margin: 10,
    },
});

const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 0,
    paddingTop: 0,
}));

const ColumnContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 0,
    paddingLeft: 5,
}));

const ProfileCard = (props) => {
    const classes = useStyles();

    return (
        <>
            <StyledContainer maxWidth="md">
                <Box display={{ xs: 'none', sm: 'block' }}>
                    <Avatar variant="rounded" sx={{ width: 75, height: 75, bgcolor: '#253443' }} className={classes.icon}>
                        <PersonIcon className={classes.icon} fontSize='large' />
                    </Avatar>
                </Box>

                <Box display={{ xs: 'flex', sm: 'none' }}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems='center'
                >
                    <PersonIcon className={classes.icon} fontSize='large' />
                </Box>

                <ColumnContainer>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {props.login}
                    </Typography>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {props.region}
                    </Typography>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {props.city}
                    </Typography>
                </ColumnContainer>
            </StyledContainer>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        login: state.userState.login,
        region: state.userState.region,
        city: state.userState.city,
    }
}

const ProfileCardContainer = connect(mapStateToProps, {})(ProfileCard);
export default ProfileCardContainer;