import { React, useEffect } from 'react';
import ProfileHeaderContainer from '../../components/ProfileHeader';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import { makeStyles } from '@material-ui/core/styles';
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MyFavoritesContainer from '../../components/listings/MyFavorites';
import { fetchFavorites, favSetFetchingAC, favStateFilterChanged } from '../../redux/my-favorites';
import Spinner from '../../components/Spinner';
import { connect } from "react-redux";
import MetaTags from 'react-meta-tags';
import { Container, Stack } from '@mui/material';
import { Grid, Typography } from '@material-ui/core';
import Box from '@mui/material/Box';
import StyledSelect from '../../components/entities/StyledSelect';
import Unauthorized from '../../components/entities/Unauthed';
import { showLoginDialogAC } from "../../redux/login-reg-reducer";

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
        marginTop: 10,
        marginBottom: 10,
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        spacing: 2,
    }
}));

const stateFilter = [
    { text: 'Активные', filter: 'active' },
    { text: 'Устаревшие', filter: 'expired' },
    { text: 'Удаленные', filter: 'removed' },
    { text: 'Все', filter: 'any' },
];

const FavoritePage = (props) => {
    const classes = useStyles();
    useEffect(() => {
        props.sync();
    }, []);

    if (!props.isLoggedIn) {
        props.showLoginDialog();
        return (
            <>
                <Unauthorized authCb={props.showLoginDialog} />
            </>
        )
    }


    const renderContent = () => {
        if (props.isFetching) {
            return <Spinner />
        }

        if (props.listingsTotal === 0) {
            return (
                <>
                    <h2>¯\_(ツ)_/¯</h2>
                    <h2>Вы пока не добавили ничего в избранное</h2>
                </>
            )
        }

        return <MyFavoritesContainer pageSize={10} />
    }

    return (
        <>
            <MetaTags>
                <title>Избранное - naTextolite</title>
            </MetaTags>

            <Breadcrumbs aria-label="breadcrumb">
                <Link color="textPrimary" href="/" className={classes.link}>
                    <HomeIcon className={classes.icon} />
                    naTeXtolite
                </Link>
                <Link color="textPrimary" href="/personal" className={classes.link}>
                    <AccountBoxIcon className={classes.icon} />
                    Личный кабинет
                </Link>
                <Typography color="textPrimary" className={classes.link}>
                    <ShoppingCartIcon className={classes.icon} />
                    Избранное
                </Typography>
            </Breadcrumbs>

            <h1>Избранное</h1>

            <ProfileHeaderContainer />

            <Box sx={{
                marginBottom: '10px',
                bgcolor: '#cccccc',
                border: '1px solid black',
                borderRadius: '4px',
                bgcolor: '#f3f3f3',
                paddingTop: '8px',
                paddingBottom: '5px',
            }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    alignContent='center'
                >

                    <Grid item xs={12} sm={'auto'}>
                        <Typography className={classes.text}>Фильтры: </Typography>
                    </Grid>

                    <Grid item xs={12} sm={'auto'}>
                        <Container sx={{ minWidth: 250, marginBottom: '5px' }}>
                            <StyledSelect
                                label={'Состояние'}
                                items={stateFilter.map(a => a.text)}
                                cb={props.changeStateFilter}
                            />
                        </Container>
                    </Grid>
                </Grid>
            </Box>

            {renderContent()}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isFetching: state.myFavoritesPage.isFetching,
        listingsTotal: state.myFavoritesPage.listingsTotal,
        isLoggedIn: state.loginReg.isLoggedIn,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sync: () => {
            dispatch(favSetFetchingAC(true));
            dispatch(fetchFavorites());
        },
        changeStateFilter: (type, index) => {
            dispatch(favStateFilterChanged(stateFilter[index].filter))
        },
        showLoginDialog: () => {
            dispatch(showLoginDialogAC());
        },
    }
}

const FavoritePageContainer = connect(mapStateToProps, mapDispatchToProps)(FavoritePage);
export default FavoritePageContainer;