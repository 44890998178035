import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from "@material-ui/core/Typography";
import StyledButton from "./StyledButton";
import { Grid } from '@material-ui/core';
import DoneIcon from '@mui/icons-material/Done';
import { connect } from "react-redux";
import { setModelAC, fetchComposition } from "../../redux/add-1-listing-reducer";
import { packSetModelAC, packFetchComposition } from "../../redux/add-multi-listings-reducer";
import FormControl from '@mui/material/FormControl';
import Container from '@mui/material/Container';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 10,
    },
    cssFocused: {
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: '#202938 !important',
    },
    input: {
        color: '#202938',
        fontWeight: 600,
    },
}));

const ModelAutocomplete = (props) => {
    const [value, setValue] = React.useState(props.model);
    const [inputValue, setInputValue] = React.useState(props.model);
    const [options, setOptions] = React.useState([]);
    const [chosen, setChosen] = React.useState(false);
    const classes = useStyles();

    const confirmModel = () => {
        if (inputValue !== "") {
            props.setModel(inputValue);
        }
    };

    function getModels(subj, callback) {
        return fetch(process.env.REACT_APP_ORIGIN + `/api/tech/tv/modelautocomplete/${inputValue}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })

            .then(
                response => response.json()
            )
            .then(json => callback(json))
    }

    const fetchModels = (request, callback) => {
        getModels(request.input, callback);
    }

    const renderButton = () => {
        if (props.noButton) {
            return null;
        } else {
            return (
                <>
                    <Container sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                    }}>
                        <StyledButton
                            icon={<DoneIcon />}
                            label="Готово"
                            onClick={confirmModel}
                        />
                    </Container>
                </>
            )
        }
    }

    React.useEffect(() => {
        let active = true;

        if (inputValue === '' || inputValue.length < 5) {
            setOptions(value ? [value] : []);
            return undefined;
        }

        if (!chosen) {
            if (typeof (inputValue) === 'string') {
                fetchModels({ input: inputValue }, (results) => {
                    if (active && results.statusCode === 200) {
                        let newOptions = [];

                        if (value) {
                            newOptions = [value];
                        }

                        if (results) {
                            newOptions = [...newOptions, ...results.data.models];
                        }

                        setOptions(newOptions);
                    }
                });
            }
        }
        else {
            let newOptions = [];
            if (value) {
                newOptions = [value];
            }
            setOptions(newOptions);
        }

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <>
            <FormControl sx={{ borderColor: 'white' }} fullWidth>
                <Autocomplete
                    freeSolo
                    id="models"
                    disableClearable
                    getOptionLabel={(option) =>
                        typeof option === 'string' ? option : option.model
                    }
                    filterOptions={(x) => x}
                    value={value}
                    inputValue={props.forceValue ? props.value : inputValue}
                    options={options}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    onInputChange={(event, newInputValue, reason) => {
                        setChosen(reason === 'reset');
                        setInputValue(newInputValue);
                        if (props.onValueChangeCb && newInputValue !== 'undefined') {
                            props.onValueChangeCb(newInputValue);
                        }
                    }}
                    onChange={(event, newValue, reason) => {
                        setOptions(newValue ? [newValue, ...options] : options);
                        setValue(newValue);
                        if (props.onValueChangeCb && newValue !== 'undefined') {
                            props.onValueChangeCb(newValue.model);
                        }
                    }}
                    sx={{
                        '& input': {
                            fontFamily: 'Jura',
                            color: 'black',
                            fontWeight: 700,
                        },
                        '&.MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                            width: '100%',
                        }

                    }}
                    renderOption={(props, option) => (
                        <li key={option.model} {...props}>
                            <Typography style={{ fontFamily: 'Jura', color: 'black', fontWeight: 700, }}>{option.model}</Typography>
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                placeholder: "Введите модель ТВ",
                                size: "small",
                                classes: {
                                    root: classes.root,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                    input: classes.input,
                                }
                            }}
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                style: {
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    width: '100%',
                                    color: '#202938',
                                    fontWeight: '600',
                                }
                            }}
                            sx={{
                                minWidth: 200,
                                maxWidth: 650
                            }}
                        />
                    )}
                />

                {renderButton()}
            </FormControl>
        </>
    )
}

export const AddSingleListingModelAutocompleteContainer = connect(
    (state, ownProps) => {
        return {
            model: state.addSingle.model,
            noButton: false,
        }
    },
    (dispatch, ownProps) => {
        return {
            setModel: (model) => {
                dispatch(setModelAC(model));
                dispatch(fetchComposition(model));
            },
        }
    },
)(ModelAutocomplete);

export const AddListingPackModelAutocompleteContainer = connect(
    (state, ownProps) => {
        return {
            model: state.addPack.model,
            noButton: false,
        }
    },
    (dispatch, ownProps) => {
        return {
            setModel: (model) => {
                dispatch(packSetModelAC(model));
                dispatch(packFetchComposition(model));
            },
        }
    },
)(ModelAutocomplete);

export const GenericModelAutocompleteContainer = connect(
    (state, ownProps) => {
        return {
            model: ownProps.model,
            noButton: ownProps.noButton,
            onValueChangeCb: ownProps.onValueChangeCb,
            forceValue: ownProps.forceValue,
            value: ownProps.value,
        }
    },
    (dispatch, ownProps) => {
        return {}
    },
)(ModelAutocomplete);