import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Grid } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsSelector from './NotificationsSelector';
import StyledButton from '../entities/StyledButton';
import SaveIcon from '@mui/icons-material/Save';
import { connect } from "react-redux";
import { updateNotificationSettingsAC } from '../../redux/my-settings-reducer';
import Snackbar from '@mui/material/Snackbar';

const NotificationSettingsBlock = (props) => {
    const [err, setErr] = React.useState('');
    const [open, setOpen] = React.useState(false);

    let onFavPriceChangeEmail = props.onFavPriceChangeEmail;
    let onFavPriceChangeTg = props.onFavPriceChangeTg;
    let onNewFeedbackEmail = props.onNewFeedbackEmail;
    let onNewFeedbackTg = props.onNewFeedbackTg;
    let onSystemEventEmail = props.onSystemEventEmail;
    let onSystemEventTg = props.onSystemEventTg;
    let onProjectNewsEmail = props.onProjectNewsEmail;
    let onProjectNewsTg = props.onProjectNewsTg;

    async function post(descData) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/users/setsettings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(descData)
        })
            .then(data => data.json())
    }

    const onClick = async () => {
        console.log("Save called")
        const response = await post({
            id: props.userId,
            notify_fav_email: onFavPriceChangeEmail,
            notify_fav_tg: onFavPriceChangeTg,
            notify_fb_email: onNewFeedbackEmail,
            notify_fb_tg: onNewFeedbackTg,
            notify_sys_email: onSystemEventEmail,
            notify_sys_tg: onSystemEventTg,
            notify_news_email: onProjectNewsEmail,
            notify_news_tg: onProjectNewsTg,
        });

        props.updateSettings(onFavPriceChangeEmail, onFavPriceChangeTg, onNewFeedbackEmail, onNewFeedbackTg,
            onSystemEventEmail, onSystemEventTg, onProjectNewsEmail, onProjectNewsTg);

        setErr(response.statusCode === 200 ? "Сделано!" : "Что-то пошло не так. Извините и попробуйте еще раз позже пожалуйста");
        setOpen(true);
    }

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <NotificationsActiveIcon sx={{ marginRight: 1 }} />
                    Уведомления
                </AccordionSummary>

                <AccordionDetails>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                        alignContent='center'
                    >
                        <Grid item xs={10} sm={6} md={6} lg={3}>
                            <NotificationsSelector
                                formLabel="Изменение цены в избранном"
                                emailChecked={props.onFavPriceChangeEmail}
                                tgChecked={props.onFavPriceChangeTg}
                                onEmailChanged={(newValue) => { onFavPriceChangeEmail = newValue; }}
                                onTgChanged={(newValue) => { onFavPriceChangeTg = newValue; }}
                            />
                        </Grid>

                        <Grid item xs={10} sm={6} md={6} lg={3}>
                            <NotificationsSelector
                                formLabel="Новый отзыв"
                                emailChecked={props.onNewFeedbackEmail}
                                tgChecked={props.onNewFeedbackTg}
                                onEmailChanged={(newValue) => { onNewFeedbackEmail = newValue; }}
                                onTgChanged={(newValue) => { onNewFeedbackTg = newValue; }}
                            />
                        </Grid>

                        <Grid item xs={10} sm={6} md={6} lg={3}>
                            <NotificationsSelector
                                formLabel="Системные уведомления"
                                emailChecked={props.onSystemEventEmail}
                                tgChecked={props.onSystemEventTg}
                                onEmailChanged={(newValue) => { onSystemEventEmail = newValue; }}
                                onTgChanged={(newValue) => { onSystemEventTg = newValue; }}
                            />
                        </Grid>

                        <Grid item xs={10} sm={6} md={6} lg={3}>
                            <NotificationsSelector
                                formLabel="Новости naTextolite"
                                emailChecked={props.onProjectNewsEmail}
                                tgChecked={props.onProjectNewsTg}
                                onEmailChanged={(newValue) => { onProjectNewsEmail = newValue; }}
                                onTgChanged={(newValue) => { onProjectNewsTg = newValue; }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        alignContent='center'
                    >
                        <Grid item>
                            <StyledButton
                                label='Сохранить'
                                icon={<SaveIcon />}
                                onClick={onClick}
                            />
                        </Grid>
                    </Grid>

                    <Snackbar
                        open={open}
                        autoHideDuration={10000}
                        onClose={handleSnackBarClose}
                        message={err}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    />
                </AccordionDetails>
            </Accordion>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userId: state.userState.userId,
        onFavPriceChangeEmail: state.settings.onFavPriceChangeEmail,
        onFavPriceChangeTg: state.settings.onFavPriceChangeTg,
        onNewFeedbackEmail: state.settings.onNewFeedbackEmail,
        onNewFeedbackTg: state.settings.onNewFeedbackTg,
        onSystemEventEmail: state.settings.onSystemEventEmail,
        onSystemEventTg: state.settings.onSystemEventTg,
        onProjectNewsEmail: state.settings.onProjectNewsEmail,
        onProjectNewsTg: state.settings.onProjectNewsTg,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateSettings: (onFavPriceChangeEmail, onFavPriceChangeTg, onNewFeedbackEmail, onNewFeedbackTg,
            onSystemEventEmail, onSystemEventTg, onProjectNewsEmail, onProjectNewsTg) => {
            dispatch(updateNotificationSettingsAC(onFavPriceChangeEmail, onFavPriceChangeTg, onNewFeedbackEmail, onNewFeedbackTg,
                onSystemEventEmail, onSystemEventTg, onProjectNewsEmail, onProjectNewsTg));
        },
    }
}

const NotificationSettingsBlockContainer = connect(mapStateToProps, mapDispatchToProps)(NotificationSettingsBlock);
export default NotificationSettingsBlockContainer;