import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import StyledButton from '../entities/StyledButton';
import EditIcon from '@mui/icons-material/Edit';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { connect } from "react-redux";
import { updateListingDesc, setFetchingAC } from '../../redux/listing-reducer'
import StyledDarkTextField from '../entities/StyledDarkTextField';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import AddCommentIcon from '@mui/icons-material/AddComment';

const useStyles = makeStyles({
    iconButton: {
        color: '#202938',
        padding: '3px',
    },
});

const DescUpdateDialog = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [text, setText] = useState(props.desc);

    const maxLen = 1200;
    const textChanged = (e) => {
        if (e.target.value.length > maxLen)
            return;
        setText(e.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const renderButton = () => {
        return (
            <>
                <StyledButton
                    icon={<EditNoteIcon />}
                    size={'small'}
                    onClick={handleClickOpen}
                    label={"Изменить описание"}
                    disabled={props.buttonDisabled}
                />
            </>
        )
    };

    return (
        <React.Fragment>
            {renderButton()}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                        fontFamily: 'Jura',
                        bgcolor: '#262831',
                        color: '#6dc353',
                    }}
                >
                    {"Редактирование описания"}
                </DialogTitle>

                <DialogContent
                    sx={{
                        fontFamily: 'Jura',
                        fontWeight: '600',
                        bgcolor: '#262831',
                        color: '#6dc353',
                    }}
                >
                    <DialogContentText id="alert-dialog-description" sx={{
                        fontFamily: 'Jura',
                        fontWeight: '500',
                        bgcolor: '#262831',
                        color: '#cccccc',
                    }}>
                        Отредактируйте описание в поле ниже и нажмите кнопку "Сохранить".
                        Максимум 1200 символов.
                    </DialogContentText>

                    <StyledDarkTextField
                        value={text}
                        onChange={textChanged}
                        multiline={true}
                    //placeholder={`Введите текст заметки сюда`}
                    />
                </DialogContent>

                <DialogActions sx={{
                    bgcolor: '#262831',
                }}>
                    <StyledButton
                        onClick={() => {
                            props.updateDesc(props.listingId, text);
                        }}
                        label={"Сохранить"}
                    />
                    <StyledButton
                        onClick={handleClose}
                        label={"Отмена"}
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}

export const EditDescriptionDialogContainer = connect(
    (state) => {
        return {
            desc: state.listingPage.bareDesc,
            listingId: state.listingPage.listingId
        }
    },
    (dispatch) => {
        return {
            updateDesc: (listingId, text) => {
                dispatch(setFetchingAC(true));
                dispatch(updateListingDesc(listingId, text));
            }
        }
    },
)(DescUpdateDialog);
