import React from 'react'
import { Container, Grid, Link } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box } from '@mui/system';
import { connect } from 'react-redux';
import transliterate from '../../utils/transliterate'
import { Stack } from '@mui/material';
import MiniContactsCardContainer from './MiniContactsCard';
import DialogAlert from '../../components/entities/DialogAlert';
import DoneIcon from '@mui/icons-material/Done';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { setSellerConfirm, setSellerDiscard, sellerSoldLast, addSellerFeedback } from '../../redux/my-trading-activity-reducer';
import { exactSetSellerConfirm, exactSellerDiscard, exactSellerSoldLast, exactAddSellerFeedback } from '../../redux/exact-sale-reducer';
import StyledButton from '../entities/StyledButton';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from '@mui/icons-material/Cancel';
import FeedbackDialog from './FeedbackDialog';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

const useStyles = makeStyles({
    header: {
        paddingLeft: 10,
        fontSize: 17,
        fontWeight: 700,
        color: 'black',
        marginBottom: 6,
        margin: 5,
        alignSelf: "stretch"
    },
    listingHeader: {
        paddingLeft: 4,
        fontSize: 16,
        fontWeight: 500,
        color: 'black',
        marginBottom: 6,
        margin: 5,
        alignSelf: "stretch"
    },
    content: {
        fontSize: 15,
        fontWeight: 500,
        color: 'black',
        marginBottom: 4,
        paddingLeft: 5,
    },
    state: {
        fontSize: 14,
        fontWeight: 600,
        color: 'black',
        marginBottom: 4,
        paddingLeft: 5,
    },
    link: {
        color: '#6dc353',
        fontSize: 16,
        fontWeight: 600,
        marginBottom: 5,
        paddingLeft: 5,
    },
    icon: {
        paddingLeft: 15,
    },
    iconPositive: {
        color: '#6dc353',
        marginLeft: 5,
    },
    iconNegative: {
        color: 'red',
        marginLeft: 5,
    },
});


const MySalePreview = (props) => {
    const classes = useStyles();

    const renderBuyerStatus = () => {
        if (props.discarded) {
            return null;
        }

        if (props.buyerConfirm) {
            return (
                <>
                    <Stack direction='row' spacing={1}>
                        <DoneIcon className={classes.icon} />
                        <Typography className={classes.state} gutterBottom>
                            Покупатель подтвердил получение товара
                        </Typography>
                    </Stack>
                </>
            )
        }
        return (
            <>
                <Stack direction='row' spacing={1}>
                    <AccessTimeIcon className={classes.icon} />
                    <Typography className={classes.state} gutterBottom>
                        Ожидается подтверждение получения товара
                    </Typography>
                </Stack>
            </>
        )
    }

    const renderSellerStatus = () => {
        if (props.discarded) {
            return null;
        }

        if (props.sellerConfirm) {
            return (
                <>
                    <Stack direction='row' spacing={1}>
                        <DoneIcon className={classes.icon} />
                        <Typography className={classes.state} gutterBottom>
                            Вы подтвердили получение оплаты
                        </Typography>
                    </Stack>
                </>
            )
        }
        return (
            <>
                <Stack direction='row' spacing={1}>
                    <AccessTimeIcon className={classes.icon} />
                    <Typography className={classes.state} gutterBottom>
                        Ожидается подтверждение получения Вами оплаты
                    </Typography>
                </Stack>
            </>
        )
    }

    const renderBuyerFeedbackStatus = () => {
        if (props.buyerFbType !== null && props.buyerFbText !== null) {
            return (
                <>
                    <Stack direction='row' spacing={1}>
                        <DoneIcon className={classes.icon} />
                        <Typography className={classes.state} gutterBottom>
                            Покупатель оставил отзыв
                        </Typography>
                    </Stack>
                </>
            )
        }
        return (
            <>
                <Stack direction='row' spacing={1}>
                    <AccessTimeIcon className={classes.icon} />
                    <Typography className={classes.state} gutterBottom>
                        Ожидается отзыв от покупателя
                    </Typography>
                </Stack>
            </>
        )
    }

    const renderSellerFeedbackStatus = () => {
        if (props.myFbType !== null && props.myFbText !== null) {
            return (
                <>
                    <Stack direction='row' spacing={1}>
                        <DoneIcon className={classes.icon} />
                        <Typography className={classes.state} gutterBottom>
                            Вы оставили отзыв
                        </Typography>
                    </Stack>
                </>
            )
        }
        return (
            <>
                <Stack direction='row' spacing={1}>
                    <AccessTimeIcon className={classes.icon} />
                    <Typography className={classes.state} gutterBottom>
                        Ожидается Ваш отзыв
                    </Typography>
                </Stack>
            </>
        )
    }

    const renderStatusDetails = () => {
        if (props.discarded) {
            if (props.discardedBy === 'seller') {
                return (
                    <>
                        <Stack direction='row' spacing={1}>
                            <CancelIcon className={classes.icon} />
                            <Typography className={classes.state} gutterBottom>
                                Вы отменили сделку
                            </Typography>
                        </Stack>
                    </>
                )
            } else if (props.discardedBy === 'buyer') {
                return (
                    <>
                        <Stack direction='row' spacing={1}>
                            <CancelIcon className={classes.icon} />
                            <Typography className={classes.state} gutterBottom>
                                Покупатель отменил сделку
                            </Typography>
                        </Stack>
                    </>
                )
            } else {
                return null;
            }
        }
        else if (props.buyerConfirm && props.sellerConfirm) {
            return (
                <>
                    {renderSellerFeedbackStatus()}
                    {renderBuyerFeedbackStatus()}
                </>
            )
        }
        else {
            return (
                <>
                    {renderSellerStatus()}
                    {renderBuyerStatus()}
                </>
            )
        }
    }

    const renderActionConfirm = () => {
        if (props.discarded) {
            return null;
        }

        if (!props.sellerConfirm) {
            return (
                <>
                    <Grid item>
                        <DialogAlert
                            buttonText='Оплата получена'
                            buttonIcon={<DoneIcon />}
                            onConfirm={() => { props.setSellerConfirm(props.id) }}
                            titleText={`Оплата получена?`}
                            descriptionText="Нажимая 'Продолжить' Вы подтверждаете что получили оплату за товар"
                            cancelText='Отмена'
                            proceedText='Продолжить'
                        />
                    </Grid>
                </>
            )
        }
    }

    const renderActionRemoveListing = () => {
        if (props.discarded) {
            return null;
        }

        if (props.sellerConfirm && props.state === 'active') {
            return (
                <>
                    <Grid item>
                        <Tooltip title="Нет ничего хуже неактуального объявления. Если у вас больше нет абсолютно таких-же запчастей - удалите объявление. На сделку это никак не повлияет" placement="bottom">
                            <span>
                                <StyledButton
                                    size={'small'}
                                    label={'Удалить объявление'}
                                    icon={<DeleteIcon />}
                                    onClick={() => { props.soldLast(props.id, props.listingId) }}
                                />
                            </span>
                        </Tooltip>
                    </Grid>
                </>
            )
        }
    }

    const renderActionFeebdback = () => {
        if (props.discarded) {
            return null;
        }

        if (props.myFbType !== null && props.myFbText !== null) {
            if (props.feedbackAllowed) {
                return (
                    <>
                        <Grid item>
                            <FeedbackDialog
                                update
                                id={props.id}
                                onConfirm={props.sellerAddFedback}
                                currentText={props.myFbText}
                                currentType={props.myFbType}
                            />
                        </Grid>
                    </>
                )
            }
            return null;
        }

        return (
            <>
                <Grid item>
                    <FeedbackDialog
                        disabled={!(props.buyerConfirm && props.sellerConfirm)}
                        id={props.id}
                        onConfirm={props.sellerAddFedback}
                    />
                </Grid>
            </>
        )
    }

    const renderActionDiscardDeal = () => {
        if (props.discarded) {
            return null;
        }

        if (!(props.sellerConfirm && props.buyerConfirm)) {
            return (
                <>
                    <Grid item>
                        <DialogAlert
                            buttonText='Отменить сделку'
                            buttonIcon={<CancelIcon />}
                            onConfirm={() => { props.setSellerDiscard(props.id) }}
                            titleText={'Сделка не состоялась?'}
                            descriptionText="Если Вы действительно хотите отменить сделку - нажмите 'Продолжить'"
                            cancelText='Отмена'
                            proceedText='Продолжить'
                        />
                    </Grid>
                </>
            )
        }
    }

    const renderAvailableActions = () => {
        return (
            <>
                <Grid container direction="row" justifyContent="center" alignItems="center" >
                    {renderActionConfirm()}
                    {renderActionRemoveListing()}
                    {renderActionFeebdback()}
                    {renderActionDiscardDeal()}
                </Grid>
            </>
        )
    }

    const emplacePic = (type) => {
        switch (type) {
            case '1': return <SentimentSatisfiedAltIcon className={classes.iconPositive} />;
            case '-1': return <SentimentVeryDissatisfiedIcon className={classes.iconNegative} />;
            case '0': return <SentimentNeutralIcon />;
            default: return <></>;
        }
    }

    const renderMyFeedback = () => {
        if (props.myFbType !== null && props.myFbText !== null) {
            return (
                <>
                    {emplacePic(props.myFbType)}
                    <Typography className={classes.state} gutterBottom>
                        {props.myFbText}
                    </Typography>
                </>
            )
        }
        return (
            <>
                <AccessTimeIcon className={classes.icon} />
                <Typography className={classes.state} gutterBottom>
                    Вы пока не оставили отзыв
                </Typography>
            </>
        )
    }

    const renderBuyerFeedback = () => {
        if (props.buyerFbType !== null && props.buyerFbText !== null) {
            return (
                <>
                    {emplacePic(props.buyerFbType)}
                    <Typography className={classes.state} gutterBottom>
                        {props.buyerFbText}
                    </Typography>
                </>
            )
        }
        return (
            <>
                <AccessTimeIcon className={classes.icon} />
                <Typography className={classes.state} gutterBottom>
                    Покупатель пока не оставил отзыв
                </Typography>
            </>
        )
    }

    const optionalRenderFeedback = () => {
        if (props.exclusiveMode) {
            return (
                <>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.header} gutterBottom>
                                Отзыв покупателя:
                            </Typography>
                            <Box display="flex"
                                flexDirection="column"
                                alignItems="center"
                            >
                                {renderBuyerFeedback()}
                            </Box>
                        </Grid>


                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.header} gutterBottom>
                                Мой Отзыв:
                            </Typography>

                            <Box display="flex"
                                flexDirection="column"
                                alignItems="center"
                            >
                                {renderMyFeedback()}
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider sx={{ margin: '4px' }} />
                </>
            )
        }
        return null;
    }

    const renderDealHeader = () => {
        if (props.exclusiveMode) {
            return (
                <>
                    <Typography className={classes.header} gutterBottom>
                        Сделка с покупателем {props.buyerLogin} от {props.sealed}
                    </Typography>
                </>
            )
        } else {
            return (
                <>
                    <Link href={`/personal/mysale/${props.id}`}>
                        <Typography className={classes.header} gutterBottom>
                            Сделка с покупателем {props.buyerLogin} от {props.sealed}
                        </Typography>
                    </Link>
                </>
            )
        }
    }

    return (
        <>
            <Box
                sx={{
                    border: '1px solid gray',
                    borderRadius: '4px',
                    marginBottom: 1,
                    paddingY: 1,
                    bgcolor: '#F1F1F1',
                }}
            >
                {renderDealHeader()}

                <Divider sx={{ margin: '4px' }} />
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12} sm={6}>
                        <Stack direction={'row'}>
                            <Link href={`/buy/${transliterate(props.title.split(' ').join('_'))}-${props.listingId}`}>
                                <Avatar
                                    alt="Thumbnail"
                                    src={props.imgUrl}
                                    sx={{ width: 100, height: 100, paddingLeft: 1 }}
                                    variant="square"
                                />
                            </Link>

                            <Box sx={{ maxWidth: 370 }}>
                                <Link href={`/buy/${transliterate(props.title.split(' ').join('_'))}-${props.listingId}`}>
                                    <Typography className={classes.listingHeader} gutterBottom>
                                        {props.title}
                                    </Typography>
                                </Link>
                                <Typography className={classes.header} gutterBottom>
                                    {props.price}р.
                                </Typography>
                            </Box>
                        </Stack>

                        <Divider sx={{ margin: '4px' }} />
                        <Typography className={classes.header} gutterBottom>
                            Статус сделки - {props.discarded ? 'Не состоялась' : props.buyerConfirm && props.sellerConfirm ? 'завершена' : 'в процессе:'}
                        </Typography>
                        {renderStatusDetails()}

                        <Divider sx={{ margin: '4px' }} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MiniContactsCardContainer
                            login={props.buyerLogin}
                            email={props.buyerEmail}
                            cityType={props.buyerCityType}
                            city={props.buyerCity}
                            region={props.buyerRegion}
                            phone={props.buyerPhone}
                            wa={props.buyerWa}
                            tg={props.buyerTg}
                        />
                    </Grid>
                </Grid>

                {optionalRenderFeedback()}

                {renderAvailableActions()}
            </Box >
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        id: ownProps.id,
        listingId: ownProps.listingId,
        buyerConfirm: ownProps.buyerConfirm,
        sellerConfirm: ownProps.sellerConfirm,
        sealed: ownProps.sealed,
        discarded: ownProps.discarded,
        discardedBy: ownProps.discardedBy,
        buyerLogin: ownProps.buyerLogin,
        buyerEmail: ownProps.buyerEmail,
        buyerCityType: ownProps.buyerCityType,
        buyerCity: ownProps.buyerCity,
        buyerRegion: ownProps.buyerRegion,
        buyerPhone: ownProps.buyerPhone,
        buyerWa: ownProps.buyerWa,
        buyerTg: ownProps.buyerTg,
        price: ownProps.price,
        title: ownProps.title,
        type: ownProps.type,
        typeRu: ownProps.typeRu,
        state: ownProps.state,
        vendor: ownProps.vendor,
        model: ownProps.model,
        imgUrl: ownProps.imgUrl,
        odd: ownProps.odd,
        key: ownProps.key,

        buyerFbType: ownProps.buyerFbType,
        buyerFbText: ownProps.buyerFbText,
        buyerFbDate: ownProps.buyerFbDate,
        myFbType: ownProps.myFbType,
        myFbText: ownProps.myFbText,
        myFbDate: ownProps.myFbDate,
        feedbackAllowed: ownProps.feedbackAllowed,
        exclusiveMode: ownProps.exclusiveMode,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setSellerConfirm: (dealId) => {
            if (ownProps.exclusiveMode) {
                dispatch(exactSetSellerConfirm(dealId));
            } else {
                dispatch(setSellerConfirm(dealId));
            }
        },
        setSellerDiscard: (dealId) => {
            if (ownProps.exclusiveMode) {
                dispatch(exactSellerDiscard(dealId));
            } else {
                dispatch(setSellerDiscard(dealId));
            }
        },
        soldLast: (dealId, listingId) => {
            if (ownProps.exclusiveMode) {
                dispatch(exactSellerSoldLast(dealId, listingId));
            } else {
                dispatch(sellerSoldLast(dealId, listingId));
            }
        },
        sellerAddFedback: (dealId, type, text) => {
            if (ownProps.exclusiveMode) {
                dispatch(exactAddSellerFeedback(dealId, type, text));
            } else {
                dispatch(addSellerFeedback(dealId, type, text));
            }
        }
    }
}

const MySalePreviewContainer = connect(mapStateToProps, mapDispatchToProps)(MySalePreview);
export default MySalePreviewContainer;