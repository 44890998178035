import * as React from 'react';
import { connect } from "react-redux";
import { Box, Typography } from '@material-ui/core';
import BaseInfoContainer from '../new-listing/BaseInfo';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                variant="determinate"
                {...props}
                sx={{
                    color: '#6dc353',
                }}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
};


const BaseInfoQueue = (props) => {
    const [progress, setProgress] = React.useState(0);

    const onConfirm = (idx) => {
        setProgress((100 / props.listings.length) * (idx + 1));
    }

    const render = () => {
        for (const [index, value] of props.listings.entries()) {
            if (!value.baseInfoConfirmed) {
                return (
                    <>
                        <BaseInfoContainer
                            multi
                            index={index}
                            onConfirm={onConfirm}
                        />
                    </>
                )
            }
        }
    }

    return (
        <>
            <CircularProgressWithLabel value={progress} />
            {render()}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        listings: state.addPack.listingsData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

const BaseInfoQueueContainer = connect(mapStateToProps, mapDispatchToProps)(BaseInfoQueue);
export default BaseInfoQueueContainer;