import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { Grid } from '@material-ui/core';
import HelperCard from '../../components/settings/HelperCard';
import StyledButton from '../entities/StyledButton';
import { pickupAddressUpdateAC } from '../../redux/my-settings-reducer';
import { connect } from "react-redux";
import SignpostIcon from '@mui/icons-material/Signpost';
import StyledTextField from '../entities/StyledTextField';
import { Box } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

const PickupAddressBlock = (props) => {
    const [address, setAddress] = useState(props.address);
    const [err, setErr] = React.useState('');
    const [open, setOpen] = React.useState(false);

    const maxLen = 500;

    const adressChanged = (e) => {
        if (e.target.value.length > maxLen)
            return;
        setAddress(e.target.value);
    };

    async function post(descData) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/users/setsettings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(descData)
        })
            .then(data => data.json())
    }

    const sendAddr = async () => {
        console.log("Sending addr");
        console.log(address);

        const response = await post({
            id: props.id,
            pickup_addr: address,
        });

        setErr(response.statusCode === 200 ? "Сделано!" : "Что-то пошло не так. Извините и попробуйте еще раз позже пожалуйста");
        setOpen(true);
    }

    const submitAddress = () => {
        props.updateAddress(address);
        sendAddr();
    };

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <SignpostIcon sx={{ marginRight: 1 }} />
                    Адрес самовывоза
                </AccordionSummary>

                <AccordionDetails>
                    В поле ниже вы можете указать адрес и другие инструкции по самовывозу:

                    <Grid
                        container
                        direction="row"
                        justifyContent="space-around"
                        alignItems="center"
                        alignContent='center'
                    >
                        <Grid item xs={12} sm={8}>
                            <Box>
                                Максимум {maxLen} символов. Текущее значение: {address.length}/{maxLen}
                            </Box>
                            <StyledTextField
                                value={address}
                                onChange={adressChanged}
                                multiline={true}
                                placeholder={`улица Светланская, 52 (район остановки Центр)\nВход со двора, зеленая дверь.\nПарковаться лучше через дорогу, на парковке торгового центра`}
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <StyledButton
                                icon={<SignpostIcon />}
                                label="Сохранить"
                                onClick={submitAddress}
                            />
                        </Grid>
                    </Grid>

                    <HelperCard helperText={
                        "Содержимое этого поля будет показано в Ваших объявлениях как адрес и инструкции по самовывозу (заполняется в свободной форме). Пример заполнения: \"улица Светланская, 52 (район остановки Центр), вход со двора, зеленая дверь. Парковаться лучше через дорогу, на парковке торгового центра\""
                    } />

                    <Snackbar
                        open={open}
                        autoHideDuration={10000}
                        onClose={handleSnackBarClose}
                        message={err}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    />
                </AccordionDetails>
            </Accordion>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        id: state.userState.userId,
        address: state.settings.pickupAdress,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateAddress: (addres) => {
            dispatch(pickupAddressUpdateAC(addres));
        },
    }
}

const PickupAddressBlockContainer = connect(mapStateToProps, mapDispatchToProps)(PickupAddressBlock);
export default PickupAddressBlockContainer;