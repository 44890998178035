import React from 'react'
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@mui/system';
import { connect } from 'react-redux';
import StyledButton from '../../components/entities/StyledButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArchiveIcon from '@mui/icons-material/Archive';
import CachedIcon from '@mui/icons-material/Cached';
import { activateCurrent, deactivateCurrent, removeCurrent, updatePrice, setFetchingAC } from '../../redux/listing-reducer';
import DialogAlert from '../entities/DialogAlert';
import { PriceChangeDialogContainer } from './PriceChangeDialog';
import { EditDescriptionDialogContainer } from './EditDescriptionDialog';

const useStyles = makeStyles({
    text: {
        color: 'black',
        fontWeight: 600,
        padding: '5px',
    },
});

const MyListingActions = (props) => {
    const classes = useStyles();

    if (!props.isLoggedIn ||
        props.submitterLogin !== props.myLogin ||
        props.state === 'removed')
        return null;

    const renderActiveActions = () => {
        return (
            <>
                <Grid item>
                    <StyledButton
                        icon={<ArchiveIcon />}
                        size='small'
                        onClick={() => {
                            props.deactivate(props.id);
                        }}
                        label='Снять с публикации'
                    />
                </Grid>

                <Grid item>
                    <DialogAlert
                        buttonText='Удалить'
                        buttonIcon={<DeleteOutlineIcon />}
                        onConfirm={() => {
                            props.remove(props.id);
                        }}
                        titleText={`Действительно удалить выбранное объявление?`}
                        descriptionText='Выбранное объявление будет удалено без возможности вернуть его на публикацию. Фото из объявления будут так-же удалены.'
                        cancelText='Отмена'
                        proceedText='Удалить'
                    />
                </Grid>

                <Grid item>
                    <PriceChangeDialogContainer
                        price={props.price}
                        listingId={props.id}
                        rowMode={false}
                        setFetchingCb={setFetchingAC}
                        updatePriceCb={updatePrice}
                        summary={props.summary}
                    />
                </Grid>

                <Grid item>
                    <EditDescriptionDialogContainer />
                </Grid>
            </>
        )
    }

    const renderExpiredActions = () => {
        return (
            <>
                <StyledButton
                    icon={<CachedIcon />}
                    size='small'
                    onClick={() => {
                        props.renew(props.id);
                    }}
                    label='Опубликовать'
                />
                <Grid item>
                    <DialogAlert
                        buttonText='Удалить'
                        buttonIcon={<DeleteOutlineIcon />}
                        onConfirm={() => {
                            props.remove(props.id);
                        }}
                        titleText={`Действительно удалить выбранное объявление?`}
                        descriptionText='Выбранное объявление будет удалено без возможности вернуть его на публикацию. Фото из объявления будут так-же удалены.'
                        cancelText='Отмена'
                        proceedText='Удалить'
                    />
                </Grid>
            </>
        )
    }

    const renderRemovedActions = () => {

    }

    const render = () => {
        switch (props.state) {
            case 'active': return renderActiveActions();
            case 'expired': return renderExpiredActions();
            case 'removed': return renderRemovedActions();
            default: break;
        }
    }

    return (
        <>
            <Box
                classes={classes.root}
                sx={{
                    border: '1px solid #f6f8f1',
                    borderRadius: '4px',
                    marginBottom: 1,
                    bgcolor: '#e5e5e5',
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    alignContent='center'
                >
                    <Grid item>
                        <Typography className={classes.text}>Доступные действия: </Typography>
                    </Grid>
                    {render()}
                </Grid>
            </Box>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        id: state.listingPage.listingId,
        state: state.listingPage.listingState,
        submitterLogin: state.listingPage.submitterLogin,
        summary: state.listingPage.summary,
        isLoggedIn: state.loginReg.isLoggedIn,
        myLogin: state.userState.login,
        price: state.listingPage.price,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        renew: (id) => {
            dispatch(setFetchingAC(true));
            dispatch(activateCurrent(id));
        },
        deactivate: (id) => {
            dispatch(setFetchingAC(true));
            dispatch(deactivateCurrent(id));
        },
        remove: (id) => {
            dispatch(setFetchingAC(true));
            dispatch(removeCurrent(id));
        },
    }
}

const MyListingActionsContainer = connect(mapStateToProps, mapDispatchToProps)(MyListingActions);
export default MyListingActionsContainer;