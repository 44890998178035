import React from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

/* Props:
    - label: text to be displayed on top of the select
    - items: array of the items to be displayed as options
    - minWidth: in px
    - maxWidth: in px
    - cb: callback with (string, num) signature to provide value update
*/

const StyledSelect = (props) => {
    const [value, setValue] = React.useState('');

    const handleChange = (event) => {
        setValue(event.target.value);
        if (props.cb) {
            props.cb(props.items[event.target.value], event.target.value);
        }
    };

    const genItemList = (itemsArr) => {
        const items = []
        for (const [index, value] of itemsArr.entries()) {
            items.push(<MenuItem sx={{ fontFamily: 'Jura', fontWeight: 500, }} key={index} value={index}>{value}</MenuItem>);
        }
        return items;
    }

    return (
        <>
            <FormControl sx={{ borderColor: 'white' }} fullWidth size="small">
                <InputLabel sx={{ fontFamily: 'Jura', fontWeight: 700 }}>{props.label}</InputLabel>
                <Select
                    value={props.forceValue ? props.value : value}
                    placeholder={props.label}
                    label={props.label}
                    onChange={handleChange}
                    size="small"
                    autoWidth
                    fullWidth={true}
                    sx={{
                        fontFamily: 'Jura',
                        fontWeight: 700,
                        "&.MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: 'rgba(20, 29, 38, 1)',
                            },
                            "&:hover fieldset": {
                                borderColor: 'rgba(20, 29, 38, 1)',
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: 'rgba(20, 29, 38, 1)',
                            }
                        },
                    }}
                >

                    {genItemList(props.items)}
                </Select>
            </FormControl>
        </>
    )
}

export default StyledSelect;