import React from 'react'
import { connect } from "react-redux";
import Paginated from '../entities/Paginated';
import MyPurchasePreviewContainer from './MyPurchasePreview';
import StyledCheckBox from '../entities/StyledCheckBox';
import { tradingPurchasesStateFilterChanged, tradingPurchasesFbFilterChanged } from '../../redux/my-trading-activity-reducer'
import { Grid, Typography } from '@material-ui/core';
import Box from '@mui/material/Box';
import StyledSelect from '../../components/entities/StyledSelect';
import { makeStyles } from '@material-ui/core/styles';
import Spinner from '../Spinner';
import { Container, Stack } from '@mui/material';

const useStyles = makeStyles({
    text: {
        color: 'black',
        fontWeight: 600,
        padding: '5px',
        paddingBottom: '13px'
    },
});

const stateFilter = [
    { text: 'Все', filter: 'any' },
    { text: 'Завершенные', filter: 'completed' },
    { text: 'Отмененные', filter: 'discarded' },
    { text: 'В процессе', filter: 'inProgress' },
];

const fbFilter = [
    { text: 'Не важно', filter: 'any' },
    { text: 'От меня', filter: 'expectMy' },
    { text: 'От продавца', filter: 'expectSeller' },
];

const MyPurchases = (props) => {
    const classes = useStyles();
    const renderListingRow = (index, value) => {
        return <MyPurchasePreviewContainer
            id={value.id}
            listingId={value.listing_id}
            sellerId={value.seller_id}
            buyerConfirm={value.buyer_confirm}
            sellerConfirm={value.seller_confirm}
            sealed={value.sealed}
            discarded={value.discarded}
            discardedBy={value.discarded_by}
            sellerLogin={value.login}
            sellerEmail={value.email}
            sellerCityType={value.city_type}
            sellerCity={value.city}
            sellerRegion={value.region}
            sellerPhone={value.phone}
            sellerWa={value.whatsapp}
            sellerTg={value.telegram}
            sellerPickUpAddr={value.pickup_addr}
            price={value.price}
            title={value.summary}
            type={value.type}
            typeRu={value.text}
            vendor={value.vendor}
            model={value.model}
            imgUrl={value.cover}
            odd={index % 2}
            key={value.id}
            sellerFbType={value.seller_fb_type}
            sellerFbText={value.seller_fb}
            sellerFbDate={value.seller_fb_ts}
            myFbType={value.buyer_fb_type}
            myFbText={value.buyer_fb}
            myFbDate={value.buyer_fb_ts}
            feedbackAllowed={value.feedback_allowed}
        />
    }

    if (props.isFetching) {
        return <Spinner />
    }

    if (props.purchasesTotal == 0) {
        return (
            <>
                <h2>¯\_(ツ)_/¯</h2>
                <h2>Вы пока ничего не купили</h2>
            </>
        )
    }

    const renderFilteredResults = () => {
        if (props.filteredTotal > 0) {
            return <Paginated
                pagesize={props.pageSize}
                renderItem={renderListingRow}
                items={props.purchases}
            />;

        }
        else {
            return (
                <>
                    <h2>¯\_(ツ)_/¯</h2>
                    <h2>Нет сделок удовлетворяющим критериям фильтра</h2>
                </>
            )
        }
    }

    return (
        <>
            <Box sx={{
                marginBottom: '10px',
                bgcolor: '#cccccc',
                border: '1px solid black',
                borderRadius: '4px',
                bgcolor: '#f3f3f3',
                paddingTop: '8px',
                paddingBottom: '5px',
            }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    alignContent='center'
                >

                    <Grid item xs={12} sm={'auto'}>
                        <Typography className={classes.text}>Фильтры: </Typography>
                    </Grid>

                    <Grid item xs={12} sm={'auto'}>
                        <Container sx={{ minWidth: 250, marginBottom: '5px' }}>
                            <StyledSelect
                                label={'Состояние сделки'}
                                items={stateFilter.map(a => a.text)}
                                cb={props.changeStateFilter}
                            />
                        </Container>
                    </Grid>

                    <Grid item xs={12} sm={'auto'}>
                        <Container sx={{ minWidth: 250, marginBottom: '5px' }}>
                            <StyledSelect
                                label={'Ожидающие отзыва'}
                                items={fbFilter.map(a => a.text)}
                                cb={props.changeFbFilter}
                            />
                        </Container>
                    </Grid>
                </Grid>
            </Box>

            {renderFilteredResults()}
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    const sortIdDesc = (deals) => {
        return deals.sort(function (a, b) {
            return parseInt(b.id, 10) - parseInt(a.id, 10);
        });
    }
    return {
        isFetching: state.tradingActivity.isFetching,
        filteredTotal: state.tradingActivity.filteredPurchases.length,
        purchasesTotal: state.tradingActivity.purchasesTotal,
        purchases: sortIdDesc(state.tradingActivity.filteredPurchases),
        pageSize: ownProps.pageSize ? ownProps.pageSize : 10,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeStateFilter: (type, index) => {
            dispatch(tradingPurchasesStateFilterChanged(stateFilter[index].filter));
        },
        changeFbFilter: (type, index) => {
            dispatch(tradingPurchasesFbFilterChanged(fbFilter[index].filter));
        },
    }
}

const MyPurchasesContainer = connect(mapStateToProps, mapDispatchToProps)(MyPurchases);
export default MyPurchasesContainer;