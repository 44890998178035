import React from 'react'
import { Grid, Link } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box } from '@mui/system';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Stack } from '@mui/material';
import transliterate from '../../utils/transliterate';
import TurnRightIcon from '@mui/icons-material/TurnRight';

/*
Props:
    fromUser: login of the submitter
    variant: 'positive'/'negative'/'neutral'
    feedback: text of the feedback
    date: date of the feedback submission
*/

const useStyles = makeStyles({
    icon: {
        marginLeft: 5,
    },
    iconPositive: {
        color: '#6dc353',
        marginLeft: 5,
    },
    iconNegative: {
        color: 'red',
        marginLeft: 5,
    },
    header: {
        fontSize: 16,
        fontWeight: 600,
        color: 'black',
    },
    content: {
        fontSize: 15,
        fontWeight: 500,
        color: 'black',
    },
    listing: {
        fontSize: 13,
        fontWeight: 600,
        color: 'black',
    },
    feedback: {
        fontSize: 16,
        fontWeight: 600,
        color: 'black',
    },
});

const FeedbackRow = (props) => {
    const classes = useStyles();

    const emplacePic = () => {
        switch (props.variant) {
            case 'positive': return <SentimentSatisfiedAltIcon className={classes.iconPositive} />;
            case 'negative': return <SentimentVeryDissatisfiedIcon className={classes.iconNegative} />;
            case 'neutral': return <SentimentNeutralIcon className={classes.icon} />;
            default: return <></>;
        }
    }

    return (
        <>
            <Box
                classes={classes.root}
                sx={{
                    border: '1px solid #f6f8f1',
                    borderRadius: '4px',
                    marginBottom: 1,
                    bgcolor: '#f3f3f3'
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems='center'
                >
                    <Grid item xs={1} sm={2}>
                        {emplacePic()}
                    </Grid>

                    <Grid item sm={3} md={2}>
                        <Typography className={classes.content} >
                            От {props.fromRole === 'seller' ? 'продавца' : 'покупателя'}
                        </Typography>

                        <Link href={`/profile/${props.fromUser}`}>
                            <Typography className={classes.header} gutterBottom>
                                {props.fromUser}
                            </Typography>
                        </Link>

                        <Typography className={classes.content} >
                            {props.date}
                        </Typography>
                    </Grid>

                    <Grid item xs={7} md={8}>
                        <Stack direction={'row'}>
                            <TurnRightIcon />
                            <Link href={`/buy/${transliterate(props.listingSummary.split(' ').join('_'))}-${props.listingId}`}>
                                <Typography className={classes.listing} >
                                    {props.listingSummary}
                                </Typography>
                            </Link>
                        </Stack>

                        <Typography className={classes.feedback} >
                            {props.feedback === '' ? 'Пользователь не оставил комментария' : props.feedback}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default FeedbackRow;