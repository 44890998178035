import React from 'react'
import { Grid, Link } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box } from '@mui/system';
import { connect } from 'react-redux';
import {
    myListingsToggleSelection, setFetchingAC, myListingsUpdateListingPrice,
    myListingsCreateListingNote, myListingsUpdateListingNote, myListingsRemoveListingNote
} from '../../redux/my-listings-reducer';
import transliterate from '../../utils/transliterate'
import { Stack } from '@mui/material';
import ListingNoteRowContainer from './ListingNoteRow';
import { PriceChangeDialogContainer } from './PriceChangeDialog';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const useStyles = makeStyles({
    header: {
        fontSize: 16,
        fontWeight: 700,
        color: 'black',
        marginBottom: 6,
        margin: 5,
        alignSelf: "stretch"
    },
    content: {
        fontSize: 15,
        fontWeight: 500,
        color: 'black',
        marginBottom: 4,
        paddingLeft: 5,
    },
    state: {
        fontSize: 16,
        fontWeight: 600,
        color: 'black',
        marginBottom: 4,
        paddingLeft: 5,
    },
    price: {
        fontSize: 18,
        fontWeight: 700,
        color: 'black',
        marginBottom: 6,
        margin: 5,
    },
    favorites: {
        fontSize: 15,
        fontWeight: 600,
        color: 'black',
        marginBottom: 4,
        paddingLeft: 5,
    }
});

/*
    Props:
        - imgUrl - URL of the thumbnail
        - title - title
        - date - submission date
        - price - price
        - id - id of the listing
        - viewsToday - todays views
        - viewsTotal - total views
        - state - listing state to be displayed
*/

const MyListingPreview = (props) => {
    const classes = useStyles();

    const calcBgColor = () => {
        if (props.checked)
            return '#D0E7CB';

        if (props.odd)
            return '#FEFDFE';

        return '#F1F1F1';
    }

    const renderListingNoteRow = () => {
        if (props.noteExist) {
            return <ListingNoteRowContainer
                noteExist={props.noteExist}
                noteText={props.noteText}
                listingId={props.id}
                setFetchingCb={setFetchingAC}
                createListingNoteCb={myListingsCreateListingNote}
                updateListingNoteCb={myListingsUpdateListingNote}
                deleteListingNoteCb={myListingsRemoveListingNote}
            />
        }
        return null;
    }

    const optionalRenderInFavorites = () => {
        if (props.inFavorites > 0) {
            return (
                <>
                    <Stack direction={'row'}>
                        <ShoppingCartIcon sx={{ paddingLeft: 1 }} />
                        <Typography className={classes.favorites} > Добавлено в избранное: {props.inFavorites} раз(а) </Typography>
                    </Stack>
                </>
            )
        }
        return null;
    }
    return (
        <>
            <Box
                classes={classes.root}
                sx={{
                    border: '1px solid #f6f8f1',
                    borderRadius: '4px',
                    marginBottom: 1,
                    bgcolor: calcBgColor(),
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    wrap='wrap'
                    sx={{ flexGrow: 1 }}
                >
                    <Grid item sx={{ order: { sm: 2, md: 2 } }}>
                        <Checkbox sx={{
                            color: '#262831',
                            '&.Mui-checked': {
                                color: '#262831',
                            },
                        }}
                            checked={props.checked}
                            onChange={(e) => {
                                props.toggleSelection(props.id);
                            }}
                        />
                    </Grid>
                    <Grid item sx={{ order: { sm: 1, md: 1 } }}>
                        <Link href={`/buy/${transliterate(props.title.split(' ').join('_'))}-${props.id}`}>
                            <Avatar
                                alt="Thumbnail"
                                src={props.imgUrl}
                                sx={{ width: 150, height: 150 }}
                                variant="square"
                            />
                        </Link>
                    </Grid>

                    <Grid item xs={7}>
                        <Link href={`/buy/${transliterate(props.title.split(' ').join('_'))}-${props.id}`}>
                            <Typography className={classes.header} gutterBottom>
                                {props.title}
                            </Typography>
                        </Link>
                        {renderListingNoteRow()}
                        <Typography className={classes.content} > Создано {props.date} </Typography>
                        {/*<Typography className={classes.content} > Просмотров: {props.viewsToday}/{props.viewsTotal} </Typography>*/}
                        {optionalRenderInFavorites()}
                    </Grid>

                    <Grid item >
                        <Stack direction={'column'}>
                            <Typography className={classes.price} gutterBottom>
                                {props.price}р.
                            </Typography>

                            <PriceChangeDialogContainer
                                price={props.price}
                                listingId={props.id}
                                rowMode
                                setFetchingCb={setFetchingAC}
                                updatePriceCb={myListingsUpdateListingPrice}
                                summary={props.title}
                            />
                        </Stack>
                    </Grid>

                    <Grid item>
                        <Stack direction={'column'}>
                            <Typography className={classes.state} gutterBottom>
                                {props.state}
                            </Typography>

                            {!props.noteExist &&
                                <ListingNoteRowContainer
                                    noteExist={props.noteExist}
                                    noteText={props.noteText}
                                    listingId={props.id}
                                    setFetchingCb={setFetchingAC}
                                    createListingNoteCb={myListingsCreateListingNote}
                                    updateListingNoteCb={myListingsUpdateListingNote}
                                    deleteListingNoteCb={myListingsRemoveListingNote}
                                />
                            }
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        id: ownProps.id,
        imgUrl: ownProps.imgUrl,
        title: ownProps.title,
        date: ownProps.date,
        viewsToday: ownProps.viewsToday,
        viewsTotal: ownProps.viewsTotal,
        inFavorites: ownProps.inFavorites,
        price: ownProps.price,
        state: ownProps.state,
        type: ownProps.type,
        checked: state.myListingsPage.selected.includes(ownProps.id),
        odd: ownProps.odd,
        noteExist: ownProps.noteExist,
        noteText: ownProps.noteText,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSelection: (id) => {
            dispatch(myListingsToggleSelection(id));
        },
    }
}

const MyListingPreviewContainer = connect(mapStateToProps, mapDispatchToProps)(MyListingPreview);
export default MyListingPreviewContainer;