import React from "react";
import { connect } from "react-redux";
import { AddSingleListingModelAutocompleteContainer } from "../entities/ModelAutocomplete";
import Spinner from "../Spinner";
import StyledSelect from "../entities/StyledSelect";
import { setVendorAC, confirmVendorAC } from "../../redux/add-1-listing-reducer";
import StyledButton from "../entities/StyledButton";
import { Grid, Typography } from '@material-ui/core';
import DoneIcon from '@mui/icons-material/Done';
import StyledCheckBox from "../entities/StyledCheckBox";
import StyledTextField from "../entities/StyledTextField";
import { Container } from "@mui/material";

const VendorModelConfirm = (props) => {
    const [noVendorInList, setNoVendorInList] = React.useState(false);
    const [customVendor, setCustomVendor] = React.useState("");

    const render = () => {
        if (props.isFetching) {
            return (<Spinner />);
        }

        if (props.modelConfirmed) {
            if (!props.vendorConfirmed) {
                return (
                    <>
                        <Container sx={{ alignContent: 'center', justifyContent: 'center' }}>
                            Модели {props.model} нет в базе, пожалуйста, укажите производителя ТВ:

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                alignContent='center'
                            >
                                <Grid item xs={12} sm={8}>
                                    {noVendorInList ?
                                        (<StyledTextField
                                            type="text"
                                            value={customVendor}
                                            onChange={(e) => {
                                                setCustomVendor(e.target.value);
                                            }}
                                        />)
                                        :
                                        (<StyledSelect
                                            label="Выберите производителя"
                                            items={props.vendorList.map(a => a.vendor)}
                                            cb={props.setVendor}
                                        />)
                                    }
                                </Grid>
                                <Grid item >
                                    <StyledButton
                                        icon={<DoneIcon />}
                                        label="Готово"
                                        onClick={() => {
                                            if (noVendorInList && customVendor !== "") {
                                                props.setCustomAndConfirm(customVendor);
                                            }

                                            if (props.vendor !== "") {
                                                props.confirmVendor()
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <StyledCheckBox
                                label="Производителя нет в списке"
                                checked={noVendorInList}
                                onChange={(e) => {
                                    setNoVendorInList(!noVendorInList);
                                    if (!noVendorInList) {
                                        props.setVendor("");
                                    }
                                }}
                            />
                        </Container>
                    </>
                )
            }
        }

        return (
            <>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    alignContent='center'
                >
                    <Grid item>
                        Укажите модель ТВ с которого сняты запчасти:
                        <AddSingleListingModelAutocompleteContainer />
                    </Grid>
                </Grid>
            </>
        )
    }

    return (
        <>
            {render()}
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        isFetching: state.addSingle.isFetching,
        vendor: state.addSingle.vendor,
        model: state.addSingle.model,
        vendorConfirmed: state.addSingle.vendorConfirmed,
        modelConfirmed: state.addSingle.modelConfirmed,
        vendorList: state.addSingle.vendors,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setVendor: (value) => {
            console.log(value);
            dispatch(setVendorAC(value, false));
        },
        confirmVendor: () => {
            dispatch(confirmVendorAC());
        },
        setCustomAndConfirm: (value) => {
            dispatch(setVendorAC(value, true));
            dispatch(confirmVendorAC());
        },
    }
}

const VendorModelConfirmContainer = connect(mapStateToProps, mapDispatchToProps)(VendorModelConfirm);
export default VendorModelConfirmContainer;


