import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import StyledButton from '../entities/StyledButton';
import EditIcon from '@mui/icons-material/Edit';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { connect } from "react-redux";
import { createListingNote, updateListingNote, setFetchingAC } from '../../redux/listing-reducer'
import StyledDarkTextField from '../entities/StyledDarkTextField';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import AddCommentIcon from '@mui/icons-material/AddComment';

/*
 * Props:
 * buttonText
 * buttonIcon
 * onConfirm - cb if user decide to proceed
 * titleText - ...
 * descriptionText - ...
 * cancelText - ...
 * proceedText - ...
 * updateExisting - bool
*/
const useStyles = makeStyles({
    iconButton: {
        color: '#202938',
        padding: '3px',
    },
});

const NoteAddUpdateDialog = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [text, setText] = useState(props.noteText);

    const maxLen = 300;
    const textChanged = (e) => {
        if (e.target.value.length > maxLen)
            return;
        setText(e.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const renderButton = () => {
        if (props.rowMode) {
            if (props.updateExisting) {
                return (
                    <>
                        <Tooltip
                            title="Изменить заметку"
                            placement="bottom"
                        >
                            <IconButton
                                aria-label="delete"
                                onClick={handleClickOpen}
                            >
                                <EditIcon
                                    className={classes.iconButton}
                                    fontSize='small'
                                />
                            </IconButton>
                        </Tooltip>
                    </>
                )
            }
            else {
                return (
                    <>
                        <Tooltip
                            title="Добавить заметку"
                            placement="bottom"
                        >
                            <IconButton
                                aria-label="delete"
                                onClick={handleClickOpen}
                            >
                                <AddCommentIcon
                                    className={classes.iconButton}
                                    fontSize='medium'
                                />
                            </IconButton>
                        </Tooltip>
                    </>
                )
            }
        }

        return (
            <>
                <StyledButton
                    icon={<EditNoteIcon />}
                    size={'small'}
                    onClick={handleClickOpen}
                    label={props.updateExisting ? "Изменить заметку" : "Добавить заметку"}
                    disabled={props.buttonDisabled}
                />
            </>
        )
    };

    return (
        <React.Fragment>
            {renderButton()}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                        fontFamily: 'Jura',
                        bgcolor: '#262831',
                        color: '#6dc353',
                    }}
                >
                    {props.noteExist ? "Редактирование заметки" : "Создание заметки"}
                </DialogTitle>

                <DialogContent
                    sx={{
                        fontFamily: 'Jura',
                        fontWeight: '600',
                        bgcolor: '#262831',
                        color: '#6dc353',
                    }}
                >
                    <DialogContentText id="alert-dialog-description" sx={{
                        fontFamily: 'Jura',
                        fontWeight: '500',
                        bgcolor: '#262831',
                        color: '#cccccc',
                    }}>
                        Введите текст заметки в поле ниже и нажмите кнопку "Сохранить".
                        Максимум 300 символов.
                    </DialogContentText>

                    <StyledDarkTextField
                        value={text}
                        onChange={textChanged}
                        multiline={true}
                        placeholder={`Введите текст заметки сюда`}
                    />
                </DialogContent>

                <DialogActions sx={{
                    bgcolor: '#262831',
                }}>
                    <StyledButton
                        onClick={() => {
                            if (props.noteExist) {
                                props.updateNote(props.listingId, text);
                            }
                            else {
                                props.addNote(props.listingId, text);
                            }
                        }}
                        label={"Сохранить"}
                    />
                    <StyledButton
                        onClick={handleClose}
                        label={"Отмена"}
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}

// Default form of the dialog with full-size buttons
// Used from 'actions' context on Listing page
export const ButtonAddUpdateDialogContainer = connect(
    (state, ownProps) => {
        return {
            noteExist: state.listingPage.noteExist,
            noteText: state.listingPage.noteText,
            updateExisting: ownProps.updateExisting,
            listingId: state.listingPage.listingId,
            rowMode: false,
            buttonDisabled: ownProps.buttonDisabled,
        }
    },
    (dispatch, ownProps) => {
        return {
            addNote: (id, text) => {
                dispatch(setFetchingAC(true));
                dispatch(createListingNote(id, text));
            },
            updateNote: (id, text) => {
                dispatch(setFetchingAC(true));
                dispatch(updateListingNote(id, text));
            }
        }
    },
)(NoteAddUpdateDialog);

// Inline form of the dialog with icon-buttons
// Used from 'listing preview' context on MyFavorites/MyListings pages
export const InlineNoteAddUpdateDialogContainer = connect(
    (state, ownProps) => {
        return {
            noteExist: ownProps.noteExist,
            noteText: ownProps.noteText,
            updateExisting: ownProps.updateExisting,
            listingId: ownProps.listingId,
            rowMode: true,
        }
    },
    (dispatch, ownProps) => {
        return {
            addNote: (id, text) => {
                dispatch(ownProps.setFetchingCb(true));
                dispatch(ownProps.createListingNoteCb(id, text));
            },
            updateNote: (id, text) => {
                dispatch(ownProps.setFetchingCb(true));
                dispatch(ownProps.updateListingNoteCb(id, text));
            }
        }
    },
)(NoteAddUpdateDialog);
