import React from 'react'
import { connect } from 'react-redux';
import Paginated from '../entities/Paginated';
import SearchListingPreviewContainer from './SearchListingPreview';
import Spinner from '../Spinner';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@material-ui/core/styles';
import SubscriptionAddContainer from './SubscriptionAdd';

const useStyles = makeStyles((theme) => ({
    divider: {

    },
}));

const ListingsSearchResults = (props) => {
    const classes = useStyles();

    const renderListingRow = (index, value) => {
        return <SearchListingPreviewContainer
            id={value.id}
            submitter={value.login}
            city={value.city}
            region={value.region}
            imgUrl={value.cover}
            title={value.summary}
            date={value.created_at}
            price={value.price}
            odd={index % 2}
            key={index}
        />
    }

    const renderSubscription = () => {
        if (props.isLoggedIn) {
            return <SubscriptionAddContainer />
        }
    }

    const renderSearchResults = () => {
        if (props.myCity === '' || props.myRegion === '') {
            return (
                <>
                    <Paginated
                        pagesize={props.pageSize}
                        renderItem={renderListingRow}
                        items={props.listings}
                    />
                </>
            )
        }
        else { // render sorted with location
            return (
                <>
                    <Divider textAlign="left" sx={{
                        "&::before, &::after": {
                            borderColor: '#262831',
                        },
                        marginBottom: 2,
                    }} >В Вашем городе: {props.inCityListings.length}</Divider>
                    {
                        props.inCityListings.length > 0 &&
                        <Paginated
                            pagesize={props.pageSize}
                            renderItem={renderListingRow}
                            items={props.inCityListings}
                        />
                    }

                    <Divider textAlign="left" sx={{
                        "&::before, &::after": {
                            borderColor: '#262831',
                        },
                        marginBottom: 2,
                    }}>В Вашем регионе: {props.inRegionListings.length}</Divider>
                    {
                        props.inRegionListings.length > 0 &&
                        <Paginated
                            pagesize={props.pageSize}
                            renderItem={renderListingRow}
                            items={props.inRegionListings}
                        />
                    }

                    <Divider textAlign="left" sx={{
                        "&::before, &::after": {
                            borderColor: '#262831',
                        },
                        marginBottom: 2,
                    }}>В других регионах: {props.restListings.length}</Divider>
                    {
                        props.restListings.length > 0 &&
                        <Paginated
                            pagesize={props.pageSize}
                            renderItem={renderListingRow}
                            items={props.restListings}
                        />
                    }
                </>
            )
        }
    }

    const renderHeader = () => {
        if (props.lastSearchType === 'bytype') {
            return (
                <>
                    <h3>
                        {props.typeRu} для {props.typeSearchModel}
                    </h3>
                </>
            )
        }
        else if (props.lastSearchType === 'bypn') {
            return (
                <>
                    <h3>
                        {props.lastSearchPn} для {props.pnSearchModel === '' ? 'любых моделей' : props.pnSearchModel}
                    </h3>
                </>
            )
        }
    }

    const renderHeaderNoResults = () => {
        if (props.lastSearchType === 'bytype') {
            return (
                <>
                    <h3>
                        По запросу {props.typeRu} для {props.typeSearchModel} ничего не нашлось
                    </h3>
                </>
            )
        }
        else if (props.lastSearchType === 'bypn') {
            return (
                <>
                    <h3>
                        По запросу {props.lastSearchPn} для {props.pnSearchModel === '' ? 'любых моделей' : props.pnSearchModel} ничего не нашлось
                    </h3>
                </>
            )
        }
    }

    if (props.didntStart) {
        return null;
    }

    if (props.isFetching) {
        return <Spinner />
    }

    if (props.listingsTotal === 0) {
        return (
            <>
                <h2>¯\_(ツ)_/¯</h2>
                {renderHeaderNoResults()}
                {renderSubscription()}
            </>
        )
    }

    return (
        <>
            {renderSubscription()}
            <h2>Результаты по запросу:</h2>
            {renderHeader()}
            {renderSearchResults()}
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    const sortPriceAsc = (listings) => {
        return listings.sort(function (a, b) {
            return parseInt(a.price, 10) - parseInt(b.price, 10);
        });
    }

    return {
        isFetching: state.listingsSearch.isFetching,
        listings: state.listingsSearch.listings,
        listingsTotal: state.listingsSearch.listingsTotal,
        pageSize: ownProps.pageSize ? ownProps.pageSize : 10,
        typeRu: state.listingsSearch.hwTypeRu,
        typeSearchModel: state.listingsSearch.lastSearchedByTypeModel,
        pnSearchModel: state.listingsSearch.lastSearchedByPnModel,

        didntStart: state.listingsSearch.didntStartYet,
        lastSearchType: state.listingsSearch.lastSearchType,
        lastSearchPn: state.listingsSearch.lastSearchPn,
        myCity: state.userState.city,
        myRegion: state.userState.region,
        isLoggedIn: state.loginReg.isLoggedIn,

        inCityListings: sortPriceAsc(state.listingsSearch.listings.filter(function (item) {
            return item.region === state.userState.region &&
                item.city === state.userState.city;
        })),
        inRegionListings: sortPriceAsc(state.listingsSearch.listings.filter(function (item) {
            return item.region === state.userState.region &&
                item.city !== state.userState.city;
        })),
        restListings: sortPriceAsc(state.listingsSearch.listings.filter(function (item) {
            return item.region !== state.userState.region &&
                item.city !== state.userState.city;
        })),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

const ListingsSearchResultsContainer = connect(mapStateToProps, mapDispatchToProps)(ListingsSearchResults);
export default ListingsSearchResultsContainer;