import { FormGroup2pn } from "../../FormGroup";
import React from "react";
import {
    updateTnPn1AC, updateTnPn2AC,
} from "../../../redux/add-composition-reducer";
import { connect } from "react-redux";

const TnAddForm = (props) => {

    return (
        <>
            <FormGroup2pn
                title={"Тюнер"}
                description={"Внимательно осмотрите плату и наклейки на ней. После этого укажите здесь партнамберы, которые видите.\n" +
                    "                            Каждый партнамбер указывайте в отдельном поле.\n" +
                    "                            Например, на плате вы видите \"BN41-01976B\", а на наклейке \"BN96-26401N\".\n" +
                    "                            В поле \"Партнамбер 1\" укажите \"BN41-01976B\", а в поле \"Партнамбер 2\" - \"BN96-26401N\"."}
                val_pn1={props.tn1}
                val_pn2={props.tn2}
                cb_pn1={props.updateTnPn1}
                cb_pn2={props.updateTnPn2}
                pn1_err={props.tn1Err}
                pn2_err={props.tn2Err}
                pn1_errDesc={props.tn1ErrWhat}
                pn2_errDesc={props.tn2ErrWhat}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        tn1: state.addCompositionPage.tn_pn1,
        tn2: state.addCompositionPage.tn_pn2,
        tn1Err: state.addCompositionPage.tn_pn1Err,
        tn2Err: state.addCompositionPage.tn_pn2Err,
        tn1ErrWhat: state.addCompositionPage.tn_pn1ErrWhat,
        tn2ErrWhat: state.addCompositionPage.tn_pn2ErrWhat,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateTnPn1: (val, err) => { dispatch(updateTnPn1AC(val, err)); },
        updateTnPn2: (val, err) => { dispatch(updateTnPn2AC(val, err)); },
    }
}
const TnAddFormContainer = connect(mapStateToProps, mapDispatchToProps)(TnAddForm);
export default TnAddFormContainer;