import React from 'react'
import { AppBar, Toolbar, Typography, Container } from "@material-ui/core";
import './../style/fonts.css'
import logo from './logo.svg'
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import HeaderToolbarContainer from "./HeaderToolbar";
import { Box } from '@mui/material';

const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between'
}));

export default function Header() {
    return (
        <>
            <AppBar position="static" color="primary">
                <StyledContainer maxWidth="md">
                    <Toolbar>
                        <Link to="/">
                            <img
                                src={logo}
                                height="48"
                                width="48"
                                className="d-inline-block align-top"
                                alt="Logo"
                            />
                        </Link>
                        <Box display={{ xs: 'none', sm: 'block' }}>
                            <Link to="/" style={{ textDecoration: 'none' }}>
                                <Typography variant="h6" color="secondary">
                                    naTeXtolite
                                </Typography>
                            </Link>
                        </Box>
                    </Toolbar>

                    <HeaderToolbarContainer />

                </StyledContainer>
            </AppBar>
        </>
    )
}