import * as React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import Tooltip from "@mui/material/Tooltip";

/* Props:
    - text: text of the help
*/

const HelpIconWithTooltip = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
                <Tooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={props.text}
                >
                    <HelpCenterIcon sx={{
                        color: props.darkMode ? 'white' : '#262831',
                    }} onClick={handleTooltipOpen} />
                </Tooltip>
            </div>
        </ClickAwayListener>
    );
}

export default HelpIconWithTooltip;