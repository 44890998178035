import React from 'react'
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid } from '@material-ui/core';
import Tooltip from "@mui/material/Tooltip";
import StyledButton from '../../components/entities/StyledButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {ButtonAddUpdateDialogContainer} from './NoteAddUpdateDialog';
import { removeListingNote, setFetchingAC } from '../../redux/listing-reducer'


const useStyles = makeStyles({
    root: {
        marginRight: 5,
        marginLeft: 5,
        marginBottom: 10,
        background: '#202938',
        paddingBottom: 0,
        paddingTop: 0,
        borderColor: 'white',
    },
    link: {
        color: '#6dc353',
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 5,
    },
    header: {
        fontSize: 16,
        fontWeight: 700,
        color: '#6dc353',
        marginBottom: 6,
    },
    content: {
        color: '#dddddd',
        marginBottom: 4,
        paddingLeft: 5,
    },
    notice: {
        color: '#dddddd',
        marginBottom: 4,
        paddingLeft: 5,
        fontSize: 13,
    },
    contentGreen: {
        color: '#6dc353',
    },
    contentRed: {
        color: 'red',
    },
});

const ListingNoteCard = (props) => {
    const classes = useStyles();

    if (!props.noteExist) {
        return null;
    }

    return (
        <>
            <Card className={classes.root} variant="outlined">
                <CardContent>
                    <Typography className={classes.header}>
                        Ваша заметка:
                    </Typography>

                    <Typography className={classes.content}>
                        {props.noteText}
                    </Typography>

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        alignContent='center'
                    >
                        <Grid item>
                            <Tooltip
                                title="Нажмите чтобы изменить текст заметки. Заметку видите только Вы"
                                placement="bottom"
                            >
                                <span>
                                    <ButtonAddUpdateDialogContainer updateExisting />
                                </span>
                            </Tooltip>
                        </Grid>

                        <Grid item>
                            <Tooltip
                                title="Удалить заметку"
                                placement="bottom"
                            >
                                <span>
                                    <StyledButton
                                        label={"Удалить"}
                                        icon={<DeleteOutlineIcon />}
                                        size='small'
                                        onClick={() => { props.remove(props.listingId) }}
                                    />
                                </span>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    if (ownProps.preview) {
        return {
            noteExist: state.listingPage.login,
            noteText: state.listingPage.city,
        }
    }
    return {
        noteExist: state.listingPage.noteExist,
        noteText: state.listingPage.noteText,
        noteUpdatedAt: state.listingPage.noteUpdatedAt,
        listingId: state.listingPage.listingId,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        remove: (id) => {
            dispatch(setFetchingAC(true));
            dispatch(removeListingNote(id));
        }
    }
}

const ListingNoteCardContainer = connect(mapStateToProps, mapDispatchToProps)(ListingNoteCard);
export default ListingNoteCardContainer;