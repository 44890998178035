import React from 'react'
import { connect } from "react-redux";
import Spinner, { InvertedSpinner } from "./Spinner";
import { fetchUsages, updateSearchPnTextActionCreator } from "../redux/usages-reducer";
import TvIcon from '@material-ui/icons/Tv';
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Typography from '@mui/material/Typography';
import Paginated from './entities/Paginated';


const useStyles = makeStyles({
    listItem: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        fontWeight: 600,
    },
    listItemIcon: {
        width: 'content',
        paddingRight: 4,
        paddingLeft: 4,
        fontSize: 'medium',
    },
});

const UsagesResult = (props) => {
    const classes = useStyles();

    const renderHeader = () => {
        return (
            <>
                <Typography sx={{
                    fontFamily: "Jura",
                    fontSize: 15,
                    fontWeight: 500,
                    color: '#dddddd',
                }}>
                    {props.notice}
                </Typography>

                <Typography sx={{
                    fontFamily: "Jura",
                    fontSize: 15,
                    fontWeight: 500,
                    color: 'white',
                }}>
                    {props.searchedItem} применяется в:
                </Typography>
            </>
        )
    }

    const renderPaginated = (index, value) => {
        return (
            <>
                <ListItem key={value.model} className={classes.listItem}>
                    <TvIcon className={classes.listItemIcon} key={value.model} />
                    <Link
                        href={`/services/composition/tv/${value.model.split("/").join("____")}`}
                        color="inherit">
                        <ListItemText primary={value.vendor + " " + value.model} />
                    </Link>
                </ListItem>
            </>
        )
    }

    const renderResults = () => {
        if (props.isFetching) {
            if (props.invertedSpinner) {
                return (
                    <InvertedSpinner />
                )
            } else {
                return (
                    <Spinner />
                )
            }
        }

        if (props.usages.length === 0) {
            if (props.searchedItem === '') {
                return null;
            }
            
            return (
                <>
                    <h2>Хм.. Странно, но мы пока не слышали про {props.searchedItem} </h2>
                    <h3> ¯\_(ツ)_/¯ </h3>
                </>
            )
        } else {
            if (props.paginate) {
                return (
                    <>
                        {renderHeader()}
                        <Paginated
                            pagesize={props.pageSize}
                            renderItem={renderPaginated}
                            items={props.usages}
                            darkBg
                        />
                    </>
                )
            }
            else {
                return (
                    <>
                        {renderHeader()}
                        <List>
                            {
                                props.usages.map(value => {
                                    return (
                                        <>
                                            <ListItem key={value.model} className={classes.listItem}>
                                                <TvIcon className={classes.listItemIcon} key={value.model} />
                                                <Link
                                                    href={`/services/composition/tv/${value.model.split("/").join("____")}`}
                                                    color="inherit">
                                                    <ListItemText primary={value.vendor + " " + value.model} />
                                                </Link>
                                            </ListItem>
                                        </>
                                    )
                                })
                            }
                        </List>
                    </>
                )
            }
        }
    }

    return (
        <>
            {renderResults()}
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        searchedItem: state.usagesPage.searchedItem.split("____").join("/"),
        usages: state.usagesPage.usages.usages,
        isFetching: state.usagesPage.isFetching,
        invertedSpinner: false,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        syncState: (newText) => {
            dispatch(updateSearchPnTextActionCreator(newText));
            dispatch(fetchUsages());
        }
    }
}

const UsagesResultContainer = connect(mapStateToProps, mapDispatchToProps())(UsagesResult);
export default UsagesResultContainer;

const mapStateToPropsSecondary = (state, ownProps) => {
    return {
        searchedItem: 'Выбранный элемент',
        usages: state.compositionPage.secondaryUsages.usages,
        isFetching: state.compositionPage.isSecondaryUsagesFetching,
        invertedSpinner: true,
        notice: ownProps.notice,
        paginate: ownProps.paginate,
        pageSize: ownProps.pageSize,
    }
}
const SecondaryUsagesResultContainer = connect(mapStateToPropsSecondary, {})(UsagesResult);
export { SecondaryUsagesResultContainer };