import fetch from "cross-fetch";

const EXACT_PURCHASE_SET_FETCHING = 'EXACT_PURCHASE_SET_FETCHING';
const EXACT_PURCHASE_DATA_RECEIVED = 'EXACT_PURCHASE_DATA_RECEIVED';
const EXACT_PURCHASE_DATA_UPDATED = 'EXACT_PURCHASE_DATA_UPDATED';
const EXACT_PURCHASE_SET_ERR = 'EXACT_PURCHASE_SET_ERR';
const LOG_REG_LOGOUT = 'LOG_REG_LOGOUT';

let initialState = {
    isFetching: true,

    purchase: {},

    errorCondition: false,
    errWhat: '',
};

const exactPurchaseReducer = (state = initialState, action) => {
    switch (action.type) {
        case EXACT_PURCHASE_SET_FETCHING:
            return Object.assign({}, state, {
                isFetching: action.isFetching,
            });

        case EXACT_PURCHASE_DATA_RECEIVED:
        case EXACT_PURCHASE_DATA_UPDATED:
            return Object.assign({}, state, {
                isFetching: false,
                purchase: action.purchase,
            });

        case EXACT_PURCHASE_SET_ERR:
            return Object.assign({}, state, {
                errorCondition: true,
                errWhat: action.what,
                isFetching: false,
            });

        case LOG_REG_LOGOUT:
            return Object.assign({}, state, initialState);

        default:
            return state;
    }
};

export const purchaseSetFetchingAC = (isFetching) => ({
    type: EXACT_PURCHASE_SET_FETCHING,
    isFetching: isFetching
})

export const purchaseReceiveData = (data) => ({
    type: EXACT_PURCHASE_DATA_RECEIVED,
    purchase: data.purchase,
})

export const purchaseReceiveUpdates = (data) => ({
    type: EXACT_PURCHASE_DATA_UPDATED,
    purchase: data.purchaseUpdate,
})

export const purchaseSetError = (what) => ({
    type: EXACT_PURCHASE_SET_ERR,
    what: what,
})

export function fetchExactPurchase(id) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + `/api/listings/purchase/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(purchaseReceiveData(json.data));
                    } else {
                        dispatch(purchaseSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function exactSetBuyerConfirm(deal_id) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/buyerconfirm', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                dealId: deal_id,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(purchaseReceiveUpdates(json.data));
                    } else {
                        dispatch(purchaseSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}


export function exactSetBuyerDiscard(deal_id) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/buyerdiscard', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                dealId: deal_id,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(purchaseReceiveUpdates(json.data));
                    } else {
                        dispatch(purchaseSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function exactAddBuyerFeedback(deal_id, fbType, fbText) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/buyeraddfb', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                dealId: deal_id,
                fbType: fbType,
                fbText: fbText,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(purchaseReceiveUpdates(json.data));
                    } else {
                        dispatch(purchaseSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export default exactPurchaseReducer;