import React, { useEffect } from 'react'
import { connect } from "react-redux";
import { fetchListingData, setFetchingAC, sealTheDeal } from '../../redux/listing-reducer';
import { Box, Grid } from '@material-ui/core';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import ListingSubmitterCardContainer from '../../components/listings/ListingSubmitterCard';
import ContactsCardContainer from '../../components/listings/ContactsCard';
import DynamicUsagesLinksCardContainer from "../../components/DynamicUsagesLinksCard";
import { fetchSecondaryUsages } from '../../redux/composition-reducer';
import DescriptionContainer from '../../components/listings/Description';
import ActionsContainer from '../../components/listings/Actions';
import Spinner from '../../components/Spinner';
import MetaTags from 'react-meta-tags';
import logo from '../../components/logo.svg';
import StateNoticeContainer from '../../components/listings/StateNotice';
import MyListingActionsContainer from '../../components/listings/MyListingActions';
import ListingNoteCardContainer from '../../components/listings/ListingNoteCard';
import StyledButton from '../../components/entities/StyledButton';
import Tooltip from "@mui/material/Tooltip";
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import Container from '@mui/material/Container';
import DialogAlert from '../../components/entities/DialogAlert';
import { listingGalleryFullScreened } from '../../redux/listing-reducer';
import history from "../../history/history";

const Listing = ({ match, ...props }) => {
    useEffect(() => {
        if (!props.gotData) {
            let id = match.params.humanReadablePlusId.split("-").pop();
            props.syncState(id);
        }
        else {
            props.checkUsages(props.pns, true);
        }
    }, [props.pns, match.params.humanReadablePlusId]);

    if (props.isFetching) {
        return <Spinner />
    }

    if (props.listingDoesntExist) {
        return (
            <>
                <h2>Похоже такого объявления нет и никогда не было. Если Вы уверены в обратном, пожалуйста, напишите нам на admin@natextolite.ru</h2>
                <h2>Мы обязательно разберемся</h2>
            </>
        )
    }

    const renderBuyButton = () => {
        if (props.isLoggedIn &&
            props.submitter !== props.myLogin &&
            props.state === 'active') {
            return (
                <>
                    <Grid container direction="column" justifyContent="center" alignItems="center" >
                        <Grid item>
                            <DialogAlert
                                primaryButtonSize='medium'
                                buttonText='Купить'
                                buttonIcon={<PointOfSaleIcon />}
                                onConfirm={() => {
                                    props.buy(props.submitterId, props.listingId);
                                    history.push('/personal/mypurchases');
                                }}
                                titleText={`Заключаем сделку?`}
                                descriptionText='Если у Вас нет вопросов по товару и Вы действительно готовы его купить - просто нажмите "Купить!". Если у Вас есть вопросы по товару - нажмите кнопку "Отмена", а затем, кнопку "Контакты". Задайте все интересующие Вас вопросы продавцу по указанному телефону, в почте или мессенджерах'
                                cancelText='Отмена'
                                proceedText='Купить!'
                            />
                        </Grid>
                    </Grid>
                </>
            )
        }
    }

    const forceResizeImages = (fullScreen) => {
        props.forceResizeImgs(fullScreen);
    }

    return (
        <>
            <MetaTags>
                <title>{`${props.summary} купить ${props.city}. Цена: ${props.price}₽`}</title>
                <meta name="description" content={`${props.hwTypeHumanReadable.split("/").join(', ')} для ${props.vendor} ${props.model}, ${props.pns.join(', ')} купить, цена: ${props.price}₽. В наличии. ${props.city} - наТекстолите`} />
                <meta name="keywords" content={`${props.hwTypeHumanReadable.split("/").join(', ')}, ${props.model}, ${props.pns.join(', ')}, цена, купить`} />
                <meta property="og:title" content={`${props.summary} купить ${props.city}. Цена: ${props.price}₽`} />
                <meta property="og:site_name" content="naTextolite.ru" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`${window.location.href}`} />
                <meta property="og:image" content={props.coverExist ? props.imgs[0].thumbnail : logo} />
                <meta property="og:image:secure_url" content={props.coverExist ? props.imgs[0].thumbnail : logo} />
                <meta property="og:description" content={`${props.hwTypeHumanReadable.split("/").join(', ')} для ${props.vendor} ${props.model}, ${props.pns.join(', ')} купить, цена: ${props.price}₽. В наличии. ${props.city} - наТекстолите`} />
            </MetaTags>

            <h2>
                {props.summary}
            </h2>
            <p>{props.date}</p>
            <StateNoticeContainer />
            <MyListingActionsContainer />

            <Grid container direction="row" justifyContent="center" alignItems="center" alignContent='center'>
                <Grid item xs={12} sm={6}>
                    <ListingSubmitterCardContainer />
                    <ListingNoteCardContainer />
                    <ContactsCardContainer />
                </Grid>
                <Grid item xs={12} sm={6}>
                    {

                        props.imgs.length > 0 ? (
                            <ImageGallery
                                items={props.imgs}
                                showPlayButton={false}
                                onScreenChange={forceResizeImages}
                            />
                        ) : (
                            <>
                                <h3>К сожалению, продавец не предоставил фото</h3>
                            </>
                        )
                    }
                </Grid>
            </Grid>

            <StateNoticeContainer />

            {renderBuyButton()}
            <ActionsContainer />

            {
                (props.showCompatBlock && !props.noUsages) ? (
                    <>
                        <Grid container direction="row" justifyContent="center" alignItems="stretch" alignContent='center'>
                            <Grid item xs={12} sm={8}>
                                <DescriptionContainer />
                                <ContactsCardContainer />
                                <ActionsContainer />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <DynamicUsagesLinksCardContainer
                                    header="Совместимость"
                                    notice={`Внимание! В базе составов есть информация что ${props.pns[0]} устанавливается в ТВ из списка ниже. 
                                    Однако, мы НЕ можем гарантировать, что платы с этих ТВ взаимозаменяемы.`}
                                    paginate
                                    pageSize={10}
                                />
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <>
                        <DescriptionContainer />
                        <ContactsCardContainer />
                        <ActionsContainer />
                    </>
                )
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isFetching: state.listingPage.isFetching,
        submitter: state.listingPage.submitterLogin,
        showCompatBlock: state.listingPage.showCompatibilityBlock,
        summary: state.listingPage.summary,
        date: state.listingPage.date,
        imgs: state.listingPage.imgs,
        pns: state.listingPage.pns,
        type: state.listingPage.type,
        vendor: state.listingPage.vendor,
        model: state.listingPage.model,
        hwTypeHumanReadable: state.listingPage.typeHumanReadable,
        noUsages: state.compositionPage.secondaryUsages.empty,
        gotData: state.listingPage.gotData,
        city: state.listingPage.submitterCity,
        price: state.listingPage.price,
        coverExist: state.listingPage.listingState === 'active' || state.listingPage.listingState === 'expired',
        isLoggedIn: state.loginReg.isLoggedIn,
        myLogin: state.userState.login,
        state: state.listingPage.listingState,
        submitterId: state.listingPage.submitterId,
        listingId: state.listingPage.listingId,
        listingDoesntExist: state.listingPage.listingDoesntExist,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        syncState: (id) => {
            dispatch(setFetchingAC(true));
            dispatch(fetchListingData(id));
        },

        checkUsages: (pns, doNotClear) => {
            dispatch(fetchSecondaryUsages(pns, doNotClear));
        },
        buy: (sellerId, listingId) => {
            dispatch(sealTheDeal(sellerId, listingId));
        },
        forceResizeImgs: (fullScreen) => {
            dispatch(listingGalleryFullScreened(fullScreen));
        }
    }
}

const ListingContainer = connect(mapStateToProps, mapDispatchToProps)(Listing);
export default ListingContainer;