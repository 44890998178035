import React from 'react'
import { Grid, Link } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box } from '@mui/system';
import { connect } from 'react-redux';
import transliterate from '../../utils/transliterate'
import { Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { rmFromFavAndFetchRemaining } from '../../redux/my-favorites'
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import ListingNoteRowContainer from './ListingNoteRow';
import { favCreateListingNote, favUpdateListingNote, favRemoveListingNote, favSetFetchingAC } from '../../redux/my-favorites'

const useStyles = makeStyles({
    header: {
        fontSize: 16,
        fontWeight: 700,
        color: 'black',
        marginBottom: 6,
        margin: 5,
        alignSelf: "stretch"
    },
    headerInverted: {
        fontSize: 16,
        fontWeight: 700,
        color: 'white',
        marginBottom: 6,
        margin: 5,
        alignSelf: "stretch"
    },
    content: {
        fontSize: 15,
        fontWeight: 500,
        color: 'black',
        marginBottom: 4,
        paddingLeft: 5,
    },
    contentInverted: {
        fontSize: 15,
        fontWeight: 500,
        color: 'white',
        marginBottom: 4,
        paddingLeft: 5,
    },
    state: {
        fontSize: 16,
        fontWeight: 600,
        color: 'black',
        marginBottom: 4,
        paddingLeft: 5,
    },
    stateInactive: {
        fontSize: 16,
        fontWeight: 600,
        color: 'white',
        marginBottom: 4,
        paddingLeft: 5,
    },
    link: {
        color: 'black',
        paddingRight: 15,
    },
    linkOfInactive: {
        color: 'white',
        paddingRight: 15,
    },
    iconButton: {
        color: '#202938',
        padding: '3px',
    },
});

/*
    Props:
        - imgUrl - URL of the thumbnail
        - title - title
        - submitter - submitter login
        - date - submission date
        - price - price
        - id - id of the listing
        - region - region of the submitter
        - city - submitter city
        - state - listing state
        - noteExist - boolean
        - noteText - text of the note
        - noteUpdated - note last update date time
*/

const FavoriteListingPreview = (props) => {
    const classes = useStyles();

    const calcBgColor = () => {
        switch (props.state) {
            case 'removed': case 'expired': return '#696969';
            default: break;
        }

        if (props.checked)
            return '#D0E7CB';

        if (props.odd)
            return '#FEFDFE';

        return '#F1F1F1';
    }

    const renderListingNoteRow = () => {
        if (props.noteExist) {
            return <ListingNoteRowContainer
                noteExist={props.noteExist}
                noteText={props.noteText}
                listingId={props.id}
                setFetchingCb={favSetFetchingAC}
                createListingNoteCb={favCreateListingNote}
                updateListingNoteCb={favUpdateListingNote}
                deleteListingNoteCb={favRemoveListingNote}
            />
        }
        return null;
    }

    const deleteMe = () => {
        props.rmCurrent(props.id);
    }

    const optionalRenderListingState = () => {
        if (props.state !== 'active') {
            return (
                <>
                    <Grid item>
                        <Typography className={props.state === 'active' ? classes.state : classes.stateInactive} gutterBottom>
                            {props.stateRu}
                        </Typography>
                    </Grid>
                </>
            )
        }
        return null;
    }

    return (
        <>
            <Box
                classes={classes.root}
                sx={{
                    border: '1px solid #f6f8f1',
                    borderRadius: '4px',
                    marginBottom: 1,
                    bgcolor: calcBgColor(),
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    wrap='wrap'
                    sx={{ flexGrow: 1 }}
                >
                    <Grid item sx={{ order: { sm: 1, md: 1 } }}>
                        <Link href={`/buy/${transliterate(props.title.split(' ').join('_'))}-${props.id}`}>
                            <Avatar
                                alt="Thumbnail"
                                src={props.imgUrl}
                                sx={{ width: 150, height: 150 }}
                                variant="square"
                            />
                        </Link>
                    </Grid>

                    <Grid item xs={7}>
                        <Link
                            href={`/buy/${transliterate(props.title.split(' ').join('_'))}-${props.id}`}
                            className={props.state === 'active' ? classes.link : classes.linkOfInactive}
                        >
                            <Typography className={props.state === 'active' ? classes.header : classes.headerInverted} gutterBottom>
                                {props.title}
                            </Typography>
                        </Link>

                        {renderListingNoteRow()}

                        <Typography className={props.state === 'active' ? classes.content : classes.contentInverted} >
                            <Link
                                href={`/profile/${props.submitter}`}
                                className={props.state === 'active' ? classes.link : classes.linkOfInactive}
                            >
                                {props.submitter}
                            </Link>
                            {props.date}
                        </Typography>
                        <Typography className={props.state === 'active' ? classes.content : classes.contentInverted} >
                            {props.region}, {props.city}
                        </Typography>
                    </Grid>

                    <Grid item >
                        <Stack>
                            <Typography className={props.state === 'active' ? classes.header : classes.headerInverted} gutterBottom>
                                {props.price}р.
                            </Typography>

                        </Stack>
                        {optionalRenderListingState()}
                    </Grid>

                    <Grid item >
                        <Stack direction={'column'}>
                            <Tooltip title="Удалить из избранного" placement="bottom">
                                <IconButton
                                    aria-label="delete"
                                    onClick={deleteMe}
                                >
                                    <DeleteIcon
                                        className={classes.iconButton}
                                        fontSize='medium'
                                    />
                                </IconButton>
                            </Tooltip>

                            {!props.noteExist &&
                                <ListingNoteRowContainer
                                    noteExist={props.noteExist}
                                    noteText={props.noteText}
                                    listingId={props.id}
                                    setFetchingCb={favSetFetchingAC}
                                    createListingNoteCb={favCreateListingNote}
                                    updateListingNoteCb={favUpdateListingNote}
                                    deleteListingNoteCb={favRemoveListingNote}
                                />
                            }

                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        id: ownProps.id,
        imgUrl: ownProps.imgUrl,
        title: ownProps.title,
        date: ownProps.date,
        viewsToday: ownProps.viewsToday,
        viewsTotal: ownProps.viewsTotal,
        price: ownProps.price,
        state: ownProps.state,
        type: ownProps.type,
        odd: ownProps.odd,
        noteExist: ownProps.noteExist,
        noteText: ownProps.noteText,
        noteUpdated: ownProps.noteUpdated,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        rmCurrent: (id) => {
            dispatch(rmFromFavAndFetchRemaining(id));
        }

    }
}

const FavoriteListingPreviewContainer = connect(mapStateToProps, mapDispatchToProps)(FavoriteListingPreview);
export default FavoriteListingPreviewContainer;