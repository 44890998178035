import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SellIcon from '@mui/icons-material/Sell';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Logout from '@mui/icons-material/Logout';
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from '@mui/icons-material/Menu';
import history from '../history/history';
import { connect } from "react-redux";
import { logout } from "../redux/login-reg-reducer"
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import BadgeIcon from '@mui/icons-material/Badge';


const useStyles = makeStyles((theme) => ({
    iconButton: {
        color: '#6dc353',
        padding: '3px',
    },
    login: {
        fontSize: 17,
        fontWeight: 500,
        color: '#6dc353',
    },
    drawer: {
        backgroundColor: '#202938',
        color: '#6dc353',
        fontFamily: 'Jura, Arial',
    }
}));

const HeaderMenu = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePersonal = () => {
        setAnchorEl(null);
        history.push('/personal')
    };
    const handleMyListings = () => {
        setAnchorEl(null);
        history.push('/personal/mylistings')
    };
    const handleMyFavorites = () => {
        setAnchorEl(null);
        history.push('/personal/favorites')
    };
    const handleMySubscriptions = () => {
        setAnchorEl(null);
        history.push('/personal/subscriptions')
    };
    const handleMyPurchases = () => {
        setAnchorEl(null);
        history.push('/personal/mypurchases')
    };
    const handleMySales = () => {
        setAnchorEl(null);
        history.push('/personal/mysales')
    };
    const handleMySettings = () => {
        setAnchorEl(null);
        history.push('/personal/settings')
    };
    const handleMyFeedback = () => {
        setAnchorEl(null);
        history.push('/personal/feedback')
    };
    const handleMyPage = () => {
        setAnchorEl(null);
        history.push(`/profile/${props.login}`)
    };

    const handleLogOut = () => {
        setAnchorEl(null);
        props.logout();
        history.push('/')
    };

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Меню">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <MenuIcon className={classes.iconButton} sx={{ width: 32, height: 32 }} />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        backgroundColor: '#202938',
                        color: '#6dc353',
                        fontFamily: 'Jura, Arial',

                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiButtonBase-root': {
                            fontFamily: 'Jura, Arial',
                            fontWeight: 600,
                        },
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handlePersonal}>
                    <ListItemIcon>
                        <AccountBoxIcon className={classes.iconButton} />
                    </ListItemIcon>
                    Личный кабинет
                </MenuItem>

                <Divider />

                <MenuItem onClick={handleMyListings}>
                    <ListItemIcon>
                        <SellIcon className={classes.iconButton} />
                    </ListItemIcon>
                    Мои объявления
                </MenuItem>
                <MenuItem onClick={handleMyFavorites}>
                    <ListItemIcon>
                        <ShoppingCartIcon className={classes.iconButton} />
                    </ListItemIcon>
                    Избранное
                </MenuItem>
                <MenuItem onClick={handleMySubscriptions}>
                    <ListItemIcon>
                        <NotificationsActiveIcon className={classes.iconButton} />
                    </ListItemIcon>
                    Подписки
                </MenuItem>
                <MenuItem onClick={handleMySettings}>
                    <ListItemIcon>
                        <SettingsIcon className={classes.iconButton} />
                    </ListItemIcon>
                    Настройки
                </MenuItem>

                <Divider />

                <MenuItem onClick={handleMyPurchases}>
                    <ListItemIcon>
                        <ShoppingBagIcon className={classes.iconButton} />
                    </ListItemIcon>
                    Купленное
                </MenuItem>
                <MenuItem onClick={handleMySales}>
                    <ListItemIcon>
                        <PointOfSaleIcon className={classes.iconButton} />
                    </ListItemIcon>
                    Проданное
                </MenuItem>
                <MenuItem onClick={handleMyFeedback}>
                    <ListItemIcon>
                        <ThumbUpIcon className={classes.iconButton} />
                    </ListItemIcon>
                    Отзывы
                </MenuItem>
                <MenuItem onClick={handleMyPage}>
                    <ListItemIcon>
                        <BadgeIcon className={classes.iconButton} />
                    </ListItemIcon>
                    Моя страница
                </MenuItem>

                <Divider />

                <MenuItem onClick={handleLogOut}>
                    <ListItemIcon>
                        <Logout className={classes.iconButton} />
                    </ListItemIcon>
                    Выйти
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        login: state.userState.login,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logout());
        },
    }
}

const HeaderMenuContainer = connect(mapStateToProps, mapDispatchToProps)(HeaderMenu);
export default HeaderMenuContainer;