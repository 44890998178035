import React from 'react'
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Stack } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import Tooltip from "@mui/material/Tooltip";
import HelpIconWithTooltip from '../entities/HelpIconWithTooltip';

const useStyles = makeStyles({
    root: {
        marginRight: 5,
        marginLeft: 5,
        marginBottom: 10,
        background: '#202938',
        paddingBottom: 0,
        paddingTop: 0,
        borderColor: 'white',
    },
    price: {
        fontSize: 25,
        fontWeight: 700,
        color: 'white',
        marginBottom: 10,
        marginTop: 10,
    },
    link: {
        color: '#6dc353',
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 5,
    },
    header: {
        fontSize: 16,
        fontWeight: 700,
        color: '#6dc353',
        marginBottom: 6,
    },
    content: {
        color: '#dddddd',
        marginBottom: 4,
        paddingLeft: 5,
    },
    contentGreen: {
        color: '#6dc353',
    },
    contentRed: {
        color: 'red',
    },
});

const ListingSubmitterCard = (props) => {
    const classes = useStyles();

    return (
        <>
            <Card className={classes.root} variant="outlined">
                <CardContent>
                    <Typography className={classes.price}>
                        {props.price}₽
                    </Typography>

                    <Stack direction='row' spacing={0}>
                        <PersonIcon sx={{ color: '#6dc353' }} />
                        <Link href={`/profile/${props.submitter}`}>
                            <Typography className={classes.link}>
                                {props.submitter}
                            </Typography>
                        </Link>
                    </Stack>

                    <Stack direction='row' spacing={1}>
                        <Stack direction='row' spacing={0}>
                            <HelpIconWithTooltip
                                darkMode
                                text="Первое число - это количество положительных минус количество отрицательных отзывов на продавца. Второе - процент положительных отзывов от общего их количества"
                            />
                            <Typography className={classes.content}>
                                Рейтинг
                            </Typography>
                        </Stack>

                        <Tooltip
                            title="Первое число - это количество положительных минус количество отрицательных отзывов на продавца. Второе - процент положительных отзывов от общего их количества"
                            placement="bottom"
                            arrow
                        >
                            <Typography className={props.submitterAbsRating > 0 ? classes.contentGreen : props.submitterAbsRating < 0 ? classes.contentRed : classes.content}>
                                {props.submitterAbsRating} ( {props.submitterRelRating}% )
                            </Typography>
                        </Tooltip>
                    </Stack>

                    <Typography className={classes.content}>
                        Продавец на сайте с {props.submitterWorksSince}
                    </Typography>

                    <Typography className={classes.content}>
                        {props.region}, {props.city}
                    </Typography>
                </CardContent>
            </Card>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    if (ownProps.preview) {
        return {
            submitter: state.userState.login,
            city: state.userState.city,
            region: state.userState.region,
            submitterAbsRating: state.userState.absRating,
            submitterRelRating: state.userState.relRating,
            submitterWorksSince: state.userState.registeredSince,
            price: ownProps.multi ? state.addPack.listingsData[ownProps.index].price : state.addSingle.price,
        }
    }
    return {
        submitter: state.listingPage.submitterLogin,
        city: state.listingPage.submitterCity,
        region: state.listingPage.submitterRegion,
        submitterAbsRating: state.listingPage.submitterRatingAbs,
        submitterRelRating: state.listingPage.submitterRatingRel,
        submitterWorksSince: state.listingPage.submitterWorksSince,
        price: state.listingPage.price,
    }
}

const ListingSubmitterCardContainer = connect(mapStateToProps, {})(ListingSubmitterCard);
export default ListingSubmitterCardContainer;