const SETTINGS_SET_FETCHING = 'SET_FETCHING';
const SETTINGS_UPDATE_NOTIFICATION_SETTINGS = 'UPDATE_NOTIFICATION_SETTINGS';

const SETTINGS_PICKUP_ADDRESS_UPDATE = 'PICKUP_ADDRESS_UPDATE';
const SETTINGS_TELEGRAM_NAME_UPDATE = 'TELEGRAM_NAME_UPDATE';
const SETTINGS_USE_WHATSAPP_TOGGLE = 'USE_WHATSAPP_TOGGLE';
const SETTINGS_WHATSAPP_NUM_UPDATE = 'WHATSAPP_NUM_UPDATE';

const SETTINGS_SET_STORE_DESCRIPTION = 'SET_STORE_DESCRIPTION';
const SETTINGS_SET_PAYMENT_OPTIONS = 'SET_PAYMENT_OPTIONS';
const SETTINGS_SET_DELIVERY_OPTIONS = 'SET_DELIVERY_OPTIONS';
const SETTINGS_SET_WARRANTY_OPTIONS = 'SET_WARRANTY_OPTIONS';
const SETTINGS_SET_DESCRIPTION_ADDON = 'SET_DESCRIPTION_ADDON';
const SETTINGS_TOGGLE_SHOW_COMPAT = 'TOGGLE_SHOW_COMPAT';

const SETTINGS_INITIAL_SYNC = 'INITIAL_SYNC';
const LOG_REG_LOGOUT = 'LOG_REG_LOGOUT';

let initialState = {
    isFetching: false,
    synced: false,

    // notifications settings:
    onFavPriceChangeEmail: true,
    onFavPriceChangeTg: true,
    onNewFeedbackEmail: true,
    onNewFeedbackTg: false,
    onSystemEventEmail: true,
    onSystemEventTg: true,
    onProjectNewsEmail: true,
    onProjectNewsTg: true,

    tgUserName: "",
    useWa: false,
    waPhoneNum: "",

    storeDescription: "",

    paymentOptions: "",
    pickupAdress: "",
    deliveryOptions: "",
    warrantyOptions: "",
    descriptionAddon: "",

    showCompatBlock: true,

    tgAlreadyBinded: false,
    tgBindId: '',
};

const mySettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SETTINGS_SET_FETCHING:
            return Object.assign({}, state, {
                isFetching: action.isFetching,
            });

        case SETTINGS_UPDATE_NOTIFICATION_SETTINGS:
            return Object.assign({}, state, {
                onFavPriceChangeEmail: action.onFavPriceChangeEmail,
                onFavPriceChangeTg: action.onFavPriceChangeTg,
                onNewFeedbackEmail: action.onNewFeedbackEmail,
                onNewFeedbackTg: action.onNewFeedbackTg,
                onSystemEventEmail: action.onSystemEventEmail,
                onSystemEventTg: action.onSystemEventTg,
                onProjectNewsEmail: action.onProjectNewsEmail,
                onProjectNewsTg: action.onProjectNewsTg,
            });

        case SETTINGS_SET_STORE_DESCRIPTION:
            return Object.assign({}, state, {
                storeDescription: action.description,
            });

        case SETTINGS_PICKUP_ADDRESS_UPDATE:
            return Object.assign({}, state, {
                pickupAdress: action.newAddr,
            });

        case SETTINGS_SET_PAYMENT_OPTIONS:
            return Object.assign({}, state, {
                paymentOptions: action.paymentOptions,
            });

        case SETTINGS_SET_DELIVERY_OPTIONS:
            return Object.assign({}, state, {
                deliveryOptions: action.deliveryOptions,
            });

        case SETTINGS_SET_WARRANTY_OPTIONS:
            return Object.assign({}, state, {
                warrantyOptions: action.warrantyOptions,
            });

        case SETTINGS_SET_DESCRIPTION_ADDON:
            return Object.assign({}, state, {
                descriptionAddon: action.descriptionAddon,
            });

        case SETTINGS_TOGGLE_SHOW_COMPAT:
            return Object.assign({}, state, {
                showCompatBlock: !state.showCompatBlock,
            });



        case SETTINGS_TELEGRAM_NAME_UPDATE:
            return Object.assign({}, state, {
                tgUserName: action.tgUserName,
            });

        case SETTINGS_USE_WHATSAPP_TOGGLE:
            return Object.assign({}, state, {
                useWa: !state.useWa,
                waPhoneNum: "",
            });

        case SETTINGS_WHATSAPP_NUM_UPDATE:
            return Object.assign({}, state, {
                waPhoneNum: action.waPhoneNum,
            });

        case SETTINGS_INITIAL_SYNC:
            return Object.assign({}, state, {
                tgUserName: action.settings.telegram,
                useWa: action.settings.whatsapp !== "",
                waPhoneNum: action.settings.whatsapp,
                storeDescription: action.settings.store_description,
                pickupAdress: action.settings.pickup_addr,
                paymentOptions: action.settings.payment_options,
                deliveryOptions: action.settings.delivery_options,
                warrantyOptions: action.settings.warranty_options,
                descriptionAddon: action.settings.description_addon,
                showCompatBlock: action.settings.show_compat,
                onFavPriceChangeEmail: action.settings.notify_fav_email,
                onFavPriceChangeTg: action.settings.notify_fav_tg,
                onNewFeedbackEmail: action.settings.notify_fb_email,
                onNewFeedbackTg: action.settings.notify_fb_tg,
                onSystemEventEmail: action.settings.notify_sys_email,
                onSystemEventTg: action.settings.notify_sys_tg,
                onProjectNewsEmail: action.settings.notify_news_email,
                onProjectNewsTg: action.settings.notify_news_tg,
                tgAlreadyBinded: action.settings.tg_chat_id !== "",
                tgBindId: action.settings.tg_bind_id,
                tgBindUrl: action.settings.tg_bind_url,
                synced: true,
                isFetching: false,
            });

        case LOG_REG_LOGOUT:
            return Object.assign({}, state, initialState);


        default:
            return state;
    }
};

export const setFetchingAC = (isFetching) => ({
    type: SETTINGS_SET_FETCHING,
    isFetching: isFetching
})

export const updateNotificationSettingsAC = (
    onFavPriceChangeEmail, onFavPriceChangeTg,
    onNewFeedbackEmail, onNewFeedbackTg,
    onSystemEventEmail, onSystemEventTg,
    onProjectNewsEmail, onProjectNewsTg
) => ({
    type: SETTINGS_UPDATE_NOTIFICATION_SETTINGS,
    onFavPriceChangeEmail: onFavPriceChangeEmail,
    onFavPriceChangeTg: onFavPriceChangeTg,
    onNewFeedbackEmail: onNewFeedbackEmail,
    onNewFeedbackTg: onNewFeedbackTg,
    onSystemEventEmail: onSystemEventEmail,
    onSystemEventTg: onSystemEventTg,
    onProjectNewsEmail: onProjectNewsEmail,
    onProjectNewsTg: onProjectNewsTg,
})

export const setStoreDescription = (description) => ({
    type: SETTINGS_SET_STORE_DESCRIPTION,
    description: description
})

export const pickupAddressUpdateAC = (newAddr) => ({
    type: SETTINGS_PICKUP_ADDRESS_UPDATE,
    newAddr: newAddr,
})

export const setPaymentOptions = (paymentOptions) => ({
    type: SETTINGS_SET_PAYMENT_OPTIONS,
    paymentOptions: paymentOptions
})

export const setDeliveryOptions = (deliveryOptions) => ({
    type: SETTINGS_SET_DELIVERY_OPTIONS,
    deliveryOptions: deliveryOptions
})

export const setWarrantyOptions = (warrantyOptions) => ({
    type: SETTINGS_SET_WARRANTY_OPTIONS,
    warrantyOptions: warrantyOptions
})

export const setDescriptionAddon = (descriptionAddon) => ({
    type: SETTINGS_SET_DESCRIPTION_ADDON,
    descriptionAddon: descriptionAddon
})

export const tgNameUpdateAC = (newName) => ({
    type: SETTINGS_TELEGRAM_NAME_UPDATE,
    tgUserName: newName,
})

export const toggleUseWaAC = () => ({ type: SETTINGS_USE_WHATSAPP_TOGGLE })

export const waNumUpdateAC = (num) => ({
    type: SETTINGS_WHATSAPP_NUM_UPDATE,
    waPhoneNum: num,
})

export const toggleShowCompatAC = () => ({ type: SETTINGS_TOGGLE_SHOW_COMPAT })

export const intialAC = (settings) => ({
    type: SETTINGS_INITIAL_SYNC,
    settings: settings,
})


export default mySettingsReducer;