import React, { useCallback, forwardRef, memo, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { confirmPhotosAC, setisFetchingAC } from "../../redux/add-1-listing-reducer";
import { Grid, Link } from "@material-ui/core";
import UploadIcon from '@mui/icons-material/Upload';
import { Box, Divider } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from "@mui/material/Tooltip";
import ImageUploading from "react-images-uploading";
import StyledButton from "../entities/StyledButton";
import Avatar from '@mui/material/Avatar';
import { Stack } from "@mui/material";
import { packConfirmPhotosAC, packConfirmStageIfLastPhotoAC, packSetIsFetchingAC } from "../../redux/add-multi-listings-reducer"
import Resizer from "react-image-file-resizer";
import { Typography } from '@material-ui/core';
import Spinner from "../Spinner";

const useStyles = makeStyles({
    icon: {
        color: '#6dc353',
        margin: 10,
    },
    iconDragNDrop: {
        color: 'white',
        margin: 10,
    },
    notice: {
        fontFamily: 'Jura',
        fontWeight: '600',
    }
});

const PhotoUploader = (props) => {
    const [err, setErr] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [images, setImages] = React.useState(props.photos);
    const thumbnails = React.useRef([]);
    const classes = useStyles();

    React.useEffect(() => {
        if (props.photos.length !== 0 && (images.length !== props.photos.length)) {
            setImages(props.photos.map((a) => ({ data_url: a.data_url, file: a.file, })));
        }
    }, [props.index]);

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        console.log(imageList);
        setImages(imageList);
    };

    let processed = 0;

    const resizeFile = (photo, index) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                photo,
                300,
                300,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                    thumbnails.current[index] = uri;
                    if (thumbnails.current.length === images.length) {
                        const result = images.map((a, index) => ({ ...a, thumbnail: thumbnails.current[index] }));
                        processed++;
                        if (processed === images.length) {
                            props.confirmPhotos(result);
                        }
                    }
                },
                "base64"
            );
        })

    async function generateThumbnail(photo, index) {
        await resizeFile(photo, index);
    }

    const onConfirm = () => {
        thumbnails.current = new Array(images.length);
        images.forEach(function (img, i) {
            generateThumbnail(img.file, i)
        })
    }

    const onError = (errors, files) => {
        console.log(errors);

        let reason;
        if (errors.maxNumber) {
            reason = "Максимальное число фото в объявлении - 5";
        }
        if (errors.acceptType) {
            reason = "Поддерживаются только фото с расширением jpg/jpeg";
        }
        if (errors.maxFileSize) {
            reason = "Максимальный размер одного фото - 5Мб";
        }
        if (errors.resolution) {
            reason = "Максимальное разрешение фото - 4000х4000 пикселей";
        }
        setErr(reason);
        setOpen(true);
    }

    if (props.isFetching) {
        return <Spinner />
    }

    return (
        <>
            <Typography className={classes.notice} gutterBottom>
                Максимум 5 фото, максимальный размер одного фото 5Мб
            </Typography>

            <Typography className={classes.notice} gutterBottom>
                Чтобы загрузить фото нажмите на темный прямоугольник с зеленой стрелкой ниже или перетащите фото на него
            </Typography>

            <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                onError={onError}
                maxNumber={5}
                maxFileSize={5242880}
                dataURLKey="data_url"
                acceptType={["jpg", "jpeg"]}
            >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps
                }) => (
                    <>

                        <StyledButton
                            label='Удалить все'
                            onClick={onImageRemoveAll}
                        />

                        <Tooltip title="Нажмите сюда чтобы загрузить фото или перетащите их в этот прямоугольник" placement="bottom">
                            <Avatar
                                variant="rounded"
                                sx={{
                                    width: 'auto',
                                    height: 150,
                                    bgcolor: isDragging ? '#202937' : '#253443'
                                }}

                                className={classes.icon}
                                onClick={onImageUpload}

                                {...dragProps}
                            >
                                <UploadIcon
                                    fontSize="large"
                                    className={isDragging ? classes.iconDragNDrop : classes.icon}
                                />
                            </Avatar>
                        </Tooltip>

                        <Divider />

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            alignContent='center'
                        >
                            {imageList.map((image, index) => (
                                <Grid item >
                                    <Stack direction='column' spacing={0}>
                                        <img src={image.data_url} alt="" width="200" />
                                        <StyledButton
                                            label='Удалить'
                                            onClick={() => onImageRemove(index)}
                                        />
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>

                        <Divider />

                        <StyledButton
                            label='Подтвердить'
                            onClick={() => {
                                //props.confirmPhotos(images);
                                if (props.photos.length > 0)
                                    props.setFething(true);
                                onConfirm();
                                setImages([]);
                            }}
                        />

                        <Snackbar
                            open={open}
                            autoHideDuration={10000}
                            onClose={handleSnackBarClose}
                            message={err}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                        />
                    </>
                )}
            </ImageUploading>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    if (ownProps.multi) {
        return {
            photos: state.addPack.listingsData[ownProps.index].photos.map((a) => ({ data_url: a.data_url, file: a.file, })),
            isFetching: state.addPack.isFetching,
            index: ownProps.index,
        }
    }
    return {
        photos: state.addSingle.photos,
        isFetching: state.addSingle.isFetching,
        index: 0,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    if (ownProps.multi) {
        return {
            confirmPhotos: (photos) => {
                dispatch(packConfirmPhotosAC(ownProps.index, photos));
                dispatch(packConfirmStageIfLastPhotoAC());
                if (ownProps.onConfirm) {
                    ownProps.onConfirm(ownProps.index);
                }
                dispatch(packSetIsFetchingAC(false));
            },
            setFething: (fetching) => {
                dispatch(packSetIsFetchingAC(fetching));
            }
        }
    }
    return {
        confirmPhotos: (photos) => {
            dispatch(confirmPhotosAC(photos));
            dispatch(setisFetchingAC(false));
        },
        setFething: (fetching) => {
            dispatch(setisFetchingAC(fetching));
        }
    }
}

const PhotoUploaderContainer = connect(mapStateToProps, mapDispatchToProps)(PhotoUploader);
export default PhotoUploaderContainer;
