import fetch from "cross-fetch";

const EXACT_SALE_SET_FETCHING = 'EXACT_SALE_SET_FETCHING';
const EXACT_SALE_DATA_RECEIVED = 'EXACT_SALE_DATA_RECEIVED';
const EXACT_SALE_DATA_UPDATED = 'EXACT_SALE_DATA_UPDATED';
const EXACT_SALE_SET_ERR = 'EXACT_SALE_SET_ERR';
const LOG_REG_LOGOUT = 'LOG_REG_LOGOUT';

let initialState = {
    isFetching: true,

    sale: {},

    errorCondition: false,
    errWhat: '',
};

const exactSaleReducer = (state = initialState, action) => {
    switch (action.type) {
        case EXACT_SALE_SET_FETCHING:
            return Object.assign({}, state, {
                isFetching: action.isFetching,
            });

        case EXACT_SALE_DATA_RECEIVED:
        case EXACT_SALE_DATA_UPDATED:
            return Object.assign({}, state, {
                isFetching: false,
                sale: action.sale,
            });

        case EXACT_SALE_SET_ERR:
            return Object.assign({}, state, {
                errorCondition: true,
                errWhat: action.what,
                isFetching: false,
            });

        case LOG_REG_LOGOUT:
            return Object.assign({}, state, initialState);

        default:
            return state;
    }
};

export const saleSetFetchingAC = (isFetching) => ({
    type: EXACT_SALE_SET_FETCHING,
    isFetching: isFetching
})

export const saleReceiveData = (data) => ({
    type: EXACT_SALE_DATA_RECEIVED,
    sale: data.sale,
})

export const saleReceiveUpdates = (data) => ({
    type: EXACT_SALE_DATA_UPDATED,
    sale: data.saleUpdate,
})

export const saleSetError = (what) => ({
    type: EXACT_SALE_SET_ERR,
    what: what,
})

export function fetchExactSale(id) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + `/api/listings/sale/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(saleReceiveData(json.data));
                    } else {
                        dispatch(saleSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function exactSetSellerConfirm(deal_id) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/sellerconfirm', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                dealId: deal_id,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(saleReceiveUpdates(json.data));
                    } else {
                        dispatch(saleSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function exactSellerDiscard(deal_id) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/sellerdiscard', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                dealId: deal_id,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(saleReceiveUpdates(json.data));
                    } else {
                        dispatch(saleSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function exactSellerSoldLast(deal_id, listing_id) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/soldlast', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                dealId: deal_id,
                listingId: listing_id,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(saleReceiveUpdates(json.data));
                    } else {
                        dispatch(saleSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function exactAddSellerFeedback(deal_id, fbType, fbText) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/selleraddfb', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                dealId: deal_id,
                fbType: fbType,
                fbText: fbText,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(saleReceiveUpdates(json.data));
                    } else {
                        dispatch(saleSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}


export default exactSaleReducer;