import { FormGroup2pn } from "../../FormGroup";
import React from "react";
import {
    updatePnlPn1AC, updatePnlPn2AC,
} from "../../../redux/add-composition-reducer";
import { connect } from "react-redux";

const PnlAddForm = (props) => {

    return (
        <>
            <FormGroup2pn
                title={"Матрица/LED-панель"}
                description={"Внимательно осмотрите плату и наклейки на ней. После этого укажите здесь партнамберы, которые видите.\n" +
                    "                            Каждый партнамбер указывайте в отдельном поле.\n" +
                    "                            Например, на плате вы видите \"BN41-01976B\", а на наклейке \"BN96-26401N\".\n" +
                    "                            В поле \"Партнамбер 1\" укажите \"BN41-01976B\", а в поле \"Партнамбер 2\" - \"BN96-26401N\"."}
                val_pn1={props.pnl1}
                val_pn2={props.pnl2}
                cb_pn1={props.updatePnlPn1}
                cb_pn2={props.updatePnlPn2}
                pn1_err={props.pnl1Err}
                pn2_err={props.pnl2Err}
                pn1_errDesc={props.pnl1ErrWhat}
                pn2_errDesc={props.pnl2ErrWhat}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        pnl1: state.addCompositionPage.pnl_pn1,
        pnl2: state.addCompositionPage.pnl_pn2,
        pnl1Err: state.addCompositionPage.pnl_pn1Err,
        pnl2Err: state.addCompositionPage.pnl_pn2Err,
        pnl1ErrWhat: state.addCompositionPage.pnl_pn1ErrWhat,
        pnl2ErrWhat: state.addCompositionPage.pnl_pn2ErrWhat,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updatePnlPn1: (val, err) => { dispatch(updatePnlPn1AC(val, err)); },
        updatePnlPn2: (val, err) => { dispatch(updatePnlPn2AC(val, err)); },
    }
}
const PnlAddFormContainer = connect(mapStateToProps, mapDispatchToProps)(PnlAddForm);
export default PnlAddFormContainer;