import fetch from "cross-fetch";

const MY_LIST_SET_FETCHING = 'MY_LIST_SET_FETCHING';
const MY_LIST_DATA_RECEIVED = 'MY_LIST_DATA_UPDATED';

const MY_LIST_FILTER_STATE_UPDATED = 'MY_LIST_FILTER_STATE_UPDATED';
const MY_LIST_FILTER_TYPE_UPDATED = 'MY_LIST_FILTER_TYPE_UPDATED';
const MY_LIST_SORT_TYPE_UPDATED = 'MY_LIST_SORT_TYPE_UPDATED';

const MY_LIST_TOGGLE_SELECT_LISTING = 'MY_LIST_TOGGLE_SELECT_LISTING';
const MY_LIST_SET_ERR = 'MY_LIST_SET_ERR';
const MY_LIST_TOGGLE_SELECT_ALL_FILTERED = 'MY_LIST_TOGGLE_SELECT_ALL_FILTERED';

const MY_LIST_CHOSEN_KEEP_EXPIRED = 'MY_LIST_CHOSEN_KEEP_EXPIRED';
const MY_LIST_CHOSEN_KEEP_ACTIVE = 'MY_LIST_CHOSEN_KEEP_ACTIVE';
const MY_LIST_CHOSEN_KEEP_NONREMOVED = 'MY_LIST_CHOSEN_KEEP_NONREMOVED';

const MY_LIST_RECEIVE_HW_TYPES_LIST = 'MY_LIST_RECEIVE_HW_TYPES_LIST';

const LOG_REG_LOGOUT = 'LOG_REG_LOGOUT';

let initialState = {
    isFetching: true,

    listingsTotal: 0,
    activeListings: 0,
    expiredListings: 0,
    removedListings: 0,

    listings: [{},], //listings data:...

    // filtered
    filteredListings: [],
    stateFilter: 'active',
    typeFilter: 'any',
    sortType: 'date',

    // picked listings list (their ids)
    selected: [],

    // errors
    errorCondition: false,
    errWhat: "",

    hwTypes: [],

};

const sortAndFilter = (stateFilter, typeFilter, sortType, listings) => {
    let result = listings;
    if (stateFilter !== 'any') {
        result = result.filter(function (item) { return item.state === stateFilter; });
    }

    if (typeFilter !== 'any') {
        result = result.filter(function (item) { return item.type === typeFilter; });
    }

    switch (sortType) {
        case 'date':
            result.sort(function (a, b) {
                return parseInt(b.id, 10) - parseInt(a.id, 10);
            });
            break;

        case 'price-asc':
            result.sort(function (a, b) {
                return parseInt(a.price, 10) - parseInt(b.price, 10);
            });
            break;

        case 'price-desc':
            result.sort(function (a, b) {
                return parseInt(b.price, 10) - parseInt(a.price, 10);
            });
            break;

        default: break;
    }

    return result;
}

const myListingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case MY_LIST_SET_FETCHING:
            return Object.assign({}, state, {
                isFetching: action.isFetching,
            });

        case MY_LIST_DATA_RECEIVED:
            return Object.assign({}, state, {
                isFetching: false,
                listings: action.listings,
                filteredListings: sortAndFilter('active', 'any', 'date', action.listings),
                listingsTotal: action.listingsTotal,
                activeListings: action.listings.filter(function (item) { return item.state === "active"; }).length,
                expiredListings: action.listings.filter(function (item) { return item.state === "expired"; }).length,
                removedListings: action.listings.filter(function (item) { return item.state === "removed"; }).length,
                stateFilter: 'active',
                typeFilter: 'any',
                selected: [],
                errorCondition: false,
                errWhat: '',
            });

        case MY_LIST_FILTER_STATE_UPDATED:
            return Object.assign({}, state, {
                stateFilter: action.stateFilter,
                filteredListings: sortAndFilter(action.stateFilter, state.typeFilter, state.sortType, state.listings),
                selected: [],
            });

        case MY_LIST_FILTER_TYPE_UPDATED:
            return Object.assign({}, state, {
                typeFilter: action.typeFilter,
                filteredListings: sortAndFilter(state.stateFilter, action.typeFilter, state.sortType, state.listings),
                selected: [],
            });

        case MY_LIST_SORT_TYPE_UPDATED:
            return Object.assign({}, state, {
                sortType: action.sortType,
                filteredListings: sortAndFilter(state.stateFilter, state.typeFilter, action.sortType, state.listings),
            });

        case MY_LIST_TOGGLE_SELECT_LISTING:
            return Object.assign({}, state, {
                selected: state.selected.includes(action.id) ?
                    state.selected.filter(item => item !== action.id) : state.selected.concat(action.id)
            });

        case MY_LIST_SET_ERR:
            return Object.assign({}, state, {
                errorCondition: true,
                errWhat: action.what,
                isFetching: false,
            });

        case MY_LIST_TOGGLE_SELECT_ALL_FILTERED:
            return Object.assign({}, state, {
                selected: state.selected.length === state.filteredListings.length ?
                    [] : state.filteredListings.map(a => a.id)
            });

        case MY_LIST_CHOSEN_KEEP_ACTIVE:
            return Object.assign({}, state, {
                selected: state.listings.filter(function (item) {
                    return state.selected.includes(item.id) && item.state === 'active';
                }).map(a => a.id)
            });

        case MY_LIST_CHOSEN_KEEP_EXPIRED:
            return Object.assign({}, state, {
                selected: state.listings.filter(function (item) {
                    return state.selected.includes(item.id) && item.state === 'expired';
                }).map(a => a.id)
            });

        case MY_LIST_CHOSEN_KEEP_NONREMOVED:
            return Object.assign({}, state, {
                selected: state.listings.filter(function (item) {
                    return state.selected.includes(item.id) && item.state !== 'removed';
                }).map(a => a.id)
            });

        case MY_LIST_RECEIVE_HW_TYPES_LIST:
            return Object.assign({}, state, {
                hwTypes: [{ id: 99, type: 'any', text: 'Любой' }].concat(action.types),
            });

        case LOG_REG_LOGOUT:
            return Object.assign({}, state, initialState);


        default:
            return state;
    }
};

export const setFetchingAC = (isFetching) => ({
    type: MY_LIST_SET_FETCHING,
    isFetching: isFetching
})

export const updatedUserData = (data) => ({
    type: MY_LIST_DATA_RECEIVED,
    listingsTotal: data.listingsTotal,
    listings: data.listings,
})

export const stateFilterUpdated = (newValue) => ({
    type: MY_LIST_FILTER_STATE_UPDATED,
    stateFilter: newValue,
})

export const typeFilterUpdated = (newValue) => ({
    type: MY_LIST_FILTER_TYPE_UPDATED,
    typeFilter: newValue,
})

export const sortTypeUpdated = (newValue) => ({
    type: MY_LIST_SORT_TYPE_UPDATED,
    sortType: newValue,
})

export const myListingsToggleSelection = (id) => ({
    type: MY_LIST_TOGGLE_SELECT_LISTING,
    id: id,
})

export const myListingsSetError = (what) => ({
    type: MY_LIST_SET_ERR,
    what: what,
})

export const myListingsToggleAllFilteredSelection = () => ({
    type: MY_LIST_TOGGLE_SELECT_ALL_FILTERED,
})

export const myListingsChosenKeepExpired = () => ({
    type: MY_LIST_CHOSEN_KEEP_EXPIRED,
})

export const myListingsChosenKeepActive = () => ({
    type: MY_LIST_CHOSEN_KEEP_ACTIVE,
})

export const myListingsChosenKeepNonremoved = () => ({
    type: MY_LIST_CHOSEN_KEEP_NONREMOVED,
})

export const myListingsReceiveHwTypesListAC = (json) => ({
    type: MY_LIST_RECEIVE_HW_TYPES_LIST,
    types: json.data.types
})




export function fetchMyListings() {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/my', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(updatedUserData(json.data));
                        dispatch(stateFilterUpdated('active'));
                        dispatch(typeFilterUpdated('any'));
                    } else {
                        dispatch(myListingsSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function activateChosen(selected) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/activate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                ids: selected,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(updatedUserData(json.data));
                        dispatch(stateFilterUpdated('active'));
                        dispatch(typeFilterUpdated('any'));
                    } else {
                        dispatch(myListingsSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function deactivateChosen(selected) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/deactivate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                ids: selected,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(updatedUserData(json.data));
                        dispatch(stateFilterUpdated('active'));
                        dispatch(typeFilterUpdated('any'));
                    } else {
                        dispatch(myListingsSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function removeChosen(selected) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/remove', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                ids: selected,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(updatedUserData(json.data));
                        dispatch(stateFilterUpdated('active'));
                        dispatch(typeFilterUpdated('any'));
                    } else {
                        dispatch(myListingsSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function myListingsCreateListingNote(id, newText) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/addnote', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                listing_id: id,
                note_text: newText,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(fetchMyListings());
                    } else {
                        dispatch(myListingsSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function myListingsUpdateListingNote(id, newText) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/updatenote', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                listing_id: id,
                note_text: newText,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(fetchMyListings());
                    } else {
                        dispatch(myListingsSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function myListingsRemoveListingNote(id) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/removenote', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                listing_id: id,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(fetchMyListings());
                    } else {
                        dispatch(myListingsSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function myListingsFetchHwTypes() {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/tech/tv/hwtypes', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    dispatch(myListingsReceiveHwTypesListAC(json))
                }
            )
    }
}

export function myListingsUpdateListingPrice(id, oldPrice, newPrice, summary) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/updateprice', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                listing_id: id,
                old_price: oldPrice,
                new_price: newPrice,
                summary: summary,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(fetchMyListings());
                    } else {
                        dispatch(myListingsSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export default myListingsReducer;