import * as React from 'react';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@material-ui/core';
import HelperCard from '../../components/settings/HelperCard';
import StyledButton from '../entities/StyledButton';
import StyledCheckBox from '../entities/StyledCheckBox';
import { toggleShowCompatAC } from '../../redux/my-settings-reducer';
import { connect } from 'react-redux';
import SaveIcon from '@mui/icons-material/Save';
import Snackbar from '@mui/material/Snackbar';

const ShowCompatBlock = (props) => {
    const [err, setErr] = React.useState('');
    const [open, setOpen] = React.useState(false);

    const toggleShowCompat = (e) => {
        props.toggleShowCompat();
    }

    async function post(descData) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/users/setsettings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(descData)
        })
            .then(data => data.json())
    }

    const sendShowCompat = async () => {
        console.log("Sending data");
        console.log(props.showCompat);

        const response = await post({
            id: props.id,
            show_compat: props.showCompat,
        });

        setErr(response.statusCode === 200 ? "Сделано!" : "Что-то пошло не так. Извините и попробуйте еще раз позже пожалуйста");
        setOpen(true);
    }

    const submitShowCompat = () => {
        sendShowCompat();
    };

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <AltRouteIcon sx={{ marginRight: 1 }} />
                    Отображение блока совместимости
                </AccordionSummary>

                <AccordionDetails>
                    <StyledCheckBox
                        label="Отображать блок совместимости в моих объявлениях"
                        checked={props.showCompat}
                        onChange={toggleShowCompat}
                    />

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        alignContent='center'
                    >
                        <Grid item>
                            <StyledButton
                                icon={<SaveIcon />}
                                label="Сохранить"
                                onClick={submitShowCompat}
                            />
                        </Grid>
                    </Grid>

                    <HelperCard
                        summary="Что изменится?"
                        helperText={
                            "Если эта галочка проставлена, мы будет показывать в ваших объявлениях список моделей ТВ, к которым может подойти запчасть из объявления. Список формируется исходя из информации в базе данных составов ТВ и будет содержать уведомление о том что предоставленная информация требует проверки. Если галочка снята - мы не будем показывать этот блок в ваших объявлениях"
                        } />

                    <Snackbar
                        open={open}
                        autoHideDuration={10000}
                        onClose={handleSnackBarClose}
                        message={err}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    />

                </AccordionDetails>
            </Accordion>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        id: state.userState.userId,
        showCompat: state.settings.showCompatBlock,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleShowCompat: () => {
            dispatch(toggleShowCompatAC());
        },
    }
}

const ShowCompatBlockContainer = connect(mapStateToProps, mapDispatchToProps)(ShowCompatBlock);
export default ShowCompatBlockContainer;