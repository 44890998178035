import * as React from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StyledPhoneInput from '../../components/entities/StyledPhoneInput';
import { Grid } from '@material-ui/core';
import HelperCard from '../../components/settings/HelperCard';
import StyledButton from '../entities/StyledButton';
import StyledCheckBox from '../entities/StyledCheckBox';
import { toggleUseWaAC, waNumUpdateAC } from '../../redux/my-settings-reducer';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

const BindWhatsappBlock = (props) => {
    const [err, setErr] = React.useState('');
    const [open, setOpen] = React.useState(false);

    const phoneChanged = (value, country, e, formattedValue) => {
        console.log(value, country, e, formattedValue);
        props.updateWaPhone(value);
    };

    const toggleUseWa = (e) => {
        props.toggleUseWa();
    }

    async function post(descData) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/users/setsettings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(descData)
        })
            .then(data => data.json())
    }

    const sendPhone = async () => {
        console.log("Sending phone");
        console.log(props.waPhoneNum);

        const response = await post({
            id: props.id,
            whatsapp: props.waPhoneNum,
        });

        setErr(response.statusCode === 200 ? "Сделано!" : "Что-то пошло не так. Извините и попробуйте еще раз позже пожалуйста");
        setOpen(true);
    }

    const submitWaPhone = () => {
        sendPhone();
    };

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const useSameNum = () => {
        props.updateWaPhone(props.phone);
    }

    const renderUseSameNumButton = () => {
        if (props.phone) {
            return (
                <>
                    <Grid item>
                        <StyledButton
                            label="Тот же номер"
                            onClick={useSameNum}
                        />
                    </Grid>
                </>
            )
        }
    }

    const renderPhoneNumFields = () => {
        if (props.useWa) {
            return (
                <>
                    <Box>
                        Номер телефона, к которому привязан WhatsApp:
                    </Box>

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        alignContent='center'
                    >
                        {renderUseSameNumButton()}
                        <Grid item>
                            <StyledPhoneInput
                                value={props.waPhoneNum}
                                onChange={phoneChanged}
                            />
                        </Grid>
                        <Grid item>
                            <StyledButton
                                icon={<WhatsAppIcon />}
                                label="Сохранить"
                                disabled={(props.waPhoneNum.length !== 11)}
                                onClick={submitWaPhone}
                            />
                        </Grid>
                    </Grid>

                    <HelperCard helperText={
                        "Рекомендуется заполнить это поле, хотя это необязательно. Зная номер телефона, мы сможем автоматически подставлять в Ваши объявления ссылку, нажимая на которую, покупатель автоматически перейдет в Whatsapp на своем устройстве, где уже будет открыт диалог с Вами и подставлена ссылка на объявление, из которого он перешел. Если вы указали номер телефона выше, то номер подставится автоматически. Если для связи по Whatsapp вы используете другой номер - укажите его здесь"
                    } />
                </>
            )
        }
        else {
            return (
                <>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        alignContent='center'
                    >
                        <Grid item>
                            <StyledButton
                                icon={<WhatsAppIcon />}
                                label="Сохранить"
                                onClick={submitWaPhone}
                                disabled={props.useWa}
                            />
                        </Grid>
                    </Grid>
                </>
            )
        }
    }

    return (
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <WhatsAppIcon sx={{ marginRight: 1 }} />
                    WhatsApp
                </AccordionSummary>

                <AccordionDetails>
                    <StyledCheckBox
                        label="Я использую WhatsApp для связи с покупателями"
                        checked={props.useWa}
                        onChange={toggleUseWa}
                    />

                    {renderPhoneNumFields()}

                    <Snackbar
                        open={open}
                        autoHideDuration={10000}
                        onClose={handleSnackBarClose}
                        message={err}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    />
                </AccordionDetails>
            </Accordion>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        id: state.userState.userId,
        useWa: state.settings.useWa,
        waPhoneNum: state.settings.waPhoneNum,
        phone: state.userState.phone,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleUseWa: () => {
            dispatch(toggleUseWaAC());
        },
        updateWaPhone: (phoneNum) => {
            dispatch(waNumUpdateAC(phoneNum));
        }
    }
}

const BindWhatsappBlockContainer = connect(mapStateToProps, mapDispatchToProps)(BindWhatsappBlock);
export default BindWhatsappBlockContainer;