import { React, useEffect } from 'react'
import { connect } from "react-redux";
import { Container } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import {
    stateFilterUpdated, typeFilterUpdated, sortTypeUpdated,
    myListingsFetchHwTypes
} from '../../redux/my-listings-reducer';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import StyledSelect from '../entities/StyledSelect';
import Box from '@mui/material/Box';

const useStyles = makeStyles({
    text: {
        color: 'black',
        fontWeight: 600,
        padding: '5px',
        paddingBottom: '8px'
    },
});

const MyListingsFilters = (props) => {
    const classes = useStyles();
    useEffect(() => {
        props.fetchHwTypes();
    }, []);

    const sort = [
        { text: 'Дате публикации', value: 'date' },
        { text: 'Цене, по возрастанию', value: 'price-asc' },
        { text: 'Цене, по убыванию', value: 'price-desc' },
    ];

    const stateFilter = [
        { text: 'Активные', filter: 'active' },
        { text: 'Устаревшие', filter: 'expired' },
        { text: 'Удаленные', filter: 'removed' },
        { text: 'Все', filter: 'any' },
    ];

    const onTypeChange = (type, index) => {
        props.typeFilterChanged(props.hwTypesList[index].type);
    };

    const onActivityChange = (type, index) => {
        props.stateFilterChanged(stateFilter[index].filter);
    };

    const onSortTypeChange = (type, index) => {
        props.sortTypeChanged(sort[index].value);
    };

    return (
        <>
            <Box sx={{
                marginBottom: '10px',
                bgcolor: '#cccccc',
                border: '1px solid black',
                borderRadius: '4px',
                bgcolor: '#f3f3f3',
                paddingTop: '8px',
                paddingBottom: '5px',
            }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    alignContent='center'
                >

                    <Grid item >
                        <Typography className={classes.text}>Фильтры: </Typography>
                        <Container sx={{ minWidth: 250, marginBottom: '5px' }}>
                            <StyledSelect
                                label={'По активности'}
                                items={stateFilter.map(a => a.text)}
                                cb={onActivityChange}
                            />
                        </Container>

                        <Container sx={{ minWidth: 250, marginBottom: '5px' }}>
                            <StyledSelect
                                label="По типу"
                                items={props.hwTypesList.map(a => a.text.split('/')[0])}
                                cb={onTypeChange}
                            />
                        </Container>
                    </Grid>

                    <Grid item >
                        <Typography className={classes.text}>Сортировка: </Typography>
                        <Container sx={{ minWidth: 250, marginBottom: '5px' }}>
                            <StyledSelect
                                label={'Сортировать по...'}
                                items={sort.map(a => a.text)}
                                cb={onSortTypeChange}
                            />
                        </Container>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        hwTypesList: state.myListingsPage.hwTypes,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchHwTypes: () => {
            dispatch(myListingsFetchHwTypes());
        },
        stateFilterChanged: (newValue) => {
            dispatch(stateFilterUpdated(newValue));
        },
        typeFilterChanged: (newValue) => {
            dispatch(typeFilterUpdated(newValue));
        },
        sortTypeChanged: (newValue) => {
            dispatch(sortTypeUpdated(newValue));
        },
    }
}

const MyListingsFiltersContainer = connect(mapStateToProps, mapDispatchToProps)(MyListingsFilters);
export default MyListingsFiltersContainer;