import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

/*
Props:
    label - text to be displayed
    onChange - cb 
    checked - initial state
    disabled - disabled state
*/

const StyledCheckBox = (props) => {
    return (
        <>

            <FormControlLabel
                disableTypography
                sx={{ fontFamily: 'Jura', color: 'black', fontWeight: 700, fontSize: '12px' }}
                control={<Checkbox
                    sx={{
                        '&.Mui-checked': {
                            color: '#6dc353',
                            backgroundColor: 'white',
                        },
                    }}
                />}
                label={props.label}
                onChange={props.onChange}
                checked={props.checked}
                disabled={props.disabled}
            />
        </>
    );
}

export default StyledCheckBox;