import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        borderColor: '#000000',
        borderRadius: 4,
        '&:hover': {
            borderColor: '#000000',
        },
        '&:focus': {
            borderColor: '#000000',
        },
        fontWeight: 600,
    },
    button: {
        margin: theme.spacing(1),
        whiteSpace: 'nowrap',
    },
    label: {
        color: '#6dc353',
        textTransform: 'capitalize',
    },
    container: {
        padding: 0,
        margin: 0,
        display: 'flex',
        justifyContent: 'center'
    }
}));

const StyledButton = (props) => {
    const classes = useStyles();

    return (
        <>
            <Container className={classes.container} >
                <Button
                    variant={props.variant ? props.variant : "contained"}
                    color="primary"
                    type={props.type}
                    className={classes.button}
                    startIcon={props.icon}
                    size={props.size ? props.size : "medium"}
                    classes={{
                        root: classes.root,
                        label: classes.label,
                    }}
                    onClick={props.onClick}
                    disabled={props.disabled}
                > {props.label} </Button>
            </Container>
        </>
    )
}

export default StyledButton;