import React from 'react'
import ProfileHeaderContainer from '../../components/ProfileHeader';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import NotificationSettingsBlockContainer from '../../components/settings/NotificationBlock';
import BindPhoneBlockContainer from '../../components/settings/BindPhoneBlock';
import BindWhatsappBlockContainer from '../../components/settings/BindWhatsappBlock';
import SetLocationBlock from '../../components/settings/SetLocationBlock';
import ChangePasswordBlock from '../../components/settings/ChangePasswordBlock';
import BindTelegramBlockContainer from '../../components/settings/BindTelegramBlock';
import PickupAddressBlockContainer from '../../components/settings/PickupAddress';
import StoreDescriptionBlockContainer from '../../components/settings/StoreDescription';
import MyPayOptionsBlockContainer from '../../components/settings/MyPayOptions';
import MyDeliveryOptionsBlockContainer from '../../components/settings/MyDeliveryOptions';
import MyWarrantyOptionsBlockContainer from '../../components/settings/MyWarrantyOptions';
import DescriptionAddonBlockContainer from '../../components/settings/DescriptionAddon';
import ShowCompatBlockContainer from '../../components/settings/ShowCompatBlock';
import Spinner from '../../components/Spinner';
import { connect } from "react-redux";
import MetaTags from 'react-meta-tags';
import Unauthorized from '../../components/entities/Unauthed';
import { showLoginDialogAC } from "../../redux/login-reg-reducer";
import BindTelegramNotificationsBlockContainer from '../../components/settings/BindTgNotificationsBlock';

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
        marginTop: 10,
        marginBottom: 10,
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
}));

const UserSettings = (props) => {
    const classes = useStyles();

    if (!props.isLoggedIn) {
        props.showLoginDialog();
        return (
            <>
                <Unauthorized authCb={props.showLoginDialog} />
            </>
        )
    }

    const render = () => {
        if (!props.synced) {
            return <Spinner />
        }
        return (
            <>
                <ChangePasswordBlock />
                <SetLocationBlock />
                <StoreDescriptionBlockContainer />

                <h2>Уведомления</h2>
                <BindTelegramNotificationsBlockContainer />
                <NotificationSettingsBlockContainer />

                <h2>Способы связи в объявлениях</h2>
                <BindPhoneBlockContainer />
                <BindWhatsappBlockContainer />
                <BindTelegramBlockContainer />
                <PickupAddressBlockContainer />

                <h2>Шаблоны в объявлениях</h2>
                <MyPayOptionsBlockContainer />
                <MyDeliveryOptionsBlockContainer />
                <MyWarrantyOptionsBlockContainer />
                <DescriptionAddonBlockContainer />
                <ShowCompatBlockContainer />
            </>
        )
    }

    return (
        <>
            <MetaTags>
                <title>Настройки - naTextolite</title>
            </MetaTags>

            <Breadcrumbs aria-label="breadcrumb">
                <Link color="textPrimary" href="/" className={classes.link}>
                    <HomeIcon className={classes.icon} />
                    naTeXtolite
                </Link>
                <Link color="textPrimary" href="/personal" className={classes.link}>
                    <AccountBoxIcon className={classes.icon} />
                    Личный кабинет
                </Link>
                <Typography color="textPrimary" className={classes.link}>
                    <SettingsIcon className={classes.icon} />
                    Настройки
                </Typography>
            </Breadcrumbs>

            <h1>Настройки</h1>
            <ProfileHeaderContainer />

            <h2>Персональные настройки</h2>
            {render()}
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        synced: state.settings.synced,
        isLoggedIn: state.loginReg.isLoggedIn,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showLoginDialog: () => {
            dispatch(showLoginDialogAC());
        },
    }
}

const UserSettingsContainer = connect(mapStateToProps, mapDispatchToProps)(UserSettings);
export default UserSettingsContainer;