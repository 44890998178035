import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

/* Props:
    - id: formId
    - label: text to be displayed as summary
    - items: array of the items to be displayed as options (each item is an object with 2 props: label and value)
    - defaultValue: default selected value
    - onChange: callback on value change
*/

const RadioRow = (props) => {
    const genItemList = (itemsArr) => {
        const items = []
        for (const [index, value] of itemsArr.entries()) {
            items.push(
                <FormControlLabel
                    value={value.value}
                    disableTypography
                    control={
                        <Radio
                            size="small"
                            sx={{
                                color: 'black',
                                '& .MuiSvgIcon-root': {
                                    fontSize: 16,
                                },
                                '&.Mui-checked': {
                                    color: '#6dc353',
                                },
                            }}
                        />}
                    label={value.label}
                    key={value.value}
                    sx={{ fontFamily: 'Jura', fontWeight: 500 }}
                />
            );
        }
        return items;
    }

    return (
        <FormControl>
            <FormLabel
                id={props.id}
                sx={{
                    fontFamily: 'Jura',
                    fontWeight: 900,
                    color: 'black',
                }}
            >
                {props.label}
            </FormLabel>
            <RadioGroup
                /*row*/
                aria-labelledby={props.id}
                defaultValue={props.defaultValue}
                name="radio-buttons-group"
                onChange={props.onChange}
            >
                {genItemList(props.items)}
            </RadioGroup>
        </FormControl>
    );
}

export default RadioRow;