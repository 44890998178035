import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Stack } from '@mui/material';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { connect } from "react-redux";

const useStyles = makeStyles({
    root: {
        marginRight: 5,
        marginLeft: 5,
        marginBottom: 10,
        background: '#202938',
        paddingBottom: 0,
        paddingTop: 0,
        borderColor: 'white',
    },
    header: {
        fontSize: 16,
        fontWeight: 700,
        color: '#6dc353',
        marginBottom: 6,
    },
    content: {
        color: 'white',
        marginBottom: 4,
        paddingLeft: 5,
    },
    notice: {
        color: 'white',
        fontWeight: 500,
        marginBottom: 4,
    },
    warning: {
        paddingTop: 10,
        color: '#6dc353',
        fontWeight: 600,
        marginBottom: 4,
    },
    positive: {
        color: '#6dc353',
    },
    negative: {
        color: 'red',
    },
    neutral: {
        color: 'white',
    },
});

/*
Props:
    fromRole: "buyer"/"seller" 
    feedback: array of the feedback
*/

const FeedbackFrom = (props) => {
    const classes = useStyles();

    const countFeedbackFromRole = () => {
        if (props.feedback) {
            return props.feedback.reduce((count, value) => (value.fromRole === props.fromRole ? count + 1 : count), 0);
        }
        return 0;
    }

    const countPositive = () => {
        if (props.feedback) {
            return props.feedback.reduce((count, value) => (value.fromRole === props.fromRole ?
                value.type === "positive" ? count + 1 : count : count), 0);
        }
        return 0;
    }

    const countNegative = () => {
        if (props.feedback) {
            return props.feedback.reduce((count, value) => (value.fromRole === props.fromRole ?
                value.type === "negative" ? count + 1 : count : count), 0);
        }
        return 0;
    }

    const countNeutral = () => {
        if (props.feedback) {
            return props.feedback.reduce((count, value) => (value.fromRole === props.fromRole ?
                value.type === "neutral" ? count + 1 : count : count), 0);
        }
        return 0;
    }

    const totalFeedbackCount = countFeedbackFromRole();
    const totalPositive = countPositive();
    const totalNeutral = countNeutral();
    const totalNegative = countNegative();
    const totalRating = totalPositive - totalNegative;

    const renderNotice = () => {
        if (!props.noNotice) {
            return (
                <>
                    <Typography className={classes.warning}>
                        Внимание:
                    </Typography>
                    <Typography className={classes.notice}>
                        При расчете итогового рейтинга, отзыв одного типа от одного пользователя учитывается только один раз. Нейтральные отзывы не учитываются.
                        Это сделано в целях противодействия мошенничеству, чтобы нельзя было завести второй аккаунт и быстро "накрутить" себе рейтинг.
                    </Typography>
                </>
            )
        }
    }

    return (
        <>
            <Card className={classes.root} variant="outlined">
                <CardContent>
                    <Typography className={classes.header}>
                        Отзывы {props.fromRole === "buyer" ? "покупателей" : "продавцов"}: {totalFeedbackCount}
                    </Typography>

                    <Stack direction='row' spacing={2}>
                        <Stack direction='row' spacing={1}>
                            <SentimentSatisfiedAltIcon sx={{ color: '#6dc353' }} />
                            <Typography className={classes.content}>
                                Положительных:
                            </Typography>
                            <Typography className={classes.positive}>
                                {totalPositive}
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack direction='row' spacing={2}>
                        <Stack direction='row' spacing={1}>
                            <SentimentNeutralIcon sx={{ color: 'white' }} />
                            <Typography className={classes.content}>
                                Нейтральных:
                            </Typography>
                            <Typography className={classes.neutral}>
                                {totalNeutral}
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack direction='row' spacing={2}>
                        <Stack direction='row' spacing={1}>
                            <SentimentVeryDissatisfiedIcon sx={{ color: 'red' }} />
                            <Typography className={classes.content}>
                                Отрицательных:
                            </Typography>
                            <Typography className={classes.negative}>
                                {totalNegative}
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack direction='row' spacing={1}>
                        <Typography className={classes.content}>
                            Итоговый рейтинг:
                        </Typography>
                        <Typography className={classes.positive}>
                            {totalPositive}
                        </Typography>
                        <Typography className={classes.neutral}>
                            -
                        </Typography>
                        <Typography className={classes.negative}>
                            {totalNegative}
                        </Typography>
                        <Typography className={classes.neutral}>
                            =
                        </Typography>
                        <Typography className={totalRating > 0 ? classes.positive : totalRating === 0 ? classes.neutral : classes.negative}>
                            {totalRating}
                        </Typography>
                    </Stack>

                    {renderNotice()}
                </CardContent>
            </Card>
        </>
    )
}

export const MyFeedbackFromContainer = connect((state, ownProps) => {
    return {
        feedback: state.myFeedbackPage.feedback,
        fromRole: ownProps.fromRole,
        noNotice: false,
    }
}, {})(FeedbackFrom);

export const UsersFeedbackFromContainer = connect((state, ownProps) => {
    return {
        feedback: state.anotherUserPage.feedback,
        fromRole: ownProps.fromRole,
        noNotice: true,
    }
}, {})(FeedbackFrom);

export default FeedbackFrom;