import React from 'react'
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Stack } from '@mui/material';
import { setShowContactsAC } from '../../redux/listing-reducer';
import StyledButton from '../entities/StyledButton';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import Container from '@mui/material/Container';
import Tooltip from "@mui/material/Tooltip";
import HelpIconWithTooltip from '../entities/HelpIconWithTooltip';

const useStyles = makeStyles({
    root: {
        marginRight: 5,
        marginLeft: 5,
        marginBottom: 10,
        background: '#202938',
        paddingBottom: 0,
        paddingTop: 0,
        borderColor: 'white',
    },
    header: {
        fontSize: 16,
        fontWeight: 700,
        color: '#6dc353',
        marginBottom: 6,
    },
    link: {
        color: '#6dc353',
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 5,
        paddingLeft: 5,
    },
    icon: {
        color: '#6dc353',
    },
    content: {
        color: '#dddddd',
        marginBottom: 4,
        paddingLeft: 5,
    },
    notice: {
        color: '#dddddd',
        fontWeight: 500,
        marginBottom: 4,
    },
    warning: {
        paddingTop: 10,
        color: '#6dc353',
        fontWeight: 600,
        marginBottom: 4,
    },
});

const ContactsCard = (props) => {
    const classes = useStyles();

    const renderWa = () => {
        if (props.wa) {
            return (
                <>
                    <Tooltip
                        title="Кликните здесь чтобы написать продавцу в WhatsApp. Ссылка на это объявление будет добавлена в сообщение автоматически"
                        placement="bottom"
                        arrow
                    >
                        <Link href={`https://wa.me/${props.wa}?text=Здравствуйте,%20насчет%20вашего%20объявления%20на%20natextolite.ru%0A${window.location.href}%0A%0A`}>
                            <WhatsAppIcon className={classes.icon} />
                        </Link>
                    </Tooltip>
                </>
            )
        }
        return <></>;
    }

    const renderTg = () => {
        if (props.tg) {
            return (
                <>
                    <Tooltip
                        title="Кликните здесь чтобы написать продавцу в Telegram"
                        placement="bottom"
                        arrow
                    >
                        <Link href={`https://t.me/${props.tg}`}>
                            <TelegramIcon className={classes.icon} />
                        </Link>
                    </Tooltip>
                </>
            )
        }
        return <></>;
    }

    const renderMessengers = () => {
        if (props.wa || props.tg) {
            return (
                <>
                    <Stack direction='row' spacing={2}>
                        <Stack direction='row' spacing={0}>
                            <HelpIconWithTooltip
                                darkMode
                                text="При нажатии на кнопку мессенджера вы будете перенаправлены в соответствующий мессенджер (WhatsApp или Telegram). На ПК перенаправление будет работать только если у Вас установлена десктоп- или веб-версия мессенджера"
                            />
                            <Typography className={classes.content}>
                                Мессенджеры:
                            </Typography>
                        </Stack>
                        {renderWa()}
                        {renderTg()}
                    </Stack>
                </>
            )
        }
        return <></>;
    }

    if (props.state !== 'active') {
        return (
            <>
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Typography className={classes.content}>
                            Просмотр контактов доступен только в актуальных объявлениях
                        </Typography>
                    </CardContent>
                </Card>
            </>
        )
    }

    if (props.contactsVisible) {
        return (
            <>
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Typography className={classes.header}>
                            Контакты продавца:
                        </Typography>

                        <Stack direction='row' spacing={2}>
                            <Stack direction='row' spacing={0}>
                                <HelpIconWithTooltip
                                    darkMode
                                    text="Если вы просматриваете сайт с телефона, то при нажатии на номер вы будете перенаправлены в приложение звонков"
                                />
                                <Typography className={classes.content}>
                                    Телефон:
                                </Typography>
                            </Stack>

                            <Link href={`tel:+${props.phone}`}>
                                <Typography className={classes.link}>
                                    +{props.phone}
                                </Typography>
                            </Link>
                        </Stack>

                        {renderMessengers()}

                        <Stack direction='row' spacing={2}>
                            <Stack direction='row' spacing={0}>
                                <HelpIconWithTooltip
                                    darkMode
                                    text="При нажатии на email вы будете перенаправлены в почтовый клиент"
                                />
                                <Typography className={classes.content}>
                                    Почта:
                                </Typography>
                            </Stack>

                            <Link href={`mailto:${props.email}`}>
                                <Typography className={classes.link}>
                                    {props.email}
                                </Typography>
                            </Link>
                        </Stack>

                        <Typography className={classes.warning}>
                            Внимание:
                        </Typography>
                        <Typography className={classes.notice}>
                            Учитывайте возможную разницу в часовых поясах - проверьте время в регионе {props.region} перед выходом на связь
                        </Typography>
                    </CardContent>
                </Card>
            </>
        )
    }
    else {
        return (
            <>
                <Container sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                }}>
                    <Tooltip title="Показать контакты продавца" placement="bottom">
                        <span>
                            <StyledButton
                                icon={<ContactPhoneIcon />}
                                label='Контакты'
                                onClick={() => {
                                    props.setContactsVisible(true);
                                }}
                            />
                        </span>
                    </Tooltip>
                </Container>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    if (ownProps.preview) {
        return {
            contactsVisible: true,
            region: state.userState.region,
            phone: state.userState.phone,
            wa: state.settings.waPhoneNum,
            tg: state.settings.tgUserName,
            email: state.userState.email,
            state: 'active',
        }
    }
    return {
        contactsVisible: state.listingPage.showContacts,
        region: state.listingPage.submitterRegion,
        phone: state.listingPage.submitterPhone,
        wa: state.listingPage.submitterWhatsAppPhone,
        tg: state.listingPage.submitterTgUserName,
        email: state.listingPage.submitterEmail,
        state: state.listingPage.listingState,

        isLoggedIn: state.loginReg.isLoggedIn,
        myLogin: state.userState.login,
        submitterId: state.listingPage.submitterId,
        listingId: state.listingPage.listingId,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setContactsVisible: (visible) => {
            dispatch(setShowContactsAC(visible));
        },
    }
}



const ContactsCardContainer = connect(mapStateToProps, mapDispatchToProps)(ContactsCard);
export default ContactsCardContainer;