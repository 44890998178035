import React, { useEffect } from 'react'
import { connect } from "react-redux";
import Spinner from '../../components/Spinner';
import MetaTags from 'react-meta-tags';
import { makeStyles } from '@material-ui/core/styles';
import { fetchSubscriptionUpdates, subUpdatesSetFetchingAC } from '../../redux/subscription-updates-reducer';
import SubscriptionUpdatesPaginatedContainer from '../../components/subscriptions/SubUpdatesPaginated';
import StyledButton from '../../components/entities/StyledButton';
import history from '../../history/history';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { Grid } from "@material-ui/core";
import Unauthorized from '../../components/entities/Unauthed';
import { showLoginDialogAC } from "../../redux/login-reg-reducer";

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
        marginTop: 10,
        marginBottom: 10,
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
}));

const SubscriptionUpdates = ({ syncState, match, ...props }) => {
    useEffect(() => {
        if (match.params.subscription_id !== '' &&
            match.params.subscription_id !== undefined) {
            syncState(match.params.subscription_id);
        }
    }, [match.params.subscription_id]);

    const classes = useStyles();

    if (!props.isLoggedIn) {
        props.showLoginDialog();
        return (
            <>
                <Unauthorized authCb={props.showLoginDialog} />
            </>
        )
    }

    const buildTitle = () => {
        let subscriptionTitle = '';
        if (props.searchType === 'bytype') {
            subscriptionTitle = `${props.typeSearchTypeRu} для ТВ ${props.typeSearchModel}`;
        }
        else if (props.searchType === 'bypn') {
            subscriptionTitle = `${props.pnSearchPn} для ${props.pnSearchModel === null || props.pnSearchModel === '' ? 'любых моделей ТВ' : ('ТВ ' + props.pnSearchModel)}`;
        }
        return subscriptionTitle;
    }

    const redirectToGeneralSearch = () => {
        let url = '';
        if (props.searchType === 'bytype') {
            url = `/services/search/tv/bytype$${props.typeSearchType}$${props.typeSearchModel}`;
        }
        else {
            url = `/services/search/tv/pnmodel$${props.pnSearchPn}$${props.pnSearchModel}`;
        }

        history.push(url);
    }

    if (props.isFetching) {
        return <Spinner />
    }

    return (
        <>
            <MetaTags>
                <title>Подписки - naTextolite</title>
            </MetaTags>

            <h1>Подписка:</h1>
            <h2>{buildTitle()}</h2>
            <SubscriptionUpdatesPaginatedContainer />

            <Grid container direction="column" justifyContent="center" alignItems="center" >
                <Grid item>
                    <StyledButton
                        label="Все результаты поиска"
                        icon={<ManageSearchIcon />}s
                        size='small'
                        onClick={redirectToGeneralSearch}
                    />
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isFetching: state.subscriptionUpdates.isFetching,
        searchType: state.subscriptionUpdates.subscription.search_type,
        typeSearchType: state.subscriptionUpdates.subscription.type_search_type,
        typeSearchTypeRu: state.subscriptionUpdates.subscription.type_search_type_ru,
        typeSearchModel: state.subscriptionUpdates.subscription.type_search_model,
        pnSearchPn: state.subscriptionUpdates.subscription.pn_search_pn,
        pnSearchModel: state.subscriptionUpdates.subscription.pn_search_model,
        isLoggedIn: state.loginReg.isLoggedIn,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        syncState: (subscription_id) => {
            console.log("syncing state for id: " + subscription_id);
            if (subscription_id === '') return;
            dispatch(subUpdatesSetFetchingAC(true));
            dispatch(fetchSubscriptionUpdates(subscription_id));
        },
        showLoginDialog: () => {
            dispatch(showLoginDialogAC());
        },
    }
}

const SubscriptionUpdatesContainer = connect(mapStateToProps, mapDispatchToProps)(SubscriptionUpdates);
export default SubscriptionUpdatesContainer;