import React from 'react'
import { connect } from "react-redux";
import Paginated from '../entities/Paginated';
import AnotherUserListingPreview from './AnotherUserListingPreview';
import Spinner from '../Spinner';

const UsersListings = (props) => {
    const renderListingRow = (index, value) => {
        return <AnotherUserListingPreview
            submitter={props.submitter}
            city={props.city}
            region={props.region}

            id={value.id}
            imgUrl={value.cover}
            title={value.summary}
            date={value.date}
            price={value.price}
            type={value.type}
            pns={value.pns}
        />
    }

    if (props.isFetching) {
        return <Spinner />
    }

    if (props.listingsTotal === 0) {
        return (
            <>
                <h2>¯\_(ツ)_/¯</h2>
                <h2>У пользователя нет активных объявлений</h2>
            </>
        )
    }

    return (
        <>
            <Paginated
                pagesize={props.pageSize}
                renderItem={renderListingRow}
                items={props.listingsArr}
            />
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching: state.anotherUserPage.isFetching,
        submitter: state.anotherUserPage.login,
        city: state.anotherUserPage.city,
        region: state.anotherUserPage.region,

        listingsArr: state.anotherUserPage.listings,
        listingsTotal: state.anotherUserPage.listingsTotal,
        pageSize: ownProps.pageSize ? ownProps.pageSize : 10,
    }
}

const UsersListingsContainer = connect(mapStateToProps, {})(UsersListings);
export default UsersListingsContainer;