import React, { Component } from 'react'
import logo from "../components/logo.svg";
import MetaTags from "react-meta-tags";
import { Box, Container, Grid } from '@mui/material';
import ActionAreaCard from '../components/main-page/ServiceCard';
import imgComposition from '../assets/composition.png';
import imgNewComposition from '../assets/new_composition.png';
import imgUsages from '../assets/usages.png';
import imgHeader from '../assets/header.png';

import listings from '../assets/listings.png';
import InfoCard from '../components/main-page/InfoCard';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import HelperCard from '../components/settings/HelperCard';

export default class Home extends Component {
    render() {
        return (
            <>
                <MetaTags>
                    <title>{`наТекстолите Главная`}</title>
                    <meta name="description" content={`наТекстолите Главная`} />
                    <meta property="og:title" content="naTeXtolite" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={`${window.location.href}`} />
                    <meta property="og:image" content={logo} />
                    <meta property="og:description" content={`наТекстолите Главная`} />
                </MetaTags>

                <Box display={{ xs: 'none', md: 'block', marginTop: '5px' }}>
                    <img src={imgHeader} alt="Header" />
                </Box>

                <h1>naTeXtolite это база составов</h1>
                <Grid container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="stretch"
                >
                    <Grid item style={{ display: 'flex' }}>
                        <ActionAreaCard
                            img={imgComposition}
                            title='База составов ТВ'
                            text='Открытая и бесплатная для всех и навсегда. Даже без регистрации'
                            linkTo='/services/composition/tv/'
                            maxWidth={300}
                        />
                    </Grid>

                    <Grid item style={{ display: 'flex' }}>
                        <ActionAreaCard
                            img={imgUsages}
                            title='Поиск использований'
                            text='Когда нужно понять в каких ТВ используется запчасть'
                            linkTo='/services/usages/tv/'
                            maxWidth={300}
                        />
                    </Grid>

                    <Grid item style={{ display: 'flex' }}>
                        <ActionAreaCard
                            img={imgNewComposition}
                            title='Добавление состава ТВ'
                            text='Помогите проекту - добавьте неизвестный состав ТВ в базу'
                            linkTo='/services/composition/tv/add'
                            maxWidth={300}
                        />
                    </Grid>
                </Grid >

                <h1>naTeXtolite это доска объявлений</h1>
                <Grid container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="stretch"
                >
                    <Grid item style={{ display: 'flex' }}>
                        <ActionAreaCard
                            img={listings}
                            title='Продавайте запчасти'
                            text='Создавайте объявления о продаже своих ТВ-запчасти и торгуйте на naTeXtolite'
                            linkTo='/personal/mylistings/new'
                            maxWidth={420}
                            imgHeight={180}
                        />
                    </Grid>

                    <Grid item style={{ display: 'flex' }}>
                        <ActionAreaCard
                            img={listings}
                            title='Покупайте запчасти'
                            text='Ищите объявления о продаже нужных Вам ТВ-запчастей'
                            linkTo='/services/search/tv/'
                            maxWidth={420}
                            imgHeight={180}
                        />
                    </Grid>
                </Grid >

                <h1>Торговля с naTeXtolite это</h1>

                <Grid container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="stretch"
                >
                    <Grid item style={{ display: 'flex' }}>
                        <InfoCard
                            title='Эффективность'
                            text='Создание качественных объявлений еще никогда не было столь быстрым: всё благодаря автоматической генерации заголовков и описания объявлений'
                        />
                    </Grid>

                    <Grid item style={{ display: 'flex' }}>
                        <InfoCard
                            title='Ничего лишнего'
                            text='Здесь торгуют только ТВ запчастями и вся система построена вокруг них. Никаких ненужных функций. Никаких велосипедов и курток по запросу "BN41-02098B"'
                        />
                    </Grid>

                    <Grid item style={{ display: 'flex' }}>
                        <InfoCard
                            title='Все что нужно'
                            text='Удобный поиск с авто-ранжированием результатов по удаленности и цене.
                             Избранное и заметки к объявлениям. Подписки на новые объявления по заданным критериям. Обмен отзывами и многое другое'
                        />
                    </Grid>
                </Grid >

                <h2>О проекте</h2>

                <Container>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <ContactSupportIcon sx={{ marginRight: 1 }} />
                            Почему Вам стоит зарегистрироваться naTextolite?
                        </AccordionSummary>
                        <AccordionDetails>
                            В отличие от известных торговых площадок, naTextolite с самого начала задумывался как площадка для торговли ТВ запчастями и только ими. Здесь нельзя найти недвижимость, велосипед или одежду. Здесь нет «Рекомендаций на основе вашего поиска» и кожаной куртки в выдаче по запросу «bn41-02098b».

                            <HelperCard
                                summary="Итого"
                                helperText={
                                    "naTextolite нет ничего лишнего, площадка «заточена» под торговлю запчастями, и поэтому, делает это хорошо."
                                } />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <ContactSupportIcon sx={{ marginRight: 1 }} />
                            В чем выражается «специализация» naTextolite под торговлю ТВ запчастями?
                        </AccordionSummary>
                        <AccordionDetails>
                            Лишь один из примеров: когда вы создаете объявление о продаже платы на обычной торговой площадке – из-за неопределенно широкой номенклатуры товаров, она не может «осознать» что именно вы продаёте. Поэтому, создавая объявление, Вы вручную выбираете раздел, подразделы, затем заполняете заголовок объявления, где перечисляете тип запчасти, модель ТВ, партнамберы. Затем проделываете тоже самое с описанием товара

                            <HelperCard
                                summary="А naTeXtolite?"
                                helperText={
                                    "naTextolite действительно понимает что Вы продаете.\nВы указываете модель ТВ, затем выбираете тип запчасти. Если указанный ТВ есть в базе составов, мы предложим выбрать из списка партнамберы. \nПосле этого мы сами сгенерируем заголовок и тело объявления – Вам останется лишь приложить фото и указать цену. \nКак два байта переслать)\nБолее того, мы понимаем, что часто мастера создают объявления после разбора донорского ТВ. В этом случае, мастер, обычно, хочет создать несколько объявлений о продаже разных запчастей с одного ТВ.\nДля этого naTextolite есть специальный режим – в нем Вы сразу создаете несколько объявлений, что невероятно экономит время.\n\nРазница в эффективности с обычной торговой площадкой – как между отверткой и шуруповертом)"
                                } />
                        </AccordionDetails>
                    </Accordion>





                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <ContactSupportIcon sx={{ marginRight: 1 }} />
                            Почему стоит разместить объявления о продаже Ваших запчастей naTextolite?
                        </AccordionSummary>
                        <AccordionDetails>
                            Вот Вам пять причин:

                            <HelperCard
                                summary="Причина 1"
                                helperText={
                                    "Как минимум, naTextolite это дополнительный источник просмотров Ваших объявлений от потенциальных покупателей со всей России. Для каждого пользователя, размещение любого количества объявлений полностью бесплатно в течение полугода с момента регистрации. Мы надеемся, что этого срока будет достаточно чтобы оценить удобство и эффективность площадки. При этом, мы не попросим Вас заранее оставлять платежные данные (номера банковских карт) – придет время, и Вы сами примете решение стоит ли продолжать пользоваться услугами площадки."
                                } />

                            <HelperCard
                                summary="Причина 2"
                                helperText={
                                    "Мы работаем не только над продвижением самой площадки, но и над продвижением Ваших объявлений. В данный момент мы работаем над поддержкой протокола IndexNow (Яндекс) и Google Indexing API, что позволит наиболее распространенным поисковым системам практически мгновенно узнавать о новых объявлениях на сайте. Это, в свою очередь, должно привести к тому, что покупатели смогут находить Ваши объявления непосредственно из поисковых систем Яндекс и Google. "
                                } />

                            <HelperCard
                                summary="Причина 3"
                                helperText={
                                    "После истечения полугодового бесплатного ознакомительного периода, стоимость размещения одного объявления на месяц составит всего 5 рублей. На момент написания данного текста, самая распространенная торговая площадка просит 46 рублей за ту же услугу"
                                } />

                            <HelperCard
                                summary="Причина 4"
                                helperText={
                                    "Это далеко не финальный вид площадки. У нас еще вагон и маленькая тележка идей, как сделать площадку более удобной, практичной и функциональной"
                                } />

                            <HelperCard
                                summary="Причина 5"
                                helperText={
                                    "Почему бы и нет?)"
                                } />
                        </AccordionDetails>
                    </Accordion>


                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <ContactSupportIcon sx={{ marginRight: 1 }} />
                            Зачем мне добавлять составы ТВ в базу?
                        </AccordionSummary>
                        <AccordionDetails>
                            <HelperCard
                                summary=" "
                                helperText={
                                    "Добавляя состав ТВ в нашу базу, вы вносите вклад в развитие проекта, делаете naTextolite конкурентоспособнее. Мы очень ценим это, но, считаем, что просто ценить – мало. Поэтому, в планах, создание бонусной программы, в рамках которой всем участникам проекта, за каждый добавленный в базу состав, будут начислены бонусы, которыми можно будет оплатить услуги площадки"
                                } />
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <ContactSupportIcon sx={{ marginRight: 1 }} />
                            Каковы ближайшие планы по развитию проекта?
                        </AccordionSummary>
                        <AccordionDetails>
                            Планов чрезвычайно много во всех аспектах работы площадки. Вот некоторые из них:

                            <HelperCard
                                summary=" "
                                helperText={
                                    "1.	Бонусная программа;\n2.	Реферальная программа;\n3.	Некое подобие тендеров;\n4.	Персонализация личного кабинета/магазина;\n5.	Возможность проведения акций и распродаж;\n6.	Поддержка системы промокодов для продавцов;\n7.	Качественное улучшение обратной связи, голосования;\n8.	Статистика просмотров и продаж для продавцов;\nВ общем, оставайтесь с нами, будет интересно)"
                                } />
                        </AccordionDetails>
                    </Accordion>

                </Container>
            </>
        )
    }
}