import {
    fetchComposition,
    updateSearchModelTextActionCreator
} from "../../redux/composition-reducer";
import { connect } from "react-redux";
import SearchForm from "./SearchForm";

const mapStateToProps = (state) => {
    return {
        currentSearchText: state.compositionPage.searchModelText,
        placeholder: 'UE40F6400AK',
        searchType: 'Поиск состава ТВ',
        stype: 'composition',
        redirectToResults: true,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSearch: (item) => {
            dispatch(fetchComposition(item));
        },
        updateSearchText: (newText) => {
            let action = updateSearchModelTextActionCreator(newText);
            dispatch(action);
        },
    }
}

const SearchModelFormContainer = connect(mapStateToProps, mapDispatchToProps)(SearchForm);
export default SearchModelFormContainer;