import { FormGroup3pn } from "../../FormGroup";
import React from "react";
import {
    updateBtPn1AC, updateBtPn2AC, updateBtPn3AC,
} from "../../../redux/add-composition-reducer";
import { connect } from "react-redux";

const BtAddForm = (props) => {

    return (
        <>
            <FormGroup3pn
                title={"BT (Bluetooth) модуль"}
                description={"Внимательно осмотрите плату и наклейки на ней. После этого укажите здесь партнамберы, которые видите.\n" +
                    "                            Каждый партнамбер указывайте в отдельном поле.\n" +
                    "                            Например, на плате вы видите \"BN41-01976B\", а на наклейке \"BN96-26401N\".\n" +
                    "                            В поле \"Партнамбер 1\" укажите \"BN41-01976B\", а в поле \"Партнамбер 2\" - \"BN96-26401N\"."}
                val_pn1={props.bt1}
                val_pn2={props.bt2}
                val_pn3={props.bt3}
                cb_pn1={props.updateBtPn1}
                cb_pn2={props.updateBtPn2}
                cb_pn3={props.updateBtPn3}
                pn1_err={props.bt1Err}
                pn2_err={props.bt2Err}
                pn3_err={props.bt3Err}
                pn1_errDesc={props.bt1ErrWhat}
                pn2_errDesc={props.bt2ErrWhat}
                pn3_errDesc={props.bt3ErrWhat}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        bt1: state.addCompositionPage.bt_pn1,
        bt2: state.addCompositionPage.bt_pn2,
        bt3: state.addCompositionPage.bt_pn3,
        bt1Err: state.addCompositionPage.bt_pn1Err,
        bt2Err: state.addCompositionPage.bt_pn2Err,
        bt3Err: state.addCompositionPage.bt_pn3Err,
        bt1ErrWhat: state.addCompositionPage.bt_pn1ErrWhat,
        bt2ErrWhat: state.addCompositionPage.bt_pn2ErrWhat,
        bt3ErrWhat: state.addCompositionPage.bt_pn3ErrWhat,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateBtPn1: (val, err) => { dispatch(updateBtPn1AC(val, err)); },
        updateBtPn2: (val, err) => { dispatch(updateBtPn2AC(val, err)); },
        updateBtPn3: (val, err) => { dispatch(updateBtPn3AC(val, err)); },
    }
}
const BtAddFormContainer = connect(mapStateToProps, mapDispatchToProps)(BtAddForm);
export default BtAddFormContainer;