import React, { useEffect } from 'react'
import { connect } from "react-redux";
import Spinner from '../../components/Spinner';
import MetaTags from 'react-meta-tags';
import logo from '../../components/logo.svg';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import {
    searchByTypeModel, listSearchSetUrlRequestText, listSearchSetFetchingAC,
    listSearchSetPnText, listSearchSetHwTypeTextAC, listSearchFixModelAC,
    searchByPnModel, listSearchByTypeSetModelText, listSearchByPnSetModelText
} from '../../redux/listings-search-reducer';
import ListingsSearchHeaderContainer from '../../components/listings/ListingsSearchHeader';
import ListingsSearchResultsContainer from '../../components/listings/ListingsSearchResults';

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
        marginTop: 10,
        marginBottom: 10,
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
}));

const SearchListings = ({ syncState, requestFromUrl, match }) => {
    useEffect(() => {
        if (/*requestFromUrl === "" &&*/
            match.params.requestFromUrl !== '' &&
            match.params.requestFromUrl !== undefined) {
            syncState(match.params.requestFromUrl);
        }
    }, [match.params.requestFromUrl]);

    const classes = useStyles();

    return (
        <>
            <MetaTags>
                <title>{'Поиск объявлений - naTextolite'}</title>
                <meta name="description" content={'Поиск объявлений - naTextolite'} />
                <meta property="og:title" content={'Поиск объявлений - naTextolite'} />
                <meta property="og:site_name" content="naTextolite.ru" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`${window.location.href}`} />
                <meta property="og:image" content={logo} />
                <meta property="og:image:secure_url" content={logo} />
                <meta property="og:description" content={'Поиск объявлений - naTextolite'} />
            </MetaTags>

            <Breadcrumbs aria-label="breadcrumb">
                <Link color="textPrimary" href="/" className={classes.link}>
                    <HomeIcon className={classes.icon} />
                    naTeXtolite
                </Link>
                <Link color="textPrimary" href="/services" className={classes.link}>
                    <ClearAllIcon className={classes.icon} />
                    Сервисы
                </Link>
                <Typography color="textPrimary" className={classes.link}>
                    <ScreenSearchDesktopIcon className={classes.icon} />
                    Поиск запчастей для ТВ
                </Typography>
            </Breadcrumbs>

            <h1>Поиск объявлений</h1>

            <ListingsSearchHeaderContainer />
            <ListingsSearchResultsContainer />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isFetching: state.listingsSearch.isFetching,
        requestFromUrl: state.listingsSearch.requestFromUrl,
        myCity: state.userState.city,
        myRegion: state.userState.region,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        syncState: (request) => {
            if (request === '') return;

            const tokens = request.split('$');

            const searchType = tokens[0];
            let hwType = '';
            let model = '';
            let pn = '';

            if (searchType === 'bytype') {
                if (tokens.length !== 3) return;
                if (tokens[1] === '' || tokens[2] === '') return;
                hwType = tokens[1];
                model = tokens[2];
            }
            else {

                if (tokens.length < 2) return;
                pn = tokens[1];
                if (tokens.length > 2)
                    model = tokens[2];
            }
            dispatch(listSearchSetFetchingAC(true));
            dispatch(listSearchSetUrlRequestText(request));
            dispatch(listSearchFixModelAC(model));
            if (searchType === 'bytype') {
                dispatch(listSearchByTypeSetModelText(model));
                dispatch(listSearchSetHwTypeTextAC(hwType));
                dispatch(searchByTypeModel(`${hwType}$${model}`));
            }
            else {
                dispatch(listSearchByPnSetModelText(model));
                dispatch(listSearchSetPnText(pn));
                dispatch(searchByPnModel(`${pn}$${model}`));
            }
        },
    }
}

const SearchListingsContainer = connect(mapStateToProps, mapDispatchToProps)(SearchListings);
export default SearchListingsContainer;