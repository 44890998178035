import * as React from 'react';
import { connect } from "react-redux";
import { Box, Grid, Typography } from '@material-ui/core';
import PartNumberConfirmContainer from '../new-listing/PartNumbersConfirm';
import HelperCard from '../settings/HelperCard';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                variant="determinate"
                {...props}
                sx={{
                    color: '#6dc353',
                }}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" >
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
};

const PnsSelector = (props) => {
    const render = () => {
        const items = [];
        for (const [index, value] of props.hwTypes.entries()) {
            if (props.listingsData[index].partnumbersConfirmed) {
                items.push(
                    <>
                        <Grid item xs={12} sm={8}>
                            <HelperCard
                                summary={value.text}
                                helperText={props.listingsData[index].partnumbers}
                            />
                        </Grid>
                    </>
                )
            }
            else {
                items.push(
                    <>
                        <Grid item xs={12} sm={8}>
                            <h3>{value.text}:</h3>
                            <PartNumberConfirmContainer
                                multi
                                index={index}
                            />
                        </Grid>
                    </>
                );
            }
        }
        return items;
    }

    return (
        <>
            <h2>Подтвердите партнамбер(ы):</h2>
            <CircularProgressWithLabel value={(100 / props.listingsData.length) * props.progress} />
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent='center'
            >
                {render()}
            </Grid >
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        hwTypes: state.addPack.chosenHwTypes,
        listingsData: state.addPack.listingsData,
        progress: state.addPack.pnsProgress,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

const PnsSelectorContainer = connect(mapStateToProps, mapDispatchToProps)(PnsSelector);
export default PnsSelectorContainer;