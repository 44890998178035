import * as React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { Container } from '@material-ui/core';
import { styled } from "@mui/material/styles";


/* Props:
    - url: url to point to
*/

const TelegramQrGenerator = (props) => {
    const StyledContainer = styled(Container)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        margin: 5,
    }));

    return (
        <>
            <StyledContainer>
                <QRCodeSVG value={props.url} />
            </StyledContainer>
        </>
    );
}

export default TelegramQrGenerator;