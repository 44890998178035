import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ForgotPassViewContainer from '../../components/login/ForgotPass';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

const ChangePasswordBlock = (props) => {
    return (
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <AssignmentIndIcon sx={{marginRight: 1}} />
                    Сменить пароль
                </AccordionSummary>

                <AccordionDetails>
                    <ForgotPassViewContainer />
                </AccordionDetails>
            </Accordion>
        </>
    );
}

export default ChangePasswordBlock;