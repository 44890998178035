import React from 'react'
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Stack } from '@mui/material';
import { setShowContactsAC } from '../../redux/listing-reducer';
import StyledButton from '../entities/StyledButton';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import Container from '@mui/material/Container';
import Tooltip from "@mui/material/Tooltip";
import HelpIconWithTooltip from '../entities/HelpIconWithTooltip';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DialogAlert from '../../components/entities/DialogAlert';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        marginRight: 5,
        marginLeft: 5,
        marginBottom: 10,
        background: '#202938',
        paddingBottom: 0,
        paddingTop: 0,
        borderColor: 'white',
    },
    header: {
        fontSize: 16,
        fontWeight: 700,
        color: '#6dc353',
        marginBottom: 6,
    },
    link: {
        color: '#6dc353',
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 5,
    },
    icon: {
        color: '#6dc353',
    },
    content: {
        color: '#dddddd',
        marginBottom: 4,
        paddingLeft: 5,
    },
    notice: {
        color: '#dddddd',
        fontWeight: 500,
        marginBottom: 4,
    },
    warning: {
        paddingTop: 10,
        color: '#6dc353',
        fontWeight: 600,
        marginBottom: 4,
    },
});

const MiniContactsCard = (props) => {
    const classes = useStyles();

    const renderWa = () => {
        if (props.wa) {
            return (
                <>
                    <Tooltip
                        title="Кликните здесь чтобы написать продавцу в WhatsApp. Ссылка на это объявление будет добавлена в сообщение автоматически"
                        placement="bottom"
                        arrow
                    >
                        <Link href={`https://wa.me/${props.wa}?text=Здравствуйте,%20насчет%20вашего%20объявления%20на%20natextolite.ru%0A${window.location.href}%0A%0A`}>
                            <WhatsAppIcon className={classes.icon} />
                        </Link>
                    </Tooltip>
                </>
            )
        }
        return <></>;
    }

    const renderTg = () => {
        if (props.tg) {
            return (
                <>
                    <Tooltip
                        title="Кликните здесь чтобы написать продавцу в Telegram"
                        placement="bottom"
                        arrow
                    >
                        <Link href={`https://t.me/${props.tg}`}>
                            <TelegramIcon className={classes.icon} />
                        </Link>
                    </Tooltip>
                </>
            )
        }
        return <></>;
    }

    const renderMessengers = () => {
        if (props.wa || props.tg) {
            return (
                <>
                    <Stack direction='row' spacing={1}>
                        <Stack direction='row' spacing={0}>
                            <HelpIconWithTooltip
                                darkMode
                                text="При нажатии на кнопку мессенджера вы будете перенаправлены в соответствующий мессенджер (WhatsApp или Telegram). На ПК перенаправление будет работать только если у Вас установлена десктоп- или веб-версия мессенджера"
                            />
                            <Typography className={classes.content}>
                                Мессенджеры:
                            </Typography>
                        </Stack>
                        {renderWa()}
                        {renderTg()}
                    </Stack>
                </>
            )
        }
        return <></>;
    }

    const renderTimeZoneWarning = () => {
        if (props.region !== props.myRegion) {
            return (
                <>
                    <Typography className={classes.warning}>
                        Внимание:
                    </Typography>
                    <Typography className={classes.notice} gutterBottom>
                        Учитывайте возможную разницу в часовых поясах!
                    </Typography>
                </>
            )
        }
    }

    return (
        <>
            <Card className={classes.root} variant="outlined">
                <CardContent>
                    <Typography className={classes.notice}>
                        Контакты <Link className={classes.link} href={`/profile/${props.login}`}>{props.login}</Link>:
                    </Typography>


                    <Stack direction='row' spacing={1}>
                        <Stack direction='row' spacing={0}>
                            <HelpIconWithTooltip
                                darkMode
                                text="Если вы просматриваете сайт с телефона, то при нажатии на номер вы будете перенаправлены в приложение звонков"
                            />
                            <Typography className={classes.content}>
                                Телефон:
                            </Typography>
                        </Stack>

                        <Link href={`tel:+${props.phone}`}>
                            <Typography className={classes.link}>
                                +{props.phone}
                            </Typography>
                        </Link>
                    </Stack>

                    {renderMessengers()}

                    <Stack direction='row' spacing={1}>
                        <Stack direction='row' spacing={0}>
                            <HelpIconWithTooltip
                                darkMode
                                text="При нажатии на email вы будете перенаправлены в почтовый клиент"
                            />
                            <Typography className={classes.content}>
                                Почта:
                            </Typography>
                        </Stack>

                        <Link href={`mailto:${props.email}`}>
                            <Typography className={classes.link}>
                                {props.email}
                            </Typography>
                        </Link>
                    </Stack>

                    <Typography className={classes.notice}>
                        {props.region}, {props.city}
                    </Typography>

                    {props.pickUpAddr && <Grid container direction="column" justifyContent="center" alignItems="center" >
                        <Grid item>
                            <DialogAlert
                                buttonText='Адрес самовывоза'
                                buttonIcon={<LocationOnIcon />}
                                onConfirm={() => { }}
                                titleText={`Адрес самовывоза`}
                                descriptionText={props.pickUpAddr}
                                cancelText='Отмена'
                                proceedText='Ок'
                            />
                        </Grid>
                    </Grid>}

                    {renderTimeZoneWarning()}
                </CardContent>
            </Card>
        </>
    )

}

const mapStateToProps = (state, ownProps) => {
    return {
        login: ownProps.login,
        email: ownProps.email,
        cityType: ownProps.cityType,
        city: ownProps.city,
        region: ownProps.region,
        phone: ownProps.phone,
        wa: ownProps.wa,
        tg: ownProps.tg,
        pickUpAddr: ownProps.pickUpAddr,
        myRegion: state.userState.region,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setContactsVisible: (visible) => {
            dispatch(setShowContactsAC(visible));
        },
    }
}



const MiniContactsCardContainer = connect(mapStateToProps, mapDispatchToProps)(MiniContactsCard);
export default MiniContactsCardContainer;