import React from 'react'
import { Grid, Link } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box } from '@mui/system';
import { connect } from 'react-redux';
import transliterate from '../../utils/transliterate'
import { Stack } from '@mui/material';

const useStyles = makeStyles({
    header: {
        fontSize: 16,
        fontWeight: 700,
        color: 'black',
        marginBottom: 6,
        margin: 5,
        alignSelf: "stretch"
    },
    content: {
        fontSize: 15,
        fontWeight: 500,
        color: 'black',
        marginBottom: 4,
        paddingLeft: 5,
    },
    link: {
        color: 'black',
        paddingRight: 15,
    },
});

/*
    Props:
        - imgUrl - URL of the thumbnail
        - title - title
        - submitter - submitter login
        - date - submission date
        - price - price
        - id - id of the listing
        - region - region of the submitter
        - city - submitter city
*/

const SearchListingPreview = (props) => {
    const classes = useStyles();

    const calcBgColor = () => {
        if (props.odd)
            return '#FEFDFE';
        return '#F1F1F1';
    }

    return (
        <>
            <Box
                classes={classes.root}
                sx={{
                    border: '1px solid #f6f8f1',
                    borderRadius: '4px',
                    marginBottom: 1,
                    bgcolor: calcBgColor(),
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    wrap='wrap'
                    sx={{ flexGrow: 1 }}
                >
                    <Grid item sx={{ order: { sm: 1, md: 1 } }}>
                        <Link href={`/buy/${transliterate(props.title.split(' ').join('_'))}-${props.id}`}>
                            <Avatar
                                alt="Thumbnail"
                                src={props.imgUrl}
                                sx={{ width: 150, height: 150 }}
                                variant="square"
                            />
                        </Link>
                    </Grid>

                    <Grid item xs={7}>
                        <Link
                            href={`/buy/${transliterate(props.title.split(' ').join('_'))}-${props.id}`}
                            className={classes.link}
                        >
                            <Typography className={classes.header} gutterBottom>
                                {props.title}
                            </Typography>
                        </Link>

                        <Typography className={classes.content} >
                            <Link
                                href={`/profile/${props.submitter}`}
                                className={classes.link}
                            >
                                {props.submitter}
                            </Link>
                            {props.date}
                        </Typography>
                        <Typography className={classes.content} >
                            {props.region}, {props.city}
                        </Typography>
                    </Grid>

                    <Grid item >
                        <Stack>
                            <Typography className={classes.header} gutterBottom>
                                {props.price}р.
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        id: ownProps.id,
        imgUrl: ownProps.imgUrl,
        title: ownProps.title,
        submitter: ownProps.submitter,
        city: ownProps.city,
        region: ownProps.region,
        date: ownProps.date,
        price: ownProps.price,
        odd: ownProps.odd,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

const SearchListingPreviewContainer = connect(mapStateToProps, mapDispatchToProps)(SearchListingPreview);
export default SearchListingPreviewContainer;