import { FormGroup2pn } from "../../FormGroup";
import React from "react";
import {
    updateLvdsPn1AC, updateLvdsPn2AC,
} from "../../../redux/add-composition-reducer";
import { connect } from "react-redux";

const LvdsAddForm = (props) => {

    return (
        <>
            <FormGroup2pn
                title={"LVDS-шлейф(ы)"}
                description={"Внимательно осмотрите шлейф и наклейки на нем. После этого укажите здесь партнамберы, которые видите.\n" +
                    "                            Каждый партнамбер указывайте в отдельном поле."}
                val_pn1={props.lvds1}
                val_pn2={props.lvds2}
                cb_pn1={props.updateLvdsPn1}
                cb_pn2={props.updateLvdsPn2}
                pn1_err={props.lvds1Err}
                pn2_err={props.lvds2Err}
                pn1_errDesc={props.lvds1ErrWhat}
                pn2_errDesc={props.lvds2ErrWhat}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        lvds1: state.addCompositionPage.lvds_pn1,
        lvds2: state.addCompositionPage.lvds_pn2,
        lvds1Err: state.addCompositionPage.lvds_pn1Err,
        lvds2Err: state.addCompositionPage.lvds_pn2Err,
        lvds1ErrWhat: state.addCompositionPage.lvds_pn1ErrWhat,
        lvds2ErrWhat: state.addCompositionPage.lvds_pn2ErrWhat,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateLvdsPn1: (val, err) => { dispatch(updateLvdsPn1AC(val, err)); },
        updateLvdsPn2: (val, err) => { dispatch(updateLvdsPn2AC(val, err)); },
    }
}
const LvdsAddFormContainer = connect(mapStateToProps, mapDispatchToProps)(LvdsAddForm);
export default LvdsAddFormContainer;