import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ym, { YMInitializer } from 'react-yandex-metrika';

const usePageTracking = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
        setInitialized(true);
    }, []);

    useEffect(() => {
        if (initialized) {
            if (!window.location.href.includes("localhost") && !window.location.href.includes("staging")) {
                ym('hit', location.pathname + location.search);
            }
        }
    }, [initialized, location]);
};

const initYM = () => {
    if (!window.location.href.includes("localhost") && !window.location.href.includes("staging")) {
        return (
            <YMInitializer accounts={[76635342]} options={{
                defer: true,
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true,
            }} version="2" />
        )
    }
}

export default usePageTracking;
export { initYM };