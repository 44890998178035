import fetch from "cross-fetch";

const SUB_UPDATES_SET_FETCHING = 'SUBSCRIPTIONS_SET_FETCHING';
const SUB_UPDATES_DATA_UPDATED = 'SUB_UPDATES_DATA_UPDATED';
const SUB_UPDATES_SET_ERR = 'SUB_UPDATES_SET_ERR';

const LOG_REG_LOGOUT = 'LOG_REG_LOGOUT';

let initialState = {
    isFetching: false,

    //subscription updates data:...
    subscription: {
        /* e.g.:
            "id": "1",
            "search_type": "bytype",
            "type_search_model": "UE40F6400AK",
            "type_search_type": "ssb",
            "pn_search_model": null,
            "pn_search_pn": null,
            "updates": "5",
            "created": "2024-05-22 00:29:15",
            "type_search_type_ru": "Материнская плата/Майн/SSB"
        */
    },

    listingsTotal: 0,
    listings: [
        /* e.g.:
        {
            "listing_id": "13",
            "id": "13",
            "user_id": "1",
            "price": "3000",
            "created_at": "2024-04-09 02:26:21",
            "summary": "Материнская плата для ТВ SAMSUNG UE40F6400AK BN41-01958A, BN94-06555H",
            "state": "active",
            "stateRu": "Активно",
            "login": "ntxService",
            "city": "",
            "region": "",
            "cover": "https://storage.yandexcloud.net/ntx-public-staging/4d84c705-8026-4569-b7a4-88e735e818e8/0_thm.jpeg"
        },
        ... */
    ],

    errorCondition: false,
    errWhat: '',
};

const subscriptionsUpdatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUB_UPDATES_SET_FETCHING:
            return Object.assign({}, state, {
                isFetching: action.isFetching,
            });

        case SUB_UPDATES_DATA_UPDATED:
            return Object.assign({}, state, {
                isFetching: false,
                listings: action.listings,
                listingsTotal: action.listingsTotal,
                subscription: action.subscription,
            });

        case SUB_UPDATES_SET_ERR:
            return Object.assign({}, state, {
                errorCondition: true,
                errWhat: action.what,
                isFetching: false,
            });


        case LOG_REG_LOGOUT:
            return Object.assign({}, state, initialState);

        default:
            return state;
    }
};

export const subUpdatesSetFetchingAC = (isFetching) => ({
    type: SUB_UPDATES_SET_FETCHING,
    isFetching: isFetching
})

export const subUpdatesReceiveUpdates = (data) => ({
    type: SUB_UPDATES_DATA_UPDATED,
    listingsTotal: data.listingsTotal,
    listings: data.listings,
    subscription: data.subscription,
})

export const subUpdatesSetError = (what) => ({
    type: SUB_UPDATES_SET_ERR,
    what: what,
})

export function fetchSubscriptionUpdates(id) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + `/api/listings/subscription/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(subUpdatesReceiveUpdates(json.data));
                    } else {
                        dispatch(subUpdatesSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}




export default subscriptionsUpdatesReducer;