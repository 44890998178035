import { FormGroup3pn } from "../../FormGroup";
import React from "react";
import {
    updatePsuPn1AC,
    updatePsuPn2AC,
    updatePsuPn3AC,
} from "../../../redux/add-composition-reducer";
import { connect } from "react-redux";

const PsuAddForm = (props) => {

    return (
        <>
            <FormGroup3pn
                title={"PSU/Блок питания"}
                description={"Внимательно осмотрите плату и наклейки на ней. После этого укажите здесь партнамберы, которые видите.\n" +
                    "                            Каждый партнамбер указывайте в отдельном поле.\n" +
                    "                            Например, на плате вы видите \"BN44-00622B\", а на наклейке \"L42X1Q_DHS\".\n" +
                    "                            В поле \"Партнамбер 1\" укажите \"BN44-00622B\", а в поле \"Партнамбер 2\" - \"L42X1Q_DHS\"."}
                val_pn1={props.psu1}
                val_pn2={props.psu2}
                val_pn3={props.psu3}
                cb_pn1={props.updatePsuPn1}
                cb_pn2={props.updatePsuPn2}
                cb_pn3={props.updatePsuPn3}
                pn1_err={props.psu1Err}
                pn2_err={props.psu2Err}
                pn3_err={props.psu3Err}
                pn1_errDesc={props.psu1ErrWhat}
                pn2_errDesc={props.psu2ErrWhat}
                pn3_errDesc={props.psu3ErrWhat}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        psu1: state.addCompositionPage.psu_pn1,
        psu2: state.addCompositionPage.psu_pn2,
        psu3: state.addCompositionPage.psu_pn3,
        psu1Err: state.addCompositionPage.psu_pn1Err,
        psu2Err: state.addCompositionPage.psu_pn2Err,
        psu3Err: state.addCompositionPage.psu_pn3Err,
        psu1ErrWhat: state.addCompositionPage.psu_pn1ErrWhat,
        psu2ErrWhat: state.addCompositionPage.psu_pn2ErrWhat,
        psu3ErrWhat: state.addCompositionPage.psu_pn3ErrWhat,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updatePsuPn1: (val, err) => { dispatch(updatePsuPn1AC(val, err)); },
        updatePsuPn2: (val, err) => { dispatch(updatePsuPn2AC(val, err)); },
        updatePsuPn3: (val, err) => { dispatch(updatePsuPn3AC(val, err)); },
    }
}
const PsuAddFormContainer = connect(mapStateToProps, mapDispatchToProps)(PsuAddForm);
export default PsuAddFormContainer;