import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles({
    root: {
        minWidth: 150,
        marginTop: 10,
        marginBottom: 10,
        background: '#202938',
        paddingBottom: 0,
        paddingTop: 0,
        borderColor: 'white',
    },
    header: {
        fontSize: 16,
        fontWeight: 700,
        color: '#6dc353',
        marginBottom: 6,
    },
    content: {
        fontSize: 15,
        fontWeight: 500,
        color: '#dddddd',
        marginBottom: 4,
        paddingLeft: 5,
    },
});

const HelperCard = (props) => {
    const classes = useStyles();

    return (
        <>
            <Card className={classes.root} variant="outlined">
                <CardContent>
                    <Typography className={classes.header} gutterBottom>
                        {props.summary ? props.summary : "Зачем нам это знать:"}
                    </Typography>
                    <Typography className={classes.content} color="textSecondary" style={{whiteSpace: 'pre-line'}} >
                        {props.helperText}
                    </Typography>
                </CardContent>
            </Card>
        </>
    )
}

export default HelperCard;