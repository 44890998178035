import { React, useEffect } from 'react'
import ProfileHeaderContainer from '../../components/ProfileHeader';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { connect } from 'react-redux';
import { saleSetFetchingAC, fetchExactSale } from '../../redux/exact-sale-reducer';
import Spinner from '../../components/Spinner';
import MetaTags from 'react-meta-tags';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import MySalePreviewContainer from '../../components/trading/MySalePreview';
import Unauthorized from '../../components/entities/Unauthed';
import { showLoginDialogAC } from "../../redux/login-reg-reducer";

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
        marginTop: 10,
        marginBottom: 10,
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        spacing: 2,
    }
}));

const ExactSale = ({ sync, match, ...props }) => {
    const classes = useStyles();
    useEffect(() => {
        if (/*requestFromUrl === "" &&*/
            match.params.reqId !== '' &&
            match.params.reqId !== undefined) {
            sync(match.params.reqId);
        }
    }, [match.params.reqId]);

    if (!props.isLoggedIn) {
        props.showLoginDialog();
        return (
            <>
                <Unauthorized authCb={props.showLoginDialog} />
            </>
        )
    }

    if (props.isFetching) {
        return <Spinner />
    }

    return (
        <>
            <MetaTags>
                <title>Моя продажа - naTextolite</title>
            </MetaTags>

            <Breadcrumbs aria-label="breadcrumb">
                <Link color="textPrimary" href="/" className={classes.link}>
                    <HomeIcon className={classes.icon} />
                    naTeXtolite
                </Link>
                <Link color="textPrimary" href="/personal" className={classes.link}>
                    <AccountBoxIcon className={classes.icon} />
                    Личный кабинет
                </Link>
                <Link color="textPrimary" href="/personal/mysales" className={classes.link}>
                    <PointOfSaleIcon className={classes.icon} />
                    Мои продажи
                </Link>
                <Typography color="textPrimary" className={classes.link}>
                    Детали сделки
                </Typography>
            </Breadcrumbs>

            <ProfileHeaderContainer />

            <MySalePreviewContainer
                id={props.id}
                listingId={props.listing_id}
                buyerConfirm={props.buyer_confirm}
                sellerConfirm={props.seller_confirm}
                sealed={props.sealed}
                discarded={props.discarded}
                discardedBy={props.discarded_by}
                buyerLogin={props.login}
                buyerEmail={props.email}
                buyerCityType={props.city_type}
                buyerCity={props.city}
                buyerRegion={props.region}
                buyerPhone={props.phone}
                buyerWa={props.whatsapp}
                buyerTg={props.telegram}
                price={props.price}
                title={props.summary}
                type={props.type}
                typeRu={props.text}
                state={props.state}
                vendor={props.vendor}
                model={props.model}
                imgUrl={props.cover}
                buyerFbType={props.buyer_fb_type}
                buyerFbText={props.buyer_fb}
                buyerFbDate={props.buyer_fb_ts}
                myFbType={props.seller_fb_type}
                myFbText={props.seller_fb}
                myFbDate={props.seller_fb_ts}
                feedbackAllowed={props.feedback_allowed}
                exclusiveMode
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isFetching: state.exactSale.isFetching,
        id: state.exactSale.sale.id,
        listing_id: state.exactSale.sale.listing_id,
        buyer_confirm: state.exactSale.sale.buyer_confirm,
        seller_confirm: state.exactSale.sale.seller_confirm,
        sealed: state.exactSale.sale.sealed,
        discarded: state.exactSale.sale.discarded,
        discarded_by: state.exactSale.sale.discarded_by,
        login: state.exactSale.sale.login,
        email: state.exactSale.sale.email,
        city_type: state.exactSale.sale.city_type,
        city: state.exactSale.sale.city,
        region: state.exactSale.sale.region,
        phone: state.exactSale.sale.phone,
        whatsapp: state.exactSale.sale.whatsapp,
        telegram: state.exactSale.sale.telegram,
        price: state.exactSale.sale.price,
        summary: state.exactSale.sale.summary,
        type: state.exactSale.sale.type,
        text: state.exactSale.sale.text,
        state: state.exactSale.sale.state,
        vendor: state.exactSale.sale.vendor,
        model: state.exactSale.sale.model,
        cover: state.exactSale.sale.cover,
        buyer_fb_type: state.exactSale.sale.buyer_fb_type,
        buyer_fb: state.exactSale.sale.buyer_fb,
        buyer_fb_ts: state.exactSale.sale.buyer_fb_ts,
        seller_fb_type: state.exactSale.sale.seller_fb_type,
        seller_fb: state.exactSale.sale.seller_fb,
        seller_fb_ts: state.exactSale.sale.seller_fb_ts,
        feedback_allowed: state.exactSale.sale.feedback_allowed,
        isLoggedIn: state.loginReg.isLoggedIn,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sync: (id) => {
            dispatch(saleSetFetchingAC(true));
            dispatch(fetchExactSale(id));
        },
        showLoginDialog: () => {
            dispatch(showLoginDialogAC());
        },
    }
}

const ExactSaleContainer = connect(mapStateToProps, mapDispatchToProps)(ExactSale);
export default ExactSaleContainer;