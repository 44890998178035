import React from 'react'

const Spinner = () => {
    return (
        <>
            <div className="sk-cube-grid">
                <div className="sk-cube sk-cube1"></div>
                <div className="sk-cube sk-cube2"></div>
                <div className="sk-cube sk-cube3"></div>
                <div className="sk-cube sk-cube4"></div>
                <div className="sk-cube sk-cube5"></div>
                <div className="sk-cube sk-cube6"></div>
                <div className="sk-cube sk-cube7"></div>
                <div className="sk-cube sk-cube8"></div>
                <div className="sk-cube sk-cube9"></div>
            </div>
        </>
    )
}
export default Spinner;

const InvertedSpinner = () => {
    return (
        <>
            <div className="sk-cube-grid">
                <div className="sk-cube-inv sk-cube1"></div>
                <div className="sk-cube-inv sk-cube2"></div>
                <div className="sk-cube-inv sk-cube3"></div>
                <div className="sk-cube-inv sk-cube4"></div>
                <div className="sk-cube-inv sk-cube5-inv"></div>
                <div className="sk-cube-inv sk-cube6"></div>
                <div className="sk-cube-inv sk-cube7"></div>
                <div className="sk-cube-inv sk-cube8"></div>
                <div className="sk-cube-inv sk-cube9"></div>
            </div>
        </>
    )
}
export { InvertedSpinner };