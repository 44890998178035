import { React, useEffect } from 'react';
import { connect } from "react-redux";
import Paginated from '../entities/Paginated';
import SubscriptionCardContainer from '../profile/SubscriptionCard';
import Spinner from '../../components/Spinner';
import { subscriptionsSetFetchingAC, fetchSubscriptions, subscriptionsFetchHwTypes } from '../../redux/my-subscriptions-reducer';

const MySubscriptions = (props) => {
    useEffect(() => {
        props.sync();
    }, []);

    const renderListingRow = (index, value) => {
        return <SubscriptionCardContainer
            id={value.id}
            searchType={value.search_type}
            typeSearchType={value.type_search_type}
            typeSearchTypeRu={value.type_search_type_ru}
            typeSearchModel={value.type_search_model}
            pnSearchPn={value.pn_search_pn}
            pnSearchModel={value.pn_search_model}
            date={value.created}
            updates={value.updates}
            odd={index % 2}
            key={index}
        />
    }

    if (props.isFetching) {
        return <Spinner />
    }

    if (props.subscriptionsTotal === 0) {
        return (
            <>
                <h2>¯\_(ツ)_/¯</h2>
                <h2>Пока у вас нет подписок</h2>
            </>
        )
    }

    return (
        <>
            <Paginated
                pagesize={props.pageSize}
                renderItem={renderListingRow}
                items={props.subscriptions}
            />
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching: state.subscriptionsPage.isFetching,
        subscriptionsTotal: state.subscriptionsPage.subscriptionsTotal,
        subscriptions: state.subscriptionsPage.subscriptions,
        pageSize: ownProps.pageSize ? ownProps.pageSize : 10,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sync: () => {
            dispatch(subscriptionsSetFetchingAC(true));
            dispatch(fetchSubscriptions());
        },
    }
}

const MySubscriptionsContainer = connect(mapStateToProps, mapDispatchToProps)(MySubscriptions);
export default MySubscriptionsContainer;