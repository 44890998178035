import { React } from 'react';
import { connect } from "react-redux";
import Paginated from '../entities/Paginated';
import SearchListingPreviewContainer from '../listings/SearchListingPreview';
import Spinner from '../../components/Spinner';
import Divider from '@mui/material/Divider';

const SubscriptionUpdatesPaginated = (props) => {
    const renderListingRow = (index, value) => {
        return <SearchListingPreviewContainer
            id={value.id}
            submitter={value.login}
            city={value.city}
            region={value.region}
            imgUrl={value.cover}
            title={value.summary}
            date={value.created_at}
            price={value.price}
            odd={index % 2}
            key={index}
        />
    }

    const renderUpdates = () => {
        if (props.myCity === '' || props.myRegion === '') {
            return (
                <>
                    <Paginated
                        pagesize={props.pageSize}
                        renderItem={renderListingRow}
                        items={props.listings}
                    />
                </>
            )
        }
        else { // render sorted with location
            return (
                <>
                    <Divider textAlign="left" sx={{
                        "&::before, &::after": {
                            borderColor: '#262831',
                        },
                        marginBottom: 2,
                    }} >В Вашем городе: {props.inCityListings.length}</Divider>
                    {
                        props.inCityListings.length > 0 &&
                        <Paginated
                            pagesize={props.pageSize}
                            renderItem={renderListingRow}
                            items={props.inCityListings}
                        />
                    }

                    <Divider textAlign="left" sx={{
                        "&::before, &::after": {
                            borderColor: '#262831',
                        },
                        marginBottom: 2,
                    }}>В Вашем регионе: {props.inRegionListings.length}</Divider>
                    {
                        props.inRegionListings.length > 0 &&
                        <Paginated
                            pagesize={props.pageSize}
                            renderItem={renderListingRow}
                            items={props.inRegionListings}
                        />
                    }

                    <Divider textAlign="left" sx={{
                        "&::before, &::after": {
                            borderColor: '#262831',
                        },
                        marginBottom: 2,
                    }}>В других регионах: {props.restListings.length}</Divider>
                    {
                        props.restListings.length > 0 &&
                        <Paginated
                            pagesize={props.pageSize}
                            renderItem={renderListingRow}
                            items={props.restListings}
                        />
                    }
                </>
            )
        }
    }

    if (props.isFetching) {
        return <Spinner />
    }

    if (props.listingsTotal === 0) {
        return (
            <>
                <h2>¯\_(ツ)_/¯</h2>
                <h2>По этой подписке пока нет непросмотренных объявлений</h2>
            </>
        )
    }

    return (
        <>
            <h2>Непросмотренные объявления:</h2>
            {renderUpdates()}
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    const sortPriceAsc = (listings) => {
        return listings.sort(function (a, b) {
            return parseInt(a.price, 10) - parseInt(b.price, 10);
        });
    }
    return {
        isFetching: state.subscriptionUpdates.isFetching,
        listingsTotal: state.subscriptionUpdates.listingsTotal,
        listings: state.subscriptionUpdates.listings,
        pageSize: ownProps.pageSize ? ownProps.pageSize : 10,

        myCity: state.userState.city,
        myRegion: state.userState.region,
        inCityListings: sortPriceAsc(state.subscriptionUpdates.listings.filter(function (item) {
            return item.region === state.userState.region &&
                item.city === state.userState.city;
        })),
        inRegionListings: sortPriceAsc(state.subscriptionUpdates.listings.filter(function (item) {
            return item.region === state.userState.region &&
                item.city !== state.userState.city;
        })),
        restListings: sortPriceAsc(state.subscriptionUpdates.listings.filter(function (item) {
            return item.region !== state.userState.region &&
                item.city !== state.userState.city;
        })),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

const SubscriptionUpdatesPaginatedContainer = connect(mapStateToProps, mapDispatchToProps)(SubscriptionUpdatesPaginated);
export default SubscriptionUpdatesPaginatedContainer;