import fetch from "cross-fetch";


const SUBSCRIPTIONS_SET_FETCHING = 'SUBSCRIPTIONS_SET_FETCHING';
const SUBSCRIPTIONS_DATA_UPDATED = 'SUBSCRIPTIONS_DATA_UPDATED';
const SUBSCRIPTIONS_SET_ERR = 'SUBSCRIPTIONS_SET_ERR';

const LOG_REG_LOGOUT = 'LOG_REG_LOGOUT';

let initialState = {
    isFetching: false,


    //subscriptions data:...
    subscriptionsTotal: 0,
    subscriptions: [
        /* e.g.:
        {
            "id": "11",
            "search_type": "bytype",
            "type_search_model": "UE40F6400AK",
            "type_search_type": "ssb",
            "type_search_type_ru": "...",
            "pn_search_model": null,
            "pn_search_pn": null,
            "created": "2024-05-21 05:13:17"
        }, ... */
    ],

    errorCondition: false,
    errWhat: '',
};

const mySubscriptionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUBSCRIPTIONS_SET_FETCHING:
            return Object.assign({}, state, {
                isFetching: action.isFetching,
            });

        case SUBSCRIPTIONS_DATA_UPDATED:
            return Object.assign({}, state, {
                isFetching: false,
                subscriptions: action.subscriptions,
                subscriptionsTotal: action.subscriptionsTotal,
            });

        case SUBSCRIPTIONS_SET_ERR:
            return Object.assign({}, state, {
                errorCondition: true,
                errWhat: action.what,
                isFetching: false,
            });


        case LOG_REG_LOGOUT:
            return Object.assign({}, state, initialState);

        default:
            return state;
    }
};

export const subscriptionsSetFetchingAC = (isFetching) => ({
    type: SUBSCRIPTIONS_SET_FETCHING,
    isFetching: isFetching
})

export const updatedSubscriptionsData = (data) => ({
    type: SUBSCRIPTIONS_DATA_UPDATED,
    subscriptionsTotal: data.subscriptionsTotal,
    subscriptions: data.subscriptions,
})

export const subscriptionsSetError = (what) => ({
    type: SUBSCRIPTIONS_SET_ERR,
    what: what,
})


export function fetchSubscriptions() {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/mysubscriptions', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(updatedSubscriptionsData(json.data));
                    } else {
                        dispatch(subscriptionsSetError('Что-то пошло не так. Пожалуйста, попробуйте еще раз позднее'));
                    }
                }
            )
    }
}

export function rmSubscription(subscriptionId) {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/listings/unsubscribe', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                subscription_id: subscriptionId,
            })
        })
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if (json.statusCode === 200) {
                        dispatch(fetchSubscriptions());
                    }
                }
            )
    }
}



export default mySubscriptionsReducer;